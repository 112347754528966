/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import component section **/
import DashboardView from './DashboardView';

/** Import helpers section **/
import { getDashboardService } from '../../services/dashboard-services';
import { showErrorMessages } from '../../utils/utils';
import * as moment from 'moment';

const DashboardComponent = () => {
  const [quotationsData, setQuotationsData] = useState([]);
  const [shipmentsData, setShipmentsData] = useState([]);
  const [usersClosed, setUsersClosed] = useState([]);
  const [clientsClosed, setClientsClosed] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: moment(),
    end: moment()
  });

  const getDashboard = useCallback(() => {
    const startDate = dateRange.start;
    if (!startDate) return;
    getDashboardService(startDate)
      .then((response) => {
        // quotations
        const quotationsResponse = response?.quotaions;
        const quotationsArr = [];
        for (const property in quotationsResponse) {
          if (quotationsResponse.hasOwnProperty(property)) {
            const data = {
              label: property,
              value: quotationsResponse[property]
            };
            quotationsArr.push(data);
          }
        }

        // shipments
        const shipmentsResponse = response?.shipments;
        const shipmentsArr = [];
        for (const property in shipmentsResponse) {
          if (shipmentsResponse.hasOwnProperty(property)) {
            const data = {
              label: property,
              value: shipmentsResponse[property]
            };
            shipmentsArr.push(data);
          }
        }
        setQuotationsData(quotationsArr);
        setShipmentsData(shipmentsArr);
        setUsersClosed(response?.users_closed);
        setClientsClosed(response?.clients_closed);
      })
      .catch((error) => {
        showErrorMessages(error);
      });
  }, [dateRange]);

  useEffect(() => {
    if (!dateRange.start) return;
    getDashboard();
  }, [dateRange.start, getDashboard]);

  const handleDateChange = (event) => {
    if (event !== null) {
      let { value, name } = event;
      setDateRange((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  return (
    <DashboardView
      dateRange={dateRange}
      usersClosed={usersClosed}
      shipmentsData={shipmentsData}
      clientsClosed={clientsClosed}
      quotationsData={quotationsData}
      handleDateChange={handleDateChange}
    />
  );
};

export default DashboardComponent;
