/* eslint-disable react-hooks/exhaustive-deps */

/** Import react/libraries section **/
import React, { Fragment, useEffect, useState } from 'react';

/** Import component section **/
import UnitsView from './UnitsView';

/** Import helpers section **/
import { t as typy } from 'typy';
import UnitAddModalComponent from './UnitsModal/UnitAddModalComponent';
import {
	deleteUnitService,
	getUnitsService
} from '../../services/units-services';
import { toast } from 'react-toastify';
import ModalComponent from '../../components/UI/ModalComponent';
import { showErrorMessages } from '../../utils/utils';

/** Import resources section **/

const UnitsComponent = () => {
	const [loadingData, setLoadingData] = useState(false);
	const [originalData, setOriginalData] = useState([]);
	const [units, setUnits] = useState([]);

	const [modalMode, setModalMode] = useState('');
	const [unitId, setUnitId] = useState(null);
	const [showAlert, setShowAlert] = useState(false);
	const [del, setDel] = useState(false);
	const [selectedUnit, setSelectedUnit] = useState(null);

	const getData = () => {
		const token = '';
		setLoadingData(true);
		getUnitsService({ token })
			.then((units) => {
				const unitsArr = typy(units).safeArray;
				setOriginalData(unitsArr);
				setUnits(unitsArr);
			})
			.finally(() => {
				setLoadingData(false);
			});
	};

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (del === true) {
			setLoadingData(false);
			deleteUnitService(unitId)
				.then(() => {
					getData();
					toast('Unidad eliminada', { type: 'success' });
					setLoadingData(false);
					setUnitId(null);
					setDel(false);
					setShowAlert(false);
				})
				.catch((error) => {
					// console.error( 'UnitsComponent - Error deleting: ', error );
					// toast( 'Error al eliminar unidad', {type: 'error'} );
					showErrorMessages(error);
				});
		}
	}, [del]);

	const handleOnFilterBy = (filterText) => {
		let filteredItems;

		if (filterText.length === 0) {
			filteredItems = [...originalData];
		} else {
			const filteringText = filterText.toLowerCase();

			filteredItems = originalData.filter((item) => {
				const field1 = item.name?.toString().toLowerCase();
				const field2 = item.load?.toString();
				const field3 = item.long?.toString();
				const field4 = item.width?.toString();
				const field5 = item.height?.toString();
				const field6 = item.volume?.toString();
				const field7 = item.capacity_pallet_simple_bed?.toString();
				const field8 = item.capacity_pallet_double_bed?.toString();

				return (
					field1?.includes(filteringText) ||
					field2?.includes(filteringText) ||
					field3?.includes(filteringText) ||
					field4?.includes(filteringText) ||
					field5?.includes(filteringText) ||
					field6?.includes(filteringText) ||
					field7?.includes(filteringText) ||
					(field8.includes(filteringText) && true)
				);
			});
		}

		setUnits(filteredItems);
	};

	const handleAddUnit = () => setModalMode('add');

	const onEditUnit = (unitId) => {
		setModalMode('edit');
		setUnitId(unitId);
	};

	const onClose = () => setModalMode('');

	const onCloseAlert = () => {
		setShowAlert(false);
		setUnitId(null);
	};

	const onConfirm = () => {
		setDel(true);
	};

	const onDeleteUnit = (unitId) => {
		setShowAlert(true);
		let selectedUnit = units.filter((x) => x.id === unitId);
		setSelectedUnit(selectedUnit[0]);
		setUnitId(unitId);
	};

	const pushNewUnitToList = (unit) => {
		setUnits((prevState) => [
			...prevState.slice(0, units.length),
			unit,
			...prevState.slice(units.length + 1)
		]);
	};

	/**
	 * We update in current units list, new data returned from update.
	 * @param unitUpdated
	 */
	const updateUnitOnList = (unitUpdated) => {
		for (let u in units) {
			if (units[u].id === unitUpdated.id) {
				let newUnits = [...units];
				newUnits[u] = unitUpdated;
				setUnits(newUnits);
			}
		}
	};

	return (
		<Fragment>
			<UnitsView
				units={units}
				onEditUnit={onEditUnit}
				onDeleteUnit={onDeleteUnit}
				loading={loadingData}
				filteringHandler={handleOnFilterBy}
				handleAddUnit={handleAddUnit}
			/>

			{modalMode === 'add' || modalMode === 'edit' ? (
				<UnitAddModalComponent
					unitId={unitId}
					onClose={onClose}
					modalMode={modalMode}
					selectedUnit={selectedUnit}
					pushNewUnitToList={pushNewUnitToList}
					updateUnitOnList={updateUnitOnList}
				/>
			) : null}

			{showAlert === true ? (
				<ModalComponent
					open={showAlert}
					onClose={onCloseAlert}
					onConfirm={onConfirm}
					title={'Borrar unidad: ' + selectedUnit.name}
				>
					{'¿Esta seguro que desea borrar la unidad ?'}
				</ModalComponent>
			) : null}
		</Fragment>
	);
};

export default UnitsComponent;
