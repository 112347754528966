/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import services section **/
import { locationDataService } from '../../../services/location-services';

/** Import resources section **/

/** Import component section **/
import LocationSelectView from './LocationSelectView';

const LocationSelectComponent = ({ locationData, onLoad = () => {} }) => {
  const [location, setLocation] = useState(locationData);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [lastPostalCode, setLastPostalCode] = useState(null);

  const getData = useCallback(
    (cpValue) => {
      const cp = cpValue || location?.postal_code;

      locationDataService({ cp })
        .then((response) => {
          if (response) {
            const responseNeighborhoods = response?.neighborhoods || [];
            var neighborhoodId = null;
            var neighborhoodName = '';

            if (responseNeighborhoods.length > 0) {
              // localiza la colonia recibida en el arreglo
              if (locationData?.neighborhood_id) {
                const found = responseNeighborhoods?.find(
                  (item) => item?.id === locationData?.neighborhood_id
                );
                if (found) {
                  neighborhoodId = found?.id;
                  neighborhoodName = found?.name;
                }
              }
              // si no se recibió y solo es una colonia esa se toma

              if (!neighborhoodId && responseNeighborhoods.length === 1) {
                neighborhoodId = responseNeighborhoods[0]?.id;
                neighborhoodName = responseNeighborhoods[0]?.name;
              }
            }

            const strPostalCode =
              response.postal_code.toString().length === 4 // si php retorna un CP sin 0 al principio lo agregamos
                ? '0' + response?.postal_code
                : response?.postal_code;

            setNeighborhoods(responseNeighborhoods);
            setLocation((prevState) => ({
              ...prevState,
              valid: true,
              postal_code: strPostalCode,
              state_id: response?.state_id,
              state_name: response?.state_name,
              municipality_id: response?.municipality_id,
              municipality_name: response?.municipality_name,
              city_id: response?.city_id,
              city_name: response?.city_name,
              neighborhood_id: neighborhoodId,
              neighborhood_name: neighborhoodName,
              street: cpValue?.street ? cpValue?.street : '',
              number: cpValue?.number ? cpValue?.number : '',
              internal_number: cpValue?.internal_number
                ? cpValue?.internal_number
                : '',
              reference_streets: cpValue?.reference_streets
                ? cpValue?.reference_streets
                : ''
            }));
          }
        })
        .catch((errorResponse) => {
          clearLocation();
          const status = errorResponse?.response_status;
          switch (status) {
            case 406:
              toast.error('No se encontró el código postal');
              break;
            default:
              toast.error('Error al buscar el código postal');
              break;
          }
          console.error(
            'location -> Error requesting location data: ',
            errorResponse
          );
        });
    },
    [location?.postal_code, locationData?.neighborhood_id]
  );

  const handleOnLoadLocationData = (value) => {
    if (!!value) getData(value);
  };

  const handleOnInputChange = (data) => {
    let { name, value } = data;
    setLocation((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    if (
      lastPostalCode !== locationData?.postal_code &&
      !!locationData?.postal_code &&
      locationData?.postal_code?.toString()?.length === 5
    ) {
      setLastPostalCode(locationData?.postal_code);
      getData(locationData);
    }
  }, [locationData?.postal_code, locationData, lastPostalCode, getData]);

  useEffect(() => {
    onLoad(location);
  }, [location, onLoad]);

  const clearLocation = (data) => {
    setLocation((prevState) => ({
      ...prevState,
      valid: false,
      state_id: null,
      state_name: '',
      municipality_id: null,
      municipality_name: '',
      city_id: null,
      city_name: '',
      neighborhood_id: null,
      neighborhood_name: '',
      neighborhoods: []
    }));
  };

  return (
    <LocationSelectView
      neighborhoods={neighborhoods}
      location={location}
      onInputChange={handleOnInputChange}
      onLoadLocationData={handleOnLoadLocationData}
    />
  );
};

LocationSelectComponent.propTypes = {
  locationData: PropTypes.exact({
    postal_code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    state_id: PropTypes.number,
    state_name: PropTypes.string,
    municipality_id: PropTypes.number,
    municipality_name: PropTypes.string,
    city_id: PropTypes.number,
    city_name: PropTypes.string,
    neighborhood_id: PropTypes.number,
    neighborhood_name: PropTypes.string,
    street: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    internal_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reference_streets: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  onLoad: PropTypes.func
};

export default LocationSelectComponent;
