/** Import react/libraries section **/
import React from 'react';
import PropTypes from 'prop-types';

/** Import component section **/
import AddButton from '../../UI/AddButton';
import PackagingComponent from '../Packaging';
import MessagingDetail from './detailforms/MessagingDetail';
import DedicatedDetail from './detailforms/DedicatedDetail';

/** Import resources section **/
import './ShipDetailsStyles.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import CardHeader from '@material-ui/core/CardHeader';
import {ButtonBase, Card, Tooltip} from '@material-ui/core';
import EraseIcon from '../../../assets/icons/erase.svg';

const ShipDetailsView = ({
	                         modeType,
	                         products,
	                         containers,
	                         onClearForm,
	                         onAddNewPackage,
	                         onDeletePackage,
	                         deliveryTypeData,
	                         onContainerChange,
	                         onServiceDetailsChange,
	                         onContainerSelectChange,
                         }) => {
	const composeServices = () => {
		switch (deliveryTypeData.shipment_type_id) {
			case 1:
				return deliveryTypeData?.containers?.map( (service, index) => (
					<Card className="packages-form">
						<CardHeader
							className="packages-form_header"
							action={
								<>
									{
										index >= 1 ?
											<Tooltip title="Eliminar paquete">
												<ButtonBase id="deleteButton">
													<DeleteIcon
														className="g-row-action-button delete"
														onClick={event => onDeletePackage( event, index )}
													/>
												</ButtonBase>
											</Tooltip>
											: null
									}
									<Tooltip title="Limpiar formulario">
										<ButtonBase onClick={() => onClearForm( index )}>
											<img src={EraseIcon} alt="borrar" className="g-row-action-button complementary"/>
										</ButtonBase>
									</Tooltip>
								</>
							}
						/>
						<MessagingDetail
							index={index}
							containers={containers}
							deliveryTypeData={service}
							onDeletePackage={onDeletePackage}
							onContainerChange={(e) => onContainerChange( e, index )}
							onContainerSelectChange={onContainerSelectChange}
							onServiceDetailsChange={(e) => onServiceDetailsChange( e, index )}
						/>
					</Card>
				
				) );
			case 2:
			case 3:
				return deliveryTypeData?.containers?.map( (service, index) => {
					return <Card className="packages-form">
						<CardHeader
							className="packages-form_header"
							action={
								<>
									{
										index >= 1 ?
											<Tooltip title="Eliminar paquete">
												<ButtonBase id="deleteButton">
													<DeleteIcon
														className="g-row-action-button delete"
														onClick={event => onDeletePackage( event, index )}
													/>
												</ButtonBase>
											</Tooltip>
											: null
									}
									<Tooltip title="Limpiar formulario">
										<ButtonBase onClick={() => onClearForm( index )}>
											<img src={EraseIcon} alt="borrar" className="g-row-action-button complementary"/>
										</ButtonBase>
									</Tooltip>
								</>
							}
						/>
						<PackagingComponent
							index={index}
							service={service}
							modeType={modeType}
						/>
					</Card>;
					// }
				} );
			case 4:
				return deliveryTypeData?.containers?.map( (service, index) => (
					<Card className="packages-form">
						<CardHeader
							className="packages-form_header"
							action={
								<>
									{
										index >= 1 ?
											<Tooltip title="Eliminar paquete">
												<ButtonBase id="deleteButton">
													<DeleteIcon
														className="g-row-action-button delete"
														onClick={event => onDeletePackage( event, index )}
													/>
												</ButtonBase>
											</Tooltip>
											: null
									}
									<Tooltip title="Limpiar formulario">
										<ButtonBase onClick={() => onClearForm( index )}>
											<img src={EraseIcon} alt="borrar" className="g-row-action-button complementary"/>
										</ButtonBase>
									</Tooltip>
								</>
							}
						/>
						<DedicatedDetail
							index={index}
							products={products}
							containers={containers}
							deliveryTypeData={service}
							onDeletePackage={onDeletePackage}
							onContainerChange={(e) => onContainerChange( e, index )}
							onContainerSelectChange={(e) => onContainerSelectChange( e, index )}
							onServiceDetailsChange={(e) => onServiceDetailsChange( e, index )}
						/>
					</Card>
				) );
			default:
				return null;
		}
	};
	
	return (
		<div className="packages">
			<div className="packages_list">
				<p>Total de paquetes: {deliveryTypeData.containers.length}</p>
				{
					composeServices()
				}
			</div>
			<AddButton className="login_form_button" onClick={onAddNewPackage}>
				Agregar servicio
			</AddButton>
		</div>
	);
	
	
};

ShipDetailsView.propTypes = {
	containers: PropTypes.array.isRequired,
	modeType: PropTypes.string.isRequired,
	deliveryTypeData: PropTypes.object.isRequired,
	onContainerChange: PropTypes.func.isRequired,
	onServiceDetailsChange: PropTypes.func.isRequired,
	onContainerSelectChange: PropTypes.func.isRequired,
};

export default ShipDetailsView;
