/** Import react/libraries section **/
import React from 'react';
import { PropTypes } from 'prop-types';

/** Import helpers section **/
import {
  ORIGIN,
  DESTINATION
} from '../../../components/ContactSearch/contactConstants';

/** Import resources section **/

/** Import styles section **/
import './QuoteGeneralStyles.scss';

/** Import component section **/
import ClientForm from '../../../components/forms/ClientForm';
import ContactForm from '../../../components/forms/ContactForm';
// import OriginForm from '../../../components/forms/OriginForm';
// import DestinationForm from '../../../components/forms/DestinationForm';
import PickupForm from '../../../components/forms/PickupForm';
import DeliveryTypeForm from '../../../components/forms/DeliveryTypeForm';
import BillingForm from '../../../components/forms/BillingForm';

const QuoteGeneralView = ({ hasCatalogs }) => {
  return (
    <>
      {hasCatalogs === true ? (
        <div className="quoteGeneral">
          <div className="quoteGeneral_column-1">
            <div className="quote_card">
              <ClientForm search />
            </div>

            <div className="quote_card">
              <BillingForm />
            </div>
          </div>

          <div className="quoteGeneral_column-2">
            <div className="quote_card">
              <ContactForm mode={ORIGIN} />
              {/*<OriginForm search />*/}
            </div>
            <div className="quote_card">
              <PickupForm />
            </div>
          </div>

          <div className="quoteGeneral_column-3">
            <div className="quote_card">
              <ContactForm mode={DESTINATION} />
              {/*<DestinationForm search />*/}
            </div>
            <div className="quote_card">
              <DeliveryTypeForm />
            </div>
          </div>
        </div>
      ) : (
        <h3>Cargando...</h3>
      )}
    </>
  );
};

QuoteGeneralView.propTypes = {
  hasCatalogs: PropTypes.bool.isRequired
};

export default QuoteGeneralView;
