import { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API;
const ADN_URL = process.env.REACT_APP_API_ADN;

const DEFAULT_ERROR_RESPONSE = { response_status: 0 };

export default class ApiService extends Component {
  // *** request *** //
  // *** Función para realizar una petición al server *** //
  // *** Recibe un string con el código definido de una servicio y un objeto de parámetros *** //
  // *** Regresa un objeto con la respuesta exitosa o fallida *** //
  // *** En caso de respuesta exitosa {data: <objeto de información>} *** //
  // *** En caso de respuesta fallida {response_status: <entero de status>} *** //

  static async request({ url, method, params, externalServices = false }) {
    const BASE_URL = externalServices ? ADN_URL : API_URL;

    const apiProvider = axios.create({
      baseURL: BASE_URL,
      crossDomain: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      }
    });

    const token = localStorage.getItem('token');
    if (token) {
      apiProvider.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    const requestParams = params ? params : {};

    if (method === 'file') {
      apiProvider.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    }

    if (method === 'get') {
      //get
      let response = apiProvider
        .get(BASE_URL + url, {
          params: requestParams
        })
        .then((res) => ApiService.requestSuccess(res))
        .catch((err) => ApiService.requestFailure(err));
      return response;
    } else {
      //post
      let response = apiProvider
        .post(BASE_URL + url, params)
        .then((res) => ApiService.requestSuccess(res))
        .catch((err) => ApiService.requestFailure(err));
      return response;
    }
  }

  // Métodos de respuesta

  static requestSuccess(res) {
    if (res.hasOwnProperty('data') && res.data.hasOwnProperty('data')) {
      return Promise.resolve(res.data.data);
    }
    return Promise.reject(DEFAULT_ERROR_RESPONSE);
  }

  static requestFailure(err) {
    let listOfErrors = err?.response?.data?.errors;
    if (
      err?.response?.status === 401 ||
      err?.response?.statusText === 'Unauthorized'
    ) {
      toast('La sesion ha expirado', { type: 'warning' });
      localStorage.removeItem('token');
      window.location.history.push('/');
    }

    if (typeof err !== 'object') {
      return Promise.reject(DEFAULT_ERROR_RESPONSE);
    }

    if (!err.hasOwnProperty('response') || !err.response) {
      return Promise.reject(DEFAULT_ERROR_RESPONSE);
    }

    if (!err.response.hasOwnProperty('status') || !err.response.status) {
      return Promise.reject(DEFAULT_ERROR_RESPONSE);
    }

    const STATUS = err.response.status;

    return Promise.reject({ response_status: STATUS, errors: listOfErrors }); // regresa el status y el listado de errores
  }
}
