/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import helpers section **/
import { showErrorMessages } from '../../../../../utils/utils';

/** Import services section **/
import {
  addGoodsService,
  updateGoodService
} from '../../../../../services/goods-services';

/** Import component section **/
import GoodsModalView from './GoodsModalView';

export const GoodsModalComponent = ({
  onClose,
  good,
  shipmentId,
  onUpdateList
}) => {
  const [goods, setConsigmentNote] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);

  const onInputChange = (data) => {
    const { value, name } = data;
    setConsigmentNote((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    setConsigmentNote(good);
  }, [good]);

  const handleOnSave = () => {
    if (good?.id) {
      updateBusinessBilling();
    } else {
      createBusinessBilling();
    }
  };

  useEffect(() => {
    if (!goods) return;
    let validated = true;
    const dangerousMaterial = goods?.dangerous_material;
    const quantity = goods?.quantity;
    const code = goods?.code;
    const shipment_id = shipmentId;
    const unit_code = goods?.unit_code;
    const weigth = goods?.weigth;
    const dangerous_material_code = goods?.dangerous_material_code;
    const packing_code = goods?.packing_code;

    if (
      quantity === 0 ||
      shipment_id === null ||
      code === '' ||
      unit_code === '' ||
      weigth === 0
    ) {
      validated = false;
    }
    if (
      dangerousMaterial === true &&
      (packing_code === '' || dangerous_material_code === '')
    ) {
      validated = false;
    }

    setDataComplete(validated);
    if (dangerousMaterial === false) {
      goods.packing_code = null;
      goods.dangerous_material_code = null;
    }
  }, [goods, shipmentId]);

  const createBusinessBilling = () => {
    if (!shipmentId) {
      return;
    }
    let params = {
      quantity: goods?.quantity,
      description: goods?.description,
      code: goods?.code,
      shipment_id: shipmentId,
      unit_code: goods?.unit_code,
      weigth: goods?.weigth,
      value: goods?.value,
      currency: goods?.currency,
      dangerous_material: goods?.dangerous_material,
      dangerous_material_code: goods?.dangerous_material_code,
      packing_code: goods?.packing_code
    };

    addGoodsService(params)
      .then((response) => {
        toast('Bien transportado agregado', { type: 'success' });
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast('Error al egregar bienes', { type: 'error' });
        showErrorMessages(error);
      });
  };

  const updateBusinessBilling = () => {
    let params = {
      id: goods?.id,
      quantity: goods?.quantity,
      description: goods?.description,
      code: goods?.code,
      shipment_id: shipmentId,
      unit_code: goods?.unit_code,
      weigth: goods?.weigth,
      value: goods?.value,
      currency: goods?.currency,
      dangerous_material: goods?.dangerous_material,
      dangerous_material_code: goods?.dangerous_material_code,
      packing_code: goods?.packing_code
    };
    updateGoodService(params)
      .then((response) => {
        toast('Carta porte actualizada', { type: 'success' });
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast('Error al actualizar', { type: 'error' });
        showErrorMessages(error);
      });
  };

  const handleOnSwitchChange = (event) => {
    let { name, checked } = event.target;
    setConsigmentNote((prevState) => ({
      ...prevState,
      [name]: checked
    }));
  };

  return (
    <GoodsModalView
      goods={goods}
      onSave={handleOnSave}
      onClose={onClose}
      onInputChange={onInputChange}
      onSwitchChange={handleOnSwitchChange}
      disabled={!dataComplete}
    />
  );
};

export default GoodsModalComponent;
