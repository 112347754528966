/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import DashboardScoreView from './DashboardScoreView';

/** Import helpers section **/

const DashboardScoreComponent = ({ label, data }) => {
  return <DashboardScoreView data={data} label={label} />;
};

export default DashboardScoreComponent;
