import ApiService from './api-service';


/**
 * Request list of authorizations.
 * @returns {Promise}
 */
export const getAuthorizationsService: function = () => {
	const serviceDef = {
		url: 'shipment/request-credit-approval',
		method: 'get',
		params: null
	};
	return ApiService.request(serviceDef);
};

/**
 * Approve credit.
 * @returns {Promise<*>}
 */
export const approveCreditService: function = (shipmentID) => {
	const serviceDef = {
		url: 'shipment/approve-credit',
		method: 'post',
		params: { id: shipmentID }
	};
	return ApiService.request(serviceDef);
};

/**
 * Disapprove a credit for given shipment
 * @param shipmentID
 * @returns {Promise<*>}
 */
export const disapproveCreditService: function = (shipmentID) => {
	const serviceDef = {
		url: 'shipment/disapprove-credit',
		method: 'post',
		params: { id: shipmentID }
	};
	return ApiService.request(serviceDef);
};
