import React, { forwardRef, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cuid from 'cuid';

import * as ROUTES from '../../URL-Routes';

import './MainMenu.scss';

import List from '@material-ui/core/List';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import ListItem from '@material-ui/core/ListItem';

import AssessmentIcon from '@material-ui/icons/Assessment';
import SendIcon from '@material-ui/icons/Send';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ContactsIcon from '@material-ui/icons/Contacts';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import ListAltIcon from '@material-ui/icons/ListAlt';
import DirectionsIcon from '@material-ui/icons/Directions';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import PersonIcon from '@material-ui/icons/Person';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoneyIcon from '@material-ui/icons/Money';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ReportIcon from '@material-ui/icons/Report';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import ArchiveIcon from '@material-ui/icons/Archive';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Tooltip from '@material-ui/core/Tooltip';
import MenuAccess from '../../access/MenuAccess';
import { getMenuPermissionObject } from '../../access/menu_permissions';

const CustomIconToggle = forwardRef(({ eventKey }, ref) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div className="menu-list-item-expand-icon" ref={ref}>
      {isCurrentEventKey && <ExpandMoreIcon className="menu-option-icon" />}
      {!isCurrentEventKey && <ChevronRightIcon className="menu-option-icon" />}
    </div>
  );
});

function MainMenu({ open, handleOnMenuToggle }) {
  let location = useLocation();

  const MenuItem = forwardRef(({ option, eventKey, sub }, ref) => {
    const suffix = sub ? ' subitem' : '';
    const isSelected = location.pathname === option.url;

    return (
      <div
        ref={ref}
        className={'menu-item ' + suffix + (isSelected ? ' selected' : '')}
      >
        <Link to={option.url?.length > 0 ? option.url : '#'}>
          <ListItem
            className={'menu-list-item' + suffix}
            to={option.url ? option.url : location}
          >
            <Tooltip title={option.label} placement="right-end">
              <div className="menu-list-item-container">
                <option.icon
                  className={
                    'menu-list-item-icon' +
                    suffix +
                    (isSelected ? ' selected' : '')
                  }
                />

                {open && (
                  <div className={'menu-list-item-text' + suffix}>
                    {option.label}
                  </div>
                )}
              </div>
            </Tooltip>
            {open && option?.childs && <CustomIconToggle eventKey={eventKey} />}
          </ListItem>
        </Link>

        <div className="menu-divider" />
      </div>
    );
  });

  /**
   * Here we receive a menu item, we get items allowed roles and pass it as property to MenuAccess hook,
   * it returns child passed or null if no one permission is allowed for logged user profile.
   * @param item
   * @param index
   * @returns {JSX.Element}
   */
  const composeItems = (item, index = false) => {
    let itemPermissions = getMenuPermissionObject(item.id);
    return (
      <MenuAccess allowedPermissions={itemPermissions} id={item.id}>
        <MenuItem key={cuid()} option={item} eventKey={index} sub={false} />
      </MenuAccess>
    );
  };

  return (
    <div className="menu-container">
      <div className="menu-header">
        {open && <div className="menu-label">MENU</div>}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleOnMenuToggle}
          edge="start"
          className={'menu-button' + (open ? ' hide' : '')}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <div className="menu-divider" />

      <List className="menu-list">
        <Accordion defaultActiveKey={0}>
          {menuItems.map((item, index) => (
            <div key={cuid()}>
              {!item.hasOwnProperty('childs') && composeItems(item, index)}
              {item.hasOwnProperty('childs') && item.childs.length > 0 && (
                <>
                  <Accordion.Toggle as="div" eventKey={index}>
                    {composeItems(item, index)}
                  </Accordion.Toggle>

                  <Accordion.Collapse id={index} eventKey={index}>
                    <>
                      {item.childs.map((subitem) => (
                        <MenuItem key={cuid()} option={subitem} sub={true} />
                      ))}
                    </>
                  </Accordion.Collapse>
                </>
              )}
            </div>
          ))}
        </Accordion>
      </List>
    </div>
  );
}

export default MainMenu;

const menuItems = [
  {
    label: 'Tablero',
    id: 'tablero',
    url: ROUTES.DASHBOARD_ROUTE,
    icon: AssessmentIcon
  },
  {
    label: 'Solicitudes',
    id: 'solicitudes',
    url: ROUTES.REQUESTS_LIST_ROUTE,
    icon: MarkunreadMailboxIcon
  },
  {
    label: 'Cotizaciones',
    id: 'cotizaciones',
    url: ROUTES.QUOTES_ROUTE,
    icon: AssignmentIcon
  },
  {
    label: 'Envíos',
    id: 'envios',
    url: ROUTES.SHIPMENTS_ROUTE,
    icon: SendIcon
  },
  {
    label: 'Clientes',
    id: 'clientes',
    url: ROUTES.CLIENTS_ROUTE,
    icon: AccountBoxIcon
  },
  {
    label: 'Contactos',
    id: 'contacts',
    url: ROUTES.CONTACTS_ROUTE,
    icon: ContactsIcon
  },
  {
    label: 'Convenios',
    id: 'convenios',
    url: ROUTES.CLIENT_AGREEMENTS,
    icon: BusinessCenterIcon
  },
  {
    label: 'Precios',
    id: 'precios',
    icon: MonetizationOnIcon,
    url: '',
    childs: [
      {
        label: 'Productos',
        id: 'productos',
        url: ROUTES.PRODUCTS_PRICES_ROUTE,
        icon: ArrowForwardIcon
      },
      {
        label: 'Viajes dedicados',
        id: 'viajes_dedicados',
        url: ROUTES.DEDICATED_PRICES_ROUTE,
        icon: ArrowForwardIcon
      },
      {
        label: 'Costos extras',
        id: 'costos_extras',
        url: ROUTES.EXTRA_COSTS_ROUTE,
        icon: ArrowForwardIcon
      }
    ]
  },
  {
    label: 'Catálogos',
    id: 'catalogos',
    icon: ListAltIcon,
    url: '',
    childs: [
      {
        label: 'Rutas',
        id: 'rutas',
        url: ROUTES.ROUTES_ROUTE,
        icon: DirectionsIcon
      },
      {
        label: 'Cobertura',
        id: 'cobertura_ms',
        url: ROUTES.CITIES_ROUTE,
        icon: LocationCityIcon
      },
      {
        label: 'Proveedores',
        id: 'proveedores',
        url: ROUTES.PROVIDERS_ROUTE,
        icon: TransferWithinAStationIcon
      },
      {
        label: 'Unidades',
        id: 'unidades',
        url: ROUTES.UNITS_ROUTE,
        icon: LocalShippingIcon
      },
      {
        label: 'Empaques',
        id: 'empaques',
        url: ROUTES.CONTAINERS_TYPES_ROUTE,
        icon: ArchiveIcon
      }
    ]
  },
  {
    label: 'Finanzas',
    id: 'finanzas',
    icon: MoneyIcon,
    url: '',
    childs: [
      {
        label: 'Autorizaciones',
        id: 'autorizaciones',
        url: ROUTES.AUTHORIZATIONS_ROUTE,
        icon: ConfirmationNumberIcon
      },
      {
        label: 'Soporte de facturación',
        id: 'soporte_facturacion',
        url: ROUTES.BILLING_ROUTE,
        icon: MoneyIcon
      },
      {
        label: 'Facturación',
        id: 'facturas',
        url: ROUTES.MANIFESTS_ROTE,
        icon: AllInboxIcon
      },
      {
        label: 'Cobranza',
        id: 'cobranza',
        url: ROUTES.PAYMENTS_ROUTE,
        icon: MonetizationOnIcon
      },
      {
        label: 'Empresas de facturacion',
        id: 'empresas_facturacion',
        url: ROUTES.BUSINESS_BILLING,
        icon: FileCopyIcon
      }
    ]
  },
  {
    label: 'Reportes',
    id: 'reportes',
    icon: ReportIcon,
    url: '',
    childs: [
      {
        label: 'Envios',
        id: 'reporte_envios',
        url: ROUTES.SHIPMENT_REPORTS_ROUTE,
        icon: LocalShippingIcon,
        key: 'report_envios'
      },
      {
        label: 'Cotizaciones',
        id: 'reporte_cotizaciones',
        url: ROUTES.QUOTES_REPORTS_ROUTE,
        icon: ListAltIcon,
        key: 'report_quotes'
      },
      {
        label: 'CxC CxP',
        id: 'reporte_cuentas_por_cobrar',
        url: ROUTES.RECEIVABLES_REPORTS_ROUTE,
        icon: PeopleAltIcon,
        key: 'report_receivables'
      }
    ]
  },
  {
    label: 'Usuarios',
    id: 'usuarios',
    url: ROUTES.USERS_ROUTE,
    icon: PersonIcon
  }
];
