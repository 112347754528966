/** Import react/libraries section **/
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

/** Import component section **/
import ShipmentTypeView from './ShipmentTypeView';

/** Import helpers section **/
import {
	setAllServices,
	setRequirements,
	updateDeliveryType,
	updateServiceType,
} from '../../../redux/quotation/quoteSlice';
import {getAdditionalServicesByShipmentId} from '../../../services/catalogues-services';
import {getEspecialRequirements} from '../../../services/quotes-services';
import useCatalogs from '../../../hooks/useCatalogs';

const ShipmentTypeComponent = () => {
	useCatalogs();
	
	let dispatch = useDispatch();
	
	let shipmentTypes = useSelector( (store) => store.catalogs.shipmentTypes );
	let deliveryTypeData = useSelector( (store) => store.quote.quotation.delivery_type );
	let specialRequirements = useSelector( (store) => store.quote.quotation.special_requirements );
	let isShipment = useSelector( (store) => store.quote?.quotation?.is_shipment );
	
	const requestEspecialRequirements = (shipmentId = 4) => {
		getEspecialRequirements( shipmentId ).then( (response) => {
			let requirements = [];
			
			response.forEach( (req) => {
				let newReq = {
					id: req.id,
					title: req.title,
					selected: 0,
				};
				specialRequirements.forEach( (selReq) => {
					if (req.id === selReq.id) {
						newReq.selected = selReq.selected;
					}
				} );
				
				requirements.push( newReq );
			} );
			dispatch( setRequirements( {requirements: requirements} ) );
		} ).catch( (error) => {
			console.error( 'QuoteServicesComponent - Error fetching especial reqs : ', error );
		} );
	};
	
	const onShipmentChange = (event) => {
		let shipmentId = parseInt( event.target.value );
		dispatch( setAllServices( {services: []} ) );
		dispatch( updateServiceType( {field: 'shipment_type_id', value: shipmentId} ) );
		
		// Now we update the shipment_type_id for each container in array.
		deliveryTypeData.containers.forEach( (_, index) => {
			dispatch( updateDeliveryType( {
				index: index,
				field: 'shipment_type_id',
				value: shipmentId,
			} ) );
		} );
		
		
		getAdditionalServicesByShipmentId( shipmentId ).then( (response) => {
			dispatch( setAllServices( {services: response} ) );
		} ).catch( (error) => {
			console.error( 'QuoteServicesComponent - Error fetching services: ', error );
		} );
		
		if (shipmentId === 4 || shipmentId === 3) {
			requestEspecialRequirements( 4 );
		}
	};
	
	return <ShipmentTypeView
		isShipment={isShipment}
		shipmentTypes={shipmentTypes}
		deliveryTypeData={deliveryTypeData}
		onShipmentChange={onShipmentChange}
	
	/>;
};

export default ShipmentTypeComponent;

