import React from 'react';

import { Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

export const AddButton = ({ onClick, text = 'Agregar', disabled }) => {
  return (
    <Button
      data-cy="add-button"
      startIcon={<AddCircleIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default AddButton;
