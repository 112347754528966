/*********************************************************************
 *  PERMISSIONS ENUMERATE     ****************************************
 *********************************************************************/

export const EDIT_CLIENT_PERMISSION = 1;
export const ASSIGN_CLIENT_PERMISSION = 2;
export const DELETE_CLIENT_PERMISSION = 3;
export const ASSIGN_QUOTE_PERMISSION = 4;
export const DELETE_QUOTE_PERMISSION = 5;
export const DISCOUNT_PERMISSION = 6;
export const EDIT_CONTACT_PERMISSION = 7;
export const DELETE_CONTACT_PERMISSION = 8;
