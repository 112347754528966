/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUITextField from '../../../components/UI/inputs/MUITextField';

/** Import resources section **/
import './AgreementPriceModalStyles.scss';

const AgreementPriceModalView = ({
  agreementPrice,
  onInputChange,
  onClose,
  onSave
}) => {
  return (
    <ModalComponent
      open={true}
      title="Edición de precio de convenio"
      width={400}
      onClose={onClose}
      onConfirm={onSave}
    >
      <div className="agreement_price_modal">
        <MUITextField
          id="price"
          label="Precio"
          value={agreementPrice.price}
          type="number"
          name="price"
          onChange={onInputChange}
        />

        <MUITextField
          id="extra_kilogram_price"
          label="Precio Kg. Excedente"
          value={agreementPrice.extra_kilogram_price}
          type="number"
          name="extra_kilogram_price"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default AgreementPriceModalView;
