/** Import react/libraries section **/
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';

/** Import resources section **/

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import components section **/
import EditFieldModal from '../../components/UI/EditFieldModal';
import ModalComponent from '../../components/UI/ModalComponent';
import PostalCodesView from './PostalCodesView';
import LoadFileModal from './LoadFileModal';

import {
  addExtendedPostalCodeByCityService,
  addExtendedPostalCodeByMunicipalityService,
  deleteExtendedPostalCodeByMunicipalityService,
  deletePostalCodeService,
  getExtendedPostalCodesByCityService,
  getExtendedPostalCodesByMunicipalityService,
  updatePostalCodeByCityService,
  updatePostalCodeByMunicipalityService,
  changeCoverageMunicipalityService
} from '../../services/coverage-services';
import { showErrorMessages } from '../../utils/utils';

const PostalCodesComponent = () => {
  const pageParams = useParams();
  const location = useLocation();

  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [modalMode, setModalMode] = useState('');

  const locationId = pageParams.id ? parseInt(pageParams.id) : null;
  const locationMode = location.pathname.split('/')[2];

  const getCityData = useCallback(() => {
    setLoadingData(true);
    getExtendedPostalCodesByCityService(locationId)
      .then((response) => {
        setData(response[0].postal_codes);
        setOriginalData(response[0].postal_codes);
      })
      .catch((error) => {
        console.error(
          'PostalCodesComponent - Error getting postal codes: ',
          error
        );
      })
      .finally(() => {
        setLoadingData(false);
      });
  }, [locationId]);

  const getMunicipalityData = useCallback(() => {
    setLoadingData(true);
    getExtendedPostalCodesByMunicipalityService(locationId)
      .then((response) => {
        setData(response[0].postal_codes);
        setOriginalData(response[0].postal_codes);
      })
      .catch((error) => {
        console.error(
          'PostalCodesComponent - Error getting postal codes: ',
          error
        );
      })
      .finally(() => {
        setLoadingData(false);
      });
  }, [locationId]);

  const getData = useCallback(() => {
    if (locationMode === 'ciudad') {
      getCityData();
    } else if (locationMode === 'municipio') {
      getMunicipalityData();
    }
  }, [locationMode, getCityData, getMunicipalityData]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onFilterUsers = (filterText) => {
    let filteredItems;

    if (filterText.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterText.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const postalCode = item.postal_code.toString().toLowerCase();

        return postalCode.includes(filteringText) && true;
      });
    }

    setData(filteredItems);
  };

  const handleOnAddPostalCode = () => {
    setModalMode('add');
    const editableItem = {
      data: { postal_code: null },
      title:
        locationMode === 'ciudad'
          ? `Agregar código postal a: ${location?.state?.city_name} `
          : `Agregar código postal a: ${location?.state?.municipalityName}`,
      service: 'postal_code',
      fields: [
        {
          id: 'postal_code',
          label: 'Código Postal:',
          type: 'number',
          value: ''
        },
        {
          id: 'neighborhood',
          label: 'Colonía:',
          type: 'text',
          value: ''
        }
      ]
    };

    setEditItem(editableItem);
  };

  const onEditPostalCode = (cp) => {
    setModalMode('edit');
    const editableItem = {
      data: { postal_code: cp.postal_code },
      title:
        'Editar código postal: ' + locationMode === 'ciudad'
          ? location?.state?.city_name
          : location?.state?.municipalityName,
      service: 'postal_code',
      fields: [
        {
          id: 'postal_code',
          label: 'Código Postal',
          type: 'number',
          value: cp.postal_code,
          disabled: true
        },
        {
          id: 'neighborhood',
          label: 'Colonía',
          type: 'text',
          value: cp.suburb
        }
      ]
    };

    setEditItem(editableItem);
  };

  const handleOnModalClose = () => {
    setEditItem(null);
    setModalMode('');
  };

  const handleOnEditModalSave = (editedRow) => {
    const addObject = {
      city_id: locationId,
      postal_code: editedRow.postal_code,
      neighborhood: editedRow.neighborhood
    };

    if (modalMode === 'add') addPostalCode(addObject);
    if (modalMode === 'edit') updatePostalCode(addObject);

    setEditItem(null);
  };

  const addPostalCode = ({ city_id, postal_code, neighborhood }) => {
    if (locationMode === 'ciudad') {
      addExtendedPostalCodeByCityService(city_id, postal_code, neighborhood)
        .then(() => {
          setLoadingData(false);
          getCityData();
          toast('Código postal agregado');
        })
        .catch((error) => {
          showErrorMessages(error);
        })
        .finally(() => {
          setModalMode('');
        });
    } else {
      addExtendedPostalCodeByMunicipalityService(
        city_id,
        postal_code,
        neighborhood
      )
        .then(() => {
          setLoadingData(false);
          getMunicipalityData();
          toast('Código postal agregado');
        })
        .catch((error) => {
          toast('Error al agregar CP.');
          console.error('PCCompm -> Error saving postal code: ', error);
        })
        .finally(() => {
          setModalMode('');
        });
    }
  };

  const updatePostalCode = (pcData) => {
    if (locationMode === 'ciudad') {
      updatePostalCodeByCityService(pcData)
        .then(() => {
          setLoadingData(false);
          getCityData();
          toast('Código postal actualizado.', { type: 'success' });
        })
        .catch((error) => {
          toast('Error al agregar CP.', { type: 'error' });
          console.error('PCCompm -> Error saving postal code: ', error);
        })
        .finally(() => {
          setModalMode('');
        });
    } else {
      updatePostalCodeByMunicipalityService(pcData)
        .then(() => {
          setLoadingData(false);
          getMunicipalityData();
          toast('Código postal actualizado.', { type: 'success' });
        })
        .catch((error) => {
          toast('Error al agregar CP.', { type: 'error' });
          console.error('PCCompm -> Error saving postal code: ', error);
        })
        .finally(() => {
          setModalMode('');
        });
    }
  };

  const handleOnDeletePostalCode = (item) => {
    setEditItem(item);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    const deleteObject = {
      city_id: locationId,
      postal_code: editItem.postal_code
    };
    deletePostalCode(deleteObject);
    setEditItem(null);
    setModalMode('');
  };

  const deletePostalCode = ({ city_id, postal_code }) => {
    setLoadingData(false);
    if (locationMode === 'ciudad') {
      deletePostalCodeService({ city_id, postal_code })
        .then(() => {
          getCityData();
          setLoadingData(false);
          toast('Código postal borrado', { type: 'success' });
        })
        .catch(() => {
          toast('No se pudo elimiar el CP.', { type: 'error' });
        });
    } else {
      deleteExtendedPostalCodeByMunicipalityService(city_id, postal_code)
        .then(() => {
          getMunicipalityData();
          setLoadingData(false);
          toast('Código postal borrado', { type: 'success' });
        })
        .catch(() => {
          toast('No se pudo elimiar el CP.', { type: 'error' });
        });
    }
  };

  const handleOnLoadfile = () => {
    setModalMode('load');
  };

  const handleOnRefreshList = () => {
    getData();
  };

  const handleOnChangeCoverage = (row) => {
    changeCoverageMunicipalityService({ id: row?.id })
      .then(() => {
        getData();
        toast.success('Cobertura modificada');
      })
      .catch((error) => {
        toast.error('Error, no se pudo modificar la cobertura');
      });
  };

  return (
    <Fragment>
      <PostalCodesView
        data={data}
        locationMode={locationMode}
        loading={loadingData}
        onFilterUsers={onFilterUsers}
        onEditPostalCode={onEditPostalCode}
        onAddPostalCode={handleOnAddPostalCode}
        onDeletePostalCode={handleOnDeletePostalCode}
        onLoadfile={handleOnLoadfile}
        onChangeCoverage={handleOnChangeCoverage}
      />
      {editItem && (modalMode === 'add' || modalMode === 'edit') && (
        <EditFieldModal
          open={true}
          title={editItem.title}
          service={editItem.service}
          data={editItem.data}
          fields={editItem.fields}
          handleOnClose={handleOnModalClose}
          handleOnSave={handleOnEditModalSave}
        />
      )}
      {editItem && modalMode === 'delete' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar código postal "
        >
          {'Estas seguro de borrar ' + editItem.postal_code + '?'}
        </ModalComponent>
      )}
      {modalMode === 'load' && (
        <LoadFileModal
          locationMode={locationMode}
          locationId={locationId}
          onClose={handleOnModalClose}
          onRefreshList={handleOnRefreshList}
        />
      )}
    </Fragment>
  );
};

export default PostalCodesComponent;
