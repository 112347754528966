/** Import react/libraries section **/
import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

/** Import resources section **/
import './styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';

/** Import services section **/

/** Import helpers section **/
import { ToastContainer } from 'react-toastify';
import useCatalogs from './hooks/useCatalogs';
import { setSession } from './redux/session/sessionSlice';

/** Import component section **/
import MainContainer from './components/MainContainer';

function App() {
  let dispatch = useDispatch();

  useEffect(() => {}, []);

  useCatalogs();

  // We use this effect for regenerate session if exists, just before mount MainContainer.
  useEffect(() => {
    let token = localStorage.getItem('token');
    if (token) {
      let payload = {
        token: token,
        username: ''
      };
      dispatch(setSession(payload));
    }
  }, [dispatch]);

  return (
    <Fragment>
      <MainContainer />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        toastClassName="g-toast"
        bodyClassName="g-toast-body"
      />
    </Fragment>
  );
}

export default App;
