/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { useState } from 'react';

/** Import component section **/
import AssignModalView from './AssignModalView';

/** Import helpers section **/
import { assignUserService } from '../../../services/clients-services';
import { toast } from 'react-toastify';
import { showErrorMessages } from '../../../utils/utils';

/** Import resources section **/

const AssignModalComponent = ({ item, users, onClose, onConfirm }) => {
  const [selectedUser, setSelectedUser] = useState(item?.user_id);

  const handleOnUserChange = (user) => {
    setSelectedUser(user);
  };

  const handleOnAssignConfirm = () => {
    const params = {
      id: item?.id,
      user_id: selectedUser
    };
    assignUserService(params)
      .then((response) => {
        toast('Asesor asignado', { type: 'success' });
        onConfirm();
      })
      .catch((error) => {
        showErrorMessages(error);
        toast('Error, nos se pudo asignar el asesor', { type: 'error' });
      });
  };

  return (
    <AssignModalView
      users={users}
      selectedUser={selectedUser}
      onUserChange={handleOnUserChange}
      onClose={onClose}
      onConfirm={handleOnAssignConfirm}
    />
  );
};

export default AssignModalComponent;
