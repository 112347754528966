/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import MUIDataTable from '../../../components/MUIDataTable';

/** Import helpers section **/

/** Import resources section **/
import './QuoteFilesComponent.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ButtonBase, Tooltip } from '@material-ui/core';

const QuoteFilesView = ({ files, onDelete, onSend }) => {
  const ActionBill = ({ row }) => {
    return (
      <>
        <Tooltip title="Descargar">
          <a href={row.address} target="_blank" rel="noreferrer">
            <ButtonBase>
              <GetAppIcon className="g-row-action-button " />
            </ButtonBase>
          </a>
        </Tooltip>

        <Tooltip title="Borrar">
          <ButtonBase onClick={() => onDelete(row.id)}>
            <DeleteIcon className="g-row-action-button delete" />
          </ButtonBase>
        </Tooltip>
      </>
    );
  };

  const tableColumns = [
    {
      name: 'Fecha de creación',
      selector: 'created_at',
      sortable: true,
      center: true
    },
    {
      name: 'Fecha de envío',
      selector: 'send_date',
      sortable: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionBill row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    }
  ];

  return (
    <div className="quotefiles">
      <div className="quotefiles_pdfs">
        <MUIDataTable
          loading={false}
          columns={tableColumns}
          data={files}
          showDownload={false}
        />
      </div>
    </div>
  );
};

export default QuoteFilesView;
