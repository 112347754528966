/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/

/** Import styles section **/
import './ShipmentsStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import TabsNavigation from '../../components/UI/TabsNavigation';

const ShipmentsView = ({
  activeTab,
  onTabChange,
  clients,
  filters,
  onFilterBy,
  onDateChange,
  onStatusChange,
  onClientChange
}) => {
  const tabsLabels = ['Envios no facturados', 'Envios facturados'];
  return (
    <div className="shipments">
      <div className="shipments_header">
        <PageTitle title="Envíos" />
        <TabsNavigation
          tabs={tabsLabels}
          onChange={onTabChange}
          active={activeTab}
        />
      </div>
    </div>
  );
};

export default ShipmentsView;
