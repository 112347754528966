/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

/** Import helpers section **/
import {
  STATUS_APPROVED,
  STATUS_CANCELED,
  STATUS_REJECTED
} from '../../utils/enumerates';

/** Import styles section **/
import './QuoteStyles.scss';

/** Import resources section **/
import { QUOTES_ROUTE, SHIPMENTS_ROUTE } from '../../URL-Routes';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import TabsNavigation from '../../components/UI/TabsNavigation';
import ActionsMenu from '../../components/ActionsMenu';
import ModalComponent from '../../components/UI/ModalComponent';

const tabsLabels = ['Generales', 'Servicios', 'Costos', 'Archivo PDF'];
const deliveryTab = 'Entrega';

const QuoteView = (props) => {
  let {
    activeTab,
    onTabChange,
    onSave,
    onApprove,
    onPrint,
    inProcess,
    deliverShipment,
    onCloseShipment
  } = props;

  let location = useLocation();
  let history = useHistory();

  const trackingGuide = useSelector(
    (store) => store.quote.quotation.tracking_guide
  );
  const shipmentStatus = useSelector(
    (store) => store.quote.quotation.shipment_status
  );

  const is_shipment = useSelector((store) => store.quote.quotation.is_shipment);

  const [title, setTitle] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [pathName, setPathName] = useState(null);
  const [tabs, setTabs] = useState(tabsLabels);

  useEffect(() => {
    if (location.pathname.split('/')[1] === 'envios') {
      let trackingText =
        trackingGuide === undefined ? '' : ' / No. Guía ' + trackingGuide;
      let statusText =
        shipmentStatus === undefined ? '' : ' / ' + shipmentStatus;
      setTitle('Envío' + trackingText + statusText);
      setPathName('Envio');
    } else {
      let trackingText =
        trackingGuide === undefined ? '' : ' / No. Guía ' + trackingGuide;
      let statusText = shipmentStatus === undefined ? '' : shipmentStatus;
      setTitle(statusText.toLocaleLowerCase() + trackingText);
      setPathName('Cotización');
    }
  }, [location, trackingGuide, shipmentStatus]);

  useEffect(() => {
    if (is_shipment) setTabs([...tabsLabels, deliveryTab]);
  }, [is_shipment]);

  const actions = [
    {
      action: 'save',
      title: 'Guardar',
      callback: onSave,
      inProcess: inProcess
    },
    {
      action: 'print',
      title: 'Crear PDF',
      callback: onPrint
    },
    {
      action: 'approve',
      title: 'Acceptado',
      callback: () => onApprove(STATUS_APPROVED)
    },
    {
      action: 'disapprove',
      title: 'Rechazado',
      callback: () => onApprove(STATUS_REJECTED)
    },
    {
      action: 'delivered',
      title: 'Entregado',
      callback: () => deliverShipment()
    },
    {
      action: 'closed',
      title: 'Cerrado',
      callback: () => onCloseShipment()
    },
    {
      action: 'reject',
      title: 'Cancelar',
      callback: () => onApprove(STATUS_CANCELED)
    }
  ];

  const showConfirmDialog = (e) => {
    e.preventDefault();
    setShowDialog(true);
  };

  const goBackward = () => {
    onSave();
    redirectTo();
  };

  const onClose = () => {
    setShowDialog(false);
    redirectTo();
  };

  const redirectTo = () =>
    location.pathname.split('/')[1] === 'envios'
      ? history.push(SHIPMENTS_ROUTE)
      : history.push(QUOTES_ROUTE);

  return (
    <div className="g-page-header quote">
      <PageTitle title={title} onBackward={showConfirmDialog} path={pathName} />

      <TabsNavigation tabs={tabs} active={activeTab} onChange={onTabChange} />

      <ActionsMenu actions={actions} />

      {showDialog === true ? (
        <ModalComponent
          open={showDialog}
          title="Cerrar cotización"
          onClose={onClose}
          onConfirm={goBackward}
        >
          <h3>¿Desea guardar antes de salir?</h3>
        </ModalComponent>
      ) : null}
    </div>
  );
};

export default QuoteView;
