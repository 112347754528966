/** Import react/libraries section **/
import React, { useState } from 'react';
import './ClientFormStyles.scss';

/** Import component section **/
import SearchInputComponent from '../../Search';
import MUITextField from '../../UI/inputs/MUITextField';

/** Import helpers section **/

/** Import resources section **/
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { useSelector } from 'react-redux';

export const ClientFormView = ({
  client,
  onChange,
  searchCallback,
  optionsFound,
  handleOptionSelected
}) => {
  let isShipment = useSelector((store) => store.quote?.quotation?.is_shipment);
  const [show, setShow] = useState(false);

  return (
    <Card className="clientForm">
      <CardHeader
        className="clientForm_header"
        title="Cliente"
        action={
          // showSearch ? (
          <div className="clientForm_header-search">
            {show === true ? (
              <SearchInputComponent
                searchCallback={searchCallback}
                optionsFound={optionsFound}
                handleOptionSelected={handleOptionSelected}
                optionLabel="search"
                selectorWidth="200"
              />
            ) : null}
            <IconButton
              data-cy="cy-search-button"
              aria-label="settings"
              onClick={() => setShow(!show)}
              disabled={isShipment}
            >
              <SearchIcon />
            </IconButton>
          </div>
          // ) : null
        }
      />
      <CardContent className="clientForm_content">
        <MUITextField
          label="Nombre / Razón social"
          name="business_name"
          data-cy="cy-business_name"
          value={client?.business_name}
          onChange={onChange}
        />
        <MUITextField
          label="RFC"
          name="rfc"
          data-cy="cy-rfc"
          value={client?.rfc}
          onChange={onChange}
        />

        <MUITextField
          label="Solicitante"
          name="contact_name"
          data-cy="cy-client-contact"
          value={client?.contact_name}
          onChange={onChange}
        />

        <MUITextField
          label="Email"
          name="email"
          data-cy="cy-client-email"
          value={client?.email}
          onChange={onChange}
        />

        <MUITextField
          label="Teléfono"
          name="phone"
          data-cy="cy-client-phone"
          // defaultValue={client?.phone}
          value={client?.phone}
          onChange={onChange}
        />
      </CardContent>
    </Card>
  );
};

export default ClientFormView;
