/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import component section **/
import BillingsView from './BillingsView';
import {
  breakManifestService,
  exportManifestsToPDFService,
  generateZipFiles,
  onCreateBillingsFiles,
  requestManifestService,
  updateManifestService,
  getManifestDataToBillService,
  saveBillManifestDataService,
  updateBillManifesStatusService
} from '../../services/manifest-services';
import {
  checkStatusBillManifest,
  requestManifestBilling
} from '../../services/adn-external-services';

import { toast } from 'react-toastify';
import ModalComponent from '../../components/UI/ModalComponent';
import MUITextField from '../../components/UI/inputs/MUITextField';
import MUIDateField from '../../components/UI/inputs/MUIDateField';
import { STAMPED_STATUS } from '../ConsigmentNote/helpers/Constants';
import AddButton from '../../components/UI/AddButton';

/** Import helpers section **/

/** Import resources section **/

const BillingsComponent = () => {
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [manifests, setManifests] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [modalMode, setModalMode] = useState('');
  const [manifestId, setManifestId] = useState(null);
  const [billData, setBillData] = useState([]);
  const [billDate, setBillDate] = useState(null);

  const [disabled, setDisabled] = useState(true);
  const [showManifestDialog, setShowManifestDialog] = useState(false);
  const [billNumber, setBillNumber] = useState('');
  const [billStatus, setBillStatus] = useState(null);
  const [selectedManifest, setSelectedManifest] = useState(null);
  const [billManifestDocuments, setBillManifestDocuments] = useState(null);

  const ADN_URL = process.env.REACT_APP_API_ADN;

  const getManifests = () => {
    setLoading(true);
    requestManifestService()
      .then((response) => {
        setManifests(response);
        setOriginalData(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error('ManifestsComp -> Error requesting manifests: ', error);
      });
  };

  const saveBillManifestData = (billData) => {
    saveBillManifestDataService(billData)
      .then((response) => {
        if (response) {
          toast.success('factura realizada correctamente');
          onModalClose();
          getManifests();
          setDisabled(true);
        }
      })
      .catch((error) => {
        toast.warning('revisar datos de facturacion');
        if (error?.response_status === 400) {
          toast.warning('Faltan datos de facturacion');
        }
        console.error('ManifestsComp -> Error requesting bill data: ', error);
      });
  };

  const sendManifestToBill = () => {
    requestManifestBilling(billData)
      .then((response) => {
        if (response) {
          let params = {
            manifest_id: manifestId,
            folio: response?.folio,
            id: response?.id,
            billing_rfc: billData?.emisor?.rfc
          };
          saveBillManifestData(params);
          toast.success('Datos enviados correctamente');
        }
      })
      .catch((error) => {
        toast.warning('revisar datos de facturacion');
        if (error?.response_status === 400) {
          toast.warning('Faltan datos de facturacion');
        }
        console.error('ManifestsComp -> Error requesting bill data: ', error);
      });
  };

  const getManifestData = (manifestId) => {
    if (!manifestId) {
      setShowAlert(!showAlert);
      return;
    }
    /* 
    Se obtienen los datos del manifiesto por envio para facturar
    */
    getManifestDataToBillService(manifestId)
      .then((response) => {
        if (response?.manifest_data) {
          setBillData(response.manifest_data);
          setDisabled(false);
        }
      })
      .catch((error) => {
        if (error?.response_status === 400) {
          toast.warning('Faltan datos de facturacion');
        }
        console.error('ManifestsComp -> Error requesting bill data: ', error);
      });
  };

  const checkBillManifestStatus = (billParams) => {
    checkStatusBillManifest(billParams)
      .then((response) => {
        if (response) {
          const status = response?.Status;
          const pdfDoc = response?.DownloadXml;
          const xmlDoc = response?.DownloadPdf;
          setBillStatus(status);
          //cuando nos muestra por primera vez la factura timbrada, guardaremos los documentos para la consulta
          if (status === STAMPED_STATUS) {
            const docs = {
              pdf: pdfDoc,
              xml: xmlDoc
            };
            setBillManifestDocuments(docs);
          }
          const params = {
            id: billParams?.manifest_id,
            status: status
          };
          updateBillManifesStatusService(params);
          setDisabled(true);
        }
      })
      .catch(() => {
        toast.success(
          'Hubo algun error en la consulta del status realizada correctamente'
        );
      });
  };

  useEffect(() => {
    getManifests();
  }, []);

  const handleOnFilter = (filterText) => {
    let filteredItems;
    if (filterText.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterText.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item?.id
          .toString()
          .toLowerCase()
          .includes(filteringText);
        const field2 = item?.client?.business_name
          ?.toString()
          .toLowerCase()
          .includes(filteringText);
        const field3 = item?.bill_number
          ?.toString()
          .toLowerCase()
          .includes(filteringText);

        return field1 || field2 || (field3 && true);
      });
    }

    setManifests(filteredItems);
  };

  const openCloseAlert = (manifestId) => {
    setManifestId(manifestId);
    setShowAlert(!showAlert);
    setModalMode('close_manifest');
  };

  const onModalClose = (manifestId) => {
    setManifestId(null);
    setShowAlert(false);
    setDisabled(true);
    setModalMode('');
    setBillStatus(null);
    setBillManifestDocuments(null);
  };

  const handleOnBillManifest = (manifestId) => {
    setManifestId(manifestId);
    setShowAlert(!showAlert);
    setModalMode('bill_manifest');
    getManifestData(manifestId);
  };

  const onShowEditDialog = (manifest) => {
    setShowManifestDialog(true);
    setSelectedManifest(manifest);
    setBillDate(manifest.invoice_send_date);
    setBillNumber(manifest.bill_number);
  };

  const breakManifest = () => {
    breakManifestService(manifestId)
      .then(() => {
        toast.success('Se ha disuelto el manifiesto.');
        getManifests();
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al disolver manifiesto.');
      })
      .finally(() => setShowAlert(false));
  };

  const handleManifestConfirm = () => {
    let data = {
      id: selectedManifest.id,
      bill_number: billNumber,
      invoice_send_date: billDate
    };
    updateManifestService(data)
      .then(() => {
        toast.success('Manifiesto actualizado.');
        setLoading(false);
        getManifests();
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al actualizar el manifiesto.');
      })
      .finally(() => setShowManifestDialog(false));
  };

  const onExportToPDF = (manifestId) => {
    exportManifestsToPDFService(manifestId)
      .then(() => {
        toast.success('Se exporto correctamente.');
        getManifests();
        setLoading(false);
      })
      .catch(() => {
        toast.error('Intente nuevamente.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCreateFiles = (manifestId) => {
    onCreateBillingsFiles(manifestId)
      .then(() => {
        toast.success('Archivos creados correctamente.');
        getManifests();
      })
      .catch((errors) => {
        toast.error('Error al crear, intente nuevamente.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onGenerateZipFile = (url) => {
    generateZipFiles(url)
      .then((response) => {
        if (response === 'No files') {
          toast.warning('El manifiesto no tiene evidencias.');
        } else {
          getManifests();
          toast.success('Archivo creado correctamente');
        }
      })
      .catch((err) => {
        toast.error('Error al crear, intente nuevamente.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnCheckManifestBillStatus = (manifest) => {
    setManifestId(manifest?.id);
    const params = {
      id: manifest?.bill_manifest_id,
      rfc: manifest?.billing_rfc,
      manifest_id: manifest?.id
    };
    //validar si el manifiesto ya tiene el status timbrado para evitar la consulta a ADN del status
    // y mostrar directamente el boton con la descarga de los documentos
    if (manifest?.billing_status === STAMPED_STATUS) {
      console.log('entra');
      const docs = {
        pdf:
          ADN_URL + 'invoice/download/' + manifest?.bill_manifest_id + '/pdf',
        xml: ADN_URL + 'invoice/download/' + manifest?.bill_manifest_id + '/xml'
      };
      setBillManifestDocuments(docs);

      setBillStatus(STAMPED_STATUS);
    } else {
      checkBillManifestStatus(params);
    }

    setShowAlert(!showAlert);
    setModalMode('bill_manifest_status');
  };

  return (
    <>
      <BillingsView
        loading={loading}
        showAlert={showAlert}
        manifests={manifests}
        onCreateFiles={onCreateFiles}
        onExportToPDF={onExportToPDF}
        breakManifest={breakManifest}
        openCloseAlert={openCloseAlert}
        onFilter={handleOnFilter}
        onShowEditDialog={onShowEditDialog}
        onGenerateZipFile={onGenerateZipFile}
        onEditManifest={setShowManifestDialog}
        onBillManifestModal={handleOnBillManifest}
        onCheckManifestBillStatus={handleOnCheckManifestBillStatus}
      />

      {showManifestDialog === true ? (
        <ModalComponent
          open={showManifestDialog}
          onClose={() => setShowManifestDialog(false)}
          onConfirm={handleManifestConfirm}
          title="Manifiesto"
        >
          <br />
          <MUITextField
            required
            id="No. Factura"
            name="bill_num"
            data-cy="cy-modal_bill_num"
            label="No. Factura"
            value={billNumber}
            onChange={(value) => setBillNumber(value.value)}
          />
          <br />
          <MUIDateField
            id="bill_date"
            value={billDate}
            onChange={(event) => setBillDate(event?.value)}
            label="Fecha de envio"
            name="bill_date"
          />
          <br />
        </ModalComponent>
      ) : null}

      {modalMode === 'close_manifest' && manifestId && (
        <ModalComponent
          open={showAlert}
          onClose={openCloseAlert}
          onConfirm={breakManifest}
          title="Disolver manifiesto"
        >
          <h4>¿Está seguro que quiere deshacer el manifiesto?</h4>
        </ModalComponent>
      )}
      {modalMode === 'bill_manifest' && manifestId && (
        <ModalComponent
          open={showAlert}
          onClose={onModalClose}
          onConfirm={sendManifestToBill}
          disabled={disabled}
          title="¿Desea facturar maniefiesto?"
        >
          <h4>¿Está seguro que quiere facturar el manifiesto?</h4>
        </ModalComponent>
      )}
      {modalMode === 'bill_manifest_status' && manifestId && billStatus && (
        <ModalComponent
          open={showAlert}
          onClose={onModalClose}
          onConfirm={null}
          disabled={disabled}
          title="¿Desea facturar maniefiesto?"
        >
          Estatus : {billStatus}
          {billManifestDocuments ? (
            <div className="manifests_billDocs">
              <AddButton
                text="Archivo pdf"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = billManifestDocuments?.pdf;
                }}
              />
              <AddButton
                text="Archivo xml"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = billManifestDocuments?.xml;
                }}
              />
            </div>
          ) : null}
          {/* botones*/}
        </ModalComponent>
      )}
    </>
  );
};

export default BillingsComponent;
