/** Import react/libraries section **/
import React from 'react';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import ActionsMenu from '../../components/ActionsMenu';
import OriginForm from '../../components/forms/OriginForm';
import DestinationForm from '../../components/forms/DestinationForm';
import ShipDetailsComponent from '../../components/forms/ShipDetailsForm';
import ShipmentTypeComponent from '../../components/forms/ShipmentTypeForm/ShipmentTypeComponent';

/** Import resources section **/
import './RequestStyles.scss';
import { MODE_REQUEST } from '../../utils/enumerates';
import { REQUESTS_LIST_ROUTE } from '../../URL-Routes';

const RequestView = ({ onSave, inProcess }) => {
  let history = useHistory();

  const backward = () => history.push(REQUESTS_LIST_ROUTE);

  const actions = [
    {
      action: 'save',
      title: 'Guardar',
      callback: onSave,
      inProcess: inProcess
    },
    { action: 'close', title: 'Cerrar', callback: backward }
  ];

  return (
    <div className="request">
      <div className="request_header">
        <PageTitle title="Solicitud" />
        <ActionsMenu actions={actions} />
      </div>

      <div className="requestCard">
        <ShipmentTypeComponent />
      </div>

      <div className="requestCard">
        <ShipDetailsComponent modeType={MODE_REQUEST} />
      </div>

      <div className="requestCard">
        <OriginForm />
      </div>

      <div className="requestCard">
        <DestinationForm />
      </div>

      <ActionsMenu actions={actions} id="request-save" />
    </div>
  );
};

RequestView.propTypes = {
  onSave: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  inProcess: PropTypes.bool.isRequired
};

export default RequestView;
