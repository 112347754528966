/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import component section **/
import AuthorizationsView from './AuthorizationsView';
import {
	approveCreditService,
	disapproveCreditService,
	getAuthorizationsService
} from '../../services/authorizations-service';
import { toast } from 'react-toastify';
import moment from 'moment';
import { showErrorMessages } from '../../utils/utils';

/** Import helpers section **/

/** Import resources section **/

const AuthorizationsComponent = () => {
	const [loading, setLoading] = useState(false);
	const [showClear, setShowClear] = useState(false);
	const [originalData, setOriginalData] = useState([]);
	const [authorizations, setAuthorizations] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [dateRange, setDateRange] = useState({ start: null, end: null });

	const getAuthorizations = () => {
		setLoading(true);
		getAuthorizationsService()
			.then((response) => {
				setAuthorizations(response);
				setOriginalData(response);
			})
			.catch((error) => {
				console.error(
					'AuthorizationsComp -> Error getting authorizations: ',
					error
				);
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		getAuthorizations();
	}, []);

	useEffect(() => {
		if (selectedStatus !== null) {
			let filteredItems = originalData.filter(
				(item) => item.require_credit_approval === selectedStatus
			);
			setAuthorizations(filteredItems);
		}

		setShowClear(true);
	}, [selectedStatus, originalData]);

	useEffect(() => {
		if (dateRange.start !== null && dateRange.end !== null) {
			let start = dateRange.start;
			let end = moment(dateRange.end).add(12, 'h');
			let filtered = originalData.filter((x) =>
				moment(x.created_at).isBetween(
					start,
					moment(end).add(12, 'hour')
				)
			);
			setAuthorizations(filtered);
		} else {
			setAuthorizations(originalData);
		}
	}, [dateRange.start, dateRange.end, originalData]);

	const handleOnFilterBy = (filterText) => {
		let filteredItems;

		if (filterText.length === 0) {
			filteredItems = [...originalData];
		} else {
			const filteringText = filterText.toLowerCase();

			filteredItems = originalData.filter((item) => {
				const field0 = item.client.business_name
					?.toString()
					.toLowerCase();
				const field1 = item.rfc?.toString().toLowerCase();
				const field2 = item.require_credit_approval_status
					?.toString()
					.toLowerCase();

				return (
					field0?.includes(filteringText) ||
					field2?.includes(filteringText) ||
					(field1?.includes(filteringText) && true)
				);
			});
		}

		setAuthorizations(filteredItems);
	};

	const approveCredit = (shipmentID) => {
		approveCreditService(shipmentID)
			.then(() => {
				toast('Credito aprobado');
				getAuthorizations();
				setLoading(false);
			})
			.catch((error) => {
				toast('Error al aprobar credito.');
				showErrorMessages(error);
			});
	};

	const disapproveCredit = (shipmentID) => {
		disapproveCreditService(shipmentID)
			.then(() => {
				toast('Credito desaprobado');
				getAuthorizations();
				setLoading(false);
			})
			.catch((error) => {
				showErrorMessages(error);
				toast('Error al desaprobar credito.');
			});
	};

	const onClear = (event) => {
		event.preventDefault();
		setDateRange({ start: null, end: null });
		setSelectedStatus(null);
		setShowClear(false);
	};

	const handleDateChange = (event) => {
		if (event !== null) {
			setShowClear(true);
			let { value, name } = event;
			if (name === 'start') {
				setDateRange((prevState) => ({
					...prevState,
					start: value
				}));
			} else {
				setDateRange((prevState) => ({
					...prevState,
					end: value
				}));
			}
		}
	};

	const handleOnStatusChange = (value) => {
		setSelectedStatus(value);
	};

	return (
		<AuthorizationsView
			loading={loading}
			onClear={onClear}
			showClear={showClear}
			dateRange={dateRange}
			approveCredit={approveCredit}
			selectedStatus={selectedStatus}
			handleDateChange={handleDateChange}
			authorizationsList={authorizations}
			handleOnFilterBy={handleOnFilterBy}
			disapproveCredit={disapproveCredit}
			handleOnStatusChange={handleOnStatusChange}
		/>
	);
};

export default AuthorizationsComponent;
