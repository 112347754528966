/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUITextField from '../../../components/UI/inputs/MUITextField';
import ShipmentTypesSelect from '../../../components/ShipmentTypesSelect';

/** Import resources section **/
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import './ContainerTypeModalStyles.scss';

const ContainerTypeModalView = ({
  category,
  selectedTypes,
  onInputChange,
  onActiveChange,
  onShipmentTypesChange,
  onClose,
  onSave
}) => {
  return (
    <ModalComponent
      open={true}
      title={(category?.id ? 'Edición de' : 'Nueva') + ' categoría de empaque'}
      onClose={onClose}
      onConfirm={onSave}
    >
      <div className="containertype-modal">
        <div className="containertype-modal_fields">
          <MUITextField
            id="name"
            name="name"
            label="Nombre"
            type="text"
            value={category?.title}
            onChange={onInputChange}
            data-cy="cy-name"
          />
          <FormControlLabel
            control={
              <Switch
                checked={category?.active === 1}
                onChange={onActiveChange}
                name="active"
                data-cy="cy-active"
                color="primary"
              />
            }
            label="Activo"
          />
        </div>
        <div className="containertype-modal_shipmentstypes">
          <ShipmentTypesSelect
            label="Servicios a los que aplica:"
            selected={selectedTypes}
            onChange={onShipmentTypesChange}
            onlyConfigurables
          />
        </div>
      </div>
    </ModalComponent>
  );
};

export default ContainerTypeModalView;
