export const DASHBOARD_ROUTE = '/dashboard';

export const LOGIN_ROUTE = '/login';

export const SHIPMENTS_ROUTE = '/';
export const SHIPMENTS_EDIT_ROUTE = '/envios/editar';

export const QUOTES_ROUTE = '/cotizaciones';
export const QUOTE_EDIT = '/cotizacion/editar';
export const QUOTE_NEW = '/cotizacion/crear';

export const REQUEST_ROUTE = '/solicitud';
export const REQUESTS_LIST_ROUTE = '/solicitudes';
export const REQUESTS_EDIT_ROUTE = '/solicitudes/editar';
export const REQUESTS_CREATE_ROUTE = '/solicitudes/crear';

export const AUTHORIZATIONS_ROUTE = '/autorizacion';

export const BILLING_ROUTE = '/facturacion';
export const MANIFESTS_ROTE = '/manifiestos';
export const PAYMENTS_ROUTE = '/pagos';

export const CLIENTS_ROUTE = '/clientes';
export const CONTACTS_ROUTE = '/contactos';

export const PRODUCTS_PRICES_ROUTE = '/precios-productos';
export const DEDICATED_PRICES_ROUTE = '/precios-viajes-dedicados';
export const EXTRA_COSTS_ROUTE = '/costos-adicionales';
export const STAYS_ROUTE = '/costos-adicionales/estadias';
export const CLIENT_AGREEMENTS = '/convenios';
export const AGREEMENT_PRICES = '/precios-convenio';

export const ROUTES_ROUTE = '/rutas';
export const ROUTES_ROUTE_EXTENDED = '/rutas-extendidas';
export const ROUTES_ROUTE_EXTENDED_CP = '/rutas-extendidas/postal_codes';
export const CITIES_ROUTE = '/ciudades';
export const CITY_POSTAL_CODES_ROUTE = '/codigos-postales/ciudad';
export const MPIO_POSTAL_CODES_ROUTE = '/codigos-postales/municipio';
export const PROVIDERS_ROUTE = '/proveedores';
export const UNITS_ROUTE = '/unidades';
export const CONTAINERS_TYPES_ROUTE = '/contenedores';
export const CONTAINERS_ROUTE = '/contenedores/productos';
export const BUSINESS_BILLING = '/empresas/facturacion';
export const CONTAINER_GOODS = '/bienes-transportados';

export const SHIPMENT_REPORTS_ROUTE = '/reportes/envios';
export const QUOTES_REPORTS_ROUTE = '/reportes/cotizaciones';
export const RECEIVABLES_REPORTS_ROUTE = '/reportes/cuentas-por-cobrar';

export const USERS_ROUTE = '/usuarios';
