/** Import react/libraries section **/
import React, { Fragment } from 'react';

/** Import component section **/
import FilterBy from '../../components/UI/FilterBy';
import PageTitle from './../../components/PageTitle';
import MUIDataTable from '../../components/MUIDataTable';
import AddButton from '../../components/UI/AddButton';

/** Import styles section **/
import './BillingBusinessStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const BillingBusinessView = ({
  billingBusiness,
  loading,
  filteringHandler,
  handleAddBillingBusiness,
  onEditBillingBusiness,
  onDeleteUnitBillingBusiness
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          data-cy="edit-button"
          className="g-row-action-button"
          onClick={() => onEditBillingBusiness(row)}
        />
        <DeleteIcon
          data-cy="delete-button"
          className="g-row-action-button delete"
          onClick={() => onDeleteUnitBillingBusiness(row)}
        />
      </>
    );
  };

  const tableColumns = [
    {
      name: 'Nombre del negocio',
      selector: 'business_name',
      sortable: true,
      center: true
    },
    {
      name: 'RFC',
      selector: 'rfc',
      sortable: true,
      center: true
    },
    {
      name: 'Codigo postal',
      selector: 'postal_code',
      sortable: true,
      center: true
    },
    {
      name: 'Régimen fiscal',
      selector: 'tax_regime_title',
      sortable: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    }
  ];

  return (
    <Fragment>
      <PageTitle title="Empresas de facturación" />
      <Paper className="g-page-header billingBusiness_header">
        <div className="billingBusiness_header_filterby">
          <FilterBy onChange={filteringHandler} />
        </div>
        <AddButton onClick={handleAddBillingBusiness} />
      </Paper>

      <MUIDataTable
        data={billingBusiness}
        columns={tableColumns}
        loading={loading}
      />
    </Fragment>
  );
};

export default BillingBusinessView;
