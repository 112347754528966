/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { t as typy } from 'typy';

/** Import component section **/
import ContainersView from './ContainersView';
import ModalComponent from '../../components/UI/ModalComponent';
import ContainerModal from './ContainerModal';

/** Import helpers section **/
import {
	deleteContainerService,
	getContainerService
} from '../../services/catalogues-services';
import { showErrorMessages } from '../../utils/utils';

const ContainersComponent = () => {
	const [loadingData, setLoadingData] = useState(false);
	const [originalData, setOriginalData] = useState([]);
	const [containers, setContainers] = useState([]);

	const [focusedItem, setFocusedItem] = useState(null);
	const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);

	let location = useLocation();
	let containerTypeId = location.state?.id;
	let containerTypeTitle = location.state?.title;

	const containerModel = {
		id: null,
		container_category_id: containerTypeId,
		container_category_name: containerTypeTitle,
		min_weight: 0,
		max_weight: 0
	};

	const getContainers = () => {
		setLoadingData(true);
		getContainerService(containerTypeId)
			.then((response) => {
				const unitsArr = typy(response).safeArray;
				setContainers(unitsArr);
				setOriginalData(unitsArr);
			})
			.catch((error) => {
				console.error(
					'ContainersComp -> Error getting containers: ',
					error
				);
			})
			.finally(() => {
				setLoadingData(false);
			});
	};

	useEffect(() => {
		getContainers();
	}, []);

	const handleOnFilterBy = (filterText) => {
		let filteredItems;

		if (filterText.length === 0) {
			filteredItems = [...originalData];
		} else {
			const filteringText = filterText.toLowerCase();

			filteredItems = originalData.filter((item) => {
				const field1 = item.title?.toString().toLowerCase();
				const field2 = item.container_category_name
					?.toString()
					.toLowerCase();

				return (
					field1?.includes(filteringText) ||
					(field2?.includes(filteringText) && true)
				);
			});
		}

		setContainers(filteredItems);
	};

	const handleOnAddContainer = () => {
		const newItem = containerModel;
		setFocusedItem(newItem);
	};

	const handleOnEditContainer = (item) => {
		const editItem = JSON.parse(JSON.stringify(item));
		setFocusedItem(editItem);
	};

	const handleOnModalClose = () => {
		setFocusedItem(null);
		setDeleteAlertOpen(false);
	};

	const handleOnDeleteContainer = (row) => {
		const deleteItem = {
			id: row.id,
			min_weight: row.min_weight,
			max_weight: row.max_weight
		};

		setFocusedItem(deleteItem);
		setDeleteAlertOpen(true);
	};

	const handleOnDeleteConfirm = () => {
		deleteContainerService(focusedItem?.id)
			.then(() => {
				getContainers();
				toast('Producto eliminado', { type: 'success' });
			})
			.catch((error) => {
				toast('Error, no pudo eliminar el producto', { type: 'error' });
				showErrorMessages(error);
			});
		setFocusedItem(null);
		setDeleteAlertOpen(false);
	};

	const handleOnSaveConfirm = () => {
		setFocusedItem(null);
		setDeleteAlertOpen(false);
		getContainers();
	};

	return (
		<Fragment>
			<ContainersView
				title={containerTypeTitle}
				loading={loadingData}
				containers={containers}
				onFilterBy={handleOnFilterBy}
				onAdd={handleOnAddContainer}
				onEdit={handleOnEditContainer}
				onDelete={handleOnDeleteContainer}
			/>
			{focusedItem && !deleteAlertOpen && (
				<ContainerModal
					item={focusedItem}
					onClose={handleOnModalClose}
					onConfirm={handleOnSaveConfirm}
				/>
			)}
			{focusedItem && deleteAlertOpen && (
				<ModalComponent
					open={deleteAlertOpen}
					onClose={handleOnModalClose}
					onConfirm={handleOnDeleteConfirm}
					title="Borrar empaque"
				>
					{'¿Estas seguro de borrar ' +
						containerTypeTitle +
						' de ' +
						focusedItem.min_weight +
						' a ' +
						focusedItem.max_weight +
						' kg?'}
				</ModalComponent>
			)}
		</Fragment>
	);
};

export default ContainersComponent;
