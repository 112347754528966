/** Import react/libraries section **/
import React from 'react';
import { PropTypes } from 'prop-types';

/** Import component section **/
import PageTitle from '../../../components/PageTitle';
import AddButton from '../../../components/UI/AddButton';
import MUISelect from '../../../components/UI/inputs/MUISelect';
import MUIAmountField from '../../../components/UI/inputs/MUIAmountField';

/** Import helpers section **/
import { formatNumberToCurrency } from '../../../utils/formats';

/** Import resources section **/
import './QuoteProvidersStyles.scss';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import CardHeader from '@material-ui/core/CardHeader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ButtonBase, Card, CardContent, Tooltip } from '@material-ui/core';

const QuoteProvidersView = ({
  hasCosts,
  providers,
  msprovider,
  isShipment,
  addProviders,
  addedProviders,
  onMSInputChange,
  onDeleteProvider,
  productsDetails,
  handleMSSelection,
  handleRadioChange,
  handleInputChange,
  handleSelectChange,
  handleProductDetailsInputChange
}) => {
  const composeProviders = () => {
    return addedProviders?.map((provider, index) => {
      return (
        <Card key={index} className="providers_card">
          <CardContent className="providers_card_content">
            <FormControlLabel
              label=""
              id="checkbox"
              control={
                <Checkbox
                  className="providers_card_content-checkbox"
                  checked={provider?.selected === 1}
                  onChange={(event) =>
                    handleRadioChange(event, index, provider)
                  }
                  name="selected"
                  disabled={isShipment}
                />
              }
            />

            <MUISelect
              className="providers_card_content-select"
              label="Proveedor"
              id="client"
              dataCy="cy-client"
              defaultValue={provider?.id}
              items={providers}
              idSelector="id"
              selector="name"
              noSelectedLabel="Seleccione"
              disabled={provider?.selected !== 1}
              name="client_id"
              onChange={(event) => handleSelectChange(event, index, 'id')}
              readOnly={isShipment}
            />

            <TextField
              className="providers_card_content-input"
              name="tracking_guide"
              type="text"
              label="Número de guía de proveedor "
              value={provider?.tracking_guide}
              variant="standard"
              disabled={provider?.selected !== 1}
              onChange={(event) => handleInputChange(event, index, null, null)}
            />

            <TextField
              className="providers_card_content-input"
              name="price"
              type="number"
              label="Costo"
              value={provider?.price}
              variant="standard"
              disabled={provider?.selected !== 1}
              onChange={(event) =>
                handleInputChange(
                  event,
                  index,
                  null,
                  provider.profit_percentage
                )
              }
            />

            <TextField
              className="providers_card_content-input"
              name="profit_percentage"
              type="number"
              label="% Ganancia"
              value={provider?.profit_percentage}
              variant="standard"
              disabled={
                provider?.selected === 0 || provider?.has_agreement === true
              }
              onChange={(event) =>
                handleInputChange(event, index, provider.price, null)
              }
              error={isNaN(provider?.profit_percentage)}
            />

            <TextField
              className="providers_card_content-input"
              name="final_price"
              type="text"
              label="Precio final"
              value={provider?.final_price?.toFixed(2)}
              variant="standard"
              onChange={(event) => handleInputChange(event, index)}
              error={isNaN(provider?.final_price)}
              disabled
            />

            <ButtonBase id="deleteButton">
              <DeleteIcon
                className="g-row-action-button delete"
                onClick={(event) => onDeleteProvider(event, index)}
              />
            </ButtonBase>
          </CardContent>
        </Card>
      );
    });
  };
  const originExtenderRouteStr = (extRoutetype) => {
    const extendedRoutePrices = msprovider?.extended_route_prices;
    for (let i = 0; i < extendedRoutePrices.length; i++) {
      const routePrice = extendedRoutePrices[i];
      if (routePrice?.type === extRoutetype) {
        return <>Ruta extendida: {routePrice?.title}</>;
      }
    }

    return <></>;
  };
  return (
    <>
      {productsDetails !== undefined && productsDetails?.products ? (
        <Card className="providers_details">
          <CardContent className="providers_details_content">
            <div className="providers_details_content-head">
              <div className="providers_details_content-head_location">
                Origen
                <div className="providers_details_content-head_location_title">
                  {productsDetails?.origen}
                </div>
                {originExtenderRouteStr('origin')}
              </div>
              <div className="providers_details_content-head_location">
                Destino
                <div className="providers_details_content-head_location_title">
                  {productsDetails?.destination}
                </div>
                {originExtenderRouteStr('destination')}
              </div>
            </div>
            <div className="providers_details_content-summary">
              <div>Ítems: {productsDetails?.products?.length}</div>
              <div>
                Productos:{' '}
                {
                  productsDetails?.products?.reduce(
                    (a, b) => ({
                      quantity: a?.quantity + b?.quantity
                    }),
                    0
                  ).quantity
                }
              </div>
              <div>
                Total:{' '}
                {formatNumberToCurrency(
                  productsDetails?.products?.reduce(
                    (a, b) => ({
                      price: a?.price + b?.price
                    }),
                    0
                  )?.price
                )}
              </div>
            </div>

            <div className="providers_details_content-labels">
              <div>Producto</div>
              <div>P. unitario</div>
              <div>Tipo</div>
              <div>Cantidad</div>
              <div>Subtotal</div>
            </div>

            <div className="providers_details_content-list">
              {productsDetails?.products.map((item, index) => (
                <div
                  key={index}
                  className="providers_details_content-list-item"
                >
                  <div>{item?.product_name}</div>

                  {item?.edit === true ? (
                    <MUIAmountField
                      className="providers_details_content-list-item-input"
                      name="unit_price"
                      variant="standard"
                      prefix="$ "
                      value={formatNumberToCurrency(item?.unit_price)}
                      disabled={!item?.edit}
                      onChange={(event) =>
                        handleProductDetailsInputChange(
                          event,
                          index,
                          null,
                          null
                        )
                      }
                    />
                  ) : (
                    <div>{formatNumberToCurrency(item?.unit_price)}</div>
                  )}
                  <div>{item?.type}</div>
                  <div>{item?.quantity}</div>
                  {item?.edit === true ? (
                    <MUIAmountField
                      className="providers_details_content-list-item-input"
                      name="price"
                      variant="standard"
                      prefix="$ "
                      value={item?.price}
                      disabled={!item?.edit}
                      onChange={(event) =>
                        handleProductDetailsInputChange(
                          event,
                          index,
                          null,
                          null
                        )
                      }
                    />
                  ) : (
                    <div>{formatNumberToCurrency(item?.price)}</div>
                  )}
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      ) : null}

      <PageTitle title="Proveedores" />
      {msprovider !== null ? (
        <Card className="providers_card">
          <CardHeader
            className="providers_card_header"
            title="Envios MS"
            action={
              msprovider?.extended_route === true ? (
                <MUISelect
                  label="Ruta extendida"
                  idSelector="id"
                  selector="title"
                  name="extended_route_selected"
                  onChange={onMSInputChange}
                  items={msprovider?.pallet_options}
                  value={msprovider?.extended_route_selected}
                  readOnly={isShipment}
                />
              ) : null
            }
          />

          <CardContent className="providers_card_msprovider">
            <FormControlLabel
              label=""
              id="checkbox"
              control={
                <Checkbox
                  className="providers_card_content-checkbox"
                  checked={msprovider?.selected === 1}
                  onChange={handleMSSelection}
                  name="selected"
                  disabled={isShipment}
                />
              }
            />

            <MUIAmountField
              className="providers_card_content-input"
              name="price"
              prefix="$ "
              label="Costo total"
              value={
                msprovider?.has_agreement === true
                  ? 0
                  : msprovider?.price?.toFixed(2)
              }
              variant="standard"
              onChange={(event) => onMSInputChange(event)}
              // error={isNaN( msprovider?.price )}
              disabled={true}
            />

            <MUIAmountField
              className="providers_card_content-input"
              name="profit_percentage"
              label="Adicional(%)"
              value={
                msprovider?.has_agreement === true
                  ? 0
                  : msprovider?.profit_percentage?.toFixed(2)
              }
              onChange={(event) => onMSInputChange(event)}
              error={isNaN(msprovider?.profit_percentage)}
              disabled={
                msprovider?.selected === 0 || msprovider?.has_agreement === true
              }
            />

            <MUIAmountField
              className="providers_card_content-input"
              name="final_price"
              label="Precio final"
              value={msprovider?.final_price?.toFixed(2)}
              // error={isNaN( msprovider?.final_price )}
              disabled
            />
          </CardContent>
        </Card>
      ) : null}

      {composeProviders()}

      <Tooltip
        title={hasCosts === false ? 'Debe calcular los costos primero' : ''}
      >
        <AddButton onClick={addProviders} disabled={isShipment} />
      </Tooltip>
    </>
  );
};

export default QuoteProvidersView;

QuoteProvidersView.propTypes = {
  providers: PropTypes.array.isRequired,
  addProviders: PropTypes.func.isRequired,
  addedProviders: PropTypes.array.isRequired,
  onDeleteProvider: PropTypes.func.isRequired
};
