import React, {useEffect, useState} from 'react';

import {t as typy} from 'typy';
import {toast} from 'react-toastify';

import EditFieldModal from '../../../components/UI/EditFieldModal';
import DelaysView from './DelaysView';
import {getDelaysCostService, updateDelaysCostService} from '../../../services/extra-costs-services';
import {showErrorMessages} from '../../../utils/utils';

const DelaysComponent = () => {
  const [ loadingData, setLoadingData ] = useState(false);
  const [ delays, setDelays ] = useState([]);
  const [ editItem, setEditItem ] = useState(null);
  
  useEffect(() => {
    setLoadingData(true);
    getDelaysCostService().then((response) => {
      const dataArr = typy(response.units).safeArray;
      setDelays(dataArr);
    }).catch(error => {
      console.error('DelaysComp -> Error al obtenber lista de costos: ', error);
      showErrorMessages( error );
    }).finally(() => {
      setLoadingData(false);
    });
  }, []);
  
  const handleOnEditPrice = (data) => {
    const editableElement = {
      data: data,
      title: 'Costo de estadía de ' + data.unit_name,
      service: 'cost',
      fields: [
        {
          id: 'cost',
          label: 'Costo',
          type: 'number',
          value: data.cost
        }
      ]
    };
    setEditItem(editableElement);
  };
  
  const handleOnEditModalClose = () => {
    setEditItem(null);
  };
  
  const handleOnEditModalSave = (editedRow) => {
    updateDelaysCostService( editedRow.unit_id, editedRow.cost ).then(response => {
      updateDelayOnList(response);
      toast('Costo actualizado', { type: 'success' });
    }).catch(error => {
      toast('Arror al actualizar demora', { type: 'error' });
      showErrorMessages( error );
    }).finally(() => {
      setEditItem(null);
    });
  };
  
  
  /**
   * We update current delays list, with new data returned from update.
   * @param delay
   */
  const updateDelayOnList = (delay) => {
    for (let u in delays){
      if (delays[u].unit_id === delay.unit_id ) {
        let newDelays = [ ...delays ];
        newDelays[u] = delay;
        setDelays(newDelays);
      }
    }
  };
  
  return (
    <>
      <DelaysView
        data={delays}
        loading={loadingData}
        onEditPrice={handleOnEditPrice}
      />
      {editItem && (
        <EditFieldModal
          open={true}
          title={editItem.title}
          service={editItem.service}
          data={editItem.data}
          fields={editItem.fields}
          handleOnClose={handleOnEditModalClose}
          handleOnSave={handleOnEditModalSave}
        />
      )}
    </>
  );
};

export default DelaysComponent;
