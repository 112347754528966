/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

import ClearIcon from '@material-ui/icons/Clear';

import { PropTypes } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '150px',
    minWidth: '150px',
    padding: '0 5px',
    '& .MuiTextField-root': {
      padding: '5px 0 0 0'
    },
    '& .MuiInputBase-adornedStart': {
      backgroundColor: 'white',
      paddingLeft: '10px'
    },
    '& .MuiIconButton-root': {
      padding: '0'
    },
    '& .MuiInputLabel-root': {
      paddingBottom: '10px',
      color: theme.palette.normal.main
    }
  },
  datePicker: {},
  input: {
    padding: '15px 5px 15px 0',
    fontSize: '12px',
    height: '12px',
    backgroundColor: 'white'
  },
  clearIcon: {
    position: 'absolute',
    left: '132px',
    top: '16px',
    width: '16px',
    cursor: 'pointer',
    zIndex: '10',
    color: '#333'
  }
}));

export const MUIDateField = ({
  id,
  name,
  label,
  value,
  onChange,
  ...props
}) => {
  const classes = useStyles();
  const [ date, setDate ] = useState(null);

  const onClear = () => {
    setDate(null);
    onChange(null);
  };

  const onDateChange = (e) => {
    setDate(e);
  };

  useEffect(() => {
    if (value === null || value === undefined) {
      setDate(null);
      return;
    }
    const newValue = moment.isMoment(value)
      ? value
      : moment(value, 'yyy-MM-DD hh:mm');

    if (!newValue.isSame(date)) {
      setDate(newValue);
    }
  }, [ value ]);

  useEffect(() => {
    if (moment.isMoment(date)) {
      onChange({ value: date.format('yyy-MM-DD HH:mm'), id: id, name: name });
    }
  }, [ date ]);

  return (
    <div className={classes.root}>
      <KeyboardDateTimePicker
        className={classes.datePicker}
        autoOk
        id={id}
        label={label}
        value={date}
        onChange={onDateChange}
        placeholder="dd/mm/yy hh:mm"
        format="DD/MM/YY hh:mm"
        InputAdornmentProps={{ position: 'start' }}
        inputVariant="standard"
        variant="inline"
        InputProps={{
          classes: {
            input: classes.input
          }
        }}
        disableToolbar={true}
      />
      {date && <ClearIcon className={classes.clearIcon} onClick={onClear} />}
    </div>
  );
};

MUIDateField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
  // recollectionDate: PropTypes.func.isRequired
};

export default MUIDateField;
