import ApiService from './api-service';

export const getClientsService = () => {
	const serviceDef = {
		url: 'client/list',
		method: 'post',
		params: null
	};
	return ApiService.request(serviceDef);
};
export const getClientsSelectService = () => {
	const serviceDef = {
		url: 'client/list/select',
		method: 'get',
		params: null
	};
	return ApiService.request(serviceDef);
};

export const createClientService = (client) => {
	const serviceDef = {
		url: 'client/create',
		method: 'post',
		params: client
	};
	return ApiService.request(serviceDef);
};

export const getClientByIdService = (clientId) => {
	const serviceDef = {
		url: 'client/show',
		method: 'post',
		params: { id: clientId }
	};
	return ApiService.request(serviceDef);
};

export const updateClientService = (client) => {
	const serviceDef = {
		url: 'client/update',
		method: 'post',
		params: client
	};
	return ApiService.request(serviceDef);
};

export const deleteClientService = (clientId) => {
	const serviceDef = {
		url: 'client/delete',
		method: 'post',
		params: { id: clientId }
	};
	return ApiService.request(serviceDef);
};

export const searchOverClients = (pattern) => {
	const serviceDef = {
		url: 'client',
		method: 'post',
		params: { search: pattern }
	};
	return ApiService.request(serviceDef);
};

export const assignUserService = ({ id, user_id }) => {
	const serviceDef = {
		url: 'client/add-assessor',
		method: 'post',
		params: { id, user_id }
	};
	return ApiService.request(serviceDef);
};
