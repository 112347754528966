/* eslint-disable react-hooks/exhaustive-deps */

/** Import react/libraries section **/
import React, {useEffect, useState} from 'react';

/** Import component section **/
import ContainerModalView from './ContainerModalView';
import {toast} from 'react-toastify';
import {createContainerService, updateContainerService} from '../../../services/catalogues-services';
import {showErrorMessages} from '../../../utils/utils';

/** Import helpers section **/

/** Import resources section **/

const ContainerModalComponent = ({
	                                 item,
	                                 onClose,
	                                 onConfirm,
                                 }) => {
	const [container, setContainer] = useState( {} );
	
	useEffect( () => {
		setContainer( item );
	}, [] );
	
	const handleOnInputChange = (data) => {
		const {value, name} = data;
		setContainer( (prevState) => ({
			...prevState,
			[name]: value,
		}) );
	};
	
	const handleOnSave = (event) => {
		event.preventDefault();
		if (!item.id) {
			createContainerService( container )
				.then( () => {
					toast( 'Empaque agregada', {type: 'success'} );
					onConfirm();
				} )
				.catch( error => {
					toast( 'Error, no se pudo guardar', {type: 'error'} );
					showErrorMessages( error );
				} );
		} else {
			updateContainerService( container )
				.then( () => {
					toast( 'Empaque actualizado', {type: 'success'} );
					onConfirm();
				} )
				.catch( error => {
					toast( 'Error, no se pudo actualizar', {type: 'error'} );
					showErrorMessages( error );
				} );
		}
	};
	
	return (
		<ContainerModalView
			container={container}
			onInputChange={handleOnInputChange}
			onSave={handleOnSave}
			onClose={onClose}
		/>
	);
};

export default ContainerModalComponent;
