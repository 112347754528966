/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import is from 'is_js';

/** Import component section **/
import UserModalView from './UserModalView';

/** Import helpers section **/
import {
  createUserService,
  getUserByIdService,
  updateUserService
} from '../../../services/users-services';
import { getRolesCatalog } from '../../../services/catalogues-services';
import { showErrorMessages } from '../../../utils/utils';

const UserModalComponent = ({ data, onClose, updateList }) => {
  const [user, setUser] = useState(data);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const getRoles = useCallback(() => {
    getRolesCatalog()
      .then((response) => {
        setRoles(response);
      })
      .catch((error) => {
        console.error('UserModalComponent - Error getting: ', error);
      });
  }, []);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const getUser = useCallback(() => {
    setLoading(true);
    getUserByIdService(data?.id)
      .then((response) => {
        setUser(response);
      })
      .catch((error) => {
        console.error('UserModalComp - Error fetching user data: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [data?.id]);

  useEffect(() => {
    if (data?.id) {
      getUser();
    }
  }, [data, getUser]);

  const onInputChange = (data) => {
    const { value, name } = data;
    setUser((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSwitchChange = (event) => {
    let value = event.target.checked === false ? 0 : 1;
    setUser((prevState) => ({
      ...prevState,
      active: value
    }));
  };

  const validateData = useCallback(() => {
    if (
      user.password === '' ||
      user.password_conf === '' ||
      user.name === '' ||
      user.last_name === ''
    ) {
      // setDisableButton( false );
    } else if (user.password !== user.password_conf) {
      // setDisableButton( true );
    } else if (!is.email(user.email)) {
      // setDisableButton( true );
    }
    // setDisableButton()
  }, [
    user.email,
    user.last_name,
    user.name,
    user.password,
    user.password_conf
  ]);

  useEffect(() => {
    validateData();
  }, [user, validateData]);

  const onSave = () => {
    if (user?.id) {
      updateUserService(user)
        .then(() => {
          toast('Usuario actualizado correctamente', { type: 'success' });
          updateList(user);
          onClose();
        })
        .catch((error) => {
          toast('Error al actualizar usuario', { type: 'warning' });
          showErrorMessages(error);
        });
    } else {
      if (
        user.password !== '' ||
        user.password_conf !== '' ||
        user.name !== '' ||
        user.last_name !== '' ||
        user.email !== '' ||
        is.email(user.email)
      ) {
        if (user.password === user.password_conf) {
          createUserService(user)
            .then((response) => {
              toast('Usuario creado correctamente', { type: 'success' });
              updateList(response);
              onClose();
            })
            .catch((error) => {
              showErrorMessages(error);
            });
        } else {
          toast('Las contraseñas no coinciden', { type: 'warning' });
        }
      } else {
        toast('Favor de llenar todos los campos', { type: 'warning' });
      }
    }
  };

  return (
    <UserModalView
      user={user}
      roles={roles}
      loading={loading}
      onInputChange={onInputChange}
      onSwitchChange={onSwitchChange}
      onClose={onClose}
      onSave={onSave}
    />
  );
};

export default UserModalComponent;
