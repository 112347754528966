/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/
import { setOrigin, setDestination } from '../../redux/quotation/quoteSlice';

/** Import services section **/
import {
  getContactsService,
  createContactService,
  updateContactService
} from '../../services/contacts-services';

/** Import component section **/
import { ORIGIN, DESTINATION } from './contactConstants';
import ContactSearchView from './ContactSearchView';
import ModalComponent from '../../components/UI/ModalComponent';

const ContactSearchComponent = ({ mode, complete, onChange }) => {
  const [options, setOptions] = useState([]);
  const [contact, setContact] = useState(null);
  const [canSave, setCanSave] = useState(false);
  const [openSaveConfirm, setOpenSaveConfirm] = useState(false);

  let dispatch = useDispatch();

  let clientId = useSelector((store) => store.quote.quotation.client.id);
  let origin = useSelector((store) => store.quote.quotation.origin);
  let destination = useSelector((store) => store.quote.quotation.destination);

  let contactData = mode === ORIGIN ? origin : destination;

  const getContacts = useCallback(
    (clientId) => {
      let params = {
        client_id: clientId,
        search: ''
      };
      getContactsService(params)
        .then((response) => {
          setOptions(response);
          if (contact?.id) {
            const found = response.find((item) => item.id === contact.id);
            if (!found) setContact(null);
          }
        })
        .catch((error) => {
          console.error('ContactsComp -> Error getting contacts: ', error);
        });
    },
    [contact?.id]
  );

  useEffect(() => {
    if (clientId) {
      getContacts(clientId);
      setCanSave(complete);
    } else {
      setOptions([]);
      setContact(null);
      setCanSave(false);
    }
  }, [clientId, complete, getContacts]);

  const handleOnChange = (contactSelected) => {
    setContact(contactSelected);

    let contactData = {
      id: contactSelected?.id,
      name: contactSelected?.name,
      contact: contactSelected?.contact,
      email: contactSelected?.email,
      phone: contactSelected?.phone,
      suburb: contactSelected?.suburb,
      street: contactSelected?.street,
      external_number: contactSelected?.number,
      internal_number: contactSelected?.internal_number,
      references: contactSelected?.reference_streets,
      cp: contactSelected?.cp,
      rfc: contactSelected?.rfc,
      state_id: contactSelected?.state_id,
      municipality_id: contactSelected?.municipality_id,
      city_id: contactSelected?.city_id,
      neighborhood_id: contactSelected?.neighborhood_id,
      client_id: contactSelected?.client_id
    };
    if (contactSelected?.cp) {
      switch (mode) {
        case ORIGIN: {
          dispatch(setOrigin({ origin: contactData }));
          onChange(contactSelected);
          return;
        }
        case DESTINATION: {
          dispatch(setDestination({ destination: contactData }));
          onChange(contactSelected);
          return;
        }
        default: {
          throw new Error();
        }
      }
    }
  };

  const handleOnSave = () => {
    setOpenSaveConfirm(true);
  };

  const handleOnSaveModalClose = () => {
    setOpenSaveConfirm(false);
  };

  const handleOnSaveModalConfirm = () => {
    const contactMap = {
      name: contactData.name,
      contact: contactData.contact,
      number: contactData?.external_number,
      reference_streets: contactData?.references
    };

    if (!contactData?.client_id) contactMap.client_id = clientId;
    const newContact = Object.assign({}, contactData, contactMap);

    if (contact?.id) {
      updateContactService(newContact)
        .then((response) => {
          toast.success('Contacto actualizado correctamente');
          getContacts(clientId);
        })
        .catch((error) => {
          toast.error('Error al actualizar Contacto');
          console.error('Contact Modal - Update error: ', error);
        });
    } else {
      createContactService(newContact)
        .then((response) => {
          toast.success('Contacto agregado correctamente');
          getContacts(clientId);
        })
        .catch((error) => {
          toast.error('Error al agregar Contacto');
          console.error('Contact Modal  - Create error: ', error);
        });
    }

    setOpenSaveConfirm(false);
  };

  return (
    <>
      <ContactSearchView
        mode={mode}
        canSave={canSave}
        show={clientId ? true : false}
        options={options}
        selector="name"
        value={contact}
        onChange={handleOnChange}
        onSave={handleOnSave}
      />
      <ModalComponent
        open={openSaveConfirm}
        onClose={handleOnSaveModalClose}
        onConfirm={handleOnSaveModalConfirm}
        title={contact?.id ? 'Actualizar contacto' : 'Guardar nuevo contacto'}
      >
        {'¿Estas seguro de ' +
          (contact?.id ? 'guardar' : 'crear') +
          ' el contacto ' +
          (mode === ORIGIN ? contactData?.name : contactData?.name) +
          '?'}
      </ModalComponent>
    </>
  );
};

export default ContactSearchComponent;
