/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import component section **/
import ProviderModalView from './ProviderModalView';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  saveProviderService,
  updateProviderService
} from '../../../services/providers-services';
import { showErrorMessages } from '../../../utils/utils';

/** Import resources section **/

const ProviderModalComponent = ({ item, onClose, onConfirm }) => {
  const [provider, setProvider] = useState({});
  const [selectedTypes, setSelectedTypes] = useState([]);

  useEffect(() => {
    setProvider(item);
    setSelectedTypes(item.shipment_types);
  }, []);

  const onInputChange = (data) => {
    const { value, name } = data;
    setProvider((prevState) => ({
      ...prevState,
      [name]: value
    }));
    console.log(value, name);
  };

  const handleOnSave = (event) => {
    event.preventDefault();
    provider.shipment_types = selectedTypes;
    if (!item.id) {
      saveProviderService(provider)
        .then(() => {
          toast('Proveedor agregado', { type: 'success' });
          onConfirm();
        })
        .catch((error) => {
          toast('Error, no se pudo guardar', { type: 'error' });
          showErrorMessages(error);
        });
    } else {
      updateProviderService(provider)
        .then(() => {
          toast('Proveedor actualizado', { type: 'success' });
          onConfirm();
        })
        .catch((error) => {
          toast('Error, no se pudo actualizar', { type: 'error' });
          showErrorMessages(error);
        });
    }
  };

  const handleOnShipmentTypesChange = (typesArr) => {
    setSelectedTypes(typesArr);
  };

  return (
    <ProviderModalView
      provider={provider}
      selectedTypes={selectedTypes}
      onInputChange={onInputChange}
      onShipmentTypesChange={handleOnShipmentTypesChange}
      onClose={onClose}
      onSave={handleOnSave}
    />
  );
};

export default ProviderModalComponent;
