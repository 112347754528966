import ApiService from './api-service';

/**
 *  Request all shipments reports
 * @returns {Promise<any>}
 */
export const getShipmentReportsService = () => {
  const serviceDef = {
    url: 'shipment/report',
    method: 'get',
    params: null
  };

  return ApiService.request(serviceDef);
};

export const getQuotationReportsService = () => {
  const serviceDef = {
    url: 'shipment/report-quotations',
    method: 'get',
    params: null
  };

  return ApiService.request(serviceDef);
};

/**
 * Request all clients reports
 * @returns {Promise<*>}
 */
export const getClientsReportsService = () => {
  const serviceDef = {
    url: 'client/report',
    method: 'get',
    params: null
  };

  return ApiService.request(serviceDef);
};

export const getReceivableReportService = () => {
  const serviceDef = {
    url: 'shipment/report-to-pay',
    method: 'get',
    params: null
  };
  return ApiService.request(serviceDef);
};
