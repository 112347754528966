/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import ShipmentTypeComponent from '../../../components/forms/ShipmentTypeForm/ShipmentTypeComponent';
import ShipDetailsComponent from '../../../components/forms/ShipDetailsForm';
import EspecialRequirementsComponent from '../../../components/forms/EspecialRequirements';
import EspecialConditionsComponent from '../../../components/forms/EspecialConditions';

/** Import resources section **/
import './QuoteServicesStyles.scss';
import { MODE_QUOTATION } from '../../../utils/enumerates';

const QuoteServicesView = () => {
  return (
    <div className="quoteServices">
      <div className="quoteServices_left">
        
        <div className="quote_card">
          <ShipmentTypeComponent />
        </div>
        
        <div className="quote_card">
          <ShipDetailsComponent modeType={MODE_QUOTATION} />
        </div>
        
        <EspecialRequirementsComponent />
        
      </div>

      <div className="quoteServices_right">
        <EspecialConditionsComponent />
      </div>
    </div>
  );
};

export default QuoteServicesView;
