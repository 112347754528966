/*********************************************************************
 *  QUOTATION STATUS ENUMERATE **************************************
 *********************************************************************/

export const STATUS_QUOTATION = 1;
export const STATUS_APPROVED = 2;
export const STATUS_REJECTED = 3;
export const STATUS_CANCELED = 4;
export const STATUS_DELIVERED = 5;

/*********************************************************************
 *  CREDIT REQUEST ENUMERATE  ****************************************
 *********************************************************************/

export const CREDIT_NOT_REQUESTED = 0;
export const CREDIT_REQUESTED = 1;
export const CREDIT_APPROVED = 2;

/*********************************************************************
 *  MISCELLANEOUS ENUMERATE ******************************************
 *********************************************************************/

export const MODE_REQUEST = 'MODE_REQUEST';
export const MODE_QUOTATION = 'MODE_QUOTATION';

/*********************************************************************
 *  CONTAINER TYPES ENUMERATE ****************************************
 *********************************************************************/

export const OTHER_CONTAINER = 12;
export const CONTAINER_TYPE_MESSAGE = 1;
export const CONTAINER_TYPE_PARCEL = 2;
export const CONTAINER_TYPE_CONSOLIDATED_LOAD = 3;
export const CONTAINER_TYPE_DEDICATED_MOVE = 4;
