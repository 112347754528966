/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    paddingRight: '5px',
    '& .MuiTextField-root': {
      padding: '5px 0 0 5px'
    },
    '& .MuiInputBase-input': {
      fontSize: '12px',
      paddingLeft: '15px'
    }
  }
}));

export const MUITimeField = ({
  id,
  name,
  labelId,
  label,
  value,
  onChange,
  minTime,
  disabled,
  ...props
}) => {
  const classes = useStyles();

  const onTimeChange = (event) =>
    onChange({ value: event.target.value, id: id, name: name });

  return (
    <>
      <div className={classes.root}>
        {label && (
          <InputLabel id={labelId ? labelId : label + '__InputLabel'}>
            {label}
          </InputLabel>
        )}
        <TextField
          id="time"
          type="time"
          min={minTime}
          value={value}
          onChange={onTimeChange}
          disabled={disabled}
        />
      </div>
    </>
  );
};

MUITimeField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default MUITimeField;
