/** Import react/libraries section **/
import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

/** Import resources section **/

/** Import helpers section **/

/** Import styles section **/
import './LocationSelectStyles.scss';

/** Import component section **/
import MUITextField from '../../UI/inputs/MUITextField';
import MUISelect from '../../UI/inputs/MUISelect';

const LocationSelectView = ({
  location,
  neighborhoods,
  onInputChange,
  onLoadLocationData
}) => {
  let isShipment = useSelector((store) => store.quote?.quotation?.is_shipment);

  const cpElement = useRef(null);

  useEffect(() => {
    if (cpElement.current) {
      const element = cpElement.current;
      const handler = (event) => {
        onLoadLocationData(parseInt(element.value));
      };

      element.addEventListener('blur', handler);
      return () => element.removeEventListener('blur', handler);
    }
  }, [onLoadLocationData]);

  return (
    <div className="locationSelect" id="locationSelect">
      <MUITextField
        id="postal_code"
        name="postal_code"
        label="CP"
        value={location?.postal_code}
        type="text"
        onChange={onInputChange}
        inputProps={{ ref: cpElement }}
        disabled={isShipment}
      />
      <MUITextField
        required
        id="state"
        name="state"
        type="text"
        label="Estado"
        value={location?.state_name}
        disabled
        readOnly={isShipment}
      />
      <MUITextField
        required
        id="municipality"
        name="municipality"
        type="text"
        label="Municipio"
        value={location?.municipality_name}
        disabled
        readOnly={isShipment}
      />
      <MUITextField
        required
        id="city"
        name="city"
        type="text"
        label="Ciudad"
        value={location?.city_name}
        disabled
        readOnly={isShipment}
      />
      <MUISelect
        label="Colonia"
        id="neighborhood"
        name="neighborhood_id"
        idSelector="id"
        defaultValue={location?.neighborhood_id}
        items={neighborhoods}
        selector="name"
        noSelectedLabel="Colonias"
        onChange={onInputChange}
        readOnly={isShipment}
      />
      <MUITextField
        required
        id="street"
        name="street"
        type="text"
        label="Calle"
        value={location?.street}
        onChange={onInputChange}
        readOnly={isShipment}
      />
      <MUITextField
        required
        id="number"
        name="number"
        type="text"
        label="Número exterior"
        value={location?.number}
        onChange={onInputChange}
        readOnly={isShipment}
      />
      <MUITextField
        required
        id="internal_number"
        name="internal_number"
        type="text"
        label="Número interior"
        value={location?.internal_number}
        onChange={onInputChange}
        readOnly={isShipment}
      />
      <MUITextField
        required
        id="reference_streets"
        name="reference_streets"
        label="Referencias (entre que calles)"
        value={location?.reference_streets}
        onChange={onInputChange}
        readOnly={isShipment}
      />
    </div>
  );
};

export default LocationSelectView;
