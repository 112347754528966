import ApiService from './api-service';
/**
 * Get units catalog..
 * @returns {Promise<*>}
 */

export const getConsigmentNoteService = (shipmentId) => {
  const serviceDef = {
    url: 'consigment-note/read',
    method: 'get',
    params: { shipment_id: shipmentId }
  };
  return ApiService.request(serviceDef);
};

export const consigmentNoteUpdateFolioService = ({
  folio,
  id,
  shipment_id
}) => {
  const serviceDef = {
    url: 'consigment-note/update/folio',
    method: 'post',
    params: {
      folio,
      id,
      shipment_id
    }
  };
  return ApiService.request(serviceDef);
};

export const updateConsigmentNoteDataService = ({
  id,
  send_date,
  send_hour,
  delivery_date,
  delivery_hour,
  sat_payment_method_id,
  business_billing_id
}) => {
  const serviceDef = {
    url: 'consigment-note/update',
    method: 'post',
    params: {
      id,
      send_date,
      send_hour,
      delivery_date,
      delivery_hour,
      sat_payment_method_id,
      business_billing_id
    }
  };
  return ApiService.request(serviceDef);
};

export const consigmentNoteResponseService = ({ shipment_id, xml, pdf }) => {
  console.log(xml, pdf);
  const serviceDef = {
    url: 'consigment-note/response/save',
    method: 'post',
    params: { shipment_id, xml, pdf }
  };

  return ApiService.request(serviceDef);
};

export const consigmentNoteUpdateStatusService = ({
  status,
  stamped_date,
  shipment_id
}) => {
  const serviceDef = {
    url: 'consigment-note/status/save',
    method: 'post',
    params: { status, stamped_date, shipment_id }
  };

  return ApiService.request(serviceDef);
};
