import * as roles from './ROLES';

import {
  EDIT_CLIENT_PERMISSION,
  ASSIGN_CLIENT_PERMISSION,
  DELETE_CLIENT_PERMISSION,
  ASSIGN_QUOTE_PERMISSION,
  DELETE_QUOTE_PERMISSION,
  DISCOUNT_PERMISSION,
  EDIT_CONTACT_PERMISSION,
  DELETE_CONTACT_PERMISSION
} from './PERMISSIONS';

import { getRole } from './utils';

export const rolesPermissions = [
  {
    id: roles.ADMINISTRADOR,
    permissions: [
      EDIT_CLIENT_PERMISSION,
      ASSIGN_CLIENT_PERMISSION,
      DELETE_CLIENT_PERMISSION,
      ASSIGN_QUOTE_PERMISSION,
      DELETE_QUOTE_PERMISSION,
      DISCOUNT_PERMISSION,
      EDIT_CONTACT_PERMISSION,
      DELETE_CONTACT_PERMISSION
    ]
  },
  {
    id: roles.GERENTE_DE_VENTA,
    permissions: [
      EDIT_CLIENT_PERMISSION,
      ASSIGN_CLIENT_PERMISSION,
      DELETE_CLIENT_PERMISSION,
      ASSIGN_QUOTE_PERMISSION,
      DELETE_QUOTE_PERMISSION,
      DISCOUNT_PERMISSION,
      EDIT_CONTACT_PERMISSION,
      DELETE_CONTACT_PERMISSION
    ]
  },
  {
    id: roles.COORDINADOR_DE_VENTAS,
    permissions: [
      EDIT_CLIENT_PERMISSION,
      ASSIGN_CLIENT_PERMISSION,
      DISCOUNT_PERMISSION,
      EDIT_CONTACT_PERMISSION,
      DELETE_CONTACT_PERMISSION
    ]
  },
  {
    id: roles.ASESOR_DE_TRAFICO,
    permissions: [
      EDIT_CLIENT_PERMISSION,
      EDIT_CONTACT_PERMISSION,
      DELETE_CONTACT_PERMISSION
    ]
  },
  {
    id: roles.FACTURACION_Y_COBRANZA,
    permissions: [EDIT_CLIENT_PERMISSION, ASSIGN_CLIENT_PERMISSION]
  }
];

export const getPermissionsByUserRole = () => {
  const roleId = getRole();
  const rolePermissions = rolesPermissions.find((item) => item.id === roleId);
  if (rolePermissions && rolePermissions.permissions) {
    return rolePermissions.permissions;
  } else {
    return [];
  }
};
