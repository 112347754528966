/** Import react/libraries section **/
import React, {Fragment, useMemo} from 'react';
import {PropTypes} from 'prop-types';

/** Import component section **/
import PageTitle from './../../components/PageTitle';
import FilterBy from '../../components/UI/FilterBy';
import MUIDataTable from '../../components/MUIDataTable';
import AddButton from '../../components/UI/AddButton';
import ShipmentTypesSelect from '../../components/ShipmentTypesSelect';

/** Import helpers section **/
/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './ProvidersStyles.scss';

const ProvidersView = ({
  providers,
  loading,
  onFilterBy,
  onShipmentTypesFilterChange,
  onAdd,
  onEdit,
  onDelete
}) => {
  const ServiceCell = ({ row, shipmentTypeId }) => {
    const hasService =
      row.shipment_types?.find((element) => element.id === shipmentTypeId) !==
      undefined;
    if (!hasService) return <></>;
    return (
      <div className="providers_check-cell">
        <CheckCircleIcon className="providers_check-cell_icon" />
      </div>
    );
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          data-cy="edit-button"
          className="g-row-action-button"
          onClick={() => onEdit(row)}
        />
        <DeleteIcon
          data-cy="delete-button"
          className="g-row-action-button delete"
          onClick={() => onDelete(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'id',
        selector: 'id',
        omit: true
      },
      {
        name: 'Número de proveedor',
        selector: 'provider_number',
        sortable: true,
        center: true,
        wrap: true
      },
      {
        name: 'Nombre proveedor',
        selector: 'name',
        sortable: true,
        center: true
      },
      {
        name: 'RFC',
        selector: 'rfc',
        sortable: true,
        center: true
      },
      {
        name: 'Contacto',
        selector: 'contact_name',
        sortable: true,
        center: true
      },
      {
        name: 'Teléfono',
        selector: 'phone',
        sortable: true,
        center: true
      },
      {
        name: 'Correo electrónico',
        selector: 'email',
        sortable: true,
        center: true,
        wrap:true
      },
      {
        name: 'Dirección',
        selector: 'address',
        sortable: true,
        center: true,
        minWidth: '200px',
        wrap:true
      },
      {
        name: 'Mensajeria',
        cell: (row) => <ServiceCell row={row} shipmentTypeId={1} />,
        center: true
      },
      {
        name: 'Paqueteria',
        cell: (row) => <ServiceCell row={row} shipmentTypeId={2} />,
        center: true
      },
      {
        name: 'Carga consolidada',
        cell: (row) => <ServiceCell row={row} shipmentTypeId={3} />,
        center: true
      },
      {
        name: 'Carga dedicada',
        cell: (row) => <ServiceCell row={row} shipmentTypeId={4} />,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <Fragment>
      <PageTitle title="Proveedores" />
      <Paper className="g-page-header providers_header">
        <div className="g-page-header providers_header_filters">
          <div className="providers_header_filters_filterby">
            <FilterBy onChange={onFilterBy} />
          </div>
          <ShipmentTypesSelect onChange={onShipmentTypesFilterChange} />
        </div>
        <AddButton onClick={onAdd} />
      </Paper>
      <MUIDataTable data={providers} columns={tableColumns} loading={loading} />
    </Fragment>
  );
};

ProvidersView.propTypes = {
  providers: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  filteringHandler: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default ProvidersView;
