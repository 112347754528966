import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {updateField} from '../../../redux/quotation/quoteSlice';

import PickupFormView from './PickupFormView';
import moment from 'moment';

export const PickupFormComponent = () => {
	let dispatch = useDispatch();
	
	const recollectionDate = useSelector( (store) => store.quote.quotation.recollection_date );
	const collectionStartTime = useSelector( (store) => store.quote.quotation.collection_start_time );
	const collectionEndTime = useSelector( (store) => store.quote.quotation.collection_end_time );
	let isShipment = useSelector( (store) => store.quote?.quotation?.is_shipment );
	
	const [today, setToday] = useState( null );
	
	useEffect( () => {
		setToday( moment().format( 'YYYY-MM-DD' ) );
	}, [] );
	
	
	const handleOnChangeRecollection = (data) => {
		const {name, value} = data;
		dispatch( updateField( {field: name, value: value} ) );
	};
	
	return (
		<PickupFormView
			today={today}
			isShipment={isShipment}
			onChange={handleOnChangeRecollection}
			recollectionDate={recollectionDate}
			collectionEndTime={collectionEndTime}
			collectionStartTime={collectionStartTime}
		/>
	);
};

export default PickupFormComponent;
