/* eslint-disable react-hooks/exhaustive-deps */

/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

/** Import component section **/
import ExtendedRouteAddModalView from './ExtendedRouteAddModalView';

/** Import helpers section **/
import { getMunicipalitiesByStateService } from '../../../services/location-services';
import {
  createExtendedRouteService,
  updateExtendedRouteService
} from '../../../services/routes-services';
import { showErrorMessages } from '../../../utils/utils';

/** Import resources section **/

const extendedRouteModel = {
  km: null,
  city_id: null,
  municipality_id: null,
  price_1: null,
  price_2: null,
  price_3: null
};

const ExtendedRouteAddModalComponent = ({
  municipalityId,
  stateId,
  onClose,
  modalMode,
  routeData,
  updateRouteOnList,
  pushNewProviderToList
}) => {
  const [municipalities, setMunicipalities] = useState([]);
  const [route, setRoute] = useState(extendedRouteModel);

  useEffect(() => {
    municipalityId = parseInt(municipalityId);
    let municipalityName = 'municipality_id';
    setRoute((prevState) => ({
      ...prevState,
      [municipalityName]: municipalityId
    }));
  }, [municipalityId]);

  useEffect(() => {
    if (modalMode === 'edit') {
      setRoute(routeData);
      requestMunicipalities(route?.state_id);
    } else if (modalMode === 'add') {
      requestMunicipalities(stateId);
    }
  }, []);

  const requestMunicipalities = (stateId) => {
    getMunicipalitiesByStateService(stateId)
      .then((response) => {
        setMunicipalities(response);
      })
      .catch(() => {});
  };

  const onInputChange = (data) => {
    const { name, value } = data;
    setRoute((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  console.log(route);
  const onSave = (event) => {
    event.preventDefault();

    if (modalMode === 'add') {
      createExtendedRouteService(route)
        .then((response) => {
          pushNewProviderToList(response);
          onClose();
          toast('Ruta extendida agregada correctamente', { type: 'success' });
        })
        .catch((error) => {
          toast('Error al agregar ruta extendida', { type: 'error' });
          showErrorMessages(error);
        });
    } else {
      updateExtendedRouteService(route)
        .then(() => {
          toast('Ruta extendida agregada correctamente', { type: 'success' });
          updateRouteOnList(route);
          onClose();
        })
        .catch((error) => {
          showErrorMessages(error);
        });
    }
  };

  return (
    <ExtendedRouteAddModalView
      route={route}
      onSave={onSave}
      onClose={onClose}
      modalMode={modalMode}
      onInputChange={onInputChange}
      municipalities={municipalities}
    />
  );
};

ExtendedRouteAddModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  pushNewProviderToList: PropTypes.func.isRequired,
  // updateProviderOnList: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired
};

export default ExtendedRouteAddModalComponent;
