/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import PublishIcon from '@material-ui/icons/Publish';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

/** Import styles section **/
import './LoadFileStyles.scss';

/** Import helpers section **/
import { t as typy } from 'typy';

/** Import components section **/
import MUIDialogTitle from '../../../components/UI/elements/MUIDialogTitle';
import MUIDialogActions from '../../../components/UI/elements/MUIDialogActions';
import MUITextField from '../../../components/UI/inputs/MUITextField';

const LoadFileView = ({
  file,
  isCompleted,
  onFileSet,
  onUploadFile,
  onClose
}) => {
  return (
    <Dialog open={true} onClose={onClose} className="order-load-modal">
      <MUIDialogTitle onClose={onClose}>Cargado de CPs</MUIDialogTitle>
      <DialogContent>
        <div className="order-modal-fields">
          <div className="order-modal-file-field">
            <MUITextField
              id="textFile"
              label="Archivo de códigos postales"
              value={typy(file, 'name').safeString}
              fullWidth
            />
          </div>
          <div className="order-modal-icon-file">
            <input
              accept="xlsx"
              style={{ display: 'none' }}
              id="order-file"
              type="file"
              onChange={(ev) => onFileSet(ev.target)}
            />
            <label htmlFor="order-file">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <PublishIcon />
              </IconButton>
            </label>
          </div>
        </div>
      </DialogContent>

      <MUIDialogActions
        onClose={onClose}
        onConfirm={onUploadFile}
        disabled={!isCompleted}
        confirmText="Load file"
      />
    </Dialog>
  );
};

export default LoadFileView;
