/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

/** Import resources section **/
import {
  createContactService,
  updateContactService
} from '../../../services/contacts-services';

/** Import component section **/
import ContactModalView from './ContactModalView';

const ContactModalComponent = ({ item, onClose, onConfirm }) => {
  let dispatch = useDispatch();
  const [contact, setContact] = useState(item);

  useEffect(() => {
    setContact(item);
  }, [item]);

  const handleOnInputChange = (data) => {
    let { name, value } = data;
    setContact((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnSave = () => {
    if (contact?.id) {
      updateContactService(contact)
        .then((response) => {
          toast('Contacto actualizado correctamente', { type: 'success' });
          onConfirm();
        })
        .catch((error) => {
          toast('Error al actualizar Contacto', { type: 'error' });
          console.error('Contact Modal - Update error: ', error);
        });
    } else {
      createContactService(contact)
        .then((response) => {
          toast('Contacto agregado correctamente', { type: 'success' });
          onConfirm();
        })
        .catch((error) => {
          toast('Error al agregar Contacto', { type: 'error' });
          console.error('Contact Modal  - Create error: ', error);
        });
    }
  };

  const handleOnLocationLoaded = useCallback((location) => {
    setContact((prevState) => ({
      ...prevState,
      cp: location?.postal_code,
      state_id: location?.state_id,
      state_name: location?.state_name,
      municipality_id: location?.municipality_id,
      municipality_name: location?.municipality_name,
      city_id: location?.city_id,
      city_name: location?.city_name,
      neighborhood_id: location?.neighborhood_id,
      neighborhood_name: location?.neighborhood_name,
      street: location?.street,
      number: location?.number,
      internal_number: location?.internal_number,
      reference_streets: location?.reference_streets
    }));
  }, []);

  const handleOnSelectChange = (event) => {
    let { name, value } = event;
    if (name === 'neighborhood') {
      dispatch(setContact({ field: 'neighborhood_id', value: value }));
    }
  };

  return (
    <ContactModalView
      contact={contact}
      onInputChange={handleOnInputChange}
      onClose={onClose}
      onSave={handleOnSave}
      onLocationLoaded={handleOnLocationLoaded}
      onSelectChange={handleOnSelectChange}
    />
  );
};

export default ContactModalComponent;
