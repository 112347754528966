/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/UI/FilterBy';
import MUIDataTable from '../../components/MUIDataTable';

/** Import helpers section **/
import { formatNumberToCurrency } from '../../utils/formats';

/** Import resources section **/
import { ButtonBase, Paper, Tooltip } from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import './BillingsStyles.scss';
import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import ExcelIcon from '../../assets/images/excel_icon.png';
import ZipIcon from '../../assets/icons/ZipIcon.svg';
import DownloadZipIcon from '../../assets/icons/downloadZip.svg';
import DescriptionIcon from '@material-ui/icons/Description';

const BillingsView = ({
  onFilter,
  manifests,
  loading,
  onBillManifestModal,
  onCheckManifestBillStatus,
  breakManifest,
  openCloseAlert,
  showAlert,
  onShowEditDialog,
  onCreateFiles,
  onGenerateZipFile
}) => {
  const ActionBill = ({ row }) => {
    return (
      <>
        <Tooltip title="Revertir manifiesto">
          <ButtonBase onClick={() => openCloseAlert(row.id)}>
            <RestoreIcon className="g-row-action-button manifests_restore complementary-2" />
          </ButtonBase>
        </Tooltip>
        <Tooltip title="Editar">
          <ButtonBase onClick={() => onShowEditDialog(row)}>
            <EditIcon className="g-row-action-button manifests_restore complementary-2" />
          </ButtonBase>
        </Tooltip>
        <Tooltip title="Crear archivos">
          <ButtonBase onClick={() => onCreateFiles(row.id)}>
            <CollectionsBookmarkIcon className="g-row-action-button complementary-3" />
          </ButtonBase>
        </Tooltip>
        {row.file?.address !== undefined && (
          <Tooltip title="Descargar PDF">
            <a href={row.file?.address} target="_blank" rel="noreferrer">
              <ButtonBase>
                <PictureAsPdfIcon className="g-row-action-button complementary-3" />
              </ButtonBase>
            </a>
          </Tooltip>
        )}
        {row?.download_excel_url !== undefined && (
          <Tooltip title="Descargar XLSX">
            <a href={row?.download_excel_url} target="_blank" rel="noreferrer">
              <ButtonBase>
                <img
                  src={ExcelIcon}
                  className="g-row-action-button complementary-3"
                  alt=""
                />
              </ButtonBase>
            </a>
          </Tooltip>
        )}
        {row?.create_zip_url !== undefined && (
          <Tooltip title="Generar ZIP">
            <ButtonBase onClick={() => onGenerateZipFile(row?.create_zip_url)}>
              <img
                src={ZipIcon}
                className="g-row-action-button complementary-4"
                alt=""
              />
            </ButtonBase>
          </Tooltip>
        )}
        {row?.download_zip_url !== undefined && (
          <Tooltip title="Descargar ZIP">
            <a href={row?.download_zip_url} target="_blank" rel="noreferrer">
              <img
                src={DownloadZipIcon}
                className="g-row-action-button complementary-4"
                alt=""
              />
            </a>
          </Tooltip>
        )}
        {row?.shipment_bills[0]?.shipment_id !== undefined &&
          (row?.shipment_bills[0]?.shipment_type_id === 3 ||
            row?.shipment_bills[0]?.shipment_type_id === 4) &&
          row?.shipment_bills[0]?.shipment_provider_id === 1 &&
          !row?.bill_number && (
            <Tooltip title="Facturar Manifiesto">
              <DescriptionIcon
                data-cy="assign-button"
                className="g-row-action-button complementary-3"
                onClick={() => onBillManifestModal(row.id)}
              />
            </Tooltip>
          )}
        {row?.bill_number && (
          <Tooltip title="Estatus de Manifiesto">
            <DescriptionIcon
              data-cy="assign-button"
              className="g-row-action-button complementary-3"
              onClick={() => onCheckManifestBillStatus(row)}
            />
          </Tooltip>
        )}
      </>
    );
  };

  const tableColumns = [
    {
      name: 'No. Manifiesto',
      selector: 'id',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Nombre del cliente',
      selector: 'client.business_name',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Número de factura',
      selector: 'bill_number',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Fecha de emisión',
      selector: 'invoice_send_date',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'No. total de guías',
      selector: 'shipment_bills.length',
      cell: (row) => row?.shipment_bills?.length,
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Monto / Factura',
      selector: 'total',
      format: (row) => formatNumberToCurrency(row.total),
      sortable: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionBill row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true,
      width: 300
    }
  ];

  return (
    <div className="manifests">
      <PageTitle title="Facturación" />
      <Paper className="g-page-header manifests-header">
        <div className="manifests-header-filters">
          <FilterBy onChange={onFilter} width={300} />
        </div>
      </Paper>

      <MUIDataTable
        loading={loading}
        columns={tableColumns}
        data={manifests}
        showDownload={false}
      />
    </div>
  );
};

export default BillingsView;
