/* eslint-disable react-hooks/exhaustive-deps */

/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import helpers section **/
import { getRoutesService } from '../../services/routes-services';

/** Import resources section **/
import SearchInputComponent from './../Search';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '5px'
  }
}));

const RouteList = ({
  defaultRouteId,
  onSelect,
  selected = null,
  ...otherProps
}) => {
  const classes = useStyles();

  // const [routes, setRoutes] = useState([]);
  const [foundRoutes, setFoundRoutes] = useState([]);

  useEffect(() => {
    searchCallback();
  }, []);

  // const getRoutes = () => {
  //   getRoutesService()
  //     .then((response) => {
  //       const responseArr = typy(response).safeArray;
  //       addNameToRoutes(responseArr);
  //     })
  //     .catch((error) => {
  //       console.error('RouteList -> Error requesting routes:', error);
  //     });
  // };

  const searchCallback = (pattern) => {
    let patternString = pattern ? pattern : '';
    if (patternString.length > 3) {
      console.log(pattern);
      getRoutesService(pattern)
        .then((response) => {
          setFoundRoutes(response);
          // console.log(response);
        })
        .catch((error) => {
          console.error('ClientFormComp -> SearchIcon error: ', error);
        });
    }
  };

  const handleOptionSelected = (option, value) => {
    let routeData = {
      destination_municipality_name: value?.destination_municipality_name,
      destination_state_id: value?.destination_state_id,
      destination_state_name: value?.destination_state_name,
      id: value?.id,
      origin_municipality_name: value?.origin_municipality_name,
      origin_state_id: value?.origin_state_id,
      origin_state_name: value?.origin_state_name,
      route_name: value?.route_name
    };

    onSelect(routeData.id);
  };

  // const addNameToRoutes = (routesData) => {
  //   let routes = [];
  //   routesData.forEach((route) => {
  //     route.name =
  //       route.origin_municipality_name +
  //       ' - ' +
  //       route.destination_municipality_name;
  //     routes.push(route);
  //   });
  //   setRoutes(routes);
  // };

  // const onRouteSelect = (route) => {
  //   const { value } = route;
  //   setSelectedRoute(value);
  //   onSelect(value);
  // };

  return (
    <div className={classes.root}>
      <SearchInputComponent
        searchCallback={searchCallback}
        optionsFound={foundRoutes}
        handleOptionSelected={handleOptionSelected}
        optionLabel="route_name"
        selectorWidth="400"
      />

      {/* <MUISelect
        label="Rutas"
        idSelector="id"
        selector="route_name"
        items={routes}
        value={selectedRoute}
        onChange={onRouteSelect}
        {...otherProps}
      /> */}
    </div>
  );
};

export default RouteList;
