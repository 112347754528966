import ApiService from './api-service';

export const requestConsigmentNoteService = (communicationData) => {
  const serviceDef = {
    url: 'invoice/create',
    method: 'post',
    params: communicationData,
    externalServices: true
  };
  return ApiService.request(serviceDef);
};

export const consigmentNoteCheckStatusService = ({ id, rfc }) => {
  const serviceDef = {
    url: 'invoice/check/' + id + '/' + rfc,
    method: 'get',
    externalServices: true
  };

  return ApiService.request(serviceDef);
};

export const requestManifestBilling = (manifestData) => {
  const serviceDef = {
    url: 'manifest/create',
    method: 'post',
    params: manifestData,
    externalServices: true
  };

  return ApiService.request(serviceDef);
};

export const checkStatusBillManifest = ({ id, rfc }) => {
  const serviceDef = {
    url: 'invoice/check/' + id + '/' + rfc,
    method: 'get',
    externalServices: true
  };

  return ApiService.request(serviceDef);
};
