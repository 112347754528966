/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Import component section **/
import OriginFormView from './OriginFormView';

/** Import helpers section **/
import { updateOriginField } from '../../../redux/quotation/quoteSlice';
import {
  getCitiesByStateService,
  getMunicipalitiesByStateService,
  requestLocationDataService
} from '../../../services/location-services';

export const OriginFormComponent = ({ search }) => {
  let dispatch = useDispatch();

  let origin = useSelector((store) => store.quote.quotation.origin);
  let statesCatalog = useSelector((store) => store.catalogs.states);
  let quoteId = useSelector((store) => store.quote.quotation.id);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [hasCatalogs, setHasCatalogs] = useState(false);
  const [complete, setComplete] = useState(false);

  /**
   * We need have quote data for request location data,
   * in this case we use quoteId for ensure that quote has be loaded
   */
  useEffect(() => {
    if (quoteId !== null) {
      if (origin !== undefined) {
        if (origin.cp !== null) {
          if (origin.cp !== '') {
            requestLocationData(origin.cp);
          } else {
            setStates(statesCatalog);
          }
        } else {
          setStates(statesCatalog);
          if (origin.state_id !== undefined) {
            getCitiesCatalog(origin.state_id);
            getMunicipalitiesByState(origin.state_id);
          }
        }
      }
    } else {
      setStates(statesCatalog);
      if (origin.state_id !== undefined) {
        getMunicipalitiesByState(origin.state_id);
        getCitiesCatalog(origin.state_id);
      }
    }
  }, [quoteId]);

  useEffect(() => {
    const remitter = origin?.remitter;
    const contact_name = origin?.contact_name;
    const phone = origin?.phone;
    const cp = origin?.cp;
    const state_id = origin?.state_id;
    const municipality_id = origin?.municipality_id;
    // const city_id = origin?.city_id;
    const street = origin?.street;
    const external_number = origin?.external_number;

    let completeValidated = false;

    if (
      contact_name &&
      remitter &&
      phone &&
      cp &&
      state_id &&
      municipality_id &&
      // city_id &&
      street &&
      external_number
    ) {
      completeValidated = true;
    }
    setComplete(completeValidated);
  }, [origin]);

  const getCitiesCatalog = (stateId) => {
    getCitiesByStateService(stateId)
      .then((response) => {
        setCities(response);
      })
      .catch((error) => {
        console.error('originFormComp -> Error retrieving cities: ', error);
      });
  };

  const getMunicipalitiesByState = (stateId) => {
    getMunicipalitiesByStateService(stateId)
      .then((response) => {
        setMunicipalities(response);
      })
      .catch((error) => {
        console.error(
          'DestFormComp -> Error requesting municipalities: ',
          error
        );
      });
  };

  const setCatalogs = (states, cities, municipalities) => {
    setStates(states);
    setCities(cities);
    setMunicipalities(municipalities);
  };

  const requestLocationData = (cp) => {
    if (cp?.toString().length === 5) {
      requestLocationDataService(cp.toString())
        .then((response) => {
          if (response.has_catalogs) {
            if (
              response.states.length === 0 ||
              response.cities.length === 0 ||
              response.municipalities.length === 0
            ) {
              setStates(statesCatalog);
              getMunicipalitiesByState(origin?.state_id);
              getCitiesCatalog(origin?.state_id);
              setHasCatalogs(false);
            } else {
              setCatalogs(
                response.states,
                response.cities,
                response.municipalities
              );
              setHasCatalogs(response.has_catalogs);
            }
          } else {
            setStates(statesCatalog);
            getMunicipalitiesByState(origin?.state_id);
            getCitiesCatalog(origin?.state_id);
            setHasCatalogs(false);
          }
          // setOptionsFound(response);
        })
        .catch((error) => {
          console.error(
            'DestFormComp -> Error requesting location data: ',
            error
          );
        });
    }
  };

  const handleOnChange = (data) => {
    const { name, value } = data;
    if (name === 'cp' && value?.toString().length < 5) {
      // dispatch(updateOriginField({ field: 'state_id', value: null }));
      dispatch(updateOriginField({ field: 'city_id', value: null }));
      dispatch(updateOriginField({ field: 'municipality_id', value: null }));
      setCatalogs(statesCatalog, [], []);
      setHasCatalogs(false);
    }
    if (name === 'cp' && value?.toString().length === 5) {
      requestLocationData(parseInt(value));
      dispatch(updateOriginField({ field: name, value: value }));
    }
    dispatch(updateOriginField({ field: name, value: value }));
  };

  const onSelectChange = (event) => {
    let { name, value } = event;
    if (name === 'state_id') {
      if (hasCatalogs === false) {
        getMunicipalitiesByState(value);
        getCitiesCatalog(value);
      }
      dispatch(updateOriginField({ field: 'state_id', value: value }));
    } else if (name === 'municipality_id') {
      dispatch(updateOriginField({ field: 'municipality_id', value: value }));
    } else {
      dispatch(updateOriginField({ field: name, value: value }));
    }
  };

  const handleOnContactSelect = (contact) => {
    if (contact?.cp) requestLocationData(contact?.cp);
  };

  return (
    <OriginFormView
      showSearch={search}
      origin={origin}
      states={states}
      municipalities={municipalities}
      cities={cities}
      complete={complete}
      onChange={handleOnChange}
      onSelectChange={onSelectChange}
      onContactSelect={handleOnContactSelect}
    />
  );
};

export default OriginFormComponent;
