/** Import react/libraries section **/
import React, { Fragment, useMemo } from 'react';

/** Import resources section **/
import ButtonBase from '@material-ui/core/ButtonBase';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CardTravelIcon from '@material-ui/icons/CardTravel';

/** Import styles section **/
import './UnBilledShipmentsStyles.scss';

/** Import component section **/
import MUIDataTable from '../../../components/MUIDataTable';
import MUICheckBox from '../../../components/UI/inputs/MUICheckBox';

const UnBilledShipmentsView = ({
  loading,
  dateRange,
  inProcess,
  shipments,
  onFilterBy,
  onContainerGoods,
  onEditShipment,
  onDateChange,

  onCreateOperativeRequest,
  onSelectedRowsChange,
  toggleClearSelectedRows,

  onFacturar
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <Fragment>
        <EditIcon
          data-cy="edit-button"
          className="g-row-action-button"
          onClick={() => onEditShipment(row.id)}
        />

        {row?.operative_files === null || row?.operative_files === undefined ? (
          <Tooltip title="Generar solicitud operativa">
            <ButtonBase
              disabled={inProcess}
              className={inProcess === true ? 'disabled' : ''}
            >
              <NoteAddIcon
                data-cy="delete-button"
                className="g-row-action-button complementary-3"
                onClick={() => onCreateOperativeRequest(row.id)}
              />
            </ButtonBase>
          </Tooltip>
        ) : (
          <Tooltip title="Descargar solicitud operativa">
            <a
              href={row?.operative_files?.address}
              rel="noreferrer"
              target="_blank"
            >
              <GetAppIcon
                data-cy="delete-button"
                className="g-row-action-button complementary"
                // onClick={() => onDeleteShipment( row.id )}
              />
            </a>
          </Tooltip>
        )}
        <Tooltip title="Carta Porte">
          <CardTravelIcon
            data-cy="assign-button"
            className="g-row-action-button complementary-3"
            onClick={() => onContainerGoods(row?.id)}
          />
        </Tooltip>
      </Fragment>
    );
  };

  const CityCell = ({ stateName, cityName }) => {
    return (
      <div className="routes-city-wrapper">
        <div className="routes-city">{cityName}</div>
        <div className="routes-state">{stateName}</div>
      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Número de guía envios MS',
        selector: (row) => row.tracking_guide,
        sortable: true,
        center: true,
        wrap: true
      },
      {
        name: 'Cliente',
        selector: (row) => row.client.business_name,
        sortable: true,
        center: true
      },
      {
        name: 'RFC',
        selector: (row) => row.client.rfc,
        sortable: true,
        center: true
      },
      {
        name: 'Origen',
        selector: (row) =>
          row.origin?.municipality_name + ' ' + row.origin?.state_name,
        cell: (row) => (
          <CityCell
            stateName={row?.origin?.municipality_name}
            cityName={row.origin?.state_name}
          />
        ),
        center: true
      },
      {
        name: 'Destino',
        selector: (row) =>
          row.destination?.municipality_name +
          ' ' +
          row.destination?.state_name,
        cell: (row) => (
          <CityCell
            stateName={row.destination?.municipality_name}
            cityName={row.destination?.state_name}
          />
        ),
        center: true
      },
      {
        name: 'Asesor',
        selector: 'assigned_user_name',
        cell: (row) =>
          row.assigned !== null ? row?.assigned_user_name : 'Sin asignar',
        sortable: true,
        center: true
      },
      {
        name: 'Estatus de envío',
        selector: 'shipment_status',
        sortable: true,
        center: true
      },
      {
        name: 'Estatus de facturación',
        selector: (row) => (row?.billable ? 'Facturable' : ''),
        cell: (row) => (row?.billable ? 'Facturable' : ''),
        sortable: true,
        center: true
      },
      {
        name: 'Fecha',
        selector: 'quotation_date',
        sortable: true,
        center: true,
        wrap: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );
  return (
    <div className="unBilledShipments">
      <MUIDataTable
        data={shipments}
        columns={tableColumns}
        loading={loading}
        showDownload={true}
        selectableRows={true}
        selectableRowsComponent={MUICheckBox}
        onSelectedRowsChange={onSelectedRowsChange}
        selectableRowDisabled={(row) => !!row?.billable}
        clearSelectedRows={toggleClearSelectedRows}
      />
    </div>
  );
};

export default UnBilledShipmentsView;
