/** Import react/libraries section **/
import React, {useEffect, useState} from 'react';

/** Import component section **/
import QuoteCostView from './QuoteCostView';
import {useDispatch, useSelector} from 'react-redux';
import {setIncidents, updateField, updateIncident} from '../../../redux/quotation/quoteSlice';
import {applyForCreditExtensionService, requestPaymentTypes} from '../../../services/quotes-services';
import {toast} from 'react-toastify';
import {STATUS_DELIVERED} from '../../../utils/enumerates';

/** Import helpers section **/

/** Import resources section **/

const QuoteCostComponent = () => {
	let dispatch = useDispatch();
	
	let generalData = {
		iva: useSelector( (store) => store.quote.quotation.iva ),
		total: useSelector( (store) => store.quote.quotation.total ),
		destCP: useSelector( (store) => store.quote.quotation.destination.cp ),
		nextDay: useSelector( (store) => store.quote.quotation.next_day ),
		quoteId: useSelector( (store) => store.quote.quotation.id ),
		subtotal: useSelector( (store) => store.quote.quotation.subtotal ),
		destCity: useSelector( (store) => store.quote.quotation.destination.city_name ),
		originCP: useSelector( (store) => store.quote.quotation.origin.cp ),
		retention: useSelector( (store) => store.quote.quotation.retention ),
		incidents: useSelector( (store) => store.quote.quotation.incidents ),
		originCity: useSelector( (store) => store.quote.quotation.origin.city_name ),
		clientCredit: useSelector( (store) => store.quote.quotation.client_credit ),
		businessName: useSelector( (store) => store.quote.quotation.client.business_name ),
		paymentTypeId: useSelector( (store) => store.quote.quotation.payment_type_id ),
		canApplyCredit: useSelector( (store) => store.quote.quotation.can_apply_credit ),
		recollectionDate: useSelector( (store) => store.quote.quotation.recollection_date ),
		shipmentStatusId: useSelector( (store) => store.quote.quotation.shipment_status_id ),
		shipmentTypeId: useSelector( (store) => store.quote.quotation.delivery_type.shipment_type_id ),
	};
	
	let shipmentId = useSelector( (store) => store.quote.quotation.id );
	
	const [paymentTypes, setPaymentTypes] = useState( [] );
	
	useEffect( () => {
		requestPaymentTypes( shipmentId ).then( response => {
			setPaymentTypes( response );
		} ).catch( error => {
			console.error( 'QuoteCostComp -> Error getting payment types: ', error );
		} );
	}, [shipmentId] );
	
	/**
	 * In this useEffect we only update incidents on quote redux/store if there a re not data,
	 * and shipment status is equal to 'delivered (5)'.
	 */
	useEffect( () => {
		if (generalData.shipmentStatusId === STATUS_DELIVERED && generalData.incidents.length === 0) {
			let incidents = [
				{
					id: 1,
					value: 0,
					option: 1,
					title: 'Incurrió en demora',
				},
				{
					id: 2,
					value: 0,
					option: 1,
					title: 'Tuvo retornos',
				},
			];
			dispatch( setIncidents( {incidents: incidents} ) );
		}
	}, [dispatch, generalData.shipmentStatusId, generalData.incidents] );
	
	const onIncidentChange = (event, index) => {
		event.preventDefault();
		let value = parseInt( event.target.value );
		dispatch( updateIncident( {index, value} ) );
	};
	
	const handleSelectChange = (event) => dispatch( updateField( {field: event.name, value: event.value} ) );
	
	const onCreditRequest = () => {
		applyForCreditExtensionService( shipmentId ).then( () => {
			toast( 'Se ha solicitado correctamente', {type: 'success'} );
		} ).catch( error => {
			toast( 'Error al solicitar', {type: 'error'} );
			console.error( 'QuoteCostComp -> Error requesting credit: ', error );
		} );
	};
	
	return (
		<QuoteCostView
			generalData={generalData}
			paymentTypes={paymentTypes}
			onCreditRequest={onCreditRequest}
			onIncidentChange={onIncidentChange}
			handleSelectChange={handleSelectChange}
		/>
	);
};

export default QuoteCostComponent;
