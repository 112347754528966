/** Import react/libraries section **/
import React from 'react';
import { useForm } from 'react-hook-form';

/** Import styles section **/
import './GoodsModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../../../components/UI/ModalComponent';
import MUITextField from '../../../../../components/UI/inputs/MUITextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const ConsigmentNoteView = ({
  onClose,
  onSave,
  disabled,
  goods,
  onInputChange,
  onSwitchChange
}) => {
  const { handleSubmit } = useForm();

  const onSubmit = (values) => {
    onSave(values);
  };
  const title = goods?.id
    ? 'Editar bien transportado'
    : 'Agregar bien transportado';
  return (
    <ModalComponent
      title={title}
      open={true}
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      disabled={disabled}
    >
      <div className="GoodsModal">
        <MUITextField
          label="Cantidad"
          name="quantity"
          value={goods?.quantity}
          type="number"
          onChange={onInputChange}
        />

        <MUITextField
          label="Clave de bienes transportados"
          name="code"
          value={goods?.code}
          type="text"
          onChange={onInputChange}
        />

        <MUITextField
          label="Descripción"
          id="description"
          name="description"
          value={goods?.description}
          type="text"
          onChange={onInputChange}
        />

        <MUITextField
          label="Clave de unidad"
          name="unit_code"
          value={goods?.unit_code}
          type="text"
          onChange={onInputChange}
        />

        <MUITextField
          label="Peso"
          name="weigth"
          value={goods?.weigth}
          type="number"
          onChange={onInputChange}
        />

        <MUITextField
          label="Valor"
          name="value"
          value={goods?.value}
          type="number"
          onChange={onInputChange}
        />

        <MUITextField
          label="Moneda"
          name="currency"
          value={goods?.currency}
          type="text"
          onChange={onInputChange}
        />

        <FormControlLabel
          id="MUI_active"
          labelPlacement="end"
          label="¿Contiene material peligroso?"
          control={
            <Switch
              checked={goods?.dangerous_material}
              onChange={onSwitchChange}
              name="dangerous_material"
              data-cy="cy-active"
              color="primary"
              inputProps={{ 'aria-label': 'active' }}
            />
          }
        />

        <MUITextField
          label="Clave de material peligroso"
          name="dangerous_material_code"
          value={goods?.dangerous_material_code}
          type="text"
          onChange={onInputChange}
          disabled={goods?.dangerous_material === false}
        />
        <MUITextField
          label="Clave de embalaje"
          name="packing_code"
          value={goods?.packing_code}
          type="text"
          onChange={onInputChange}
          disabled={goods?.dangerous_material === false}
        />
      </div>
    </ModalComponent>
  );
};

export default ConsigmentNoteView;
