/** Import react/libraries section **/
import React from 'react';
import { PropTypes } from 'prop-types';

/** Import component section **/
import CostBreakdownComponent from '../CostBreakdown';
import QuoteProvidersComponent from '../QuoteProviders';

/** Import helpers section **/
import {
  CONTAINER_TYPE_CONSOLIDATED_LOAD,
  CONTAINER_TYPE_DEDICATED_MOVE,
  STATUS_DELIVERED
} from '../../../utils/enumerates';

/** Import resources section **/
import './QuoteCostsStyles.scss';
import CardHeader from '@material-ui/core/CardHeader';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Card, CardContent, Radio } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/**
 * Things here a re really simple, we return card only when shipment type is consolidated load (3) or dedicated/move (4)
 * and status is delivered (5), in any other case we return nothing (null).
 * @param generalData
 * @param onIncidentChange
 * @param onCreditRequest
 * @returns {JSX.Element}
 * @constructor
 */
const QuoteCostView = ({ generalData, onIncidentChange }) => {
  const composeIncidents = () => {
    if (
      generalData.shipmentTypeId === CONTAINER_TYPE_CONSOLIDATED_LOAD ||
      generalData.shipmentTypeId === CONTAINER_TYPE_DEDICATED_MOVE
    ) {
      if (
        generalData.shipmentStatusId === STATUS_DELIVERED &&
        generalData.incidents.length > 0
      ) {
        return (
          <Card className="quoteCosts_left_incidences">
            <CardHeader title="Incidencias" />
            <CardContent className="quoteCosts_left_incidences_content">
              {generalData.incidents.map((incident, index) => (
                <div>
                  <p> {incident.title}</p>
                  <RadioGroup
                    className="quoteCosts_incidences_content-radio"
                    aria-label="incidentes"
                    name="delivery"
                    value={incident.option}
                    onChange={(event) => {
                      onIncidentChange(event, index);
                    }}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Si"
                    />
                  </RadioGroup>
                </div>
              ))}
            </CardContent>
          </Card>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <div className="quoteCosts">
      <div className="quoteCosts_left">
        <QuoteProvidersComponent quoteId={generalData.quoteId} />
        {generalData.incidents.length > 0 ? composeIncidents() : null}
      </div>

      <div className="quoteCosts_right">
        <CostBreakdownComponent />
      </div>
    </div>
  );
};

QuoteCostView.propType = {
  paymentTypes: PropTypes.array.paymentTypes,
  generalData: PropTypes.object.isRequired,
  onCreditRequest: PropTypes.func.isRequired,
  onIncidentChange: PropTypes.func.isRequired,
  onSwitchChange: PropTypes.func.paymentTypes,
  handleSelectChange: PropTypes.func.paymentTypes
};

export default QuoteCostView;
