import React, { useState } from 'react';

import ExtraCostsView from './ExtraCostsView';
import StaysComponent from './Stays';
import DelaysComponent from './Delays';
import NightDeliveryComponent from './NightDelivery';
import CostsEditComponent from './CostsEditComponent/CostsEditComponent';

const ExtraCostsComponent = () => {
  const [ activeTab, setActiveTab ] = useState(1);

  const handleOnTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <ExtraCostsView activeTab={activeTab} onTabChange={handleOnTabChange} />
      {activeTab === 0 && <StaysComponent />}
      {activeTab === 1 && <DelaysComponent />}
      {activeTab === 2 && <NightDeliveryComponent />}
      {activeTab === 3 && <CostsEditComponent />}
    </>
  );
};

export default ExtraCostsComponent;
