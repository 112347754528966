/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import {Card, CardContent, CardHeader} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import './DeliveryTypeFormStyles.scss';
import MUIDateField from '../../UI/inputs/MUIDateField';

export const DeliveryTypeFormView = ({value, onChange, onDateChange, deliveryDate, recollectionDate, isShipment}) => {
	return (
		<Card className="deliverytypeForm">
			<CardHeader title="Tipo de entrega"/>
			<CardContent className="deliverytypeForm_content">
				<RadioGroup
					className="deliverytypeForm_content-radios"
					aria-label="stdNext"
					name="deliveryType"
					value={value}
					onChange={onChange}
				>
					<FormControlLabel
						value={1}
						control={<Radio data-cy="cy-std"/>}
						label="Estandar"
						disabled={isShipment}
					/>
					<FormControlLabel
						value={2}
						control={<Radio data-cy="cy-std"/>}
						label="Express día siguiente"
						disabled={isShipment}
					/>
					
					<MUIDateField
						id="estimated"
						onChange={onDateChange}
						name="delivery_date"
						value={deliveryDate}
						label="Fecha estimada de entrega"
						minDate={recollectionDate}
						disabled={recollectionDate === null || isShipment}
						
					/>
				
				</RadioGroup>
			</CardContent>
		</Card>
	);
};

export default DeliveryTypeFormView;
