import React, { useState } from 'react';
import RouteAddView from './RouteAddView';

const RouteAddComponent = ({ onClose, onSave }) => {
  const [originMunicipalitySelected, setOriginMunicipalitySelected] =
    useState(null);
  const [
    destionationMunicipalitySelected,
    setDestionationMunicipalitySelected
  ] = useState(null);

  const handleRouteSave = () => {
    const routeData = {
      origin_municipality: originMunicipalitySelected,
      destination_municipality: destionationMunicipalitySelected
    };
    onSave(routeData);
  };

  const handleOnOriginCityChange = (municipalityData) => {
    setOriginMunicipalitySelected(municipalityData.municipality_id);
  };

  const handleOnDestCityChange = (municipalityData) => {
    setDestionationMunicipalitySelected(municipalityData.municipality_id);
  };

  return (
    <RouteAddView
      onOriginMunicipalitySelected={handleOnOriginCityChange}
      onDestinationMunicipalitySelected={handleOnDestCityChange}
      onClose={onClose}
      onSave={handleRouteSave}
    />
  );
};

export default RouteAddComponent;
