/** Import react/libraries section **/
import React, { Fragment } from 'react';

/** Import component section **/
import FilterBy from '../../components/UI/FilterBy';
import PageTitle from './../../components/PageTitle';
import AddButton from '../../components/UI/AddButton';
import MUIDataTable from '../../components/MUIDataTable';

/** Import styles section **/
import './QuotesStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';

/** Import helpers section **/
import moment from 'moment';
import { formatNumberToCurrency } from '../../utils/formats';

/** Import components section **/
import MUISelect from '../../components/UI/inputs/MUISelect';
import ModalComponent from '../../components/UI/ModalComponent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUIDateField from '../../components/UI/inputs/MUIDateField';
import ClearIcon from '@material-ui/icons/Clear';

/** Import access control section **/
import AccessControl from '../../access/AccessControl';
import {
  DELETE_QUOTE_PERMISSION,
  ASSIGN_QUOTE_PERMISSION,
  DISCOUNT_PERMISSION
} from '../../access/PERMISSIONS';

const QuotesView = (props) => {
  let {
    data,
    users,
    onClear,
    loading,
    showClear,
    dateRange,
    addHandler,
    showCanceled,
    onCloseDialog,
    onSelectChange,
    onShowCanceled,
    onAssignRequest,
    handleDateChange,
    showAssignDialog,
    filteringHandler,
    handleOnEditQuote,
    onShowAssignRequest,
    handleOnDeleteQuote,
    onShowDiscountDialog
  } = props;
  // console.log(data);
  const ActionsCell = ({ row }) => {
    return (
      <Fragment>
        <AccessControl allowedPermissions={[DISCOUNT_PERMISSION]}>
          <Tooltip title="Aplicar % descuento">
            <TrendingDownIcon
              className="g-row-action-button complementary-3"
              onClick={() => onShowDiscountDialog(row.id)}
            />
          </Tooltip>
        </AccessControl>

        <AccessControl allowedPermissions={[ASSIGN_QUOTE_PERMISSION]}>
          <Tooltip title="Asignar">
            <HowToRegIcon
              data-cy="assign-button"
              className="g-row-action-button complementary-2"
              onClick={() => onShowAssignRequest(row.id)}
            />
          </Tooltip>
        </AccessControl>

        <EditIcon
          className="g-row-action-button"
          onClick={() => handleOnEditQuote(row)}
        />

        <AccessControl allowedPermissions={[DELETE_QUOTE_PERMISSION]}>
          <DeleteIcon
            className="g-row-action-button delete"
            onClick={() => handleOnDeleteQuote(row.id, row.tracking_guide)}
          />
        </AccessControl>
      </Fragment>
    );
  };

  const CityCell = ({ stateName, cityName }) => {
    return (
      <div className="routes-city-wrapper">
        <div className="routes-city">{cityName}</div>
        <div className="routes-state">{stateName}</div>
      </div>
    );
  };

  const tableColumns = [
    {
      name: 'No. Cotización',
      selector: 'tracking_guide',
      sortable: true,
      center: true,
      wrap: true
    },

    {
      name: 'Cliente',
      selector: (row) => row?.client.business_name,
      sortable: true,
      center: true
    },
    {
      name: 'RFC',
      selector: (row) => row?.client?.rfc,
      sortable: false,
      center: true
    },
    {
      name: 'Origen',
      selector: (row) =>
        row?.origin?.municipality_name + ', ' + row.origin?.state_name,
      cell: (row) => (
        <CityCell
          stateName={row?.origin?.municipality_name}
          cityName={row.origin?.state_name}
        />
      ),
      center: true
    },
    {
      name: 'Destino',
      selector: (row) =>
        row?.destination?.municipality_name +
        ', ' +
        row.destination?.state_name,
      cell: (row) => (
        <CityCell
          stateName={row.destination?.municipality_name}
          cityName={row.destination?.state_name}
        />
      ),
      center: true
    },
    {
      name: 'Asesor',
      selector: (row) => row?.assigned?.name + ' ' + row?.assigned?.last_name,
      cell: (row) =>
        row?.assigned?.name !== undefined
          ? row?.assigned?.name + ' ' + row?.assigned?.last_name
          : 'Sin asignar',
      sortable: false,
      center: false
    },
    {
      name: 'Total',
      selector: (row) => row?.total,
      cell: (row) => formatNumberToCurrency(row.total),
      sortable: false,
      center: true
    },
    {
      name: '% de descuento',
      selector: (row) => row.discount,
      cell: (row) => row.discount,
      sortable: false,
      center: true
    },
    {
      name: 'Estatus',
      selector: 'shipment_status',
      sortable: false,
      center: true
    },
    {
      name: 'Fecha cotización',
      selector: (row) => moment(row.created_at).format('DD/MM/YY - HH:MM:ss'),
      cell: (row) => moment(row.created_at).format('DD/MM/YY - HH:MM:ss'),
      sortable: false,
      center: false,
      wrap: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    }
  ];

  return (
    <Fragment>
      <PageTitle title="Cotizaciones" />
      <Paper className="g-page-header quotes_header">
        <div className="quotes_header_filters">
          <div className="quotes_header_filters_switch">
            <FormControlLabel
              label=""
              control={<FilterBy onChange={(e) => filteringHandler(e)} />}
            />
            <div>
              <Switch
                checked={showCanceled}
                onChange={(e) => onShowCanceled(e)}
                name="show-canceled"
                inputProps={{
                  'aria-label': 'secondary checkbox'
                }}
              />
              Mostrar canceladas
            </div>

            <p>Del</p>

            <FormControlLabel
              control={
                <MUIDateField
                  onChange={handleDateChange}
                  name="start"
                  value={dateRange?.start}
                />
              }
              label=""
            />
            <p> Al </p>

            <FormControlLabel
              control={
                <MUIDateField
                  onChange={handleDateChange}
                  name="end"
                  value={dateRange?.end}
                />
              }
              label=""
            />
            {showClear === true ? (
              <Tooltip title="Limpiar filtros">
                <ClearIcon onClick={onClear} />
              </Tooltip>
            ) : null}
          </div>
        </div>

        <div>
          <AddButton data-cy="add-button" onClick={addHandler} />
        </div>
      </Paper>

      <MUIDataTable
        loading={loading}
        columns={tableColumns}
        data={data}
        showDownload={true}
      />

      {showAssignDialog === true ? (
        <ModalComponent
          title="Asignar solicitud"
          open={showAssignDialog}
          onClose={onCloseDialog}
          onConfirm={onAssignRequest}
        >
          <MUISelect
            label="Usuario"
            dataCy="cy-user"
            items={users}
            idSelector="id"
            selector="name"
            noSelectedLabel="Seleccione"
            name="client_id"
            onChange={(event) => onSelectChange(event.value)}
          />
        </ModalComponent>
      ) : null}
    </Fragment>
  );
};

export default QuotesView;
