/** Import helpers section **/
import * as roles from './ROLES';

export const menuPermissions = [
  {
    id: 'tablero',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS
    ]
  },
  {
    id: 'solicitudes',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.GERENTE_DE_VENTA
      /*
      --- se quito COORDINADOR_DE_VENTAS ---
      --- se quito ASESOR_DE_TRAFICO ---
      --- se quito EJECUTIVO_DE_VENTAS ---
      */
    ]
  },
  {
    id: 'cotizaciones',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.GERENTE_DE_VENTA,
      roles.COORDINADOR_DE_VENTAS,
      roles.ASESOR_DE_TRAFICO,
      roles.EJECUTIVO_DE_VENTAS,
      roles.FACTURACION_Y_COBRANZA,
      roles.CUENTAS_POR_PAGAR,
      roles.EJECUTIVO_DE_VENTAS
      /*
      --- se agrego CUENTAS_POR_PAGAR ---
      --- se quito ASESOR_DE_TRAFICO (solamente las suyas) ---
      --- se quito EJECUTIVO_DE_VENTAS (solamente las suyas) ---
      --- AMBAS FACTURACION_Y_COBRANZA ---
      */
    ]
  },

  {
    id: 'envios',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.GERENTE_DE_VENTA,
      roles.COORDINADOR_DE_VENTAS,
      roles.ASESOR_DE_TRAFICO,
      roles.EJECUTIVO_DE_VENTAS,
      roles.FACTURACION_Y_COBRANZA,
      roles.CUENTAS_POR_PAGAR,
      roles.GERENTE_DE_OPERACIONES,
      roles.COORDINADOR_DE_OPERACIONES,
      roles.FACTURACION_Y_COBRANZA,
      roles.EJECUTIVO_DE_VENTAS
    ]
  },
  {
    id: 'clientes',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.FACTURACION_Y_COBRANZA,
      roles.CUENTAS_POR_PAGAR,
      roles.EJECUTIVO_DE_VENTAS
      /*
       --- agrego director de finanzas ---
       --- agrego cuentas por pagar  ---
       --- agrego director general ---
       --- se quito gerente de ventas ---
       --- se quito asesor de trafico ---
       -- AGREGAR FACTURACION_Y_COBRANZA EXTERNA --
      */
    ]
  },
  {
    id: 'convenios',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.FACTURACION_Y_COBRANZA,
      roles.CUENTAS_POR_PAGAR
      /*
       --- agrego cuentas por pagar  ---
       --- se quito GERENTE_DE_VENTA ---
       -- AGREGAR FACTURACION_Y_COBRANZA EXTERNA --
      */
    ]
  },
  {
    id: 'precios',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.FACTURACION_Y_COBRANZA
    ]
    /*
       --- se quito COORDINADOR_DE_VENTAS  ---
       --- agrego FACTURACION_Y_COBRANZA INTERNA ---,
      */
  },
  {
    id: 'productos',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS
    ]
  },
  {
    id: 'viajes_dedicados',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS
    ]
  },
  {
    id: 'costos_extras',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS
    ]
  },

  {
    id: 'catalogos',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.FACTURACION_Y_COBRANZA,
      roles.GERENTE_DE_VENTA
      /*
       --- agrego FACTURACION_Y_COBRANZA INTERNA ---
       --- se quito GERENTE_DE_VENTA ---
       --- se quito COORDINADOR_DE_VENTAS ---
      */
    ]
  },
  {
    id: 'rutas',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.GERENTE_DE_VENTA,
      roles.COORDINADOR_DE_VENTAS
    ]
  },
  {
    id: 'cobertura_ms',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.GERENTE_DE_VENTA,
      roles.COORDINADOR_DE_VENTAS
    ]
  },
  {
    id: 'proveedores',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.GERENTE_DE_VENTA
    ]
  },
  {
    id: 'unidades',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS
    ]
  },
  {
    id: 'empaques',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.GERENTE_DE_VENTA
    ]
  },
  {
    id: 'finanzas',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.FACTURACION_Y_COBRANZA

      /*
       --- agrego FACTURACION_Y_COBRANZA INTERNA ---
       --- agrego FACTURACION_Y_COBRANZA EXTERNA ---
       --- se quito GERENTE_DE_VENTA ---
       --- se quito COORDINADOR_DE_VENTAS ---
      */
    ]
  },
  {
    id: 'autorizaciones',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.GERENTE_DE_VENTA,
      roles.ASESOR_DE_TRAFICO,
      roles.FACTURACION_Y_COBRANZA
    ]
  },
  {
    id: 'soporte_facturacion',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.GERENTE_DE_VENTA,
      roles.ASESOR_DE_TRAFICO,
      roles.FACTURACION_Y_COBRANZA
    ]
  },
  {
    id: 'facturas',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.GERENTE_DE_VENTA,
      roles.ASESOR_DE_TRAFICO,
      roles.FACTURACION_Y_COBRANZA
    ]
  },
  {
    id: 'cobranza',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.GERENTE_DE_VENTA,
      roles.ASESOR_DE_TRAFICO,
      roles.FACTURACION_Y_COBRANZA
    ]
  },
  {
    id: 'reportes',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS,
      roles.FACTURACION_Y_COBRANZA
    ]
  },
  {
    id: 'reporte_envios',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS
    ]
  },
  {
    id: 'reporte_cotizaciones',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS
    ]
  },
  {
    id: 'reporte_cuentas_por_cobrar',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS
    ]
  },
  {
    id: 'reportes',
    permissions: [
      roles.ADMINISTRADOR,
      roles.DIRECTOR_GENERAL,
      roles.DIRECTOR_FINANZAS
    ]
  },
  {
    id: 'usuarios',
    permissions: [roles.ADMINISTRADOR]
  }
];

export const getMenuPermissionsByUserRole = (menuLabel) => {
  return menuPermissions.filter((x) => x.id === menuLabel);
};

export const getMenuPermissionObject = (id) => {
  return menuPermissions.find((x) => x.id === id);
};
