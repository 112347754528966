/** Import react/libraries section **/
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

/** Import component section **/
import ShipmentsComponent from '../../views/Shipments';

import UsersComponent from '../../views/Users';
import RequestComponent from '../../views/Request';
import DashboardComponent from './../../views/Dashboard';
import QuotesComponent from './../../views/Quotes';
import ClientsComponent from './../../views/Clients';
import ContactsComponent from './../../views/Contacts';

import AuthorizationsComponent from '../../views/Authorizations';

import RoutesComponent from './../../views/Routes';
import ExtendedRoutesComponent from '../../views/ExtendedRoutes';
import ExtendedPostalCodesComponent from '../../views/ExtendedPostalCodes';
import CoverageComponent from '../../views/Coverage/CoverageComponent';
import PostalCodesComponent from './../../views/PostalCodes';
import ProvidersComponent from './../../views/Providers';
import ContainersComponent from './../../views/Containers';
import UnitsComponent from './../../views/Units';

import ProductsPricesComponent from './../../views/ProductsPrices';
import AgreementsComponent from '../../views/Agreements/AgreementsComponent';
import AgreementPrices from '../../views/AgreementPrices';
import DedicatedPricesComponent from './../../views/DedicatedPrices';
import ExtraCostsComponent from './../../views/ExtraCosts';
import BillingBusinessComponent from './../../views/BillingBusiness';

import RequestsComponent from '../../views/Requests';
/** Import helpers section **/
import * as ROUTES from './../../URL-Routes';
import BillingSupportComponent from '../../views/BillingSupport/BillingSupportComponent';
import PaymentsComponent from '../../views/Payments';
import BillingsComponent from '../../views/Billings/BillingsComponent';
import QuotationReportsComponent from '../../views/QuotationReports';
import ShipmentsReportsComponent from '../../views/ShipmentsReports/ShipmentsReportsComponent';
import ReceivableReportsComponent from '../../views/ReceivableReports';
import ContainerTypesComponent from '../../views/ContainerTypes';
import ConsigmentNote from './../../views/ConsigmentNote';
import QuoteComponent from '../../views/Quote';

function ContentSwitch() {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.DASHBOARD_ROUTE}
        component={DashboardComponent}
      />

      <Route exact path={ROUTES.REQUEST_ROUTE} component={RequestComponent} />
      <Route
        exact
        path={ROUTES.SHIPMENTS_ROUTE}
        component={ShipmentsComponent}
      />
      <Route
        exact
        path={ROUTES.SHIPMENTS_EDIT_ROUTE + '/:id'}
        component={QuoteComponent}
      />
      <Route
        exact
        path={ROUTES.REQUESTS_LIST_ROUTE}
        component={RequestsComponent}
      />

      <Route
        exact
        path={ROUTES.REQUESTS_EDIT_ROUTE + '/:id'}
        component={RequestComponent}
      />

      {/*<Route*/}
      {/*  exact*/}
      {/*  path={ROUTES.REQUESTS_CREATE_ROUTE}*/}
      {/*  component={RequestComponent}*/}
      {/*/>*/}

      <Route exact path={ROUTES.QUOTES_ROUTE} component={QuotesComponent} />

      <Route
        exact
        path={ROUTES.QUOTE_EDIT + '/:id'}
        component={QuoteComponent}
      />

      <Route exact path={ROUTES.QUOTE_NEW} component={QuoteComponent} />

      <Route exact path={ROUTES.CLIENTS_ROUTE} component={ClientsComponent} />
      <Route exact path={ROUTES.CONTACTS_ROUTE} component={ContactsComponent} />

      <Route
        exact
        path={ROUTES.PRODUCTS_PRICES_ROUTE}
        component={ProductsPricesComponent}
      />
      <Route
        exact
        path={ROUTES.AUTHORIZATIONS_ROUTE}
        component={AuthorizationsComponent}
      />

      <Route
        exact
        path={ROUTES.BILLING_ROUTE}
        component={BillingSupportComponent}
      />

      <Route exact path={ROUTES.MANIFESTS_ROTE} component={BillingsComponent} />

      <Route exact path={ROUTES.PAYMENTS_ROUTE} component={PaymentsComponent} />

      <Route
        exact
        path={ROUTES.CLIENT_AGREEMENTS}
        component={AgreementsComponent}
      />

      <Route exact path={ROUTES.AGREEMENT_PRICES} component={AgreementPrices} />

      <Route
        exact
        path={ROUTES.DEDICATED_PRICES_ROUTE}
        component={DedicatedPricesComponent}
      />
      <Route
        exact
        path={ROUTES.EXTRA_COSTS_ROUTE}
        component={ExtraCostsComponent}
      />
      <Route exact path={ROUTES.ROUTES_ROUTE} component={RoutesComponent} />
      <Route
        exact
        path={ROUTES.ROUTES_ROUTE_EXTENDED + '/:stateId/:municipalityStateId'}
        component={ExtendedRoutesComponent}
      />
      <Route
        exact
        path={ROUTES.ROUTES_ROUTE_EXTENDED_CP}
        component={ExtendedPostalCodesComponent}
      />
      <Route
        exact
        path={ROUTES.PROVIDERS_ROUTE}
        component={ProvidersComponent}
      />
      <Route exact path={ROUTES.CITIES_ROUTE} component={CoverageComponent} />

      <Route
        exact
        path={ROUTES.CITY_POSTAL_CODES_ROUTE + '/:id'}
        component={PostalCodesComponent}
      />

      <Route
        exact
        path={ROUTES.MPIO_POSTAL_CODES_ROUTE + '/:id'}
        component={PostalCodesComponent}
      />

      <Route exact path={ROUTES.UNITS_ROUTE} component={UnitsComponent} />

      <Route
        exact
        path={ROUTES.CONTAINERS_ROUTE}
        component={ContainersComponent}
      />

      <Route
        exact
        path={ROUTES.CONTAINERS_TYPES_ROUTE}
        component={ContainerTypesComponent}
      />

      <Route
        exact
        path={ROUTES.CONTAINER_GOODS + '/:id'}
        component={ConsigmentNote}
      />

      <Route
        exact
        path={ROUTES.BUSINESS_BILLING}
        component={BillingBusinessComponent}
      />

      <Route
        exact
        path={ROUTES.SHIPMENT_REPORTS_ROUTE}
        component={ShipmentsReportsComponent}
      />
      <Route
        exact
        path={ROUTES.QUOTES_REPORTS_ROUTE}
        component={QuotationReportsComponent}
      />

      <Route
        exact
        path={ROUTES.RECEIVABLES_REPORTS_ROUTE}
        component={ReceivableReportsComponent}
      />

      <Route exact path={ROUTES.USERS_ROUTE} component={UsersComponent} />
    </Switch>
  );
}

export default withRouter(ContentSwitch);
