/** Import react/libraries section **/
import React, { useState } from 'react';
import { toast } from 'react-toastify';

/** Import component section **/
import AgreementPriceModalView from './AgreementPriceModalView';

import {
  createAgreementPriceService,
  updateAgreementPriceService
} from '../../../services/agreement-services';
/** Import helpers section **/

const AgreementPriceModalComponent = ({ item, onClose, onConfirm }) => {
  const [agreementPrice, setAgreementPrice] = useState(item);

  const handleOnInputChange = (event) => {
    const { name, value } = event;

    setAgreementPrice((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnSave = (event) => {
    if (item.agreement_price_id) {
      updateAgreementPriceService(agreementPrice)
        .then(() => {
          toast('Precio editado', { type: 'success' });
          onConfirm(true);
        })
        .catch((error) => {
          toast('Error, no se guardo.', { type: 'error' });
          onClose();
          console.error(
            'ProdPricesComp -> Error updating price record: ',
            error
          );
        });
    } else {
      createAgreementPriceService(agreementPrice)
        .then(() => {
          toast('Precio agregado', { type: 'success' });
          onConfirm(true);
        })
        .catch((error) => {
          toast('Error, no se guardo.', { type: 'error' });
          onClose();
          console.error(
            'ProdPricesComp -> Error updating price record: ',
            error
          );
        });
    }
  };

  return (
    <AgreementPriceModalView
      agreementPrice={agreementPrice}
      onInputChange={handleOnInputChange}
      onClose={onClose}
      onSave={handleOnSave}
    />
  );
};

export default AgreementPriceModalComponent;
