/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import PackagingView from './PackagingView';
import {
  getContainerTypeByShipmentId,
  getPackagesCategories
} from '../../../services/catalogues-services';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDeliveryTypePackages,
  updateDeliveryType
} from '../../../redux/quotation/quoteSlice';
import {
  CONTAINER_TYPE_MESSAGE,
  CONTAINER_TYPE_PARCEL
} from '../../../utils/enumerates';
import { toast } from 'react-toastify';

/** Import component section **/

/** Import helpers section **/

/** Import resources section **/

const PackagingComponent = ({ index, service, modeType }) => {
  let dispatch = useDispatch();
  let deliveryTypeData = useSelector(
    (store) => store.quote.quotation.delivery_type
  );
  let isShipment = useSelector((store) => store.quote?.quotation?.is_shipment);

  const [containers, setContainers] = useState([]);
  const [products, setProducts] = useState([]);
  const [volWeight, setVolWeight] = useState(0);

  let shipmentId =
    deliveryTypeData.shipment_type_id === null
      ? 1
      : deliveryTypeData.shipment_type_id;

  useEffect(() => {
    if (!shipmentId) return;
    getContainerTypeByShipmentId(shipmentId)
      .then((response) => {
        setContainers(response);
      })
      .catch((error) => {
        console.error(
          'QuoteServicesComponent - Error fetching container : ',
          error
        );
      });

    getProducts(service.product_id);
  }, [service?.product_id, shipmentId]);

  const getProducts = (productId) => {
    getPackagesCategories(productId)
      .then((response) => {
        setProducts(response);
      })
      .catch((error) => {
        console.error(
          'ShipDetailComp -> Error getting containers type: ',
          error
        );
      });
  };

  const calculateVolumetricWeight = (index) => {
    // Calculate volumetric weight for parcel container type
    // paquetería es:  (Largo cm x ancho cm x alto cm)/(6000)
    if (deliveryTypeData.shipment_type_id === 2) {
      let volWeight = (
        (deliveryTypeData.containers[index].long *
          deliveryTypeData.containers[index].width *
          deliveryTypeData.containers[index].height) /
        5000
      ).toFixed(2);

      setVolWeight(volWeight);
      // Update field on package
      dispatch(
        updateDeliveryType({
          index: index,
          field: 'volumetric_weight',
          value: volWeight
        })
      );
    }
    // Calculate volumetric weight for consolidated load type.
    // carga consolidada es: (Largo x ancho x alto)x (400)
    if (deliveryTypeData.shipment_type_id === 3) {
      if (deliveryTypeData.containers[index].stackable === 1) {
        let volWeight = parseFloat(
          (
            deliveryTypeData.containers[index].long *
            deliveryTypeData.containers[index].width *
            2.0 *
            400
          ).toFixed(2)
        );
        setVolWeight(volWeight);
        dispatch(
          updateDeliveryType({
            index: index,
            field: 'volumetric_weight',
            value: volWeight
          })
        );
      } else {
        let volWeight =
          deliveryTypeData.containers[index].long *
          deliveryTypeData.containers[index].width *
          deliveryTypeData.containers[index].height *
          400;
        setVolWeight(volWeight);
        dispatch(
          updateDeliveryType({
            index: index,
            field: 'volumetric_weight',
            value: volWeight
          })
        );
      }
    }
    if (
      deliveryTypeData.shipment_type_id !== 1 &&
      deliveryTypeData.shipment_type_id !== 4
    ) {
      let selectedProduct = products.filter(
        (x) =>
          x.id === deliveryTypeData.containers[index].container_type_category_id
      );
      if (volWeight > selectedProduct[0]?.max_weight)
        toast(
          'El peso volumetrico excede el máximo del producto seleccionado!  ',
          { type: 'warning' }
        );
    }
  };

  const onContainerSelectChange = (data, index) => {
    let { name, value } = data;
    dispatch(updateDeliveryType({ index: index, field: name, value: value }));

    if (name === 'product_id') {
      getPackagesCategories(value)
        .then((response) => {
          let copy = [...products];
          copy[index] = response;
          setProducts(copy);
        })
        .catch((error) => {
          console.error(
            'ShipDetailComp -> Error getting containers type: ',
            error
          );
        });
    } else {
      dispatch(
        updateDeliveryType({ index: index, field: 'package_id', value: value })
      );
    }
  };

  const handleOnServiceDetailsChange = (data, index) => {
    let { name, value } = data;

    if (
      deliveryTypeData.shipment_type_id === CONTAINER_TYPE_MESSAGE &&
      name === 'weight' &&
      value > 1
    ) {
      if (value <= 0)
        dispatch(updateDeliveryType({ index: index, field: name, value: 0 }));
      toast('Alerta: ¡el peso no puede ser 0!', {
        type: 'warning',
        autoClose: true
      });

      if (value > 1) {
        dispatch(updateDeliveryType({ index: index, field: name, value: 1 }));
        toast('Alerta: ¡el peso no puede ser mayor a 1Kg!', {
          type: 'warning',
          autoClose: true
        });
      }
    } else if (
      deliveryTypeData.shipment_type_id === CONTAINER_TYPE_PARCEL &&
      name === 'weight' &&
      value !== null
    ) {
      let containerCategoryId = deliveryTypeData.containers[index].product_id;
      let product = products.filter(
        (x) => x.container_category_id === containerCategoryId
      );

      if (product.length > 0) {
        if (value > product[0]?.max_weight) {
          // toast( 'El peso excede el máximo del producto seleccionado!  ', {type: 'warning'} );
        }
      }

      if (value < 1) {
        dispatch(updateDeliveryType({ index: index, field: name, value: 1 }));
        toast('Alerta: ¡el peso no puede ser menor a 1Kg!', {
          type: 'warning'
        });
      } else if (value > 70) {
        dispatch(updateDeliveryType({ index: index, field: name, value: 70 }));
        toast('Alerta: ¡el peso no puede ser mayor a 70Kg!', {
          type: 'warning'
        });
      } else {
        dispatch(
          updateDeliveryType({ index: index, field: name, value: value })
        );
      }
    } else {
      dispatch(updateDeliveryType({ index: index, field: name, value: value }));
    }
    calculateVolumetricWeight(index);
  };

  const onRadioChange = (event, index) => {
    let { name, value } = event.target;
    dispatch(
      updateDeliveryType({ index: index, field: name, value: parseInt(value) })
    );
  };

  const onDeletePackage = (event, index) => {
    event.preventDefault();
    let newList = [...deliveryTypeData.containers];
    if (newList.length > 1) {
      newList.splice(index, 1);
      dispatch(setDeliveryTypePackages({ packages: newList }));
    }
  };

  return (
    <PackagingView
      index={index}
      modeType={modeType}
      products={products}
      volWeight={volWeight}
      isShipment={isShipment}
      containers={containers}
      deliveryTypeData={service}
      onDeletePackage={onDeletePackage}
      onContainerSelectChange={(e) => onContainerSelectChange(e, index)}
      onServiceDetailsChange={(e) => handleOnServiceDetailsChange(e, index)}
      onRadioChange={onRadioChange}
    />
  );
};

export default PackagingComponent;
