/** Import react/libraries section **/
import React, { Fragment, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

/** Import component section **/
import ModalComponent from '../../components/UI/ModalComponent';
import AgreementsView from './AgreementsView';
import AgreementModal from './AgreementModal';

/** Import helpers section **/
import {
	deleteAgreementService,
	getAgreementsService
} from '../../services/agreement-services';
import { AGREEMENT_PRICES } from '../../URL-Routes';
import { showErrorMessages } from '../../utils/utils';

/** Import resources section **/

/**
 * Component for list, add, edit or delete client agreements.
 * @returns {JSX.Element}
 * @constructor
 */
const AgreementsComponent = () => {
	const [loadingData, setLoadingData] = useState(false);
	const [originalData, setOriginalData] = useState([]);
	const [agreements, setAgreements] = useState([]);

	const [focusedItem, setFocusedItem] = useState(null);
	const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
	const [redirectToProducts, setRedirectToProducts] = useState(false);

	const getClientAgreements = () => {
		setLoadingData(true);
		getAgreementsService()
			.then((response) => {
				setAgreements(response);
				setOriginalData(response);
			})
			.catch(() => {
				console.error('AgreementComponent - Error getting agreements');
			})
			.finally(() => {
				setLoadingData(false);
			});
	};

	useEffect(() => {
		getClientAgreements();
	}, []);

	const handleOnFilterBy = (filterText) => {
		let filteredItems;

		if (filterText.length === 0) {
			filteredItems = [...originalData];
		} else {
			const filteringText = filterText.toLowerCase();

			filteredItems = originalData.filter((item) => {
				const field1 = item.name?.toString().toLowerCase();
				// const field2 = item.business_name?.toString().toLowerCase();

				return field1.includes(filteringText) && true;
			});
		}

		setAgreements(filteredItems);
	};

	const handleOnAddAgreement = () => {
		setFocusedItem({ id: null });
		setDeleteAlertOpen(false);
	};

	const handleOnEditAgreement = (item) => {
		const editItem = JSON.parse(JSON.stringify(item));
		setFocusedItem(editItem);
	};

	const handleOnModalClose = () => {
		setFocusedItem(null);
		setDeleteAlertOpen(false);
	};

	const handleOnDeleteAgreement = (row) => {
		const deleteItem = {
			id: row.agreement_id,
			name: row.name
		};
		setFocusedItem(deleteItem);
		setDeleteAlertOpen(true);
	};

	const handleOnDeleteConfirm = () => {
		setLoadingData(false);
		deleteAgreementService(focusedItem.id)
			.then(() => {
				getClientAgreements();
				toast('Acuerdo eliminado', { type: 'success' });
			})
			.catch((error) => {
				toast('Error, no pudo eliminar el acuerdo', { type: 'error' });
				showErrorMessages(error);
				console.error('UnitsComponent - Error deleting: ', error);
			})
			.finally(() => {
				setFocusedItem(null);
				setDeleteAlertOpen(true);
				setLoadingData(false);
			});
	};

	const handleOnSaveConfirm = () => {
		setFocusedItem(null);
		setDeleteAlertOpen(false);
		getClientAgreements();
	};

	const handleOnShowAgreementsPrices = (data) => {
		setFocusedItem(data);
		setRedirectToProducts(true);
	};

	return (
		<Fragment>
			<AgreementsView
				agreements={agreements}
				loading={loadingData}
				onFilterByAgreements={handleOnFilterBy}
				onAddAgreement={handleOnAddAgreement}
				onEditAgreement={handleOnEditAgreement}
				onDeleteAgreement={handleOnDeleteAgreement}
				onShowAgreementsPrices={handleOnShowAgreementsPrices}
			/>
			{focusedItem && !deleteAlertOpen && (
				<AgreementModal
					item={focusedItem}
					onClose={handleOnModalClose}
					onConfirm={handleOnSaveConfirm}
				/>
			)}
			{focusedItem && deleteAlertOpen && (
				<ModalComponent
					open={deleteAlertOpen}
					onClose={handleOnModalClose}
					onConfirm={handleOnDeleteConfirm}
					title="Borrar convenio "
				>
					{'¿Estas seguro de borrar el convenio de ' +
						focusedItem.name +
						'?'}
				</ModalComponent>
			)}
			{focusedItem && redirectToProducts && (
				<Redirect
					to={{
						pathname: AGREEMENT_PRICES,
						state: focusedItem
					}}
				/>
			)}
		</Fragment>
	);
};

export default AgreementsComponent;
