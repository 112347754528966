import {
  billingModel,
  clientModel,
  composeQuotation,
  deliveryTypeModel,
  destinationModel,
  originModel
} from '../../models/quoteModels';
import { createSlice } from '@reduxjs/toolkit';

let emptyQuotation = composeQuotation(
  null,
  clientModel,
  billingModel,
  originModel,
  destinationModel,
  null,
  1,
  [],
  deliveryTypeModel
);

const initialState = {
  quotation: emptyQuotation,
  in_process: false,
  req_quote: false
};

const quotationSlice = createSlice({
  name: 'quote',
  initialState: initialState,
  reducers: {
    /**
     * Start process setting up in_process flag.
     * @param state
     */
    startProcess: (state) => {
      state.in_process = true;
    },
    startGetQuote: (state) => {
      state.req_quote = true;
    },
    endProcess: (state) => {
      state.in_process = false;
      state.req_quote = false;
    },

    /**
     * Set entire quotation on state.
     * @param state -> local state.
     * @param quote -> Quote object.
     */

    setQuote: (state, { payload: { quote } }) => {
      state.quotation = quote;
    },
    updateField: (state, { payload: { field, value } }) => {
      state.quotation[field] = value;
    },
    setClient: (state, { payload: { client } }) => {
      state.quotation.client = client;
    },
    updateClient: (state, { payload: { field, value } }) => {
      state.quotation.client[field] = value;
    },
    setBilling: (state, { payload: { billing } }) => {
      state.quotation.billing = billing;
    },
    updateBilling: (state, { payload: { field, value } }) => {
      state.quotation.billing[field] = value;
    },
    setOrigin: (state, { payload: { origin } }) => {
      state.quotation.origin = origin;
    },
    updateOriginField: (state, { payload: { field, value } }) => {
      state.quotation.origin[field] = value;
    },
    updateOriginLocation: (state, { payload: { location } }) => {
      state.quotation.origin['cp'] = location?.cp;
      state.quotation.origin['state_id'] = location?.state_id;
      state.quotation.origin['municipality_id'] = location?.municipality_id;
      state.quotation.origin['city_id'] = location?.city_id;
      state.quotation.origin['neighborhood_id'] = location?.neighborhood_id;
      state.quotation.origin['street'] = location?.street;
      state.quotation.origin['internal_number'] = location?.internal_number;
      state.quotation.origin['external_number'] = location?.external_number;
      state.quotation.origin['references'] = location?.references;
    },
    setDestination: (state, { payload: { destination } }) => {
      state.quotation.destination = destination;
    },
    updateDestinationField: (state, { payload: { field, value } }) => {
      state.quotation.destination[field] = value;
    },
    updateDestinationLocation: (state, { payload: { location } }) => {
      state.quotation.destination['cp'] = location?.cp;
      state.quotation.destination['state_id'] = location?.state_id;
      state.quotation.destination['municipality_id'] =
        location?.municipality_id;
      state.quotation.destination['city_id'] = location?.city_id;
      state.quotation.destination['neighborhood_id'] =
        location?.neighborhood_id;
      state.quotation.destination['street'] = location?.street;
      state.quotation.destination['internal_number'] =
        location?.internal_number;
      state.quotation.destination['external_number'] =
        location?.external_number;
      state.quotation.destination['references'] = location?.references;
    },
    setAdditionalServices: (state, { payload: { service, index } }) => {
      state.quotation.additional_services[index] = service;
    },
    updateServiceValue: (state, { payload: { index, value } }) => {
      state.quotation.additional_services[index].value = value;
    },
    updateManeuver: (state, { payload: { index, value } }) => {
      state.quotation.additional_services[index].catalog_value_selected = value;
    },
    setAllServices: (state, { payload: { services } }) => {
      state.quotation.additional_services = services;
    },
    setDeliveryType: (state, { payload: { shipmentTypes } }) => {
      state.quotation.shipmentTypes = shipmentTypes;
    },
    updateDeliveryType: (state, { payload: { index, field, value } }) => {
      let packageData = state.quotation.delivery_type.containers[index];
      packageData[field] = value;
      state.quotation.delivery_type.containers[index] = packageData;
    },
    updateServiceType: (state, { payload: { field, value } }) => {
      state.quotation.delivery_type[field] = value;
    },
    appendPackage: (state, { payload: { index, packageObj } }) => {
      state.quotation.delivery_type.containers[index] = packageObj;
    },
    setDeliveryTypePackages: (state, { payload: { packages } }) => {
      state.quotation.delivery_type.containers = packages;
    },
    updateCost: (state, { payload: { field, value } }) => {
      state.quotation.costs.cost[field].cost = value;
    },
    updateOtherCostDescription: (state, { payload: { field, value } }) => {
      state.quotation.costs.cost.otro[field] = value;
    },
    setCosts: (state, { payload: { costs } }) => {
      state.quotation.costs = costs;
    },
    setIncidents: (state, { payload: { incidents } }) => {
      state.quotation.incidents = incidents;
    },
    updateIncident: (state, { payload: { index, value } }) => {
      state.quotation.incidents[index].option = value;
    },
    updateEspecialReq: (state, { payload: { req, index } }) => {
      state.quotation.special_requirements[index].selected = req;
    },
    setNewReq: (state, { payload: { req, index } }) => {
      state.quotation.special_requirements[index] = req;
    },
    setRequirements: (state, { payload: { requirements } }) => {
      state.quotation.special_requirements = requirements;
    },
    setListOfProviders: (state, { payload: { providersList } }) => {
      state.quotation.providers.list = providersList;
    },
    updateListOfProviders: (state, { payload: { index, field, value } }) => {
      let prov = state.quotation.providers.list[index];
      prov[field] = value;
      state.quotation.providers.list[index] = prov;
    },
    updateMSProvider: (state, { payload: { selected } }) => {
      state.quotation.providers.msprovider.selected = selected;
    },
    updateMSProviderField: (state, { payload: { field, value } }) => {
      state.quotation.providers.msprovider[field] = value;
    },
    setMSProvider: (state, { payload: { provider } }) => {
      state.quotation.providers.msprovider = provider;
    },
    updateListOfEvidences: (state, { payload: { index, value } }) => {
      state.quotation.shipment_evidences[index] = value;
    },
    setAccuseProperty: (state, { payload: { field, value } }) => {
      state.quotation.accuse[field] = value;
    },
    setFiles: (state, { payload: { files } }) => {
      state.quotation.shipment_files = files;
    },
    updateAccuseField: (state, { payload: { field, value } }) => {
      state.quotation.accuse[field] = value;
    },
    setProductsDetails: (state, { payload: { details } }) => {
      state.quotation.products_details = details;
    },
    updateProdDetailsField: (state, { payload: { field, index, value } }) => {
      let product = state.quotation.products_details.products[index];
      product[field] = value;
      state.quotation.products_details[index] = product;
    },
    resetQuote: (state) => {
      state.quotation = emptyQuotation;
    }
  }
});

export const {
  startGetQuote,
  startProcess,
  endProcess,
  setQuote,
  setOrigin,
  setDeliveryType,
  updateDeliveryType,
  updateServiceType,
  appendPackage,
  setDeliveryTypePackages,
  setAdditionalServices,
  updateServiceValue,
  setAllServices,
  setClient,
  updateClient,
  setBilling,
  updateBilling,
  updateOriginField,
  updateOriginLocation,
  setDestination,
  updateDestinationField,
  updateDestinationLocation,
  updateField,
  updateCost,
  updateOtherCostDescription,
  setCosts,
  setIncidents,
  updateIncident,
  updateEspecialReq,
  setRequirements,
  setNewReq,
  updateManeuver,
  setListOfProviders,
  updateListOfProviders,
  updateMSProvider,
  updateMSProviderField,
  setMSProvider,
  updateListOfEvidences,
  setAccuseProperty,
  setFiles,
  updateAccuseField,
  setProductsDetails,
  updateProdDetailsField,
  resetQuote
} = quotationSlice.actions;
export default quotationSlice.reducer;
