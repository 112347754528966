import ApiService from './api-service';

export const getUsersServices = () => {
  const serviceDef = {
    url: 'user/list',
    method: 'get',
    params: null
  };
  return ApiService.request(serviceDef);
};

export const requestUsersAssign = () => {
  const serviceDef = {
    url: 'users',
    method: 'get',
    params: null
  };
  return ApiService.request(serviceDef);
};

export const createUserService = (user) => {
  const serviceDef = {
    url: 'user/create',
    method: 'post',
    params: user
  };
  return ApiService.request(serviceDef);
};

export const updateUserService = (user) => {
  const serviceDef = {
    url: 'user/update',
    method: 'post',
    params: user
  };
  return ApiService.request(serviceDef);
};

export const deleteUserServices = ({ id }) => {
  const serviceDef = {
    url: 'user/delete',
    method: 'post',
    params: { id }
  };
  return ApiService.request(serviceDef);
};

export const getUserByIdService = (userId) => {
  const serviceDef = {
    url: 'user/show',
    method: 'post',
    params: { id: userId }
  };
  return ApiService.request(serviceDef);
};
