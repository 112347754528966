/** Import react/libraries section **/
import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// import Button from '@material-ui/core/Button';
// import PublishIcon from '@material-ui/icons/Publish';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

/** Import helpers section **/
import './PostalCodesStyles.scss';

/** Import component section **/
import FilterBy from '../../components/UI/FilterBy';
import PageTitle from '../../components/PageTitle';
// import AddButton from '../../components/UI/AddButton';
import MUIDataTable from '../../components/MUIDataTable';

const PostalCodesView = ({
  data,
  loading,
  onAddPostalCode,
  onDeletePostalCode,
  onFilterUsers,
  onEditPostalCode,
  locationMode,
  onLoadfile,
  onChangeCoverage
}) => {
  const location = useLocation();

  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditPostalCode(row)}
        />

        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeletePostalCode(row)}
        />
      </>
    );
  };

  const CoverageCell = ({ row }) => {
    if (row.extended_route)
      return (
        <CheckCircleOutlineIcon
          className="g-activeicon active users_active"
          onClick={() => onChangeCoverage(row)}
        />
      );
    return (
      <NotInterestedIcon
        className="g-activeicon users_active"
        onClick={() => onChangeCoverage(row)}
      />
    );
  };

  const tableColumns = [
    {
      name: 'Ruta extendida',
      selector: 'active',
      cell: (row) => <CoverageCell row={row} />,
      sortable: true,
      center: true,
      width: '300px'
    },
    {
      name: 'Código Postal',
      selector: 'postal_code',
      sortable: true,
      center: true
    },
    {
      name: 'Colonia',
      selector: 'suburb',
      sortable: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    }
  ];

  return (
    <>
      <PageTitle
        title={`Códigos postales - ${
          locationMode === 'ciudad'
            ? location?.state?.city_name
            : location?.state?.municipalityName
        }`}
        backUrl={location.state?.backUrl}
      />
      <Paper className="g-page-header">
        <div className="postalcodes_header_filterby">
          <FilterBy onChange={onFilterUsers} />
        </div>
        {/* <div className="postalcodes_header_buttons">
          <AddButton onClick={onAddPostalCode} />
          <Button
            onClick={onLoadfile}
            className="postalcodes_header_buttons_load"
          >
            <PublishIcon className="postalcodes_header_buttons_load_icon" />
            Cargar archivo
          </Button>
        </div> */}
      </Paper>
      <MUIDataTable data={data} columns={tableColumns} loading={loading} />
    </>
  );
};

export default PostalCodesView;
