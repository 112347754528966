/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import helpers section **/
import { createPaymentsService } from '../../../services/payment-services';
import { toast } from 'react-toastify';
import { showErrorMessages } from '../../../utils/utils';

/** Import resources section **/

/** Import component section **/
import PaymentModalView from './PaymentModalView';

const PaymentModalComponent = ({ payment, onClose, refreshList }) => {
  const [paymentData, setPaymentData] = useState(null);

  useEffect(() => {
    setPaymentData(payment);
  }, [payment]);

  const handleOnMethodChange = (value) => {
    setPaymentData((prevState) => ({
      ...prevState,
      payment_method_id: value
    }));
  };

  const handleOnDateChange = (value) => {
    setPaymentData((prevState) => ({
      ...prevState,
      payment_date: value
    }));
  };

  const handleOnCancel = () => {
    onClose();
  };

  const handleOnConfirm = () => {
    createPaymentsService(paymentData)
      .then(() => {
        refreshList();
        toast('Pago registrado');
      })
      .catch((error) => {
        toast('Error al registrar el pago.');
        showErrorMessages(error);
        console.error(
          'PaymentComp -> Error while registering payment: ',
          error
        );
      })
      .finally();
  };

  return (
    <PaymentModalView
      payment={paymentData}
      onDateChange={handleOnDateChange}
      onMethodChange={handleOnMethodChange}
      onClose={handleOnCancel}
      onConfirm={handleOnConfirm}
    />
  );
};

export default PaymentModalComponent;
