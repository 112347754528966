/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import Tooltip from '@material-ui/core/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';
import DeleteIcon from '@material-ui/icons/Delete';

/** Import styles section **/
import './BillModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUITextField from '../../../components/UI/inputs/MUITextField';
import MUIDateField from '../../../components/UI/inputs/MUIDateField';

const BillModalView = ({ data, onInputChange, onClose, onSave, onDelete }) => {
	return (
		<ModalComponent
			open={true}
			onClose={onClose}
			onConfirm={onSave}
			title={`Factura para ${data?.tracking_guide}`}
		>
			<div className="billmodal">
				<MUITextField
					required
					id="No. Factura"
					name="bill_number"
					data-cy="cy-modal_bill"
					label="No. Factura"
					value={data?.bill_number}
					onChange={onInputChange}
				/>
				<MUIDateField
					id="bill_date"
					name="bill_date"
					label="Fecha de emisión de factura"
					value={data?.bill_date}
					onChange={onInputChange}
				/>
				{data?.bill_id && (
					<Tooltip title="Eliminar factura.">
						<ButtonBase onClick={onDelete}>
							<DeleteIcon className="g-row-action-button delete" />
						</ButtonBase>
					</Tooltip>
				)}
			</div>
		</ModalComponent>
	);
};

export default BillModalView;
