
/** Import react/libraries section **/
import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';

/** Import component section **/
import FilterBy from '../../components/UI/FilterBy';
import PageTitle from './../../components/PageTitle';
import MUIDataTable from '../../components/MUIDataTable';
import AddButton from '../../components/UI/AddButton';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import './UnitsStyles.scss';

const UnitsView = ({ units, loading, filteringHandler, handleAddUnit, onEditUnit, onDeleteUnit }) => {
  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          data-cy="edit-button"
          className="g-row-action-button"
          onClick={() => onEditUnit(row.id)}
        />
        <DeleteIcon
          data-cy="delete-button"
          className="g-row-action-button delete"
          onClick={() => onDeleteUnit(row.id)}
        />
      </>
    );
  };
  
  const tableColumns = [
      {
        name: 'Unidad',
        selector: 'name',
        sortable: true,
        center: true
      },
      {
        name: 'Capacidad de carga (Kg)',
        selector: 'load',
        // format: (row) => {
        //   return row.load === null ?
        //     row.load?.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
        //     : null;
        // },
        sortable: true,
        center: true
      },
      {
        name: 'Largo (mts)',
        selector: 'long',
        sortable: true,
        center: true
      },
      {
        name: 'Ancho (mts)',
        selector: 'width',
        sortable: true,
        center: true
      },
      {
        name: 'Alto (mts)',
        selector: 'height',
        sortable: true,
        center: true
      },
      {
        name: 'Volumen',
        selector: 'volume',
        sortable: true,
        center: true
      },
      {
        name: 'Capacidad Pallets cama simple',
        selector: 'capacity_pallet_simple_bed',
        sortable: true,
        center: true
      },
      {
        name: 'Capacidad Pallets doble cama',
        selector: 'capacity_pallet_double_bed',
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ];
  
  return (
    <Fragment>
      <PageTitle title="Unidades" />
      <Paper className="g-page-header providers-header">
        <div className="providers-header-filterby">
          <FilterBy onChange={filteringHandler} />
        </div>
        <AddButton onClick={handleAddUnit}/>
      </Paper>

      <MUIDataTable data={units} columns={tableColumns} loading={loading} />
    </Fragment>
  );
};

UnitsView.propTypes = {
  units: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  filteringHandler: PropTypes.func.isRequired,
  handleAddUnit: PropTypes.func.isRequired,
  onEditUnit: PropTypes.func.isRequired,
  onDeleteUnit: PropTypes.func.isRequired,
};

export default UnitsView;
