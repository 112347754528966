import ApiService from './api-service';

/**
 * Save attached evidence files.
 * @param evidences
 * @param shipmenId
 * @param loadDate
 * @param folio
 * @returns {Promise<*>}
 */
export const saveAccuseService = (evidences, shipmenId, loadDate, folio) => {
	const serviceDef = {
		url: 'shipment-accuse/create',
		method: 'post',
		params: {
			id: shipmenId,
			accuse_load_date: loadDate,
			folio: folio,
			accuses: evidences,
		},
	};
	return ApiService.request( serviceDef );
};
/**
 * Get all PDF files
 * @param shipmenId
 * @returns {Promise<*>}
 */
export const getPDFsService = (shipmenId) => {
	// debugger;
	const serviceDef = {
		url: 'shipment-file',
		method: 'post',
		params: {
			id: shipmenId
		},
	};
	return ApiService.request( serviceDef );
};
/**
 * Create PDF quote version.
 * @param shipmenId
 * @returns {Promise<*>}
 */
export const createPDFService = (shipmenId) => {
	const serviceDef = {
		url: 'shipment-file/create-pdf',
		method: 'post',
		params: { id: shipmenId },
	};
	return ApiService.request( serviceDef );
};
/**
 * Send PDF file to client.
 * @param shipmenId
 * @returns {Promise<*>}
 */
export const sendPDFService = (shipmenId) => {
	const serviceDef = {
		url: 'shipment-file/send-pdf',
		method: 'post',
		params: { id: shipmenId },
	};
	return ApiService.request( serviceDef );
};
/**
 * Delete a given PDF
 * @returns {Promise<*>}
 * @param fileId
 */
export const deletePDFService = (fileId) => {
	const serviceDef = {
		url: 'shipment-file/delete-pdf',
		method: 'post',
		params: { id: fileId },
	};
	return ApiService.request( serviceDef );
};