/** Import react/libraries section **/
import React, {useState} from 'react';
import {toast} from 'react-toastify';

/** Import component section **/
import ProductPriceModalView from './ProductPriceModalView';

import {updateProductPriceService} from '../../../services/prices-services';
import {showErrorMessages} from '../../../utils/utils';

/** Import helpers section **/

const ProductPriceModalComponent = ({item, onClose}) => {
  
  const [productPrice, setProductPrice] = useState( item );
  
  const handleOnInputChange = (event, service) => {
    const {name, value} = event;
    
    let services = productPrice.shipment_type_prices;
    services[service][name] = value;
    
    setProductPrice(prevState => ({
      ...prevState,
      'shipment_type_prices': services
    }));
  };
  
  const onSaveUpdate = (event) => {
    event.preventDefault();
    updateProductPriceService(productPrice).then(() => {
      toast('Precio agregado', {type: 'success'});
      onClose(true);
    }).catch(error => {
      toast('Error, no se guardo.', {type: 'error'});
      showErrorMessages( error );
    });
  };
  
  return (
    <ProductPriceModalView
      onClose               ={onClose}
      onSaveUpdate          ={onSaveUpdate}
      productPrice          ={productPrice}
      onInputChange         ={handleOnInputChange}
    />
  );
};

export default ProductPriceModalComponent;
