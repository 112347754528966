import ApiService from './api-service';

export const userSignInService = (email, password) => {
  const serviceDef = {
    url: 'auth/login',
    method: 'post',
    params: { email: email, password: password }
  };

  return ApiService.request(serviceDef).then((serviceResponse) => {
    return serviceResponse;
  });
};

export const userAuthService = (email, password) => {
  const serviceDef = {
    url: 'auth/login',
    method: 'post',
    params: { email: email, password: password }
  };

  return ApiService.request(serviceDef).then((serviceResponse) => {
    return serviceResponse;
  });
};
