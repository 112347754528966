/** Import react/libraries section **/
import React, { useState } from 'react';

/** Import services section **/

/** Import helpers section **/

/** Import resources section **/

/** Import component section **/
import ShipmentsView from './ShipmentsView';
import UnBilledShipments from './UnBilledShipments';
import BilledShipments from './BilledShipments';

const ShipmentsComponent = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleOnTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <ShipmentsView activeTab={activeTab} onTabChange={handleOnTabChange} />
      {activeTab === 0 && <UnBilledShipments />}
      {activeTab === 1 && <BilledShipments />}
    </>
  );
};

export default ShipmentsComponent;
