import React, { useEffect, useState } from 'react';

import { t as typy } from 'typy';
import DedicatedPriceModalView from './DedicatedPriceModalView';

import { getUnitsService } from '../../../services/units-services';
import {
  addDedicatedPriceService,
  updateDedicatedPriceService
} from '../../../services/prices-services';
import { toast } from 'react-toastify';
import { showErrorMessages } from '../../../utils/utils';

const initialState = {
  origin_destination_id: null,
  unit_id: null,
  cost: 0,
  min_price: 0,
  public_price: 0
};

const DedicatedPriceModalComponent = ({
  item,
  onClose,
  selectedRoute,
  modalMode,
  updatePricesList
}) => {
  const [units, setUnits] = useState([]);
  const [dedicatedPrice, setDedicatedPrice] = useState(initialState);

  const getUnits = () => {
    getUnitsService().then((response) => {
      const responseArr = typy(response).safeArray;
      setUnits(responseArr);
    });
  };

  useEffect(() => {
    getUnits();
  }, []);

  useEffect(() => {
    if (modalMode === 'edit') {
      setDedicatedPrice(item);
    } else {
      setDedicatedPrice(initialState);
    }
  }, [item, modalMode]);

  const onRouteSave = () => {
    let price = {
      origin_destination_id:
        selectedRoute !== null
          ? selectedRoute
          : dedicatedPrice.origin_destination_id,
      unit_id: dedicatedPrice.unit_id,
      cost: dedicatedPrice.cost,
      min_price: dedicatedPrice.min_price,
      public_price: dedicatedPrice.public_price
    };

    if (modalMode === 'add') {
      addDedicatedPriceService(price)
        .then(() => {
          toast('Precio agregado correctamnete', { type: 'success' });
          updatePricesList(price.origin_destination_id);
        })
        .catch((error) => {
          toast('Error al agregar precio', { type: 'error' });
          showErrorMessages(error);
        })
        .finally(() => {
          onClose();
        });
    } else {
      price.id = dedicatedPrice.id;

      updateDedicatedPriceService(price)
        .then(() => {
          toast('Precio agregado correctamnete', { type: 'success' });
          updatePricesList(price.origin_destination_id);
        })
        .catch((error) => {
          toast('Error al agregar precio', { type: 'error' });
          showErrorMessages(error);
        })
        .finally(() => {
          onClose();
        });
    }
  };

  const handleOnRouteSelect = (route) => {
    const field = 'origin_destination_id';
    setDedicatedPrice((prevState) => ({
      ...prevState,
      [field]: route
    }));
  };

  const handleOnInputChange = (data) => {
    const { name, value } = data;
    setDedicatedPrice((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <DedicatedPriceModalView
      item={dedicatedPrice}
      units={units}
      onClose={onClose}
      onSave={onRouteSave}
      modalMode={modalMode}
      selectedRoute={selectedRoute}
      onInputChange={handleOnInputChange}
      onRouteSelect={handleOnRouteSelect}
    />
  );
};

export default DedicatedPriceModalComponent;
