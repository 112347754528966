/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import PageTitle from './../../components/PageTitle';
import FilterBy from '../../components/UI/FilterBy';
import AddButton from '../../components/UI/AddButton';
import MUIDataTable from '../../components/MUIDataTable';
import MUISelect from '../../components/UI/inputs/MUISelect';

/** Import helpers section  **/
import {
	ASSIGN_CLIENT_PERMISSION,
	DELETE_CLIENT_PERMISSION,
	EDIT_CLIENT_PERMISSION
} from '../../access/PERMISSIONS';

/** Import styles section **/
import './ClientsStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AccessControl from '../../access/AccessControl';

const clientTypes = [
	{ id: 0, title: 'Normal' },
	{ id: 1, title: 'Especial' }
];
const creditTypes = [
	{ id: 0, title: 'Contado' },
	{ id: 1, title: 'Crédito' }
];
const clientStatus = [
	{ id: 0, title: 'Inactivo' },
	{ id: 1, title: 'Activo' }
];

const ClientsView = ({
	clients,
	users,
	loading,
	selectedUser,
	selectedClientType,
	selectedCreditType,
	selectedStatus,
	onFilterBy,
	onUserChange,
	onClientTypeChange,
	onCreditTypeChange,
	onStatusChange,
	onAddClient,
	onCpacEdit,
	onEditClient,
	onAssign,
	onDeleteClient
}) => {
	const ActiveCell = ({ row }) => {
		if (row?.active) {
			return <CheckCircleOutlineIcon className="activeicon active" />;
		}
		return <NotInterestedIcon className="activeicon" />;
	};

	const ActionsCell = ({ row }) => {
		return (
			<div className="clients_actions">
				<AccessControl
					allowedPermissions={[EDIT_CLIENT_PERMISSION]}
					renderNoAccess={() => null}
				>
					<EditIcon
						className="g-row-action-button"
						onClick={() => onEditClient(row)}
					/>
					<LocalGasStationIcon
						className="g-row-action-button complementary-2"
						onClick={() => onCpacEdit(row)}
					/>
				</AccessControl>

				<AccessControl
					allowedPermissions={[ASSIGN_CLIENT_PERMISSION]}
					renderNoAccess={() => null}
				>
					<HowToRegIcon
						className="g-row-action-button complementary-3"
						onClick={() => onAssign(row)}
					/>
				</AccessControl>

				<AccessControl
					allowedPermissions={[DELETE_CLIENT_PERMISSION]}
					renderNoAccess={() => null}
				>
					<DeleteIcon
						clients-cy="cy-delete-button"
						className="g-row-action-button delete"
						onClick={() => onDeleteClient(row)}
					/>
				</AccessControl>
			</div>
		);
	};

	const tableColumns = [
		{
			name: 'Numero',
			selector: 'client_number',
			sortable: true,
			center: true,
			wrap: true
		},
		{
			name: 'Cliente',
			selector: 'name',
			sortable: true,
			center: true,
			wrap: true
		},
		{
			name: 'RFC',
			selector: 'rfc',
			sortable: true,
			center: true
		},
		{
			name: 'Tipo de cliente',
			cell: (row) => <>{row.client_type ? 'Especial' : 'Normal'}</>,
			sortable: true,
			center: true
		},
		{
			name: 'Forma de Pago',
			cell: (row) => <>{row.credit ? 'Crédito' : 'Contado'}</>,
			sortable: true,
			center: true
		},
		{
			name: 'Asesor',
			selector: 'assessor_name',
			sortable: true,
			center: true
		},
		{
			name: 'Activo',
			selector: 'active',
			cell: (row) => <ActiveCell row={row} />,
			sortable: true,
			center: true
		},
		{
			name: '',
			cell: (row) => <ActionsCell row={row} />,
			ignoreRowClick: true,
			right: true
		}
	];

	return (
		<div className="clients">
			<PageTitle title="Clientes" />
			<Paper className="g-page-header clients_header">
				<div className="clients_header_filters">
					<div className="coverage_header_filters_text">
						<FormControlLabel
							label=""
							control={<FilterBy onChange={onFilterBy} />}
						/>
					</div>
					<MUISelect
						name="user_id"
						label="Asesor"
						items={users}
						defaultValue={selectedUser}
						idSelector="id"
						selector="name"
						noSelectedLabel="Seleccione"
						onChange={(event) => onUserChange(event.value)}
					/>
					<MUISelect
						name="client-type"
						label="Tipo de cliente"
						defaultValue={selectedClientType}
						items={clientTypes}
						idSelector="id"
						selector="title"
						noSelectedLabel="Todos"
						onChange={(event) => onClientTypeChange(event.value)}
					/>
					<MUISelect
						name="credit-type"
						label="Forma de pago"
						defaultValue={selectedCreditType}
						items={creditTypes}
						idSelector="id"
						selector="title"
						noSelectedLabel="Todos"
						onChange={(event) => onCreditTypeChange(event.value)}
					/>
					<MUISelect
						name="client-status"
						label="Estatus"
						defaultValue={selectedStatus}
						items={clientStatus}
						idSelector="id"
						selector="title"
						noSelectedLabel="Todos"
						onChange={(event) => onStatusChange(event.value)}
					/>
				</div>
				<AddButton onClick={onAddClient} />
			</Paper>

			<MUIDataTable
				data={clients}
				columns={tableColumns}
				loading={loading}
			/>
		</div>
	);
};

export default ClientsView;
