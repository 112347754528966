/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { t as typy } from 'typy';
import { makeStyles } from '@material-ui/core/styles';

import {
  getMunicipalitiesByStateService,
  getStatesService
} from '../../services/location-services';

import { getCoverageMunicipalitiesService } from '../../services/coverage-services';

import MUISelect from './inputs/MUISelect';

import { PropTypes } from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  }
}));

const stateInit = { id: null, name: null };
const municipalityInit = { id: null, name: null };

const StateCitySelect = ({
  id,
  defaultState,
  defaultCity,
  coverageOnly,
  onSelect,
  disabled = false
}) => {
  const [states, setStates] = useState([]);
  const [stateSelected, setStateSelected] = useState(stateInit);
  const [municipalities, setMunicipalities] = useState([]);
  const [municipalitySelected, setMunicipalitySelected] =
    useState(municipalityInit);

  const classes = useStyles();

  useEffect(() => {
    getStates();
  }, []);

  const getStates = () => {
    getStatesService()
      .then((response) => {
        const responseArr = typy(response).safeArray;
        setStates(responseArr);
      })
      .catch((error) => {
        console.error('Error getting states: ', error);
      });
  };

  const doStateObj = (stateId) => {
    if (!stateId) return stateInit;
    const stateFound = states.find((item) => item.id === stateId);
    if (stateFound) {
      return {
        id: stateFound.id,
        name: stateFound.name
      };
    }
    return stateInit;
  };

  useEffect(() => {
    setStateSelected(doStateObj(defaultState));
  }, [states]);

  const handleOnStateSelect = (data) => {
    const { value } = data;
    setStateSelected(doStateObj(value));
  };

  useEffect(() => {
    if (stateSelected.id !== null) {
      if (coverageOnly) {
        getCoverageMunicipalities();
      } else {
        getMunicipalities();
      }
    } else {
      setMunicipalities([]);
      setMunicipalitySelected(municipalityInit);
      clearLocation();
    }
  }, [stateSelected.id]);

  const getMunicipalities = () => {
    setMunicipalities([]);
    getMunicipalitiesByStateService(stateSelected.id).then((response) => {
      const responseArr = typy(response).safeArray;
      setMunicipalities(responseArr);
    });
  };

  const getCoverageMunicipalities = () => {
    setMunicipalities([]);
    getCoverageMunicipalitiesService(stateSelected.id).then((response) => {
      const responseArr = typy(response).safeArray;
      setMunicipalities(responseArr.sort((a, b) => a.name > b.name));
    });
  };

  const doMunicipalityObj = (cityId) => {
    if (!cityId || municipalities.length === 0) return municipalityInit;
    const cityFound = municipalities.find((item) => item.id === cityId);
    if (cityFound) {
      return {
        id: cityFound.id,
        name: cityFound.name
      };
    }
    return municipalityInit;
  };

  useEffect(() => {
    setMunicipalitySelected(doMunicipalityObj(defaultCity));
  }, [municipalities]);

  const handleOnCitySelectMunicipality = (data) => {
    const { value } = data;
    const municipalityObj = doMunicipalityObj(value);
    setMunicipalitySelected(municipalityObj);

    const municipalitySelectData = {
      state_id: stateSelected.id,
      state_name: stateSelected.name,
      municipality_id: municipalityObj.id,
      municipality_name: municipalityObj.name
    };
    if (onSelect) onSelect(municipalitySelectData);
  };

  const clearLocation = () => {
    const municipalitySelectData = {
      state_id: null,
      state_name: null,
      municipality_id: null,
      municipality_name: null
    };
    if (onSelect) onSelect(municipalitySelectData);
  };

  return (
    <div id={id} className={classes.root}>
      <MUISelect
        label="Estado"
        id="state_id"
        idSelector="id"
        selector="name"
        onChange={handleOnStateSelect}
        items={states}
        defaultValue={stateSelected.id}
        disabled={disabled}
      />
      <MUISelect
        label="Municipio"
        id="municipality_id"
        idSelector="id"
        selector="name"
        onChange={handleOnCitySelectMunicipality}
        items={municipalities}
        defaultValue={municipalitySelected.id}
        disabled={disabled}
      />
    </div>
  );
};

StateCitySelect.propTypes = {
  defaultState: PropTypes.number,
  defaultCity: PropTypes.number,
  coverageOnly: PropTypes.bool,
  disabled: PropTypes.bool
};

export default StateCitySelect;
