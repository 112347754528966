/** Import react/libraries section **/
import React, { Fragment, useEffect, useState } from 'react';

/** Import component section **/
import BillingBusinessView from './BillingBusinessView';

/** Import helpers section **/
import { t as typy } from 'typy';
import BillingBusinessModalComponent from './BillingBusinessModal/BillingBusinessModalComponent';

import {
  deleteBillingBusinessService,
  getBillingBusinessService
} from '../../services/billing-business-services';

import { toast } from 'react-toastify';
import ModalComponent from '../../components/UI/ModalComponent';

/** Import resources section **/

const BillingBusinessModel = {
  name: '',
  postal_code: '',
  rfc: '',
  tax_regime_id: null
};

const BillingBusinessComponent = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [BillingBusiness, setBillingBusiness] = useState([]);

  const [modalMode, setModalMode] = useState('');
  const [selectedBillingBusiness, setSelectedBillingBusiness] = useState(null);

  const getBillingBusiness = () => {
    const token = '';
    setLoadingData(true);
    getBillingBusinessService({ token })
      .then((response) => {
        const BillingBusinessArr = typy(response).safeArray;
        setOriginalData(BillingBusinessArr);
        setBillingBusiness(BillingBusinessArr);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    getBillingBusiness();
  }, []);

  const handleOnDeleteConfirm = () => {
    let params = { billing_business_id: selectedBillingBusiness.id };
    deleteBillingBusinessService(params)
      .then(() => {
        getBillingBusiness();
        toast.success('Empresa de facturación borrada');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar la empresa de facturación.');
      })
      .finally(() => {
        setSelectedBillingBusiness(null);
        setModalMode(null);
      });
  };

  const handleOnFilterBy = (filterText) => {
    let filteredItems;

    if (filterText.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterText.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item?.business_name?.toString().toLowerCase();
        const field2 = item?.postal_code?.toString();
        const field3 = item?.tax_regime_title?.toString().toLowerCase();
        const field4 = item?.rfc?.toString().toLowerCase();

        return (
          field1?.includes(filteringText) ||
          field2?.includes(filteringText) ||
          field3?.includes(filteringText) ||
          field4?.includes(filteringText)
        );
      });
    }

    setBillingBusiness(filteredItems);
  };

  const handleAddBillingBusiness = () => {
    setSelectedBillingBusiness(Object.assign({}, BillingBusinessModel));
    setModalMode('add');
  };

  const onEditBillingBusiness = (selectedBillingBusiness) => {
    setModalMode('edit');
    setSelectedBillingBusiness(selectedBillingBusiness);
  };

  const onClose = () => setModalMode('');

  const handleOnUpdateList = (selectedBillingBusiness) => {
    getBillingBusiness();
  };

  const onCloseAlert = () => {
    setModalMode(null);
    setSelectedBillingBusiness(null);
  };

  const onDeleteUnitBillingBusiness = (row) => {
    setSelectedBillingBusiness(row);
    setModalMode('delete');
  };

  return (
    <Fragment>
      <BillingBusinessView
        billingBusiness={BillingBusiness}
        onEditBillingBusiness={onEditBillingBusiness}
        onDeleteUnitBillingBusiness={onDeleteUnitBillingBusiness}
        loading={loadingData}
        filteringHandler={handleOnFilterBy}
        handleAddBillingBusiness={handleAddBillingBusiness}
      />

      {(selectedBillingBusiness && modalMode === 'add') ||
      modalMode === 'edit' ? (
        <BillingBusinessModalComponent
          billingBusinessData={selectedBillingBusiness}
          onUpdateList={handleOnUpdateList}
          onClose={onClose}
          modalMode={modalMode}
        />
      ) : null}

      {modalMode === 'delete' ? (
        <ModalComponent
          open={true}
          onClose={onCloseAlert}
          onConfirm={handleOnDeleteConfirm}
          title={
            'Borrar empresa de facturación: ' +
            selectedBillingBusiness?.business_name
          }
        >
          {'¿Esta seguro que desea eliminarla?'}
        </ModalComponent>
      ) : null}
    </Fragment>
  );
};

export default BillingBusinessComponent;
