/** Import react/libraries section **/
import React, { useState, useEffect, useCallback } from 'react';

/** Import services section **/
import { getClientsSelectService } from '../../../services/clients-services';
import { requestUsersAssign } from '../../../services/users-services';

/** Import helpers section **/

/** Import resources section **/

/** Import component section **/
import FiltersView from './FiltersView';

const initFilters = {
  text: '',
  start_date: null,
  end_date: null,
  client: null,
  status: null,
  city: null
};

const FiltersComponent = ({ onFiltersChange }) => {
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState(initFilters);

  const getClients = useCallback(() => {
    getClientsSelectService()
      .then((response) => {
        if (response?.clients) setClients(response.clients);
      })
      .catch((error) => {
        console.error('FiltersComponent -> Error getting clients list ', error);
      });
  }, []);

  const getUsers = useCallback(() => {
    requestUsersAssign()
      .then((response) => {
        setUsers(response);
      })
      .catch((error) => {
        console.error('FiltersComponent -> Error getting users ', error);
      });
  }, []);

  const handleOnDateChange = (event) => {
    if (!event) return;
    let { value, name } = event;
    setFilters((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnFilterBy = (textStr) => {
    setFilters((prevState) => ({
      ...prevState,
      text: textStr?.toString().toLowerCase().trim()
    }));
  };

  const handleOnInputChange = (data) => {
    const { name, value } = data;
    setFilters((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnCitySelect = (data) => {
    const { city_id } = data;
    setFilters((prevState) => ({
      ...prevState,
      city: city_id
    }));
  };

  useEffect(() => {
    getClients();
    getUsers();
  }, [getClients, getUsers]);

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters, onFiltersChange]);

  return (
    <FiltersView
      clients={clients}
      users={users}
      filters={filters}
      onFilterBy={handleOnFilterBy}
      onDateChange={handleOnDateChange}
      onInputChange={handleOnInputChange}
      onCitySelect={handleOnCitySelect}
    />
  );
};

export default FiltersComponent;
