/** Import react/libraries section **/
import React from 'react';
import is from 'is_js';

/** Import component section **/
import PageTitle from '../../../components/PageTitle';
import ModalComponent from '../../../components/UI/ModalComponent';
import MUITextField from '../../../components/UI/inputs/MUITextField';
import MUISelect from '../../../components/UI/inputs/MUISelect';

/** Import helpers section **/

/** Import resources section **/
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './UserModalStyles.scss';

const UserModalView = ({
  user,
  roles,
  loading,
  onSave,
  onClose,
  onInputChange,
  onSwitchChange
}) => {
  return (
    <ModalComponent
      open={true}
      title={user?.id ? 'Editar usuario' : 'Nuevo usuario'}
      onClose={onClose}
      onConfirm={onSave}
    >
      {loading === true ? (
        <PageTitle title="Cargando..." />
      ) : (
        <div className="usermodal">
          <div className="usermodal_row">
            <MUITextField
              label="Nombre"
              value={user?.name}
              type="text"
              name="name"
              onChange={onInputChange}
            />

            <MUITextField
              label="Apellidos"
              value={user?.last_name}
              type="text"
              name="last_name"
              onChange={onInputChange}
            />
          </div>

          <div className="usermodal_row">
            <MUITextField
              label="Contraseña"
              value={user?.password}
              type="password"
              name="password"
              error={user?.password !== user?.password_conf}
              onChange={onInputChange}
            />

            <MUITextField
              label="Confirmar contraseña"
              value={user?.password_conf}
              type="password"
              name="password_conf"
              error={user?.password !== user?.password_conf}
              onChange={onInputChange}
            />
          </div>

          <div className="usermodal_row">
            <MUITextField
              label="Correo"
              value={user.email}
              type="text"
              name="email"
              error={user.email !== '' ? !is.email(user.email) : false}
              onChange={onInputChange}
            />

            <MUISelect
              label="Rol"
              value={user?.role_id}
              items={roles}
              idSelector="id"
              selector="title"
              noSelectedLabel="Seleccione"
              name="role_id"
              onChange={onInputChange}
            />
          </div>

          <div className="usermodal_row">
            <MUITextField
              label="Sucursal"
              value={user?.branch_office}
              type="text"
              name="branch_office"
              onChange={onInputChange}
            />
            <FormControlLabel
              control={
                <Switch
                  label="Activo"
                  checked={user?.active === 1}
                  onChange={(event) => onSwitchChange(event)}
                  name="active"
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
              label="Activo"
            />
          </div>
        </div>
      )}
    </ModalComponent>
  );
};

export default UserModalView;
