/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/

/** Import styles section **/
import './FiltersStyles.scss';

/** Import component section **/
import FilterBy from '../../../components/UI/FilterBy';
import MUIDateField from '../../../components/UI/inputs/MUIDateField';
import MUISelect from '../../../components/UI/inputs/MUISelect';
import StateCitySelect from '../../../components/UI/StateCitySelect';

const FiltersView = ({
  clients,
  users,
  filters,
  onFilterBy,
  onDateChange,
  onInputChange,
  onCitySelect
}) => {
  return (
    <div className="filters">
      <div className="filters_filterby">
        <FilterBy onChange={onFilterBy} />
      </div>

      <MUIDateField
        label="Fecha desde"
        onChange={onDateChange}
        name="start_date"
        value={filters?.start_date}
      />

      <MUIDateField
        label="Fecha hasta"
        onChange={onDateChange}
        name="end_date"
        value={filters?.end_date}
      />
      <MUISelect
        label="Cliente"
        selector="business_name"
        idSelector="id"
        name="client"
        noSelectedLabel="select"
        defaultValue={filters?.client}
        items={clients}
        onChange={onInputChange}
      />
      <StateCitySelect coverageOnly={true} onSelect={onCitySelect} />
      <MUISelect
        label="Asesor"
        selector="name"
        idSelector="id"
        name="user"
        noSelectedLabel="select"
        defaultValue={filters?.user}
        items={users}
        onChange={onInputChange}
      />
    </div>
  );
};

export default FiltersView;
