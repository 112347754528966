/* eslint-disable react-hooks/exhaustive-deps */

/** Import react/libraries section **/
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Import component section **/
import CostBreakdownView from './CostBreakdownView';

/** Import helpers section **/
import {
  endProcess,
  setCosts,
  setListOfProviders,
  setMSProvider,
  setProductsDetails,
  startProcess,
  updateCost,
  updateField,
  updateOtherCostDescription
} from '../../../redux/quotation/quoteSlice';
import {
  applyForCreditExtensionService,
  refreshQuoteCosts,
  setShipmentAsBillable
} from '../../../services/quotes-services';
import ModalComponent from '../../../components/UI/ModalComponent';

export const CostBreakdownComponent = () => {
  const dispatch = useDispatch();

  const generalData = {
    total: useSelector((store) => store.quote.quotation.total),
    clientCredit: useSelector((store) => store.quote.quotation.client_credit),
    canApplyCredit: useSelector(
      (store) => store.quote.quotation.can_apply_credit
    )
  };
  const billable = useSelector((store) => store.quote.quotation.billable);
  const quotation = useSelector((store) => store.quote.quotation);
  const shipmentId = useSelector((store) => store.quote.quotation.id);
  const costsList = useSelector((store) => store.quote.quotation.costs.cost);
  const discount = useSelector((store) => store.quote.quotation.discount);
  const hasPayment = useSelector((store) => store.quote?.quotation.has_payment);
  const isShipment = useSelector(
    (store) => store.quote?.quotation?.is_shipment
  );
  const lastUpdatedDate = useSelector(
    (store) => store.quote.quotation.costs.cost_update_date
  );
  const shipmentTypeId = useSelector(
    (store) => store.quote.quotation.delivery_type.shipment_type_id
  );
  const cpac = useSelector((store) => store.quote.quotation.client.cpac);

  const [updating, setUpdating] = useState(false);
  const [totals, setTotals] = useState({
    iva: quotation?.iva,
    total: quotation?.total,
    subtotal: quotation?.subtotal,
    retention: quotation?.retention
  });
  const [showAlert, setShowAlert] = useState(false);

  const onCostChange = (data) => {
    let { name, value } = data;
    dispatch(updateCost({ field: name, value: value }));
  };

  const onOtherInputChange = (event) => {
    let { name, value } = event;
    dispatch(updateOtherCostDescription({ field: name, value: value }));
  };

  const onRefreshCosts = () => {
    setShowAlert(true);
  };

  const onSwitchChange = (event) => {
    let status = event.target.checked === true ? 1 : 0;
    dispatch(startProcess());
    setShipmentAsBillable(shipmentId, status)
      .then(() => {
        toast('Se cambio el estatus.');
        dispatch(updateField({ field: 'billable', value: status }));
      })
      .catch((error) => {
        toast('Error al cambiar estatus.');
        console.error(
          'QuoteEditComponent -> Error setting billable status: ',
          error
        );
      })
      .finally(() => dispatch(endProcess()));
  };

  const onCreditRequest = () => {
    applyForCreditExtensionService(shipmentId)
      .then(() => {
        toast('Se ha solicitado correctamente', { type: 'success' });
      })
      .catch((error) => {
        toast('Error al solicitar', { type: 'error' });
        console.error('QuoteCostComp -> Error requesting credit: ', error);
      });
  };

  const onConfirm = () => {
    if (quotation.id !== null) {
      setUpdating(true);
      refreshQuoteCosts(quotation)
        .then((response) => {
          setTotals((prevState) => ({
            ...prevState,
            iva: response.iva,
            total: response.total,
            subtotal: response.subtotal,
            retention: response.retention
          }));

          dispatch(setCosts({ costs: response?.costs }));
          dispatch(
            updateField({ field: 'subtotal', value: response?.subtotal })
          );
          dispatch(updateField({ field: 'iva', value: response?.iva }));
          dispatch(
            updateField({ field: 'retention', value: response?.retention })
          );
          dispatch(updateField({ field: 'total', value: response?.total }));
          dispatch(
            setListOfProviders({ providersList: response?.providers.list })
          );
          dispatch(setMSProvider({ provider: response?.providers.msprovider }));
          dispatch(setProductsDetails({ details: response?.products_details }));
        })
        .catch((error) => {
          console.error('QuoteCostComponent - Error updating costs: ', error);
        })
        .finally(() => {
          setUpdating(false);
          setShowAlert(false);
        });
    } else {
      toast('La cotización debe ser guardada antes de calcular costos.');
    }
  };

  return (
    <>
      <CostBreakdownView
        totals={totals}
        costs={costsList}
        discount={discount}
        billable={billable}
        updating={updating}
        isShipment={isShipment}
        hasPayment={hasPayment}
        lastUpdate={lastUpdatedDate}
        cpac={cpac}
        generalData={generalData}
        onCostChange={onCostChange}
        shipmentTypeId={shipmentTypeId}
        onRefreshCosts={onRefreshCosts}
        onSwitchChange={onSwitchChange}
        onCreditRequest={onCreditRequest}
        onOtherInputChange={onOtherInputChange}
      />

      {showAlert === true ? (
        <ModalComponent
          open={showAlert}
          onClose={() => setShowAlert(false)}
          onConfirm={() => onConfirm()}
          title="Actualizar costos"
        >
          {'¿Esta seguro que desea actualizar los costos?'}
        </ModalComponent>
      ) : null}
    </>
  );
};

export default CostBreakdownComponent;
