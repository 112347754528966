/** Import react/libraries section **/
import React, { Fragment } from 'react';

/** Import component section **/
import CardHeader from '@material-ui/core/CardHeader';
import AddButton from '../../../components/UI/AddButton';
import MUITextField from '../../../components/UI/inputs/MUITextField';
import MUIAmountField from '../../../components/UI/inputs/MUIAmountField';

/** Import helpers section **/
import { formatNumberToCurrency } from '../../../utils/formats';

/** Import resources section **/
import './CostBreakdownStyles.scss';
import Switch from '@material-ui/core/Switch';
import { ButtonBase, Card, CardContent, Tooltip } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import WarningIcon from '@material-ui/icons/Warning';
import RefreshIcon from '@material-ui/icons/Refresh';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import cuid from 'cuid';

export const CostBreakdownView = ({
  costs,
  totals,
  discount,
  billable,
  isShipment,
  hasPayment,
  lastUpdate,
  cpac,
  generalData,
  onCostChange,
  shipmentTypeId,
  onSwitchChange,
  onRefreshCosts,
  onCreditRequest,
  onOtherInputChange
}) => {
  const composeFields = () => {
    let fieldsOrder = [
      'servicio',
      'descuento',
      'cpac',
      'ruta_extendida',
      'seguro_básico',
      'seguro_valor_declarado',
      'seguro_extendido',
      'cita',
      'maniobras',
      'demora',
      'retorno',
      'acuse_original',
      'evidencia_digital',
      'almacenaje',
      'entrega_nocturna',
      'entrega_a_domicilio',
      'entrega_ocurre',
      'cambio_de_domicilio',
      'recolección_a_domicilio',
      'rad',
      'otro'
    ];

    let costsArray = [];
    for (let i in costs) if (costs.hasOwnProperty(i)) costsArray.push(costs[i]);
    let orderedCosts = [];

    // Order costs according to fieldsOrder.
    for (let i = 0; i < fieldsOrder.length; i++) {
      for (let j = 0; j < costsArray.length; j++) {
        if (
          costsArray[j].title.toLowerCase().replaceAll(' ', '_') ===
          fieldsOrder[i]
        ) {
          orderedCosts.push(costsArray[j]);
        }
      }
    }

    let costsForreturn = [];

    orderedCosts.forEach((cost, index) => {
      if (index === 1)
        costsForreturn.push(
          <div
            key={cuid()}
            className="costBreakdown_content_fields_item discount"
          >
            <span>Descuento</span>
            <MUIAmountField
              name="Descuento"
              value={discount}
              onChange={onCostChange}
              prefix="% "
              // disabled={(cost.title !== 'Maniobras' && cost.title !== 'Otro') || hasPayment === 1}
            />
          </div>
        );

      cost.title !== 'Otro'
        ? costsForreturn.push(
            <div key={cost.id} className="costBreakdown_content_fields_item">
              <span>{cost.title}</span>
              {
                <MUIAmountField
                  id={cost.id}
                  name={cost.title.toLowerCase().replaceAll(' ', '_')}
                  value={cost.cost}
                  onChange={onCostChange}
                  prefix="$ "
                  disabled={
                    (cost.title !== 'Maniobras' && cost.title !== 'Otro') ||
                    hasPayment === 1
                  }
                />
              }
            </div>
          )
        : index === 1
        ? costsForreturn.push()
        : costsForreturn.push(
            <div className="costBreakdown_content_fields_item-other">
              <span>{cost.title}</span>
              <MUIAmountField
                id={cost.id}
                name={cost.title.toLowerCase().replaceAll(' ', '_')}
                value={cost.cost}
                onChange={onCostChange}
                prefix="$ "
                disabled={
                  (cost.title !== 'Maniobras' && cost.title !== 'Otro') ||
                  hasPayment === 1
                }
              />

              <MUITextField
                id="description"
                label="Descripción"
                type="text"
                name="description"
                value={cost.description}
                onChange={onOtherInputChange}
                disabled={
                  (cost.title !== 'Maniobras' && cost.title !== 'Otro') ||
                  hasPayment === 1
                }
              />
            </div>
          );
    });

    return costsForreturn;
  };

  const paymentMethod = () => {
    if (
      generalData.total > generalData.clientCredit?.balance &&
      generalData.canApplyCredit === 1
    ) {
      return (
        <div className="quoteCosts_right_payment_content-request">
          <div>
            <WarningIcon />
          </div>
          <p id="exceed">Crédito excedido </p>
          <AddButton
            onClick={onCreditRequest}
            text="Solicitar crédito"
            disabled={isShipment}
          />
        </div>
      );
    } else if (generalData.total < generalData.clientCredit?.balance) {
      return (
        <div className="quoteCosts_right_payment_content-credit">
          <CreditCardIcon />
          <p>El cliente tiene crédito</p>
        </div>
      );
    } else {
      return (
        <div className="quoteCosts_right_payment_content-credit">
          <MoneyOffIcon />
          <p>Cliente sin crédito debe pagar de contado</p>
        </div>
      );
    }
  };

  return (
    <Card className="costBreakdown">
      <CardHeader
        title="Desglose de costos"
        subheader={
          costs === undefined || costs.length === 0 ? (
            'Actualice la lista de costos'
          ) : (
            <>
              <div>
                Actualizado: {lastUpdate !== undefined ? lastUpdate : ''}
              </div>
              {cpac && <div>CPAC: {cpac} %</div>}
            </>
          )
        }
        className="costBreakdown_header_actions"
        action={
          <Fragment>
            <Tooltip
              className="costBreakdown_header_actions-reload"
              title="Actualizar costos"
              onClick={(e) => onRefreshCosts(true)}
            >
              <ButtonBase disabled={isShipment}>
                <RefreshIcon
                  className={`g-row-action-button ${
                    isShipment === false ? 'complementary-3' : 'disabled'
                  }`}
                />
              </ButtonBase>
            </Tooltip>

            <FormControlLabel
              className="costBreakdown_header_actions-switch"
              control={
                <Switch
                  checked={billable === 1}
                  onChange={onSwitchChange}
                  name="credit"
                  data-cy="cy-credit"
                  color="primary"
                  inputProps={{ 'aria-label': 'crédito' }}
                />
              }
              label="Facturable"
            />
          </Fragment>
        }
      />

      <CardContent className="costBreakdown_content">
        {costs ? (
          <Fragment>
            <div className="costBreakdown_content_fields">
              {composeFields()}
            </div>

            <div className="costBreakdown_content_info">
              <div className="costBreakdown_content_legends">
                {paymentMethod()}
              </div>

              <div className="costBreakdown_content_info-totals">
                <p className="costBreakdown_content_info-totals-label">
                  {' '}
                  Subtotal{' '}
                </p>
                <p className="costBreakdown_content_info-totals-value">
                  {formatNumberToCurrency(totals?.subtotal)}
                </p>

                <p className="costBreakdown_content_info-totals-label">IVA</p>
                <p className="costBreakdown_content_info-totals-value">
                  {formatNumberToCurrency(totals?.iva)}
                </p>

                {shipmentTypeId >= 2 && totals?.retention > 0 ? (
                  <>
                    <p className="costBreakdown_content_info-totals-label">
                      Retención
                    </p>
                    <p className="costBreakdown_content_info-totals-value">
                      {formatNumberToCurrency(totals?.retention)}
                    </p>
                  </>
                ) : null}

                <p className="costBreakdown_content_info-totals-label">Total</p>
                <p className="costBreakdown_content_info-totals-value">
                  {formatNumberToCurrency(totals?.total)}
                </p>
              </div>
            </div>
          </Fragment>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default CostBreakdownView;
