import ApiService from './api-service';

export const getStaysCostService = () => {
  const serviceDef = {
    url: 'stay-costs',
    method: 'get',
    params: null
  };
  return ApiService.request(serviceDef);
};

export const updateStayCostService = (unitId, cost ) => {
  const serviceDef = {
    url: 'stay-costs/update',
    method: 'post',
    params: { unit_id: unitId, cost: cost }
  };
  return ApiService.request(serviceDef);
};

export const getNightDeliveryCostService = () => {
  const serviceDef = {
    url: 'appointment-costs',
    method: 'get',
    params: null
  };
  return ApiService.request(serviceDef);
};

export const updateNightDeliveryCostService = ({ unitId, cost, daytimeCost }) => {
  const serviceDef = {
    url: 'appointment-costs/update',
    method: 'post',
    params: { unit_id: unitId, cost: cost, daytime_cost: daytimeCost }
  };
  return ApiService.request(serviceDef);
};

export const getDelaysCostService = () => {
  const serviceDef = {
    url: 'delay-costs',
    method: 'get',
    params: null
  };
  return ApiService.request(serviceDef);
};

export const updateDelaysCostService = ( unitId, cost) => {
  const serviceDef = {
    url: 'delay-costs/update',
    method: 'post',
    params: { unit_id: unitId, cost: cost }
  };
  return ApiService.request(serviceDef);
};
