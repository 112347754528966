import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import MUIDialogTitle from './elements/MUIDialogTitle';
import MUIDialogActions from './elements/MUIDialogActions';

import MUITextField from './inputs/MUITextField';
import MUIDateField from './inputs/MUIDateField';
import MUISelect from './inputs/MUISelect';

import { PropTypes } from 'prop-types';

const useStyles = makeStyles(() => ({
  modal: { minWidth: '500px' },
  modalContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    height: 'auto',
    minWidth: '400px'
  },
  inputLabel: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.38)',
    margin: '0 0 4px 0',
    backgroundColor: 'transparent',
    fontWeight: 'lighter'
  },
  inputField: {
    verticalAlign: 'center',
    backgroundColor: '#fff',
    border: `1px solid #ced4da`,
    height: '2rem',
    borderRadius: 5,
    placeholderTextColor: '#ced4da',
    padding: '5px',
    fontSize: '13px',
    margin: '0px',
    width: '100%'
  }
}));

const EditFieldModal = ({
  open,
  title,
  data,
  fields,
  handleOnClose,
  handleOnSave
}) => {
  const classes = useStyles();
  const [fieldsData, setFieldsData] = useState([]);

  console.log(fields, data);

  useEffect(() => {
    setFieldsData(fields);
  }, [fields]);

  const handleOnInputChange = (field, value) => {
    const fieldIndex = fieldsData.findIndex(
      (fieldObj) => fieldObj.label === field.label
    );
    if (fieldIndex < 0) return;

    const newFields = [...fieldsData];
    const newValue = field.type === 'number' ? parseInt(value) : value;
    newFields[fieldIndex].value = newValue;
    setFieldsData(newFields);
  };

  const handleOnButtonSave = () => {
    for (var i = 0; i < fields.length; i++) {
      const fieldId = fields[i].id;
      const fieldValue = fields[i].value;
      data[fieldId] = fieldValue;
    }
    handleOnSave(data);
  };

  return (
    <Dialog open={open} onClose={handleOnClose} className={classes.modal}>
      <MUIDialogTitle onClose={handleOnClose}>{title}</MUIDialogTitle>

      <DialogContent className={classes.modalContent}>
        {fieldsData.map((field, index) => (
          <div key={index}>
            {field.type === 'date' && (
              <MUIDateField
                id={field.id}
                // value={field.value}
                label={field.label}
                defaultValue={field.value}
                onChange={(value) => handleOnInputChange(field, value)}
                noClear={true}
              />
            )}
            {field.type === 'text' && (
              <MUITextField
                id={field.id}
                label={field.label}
                value={field.value}
                type={field.type}
                onChange={(result) => handleOnInputChange(field, result.value)}
              />
            )}

            {field.type === 'number' && (
              <MUITextField
                id={field.id}
                label={field.label}
                value={field.value}
                type={field.type}
                onChange={(result) => handleOnInputChange(field, result.value)}
                disabled={field.disabled ? field.disabled : false}
              />
            )}
            {field.type === 'select' && (
              <MUISelect
                label={field.label}
                id="state_id"
                defaultValue={field.value}
                items={field.selectOptions.items}
                idSelector={field.selectOptions.idSelector}
                selector={field.selectOptions.selector}
                onChange={(result) => handleOnInputChange(field, result.value)}
              />
            )}
          </div>
        ))}
      </DialogContent>

      <MUIDialogActions
        onClose={handleOnClose}
        onConfirm={handleOnButtonSave}
        confirmText="Guardar"
      />
    </Dialog>
  );
};

EditFieldModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  service: PropTypes.string,
  data: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['text', 'number', 'date']).isRequired,
      value: PropTypes.any.isRequired
    })
  ).isRequired,
  handleOnClose: PropTypes.func,
  handleOnSave: PropTypes.func
};

export default EditFieldModal;
