/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import MUIDataTable from '../../components/MUIDataTable';
import FilterBy from '../../components/UI/FilterBy';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUIDateField from '../../components/UI/inputs/MUIDateField';
import MUISelect from '../../components/UI/inputs/MUISelect';
import { Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

/** Import helpers section **/

/** Import resources section **/

const QuotationReportsView = ({
  reports,
  loading,
  onClear,
  onFilter,
  dateRange,
  showClear,
  selectedStatus,
  handleDateChange,
  handleOnStatusChange
}) => {
  const authorizationStatus = [
    { id: 1, title: 'Cotización' },
    { id: 3, title: 'Cancelado' }
  ];
  const tableColumns = [
    {
      name: 'No. Cotización',
      selector: 'tracking_guide',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Fecha',
      selector: 'quotation_date',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Estatus',
      selector: 'shipment_status',
      sortable: true,
      center: true
    },
    {
      name: 'Asesor',
      selector: 'name',
      sortable: true,
      center: false,
      wrap: true
    },
    {
      name: 'Cliente',
      selector: 'client',
      sortable: true,
      center: false,
      wrap: true
    },
    {
      name: 'Servicio',
      selector: 'service_type',
      sortable: true,
      center: true
    },
    {
      name: 'Subtotal',
      selector: 'subtotal',
      sortable: true,
      center: true
    },
    {
      name: 'IVA',
      selector: 'iva',
      sortable: true,
      center: true
    },
    {
      name: 'Retención',
      selector: 'retention',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
  ];

  return (
    <div className="reports">
      <PageTitle title="Reporte de Cotizaciones" />
      <Paper className="g-page-header reports_header">
        <div className="reports-header-filters">
          <div className="reports_header_filters_switch">
            <FormControlLabel
              label=""
              control={<FilterBy onChange={onFilter} />}
            />

            <p>Del</p>

            <FormControlLabel
              control={
                <MUIDateField
                  onChange={handleDateChange}
                  name="start"
                  value={dateRange?.start}
                />
              }
              label=" Al "
            />

            <FormControlLabel
              control={
                <MUIDateField
                  onChange={handleDateChange}
                  name="end"
                  value={dateRange?.end}
                />
              }
              label=""
            />

            <MUISelect
              label="Estatus"
              selector="title"
              idSelector="id"
              name="client-status"
              noSelectedLabel="Todos"
              defaultValue={selectedStatus}
              items={authorizationStatus}
              onChange={(event) => handleOnStatusChange(event.value)}
            />

            {showClear === true ? (
              <Tooltip title="Limpiar filtros">
                <ClearIcon onClick={(e) => onClear(e)} />
              </Tooltip>
            ) : null}
          </div>
        </div>
      </Paper>
      <MUIDataTable
        loading={loading}
        columns={tableColumns}
        data={reports}
        showDownload={true}
      />
    </div>
  );
};

export default QuotationReportsView;
