import ApiService from './api-service';

export const getCPACService = ({ client_id }) => {
  const serviceDef = {
    url: 'client-cpac/show',
    method: 'post',
    params: { client_id }
  };
  return ApiService.request(serviceDef);
};

export const createCPACService = ({ client_id, shipment_types_cpacs }) => {
  const serviceDef = {
    url: 'client-cpac/create',
    method: 'post',
    params: { client_id, shipment_types_cpacs }
  };
  return ApiService.request(serviceDef);
};

export const updateCPACService = ({ client_id, shipment_types_cpacs }) => {
  const serviceDef = {
    url: 'client-cpac/update',
    method: 'post',
    params: { client_id, shipment_types_cpacs }
  };
  return ApiService.request(serviceDef);
};
