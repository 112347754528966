/* eslint-disable react-hooks/exhaustive-deps */

/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

/** Import component section **/
import EditFieldModal from '../../components/UI/EditFieldModal';
import ModalComponent from '../../components/UI/ModalComponent';
import ExtendedPostalCodesView from './PostalCodesView';

/** Import helpers section **/
import {
	addExtendedPostalCodeByCityService,
	deleteExtendedPostalCodeByCityService,
	getExtendedPostalCodesByCityService
} from '../../services/coverage-services';
import { showErrorMessages } from '../../utils/utils';

const ExtendedPostalCodesComponent = () => {
	let location = useLocation();

	const [loadingData, setLoadingData] = useState(false);
	const [postalCodes, setPostalCodes] = useState([]);
	const [originalData, setOriginalData] = useState([]);
	const [editItem, setEditItem] = useState(null);
	const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
	const [municipalityId, setMunicipalityId] = useState(null);
	const [cityName, setCityName] = useState('');

	const getData = () => {
		setLoadingData(true);
		getExtendedPostalCodesByCityService(location.state?.municipality_id)
			.then((response) => {
				setPostalCodes(
					response.length === 0 ? [] : response[0]?.postal_codes
				);
				setOriginalData(
					response.length === 0 ? [] : response[0]?.postal_codes
				);
				setLoadingData(false);
			})
			.catch((error) => {
				console.error(
					'PostalCodesComponent - Error getting postal codes: ',
					error
				);
				showErrorMessages(error);
			})
			.finally(() => {
				setLoadingData(false);
			});
	};

	useEffect(() => {
		if (location.state?.municipality_id !== undefined) {
			setMunicipalityId(location.state?.municipality_id);
			setCityName(location.state?.city_name);
			getData();
		}
	}, [location]);

	const onFilterUsers = (filterText) => {
		let filteredItems;

		if (filterText.length === 0) {
			filteredItems = [...originalData];
		} else {
			const filteringText = filterText.toLowerCase();

			filteredItems = originalData.filter((item) => {
				const postalCode = item.postal_code.toString().toLowerCase();

				return postalCode.includes(filteringText) && true;
			});
		}

		setPostalCodes(filteredItems);
	};

	/**
	 * We add new postalcode to list.
	 * @param postalCode
	 */
	const pushNewPostalCodeToList = (postalCode) => {
		setPostalCodes((prevState) => [
			...prevState.slice(0, postalCodes.length),
			postalCode,
			...prevState.slice(postalCodes.length + 1)
		]);
	};

	const handleOnAddPostalCode = () => {
		const editableItem = {
			data: { postal_code: null },
			title: 'Agregar código postal',
			service: 'postal_code',
			fields: [
				{
					id: 'postal_code',
					label: 'Código Postal',
					type: 'number',
					value: ''
				}
			]
		};

		setEditItem(editableItem);
	};

	const handleOnEditModalClose = () => {
		setEditItem(null);
	};

	const handleOnEditModalSave = (editedRow) => {
		addExtendedPostalCodeByCityService(
			municipalityId,
			editedRow.postal_code
		)
			.then((response) => {
				pushNewPostalCodeToList(response);
				toast('Código postal agregado', { type: 'success' });
			})
			.catch((error) => {
				toast('Error al guardar', { type: 'error' });
				console.error('ExtPCComp -> Error saving postal code: ', error);
				showErrorMessages(error);
			});
		setEditItem(null);
	};

	const handleOnDeletePostalCode = (item) => {
		setEditItem(item);
		setDeleteAlertOpen(true);
	};

	const handleOnDeleteClose = () => {
		setEditItem(null);
		setDeleteAlertOpen(false);
	};

	const handleOnDeleteConfirm = () => {
		const deleteObject = {
			city_id: municipalityId,
			postal_code: editItem.postal_code
		};
		deletePostalCode(deleteObject);
		setEditItem(null);
		setDeleteAlertOpen(false);
	};

	const deletePostalCode = ({ city_id, postal_code }) => {
		setLoadingData(false);
		deleteExtendedPostalCodeByCityService(city_id, postal_code)
			.then(() => {
				setLoadingData(false);
				getData();
				toast('Código postal borrado', { type: 'success' });
			})
			.catch((error) => {
				toast('Error al borrar', { type: 'error' });
				console.error('ExtPCComp -> Error al borrar: ', error);
			});
	};

	return (
		<>
			<ExtendedPostalCodesView
				data={postalCodes}
				loading={loadingData}
				cityName={cityName}
				onFilterUsers={onFilterUsers}
				onAddPostalCode={handleOnAddPostalCode}
				onDeletePostalCode={handleOnDeletePostalCode}
			/>
			{editItem && !deleteAlertOpen && (
				<EditFieldModal
					open={true}
					title={editItem.title}
					service={editItem.service}
					data={editItem.data}
					fields={editItem.fields}
					handleOnClose={handleOnEditModalClose}
					handleOnSave={handleOnEditModalSave}
				/>
			)}
			{editItem && deleteAlertOpen && (
				<ModalComponent
					open={deleteAlertOpen}
					onClose={handleOnDeleteClose}
					onConfirm={handleOnDeleteConfirm}
					title="Borrar código postal "
				>
					{'Estas seguro de borrar ' + editItem.postal_code + '?'}
				</ModalComponent>
			)}
		</>
	);
};

export default ExtendedPostalCodesComponent;
