/** Import react/libraries section **/
import React from 'react';
import PropTypes from 'prop-types';

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUITextField from '../../../components/UI/inputs/MUITextField';
import PageTitle from '../../../components/PageTitle';
import { useForm } from 'react-hook-form';

const UnitAddModalView = ({
  onClose,
  onSave,
  unit,
  loading,
  modalMode,
  onInputChange
}) => {
  const { handleSubmit } = useForm();

  const onSubmit = (values) => {
    onSave();
  };

  return (
    <ModalComponent
      open={true}
      title={
        modalMode === 'edit' ? 'Editar unidad: ' + unit.name : 'Nueva Unidad'
      }
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
    >
      {loading === true ? (
        <PageTitle title="Cargando..." />
      ) : (
        <div className="product-price-modal">
          <div className="product-price-modal-row">
            <MUITextField
              label="Nombre"
              id="name"
              name="name"
              value={unit?.name}
              type="text"
              onChange={onInputChange}
            />

            <MUITextField
              label="Largo"
              name="long"
              value={unit?.long}
              type="text"
              onChange={onInputChange}
            />
          </div>

          <div className="product-price-modal-row">
            <MUITextField
              label="Ancho"
              name="width"
              value={unit?.width}
              type="text"
              onChange={onInputChange}
            />

            <MUITextField
              label="Alto"
              name="height"
              value={unit?.height}
              type="text"
              onChange={onInputChange}
            />
          </div>

          <div className="product-price-modal-row">
            <MUITextField
              label="Volumen"
              name="volume"
              value={unit?.volume}
              type="text"
              onChange={onInputChange}
            />

            <MUITextField
              label="Capacidad de carga"
              name="load"
              value={unit?.load}
              type="text"
              onChange={onInputChange}
            />
          </div>

          <div className="product-price-modal-row">
            <MUITextField
              label="Cama simple"
              name="capacity_pallet_simple_bed"
              value={unit?.capacity_pallet_simple_bed}
              type="text"
              onChange={onInputChange}
            />

            <MUITextField
              label="Cama Doble"
              name="capacity_pallet_double_bed"
              value={unit?.capacity_pallet_double_bed}
              type="text"
              onChange={onInputChange}
            />
          </div>
        </div>
      )}
    </ModalComponent>
  );
};

UnitAddModalView.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  unit: PropTypes.object.isRequired
};

export default UnitAddModalView;
