/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import component section **/
import SearchInputView from './SearchInputView';

/**
 * SearchIcon async component, receive a callback function that request API for given pattern and results
 * are provides on optionsFound
 * @param searchCallback -> callback with api request implemented.
 * @param optionsFound -> Results from API.
 * @param handleOptionSelected -> Handler for manage selected option.
 * @returns {JSX.Element}
 * @constructor
 */
const SearchInputComponent = ({
  searchCallback,
  optionsFound,
  handleOptionSelected,
  optionLabel,
  selectorWidth
}) => {
  const [open, setOpen] = useState(false);
  const [pattern, setPattern] = useState('');
  const loading = open && optionsFound.length === 0;

  useEffect(() => {
    if (!loading) return undefined;
    (async () => {
      searchCallback(pattern);
    })();
  }, [pattern]);

  const handleInputChange = (event) => {
    searchCallback(event.target.value);
    setPattern(event.target.value);
  };

  const onOptionSelected = (option, value) =>
    handleOptionSelected(option, value);

  return (
    <SearchInputView
      open={open}
      loading={loading}
      setOpen={setOpen}
      options={optionsFound}
      handleInputChange={handleInputChange}
      onOptionSelected={onOptionSelected}
      optionLabel={optionLabel}
      selectorWidth={selectorWidth}
    />
  );
};

export default SearchInputComponent;
