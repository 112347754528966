import ApiService from './api-service';

/**
 * Request quotation from API.
 * @returns {Promise<any>}
 */
export const getQuotesService = () => {
  const serviceDef = {
    url: 'quotations',
    method: 'get',
    params: null,
    token: localStorage.getItem('token')
  };
  return ApiService.request(serviceDef);
};
/**
 * Save a new quote
 * @param quotation
 * @returns {Promise<any>}
 */
export const saveQuotationService = (quotation) => {
  const serviceDef = {
    url: 'shipment/create',
    method: 'post',
    params: quotation
  };

  return ApiService.request(serviceDef);
};
/**
 * Update quote data.
 * @param quotation
 * @returns {Promise<*>}
 */
export const updateQuotationService = (quotation) => {
  const serviceDef = {
    url: 'shipment/update',
    method: 'post',
    params: quotation,
    token: localStorage.getItem('token')
  };

  return ApiService.request(serviceDef);
};
/**
 * Delete given quote.
 * @param quoteId
 * @returns {Promise<*>}
 */
export const deleteQuoteService = (quoteId) => {
  const serviceDef = {
    url: 'shipment/delete',
    method: 'post',
    params: { id: quoteId },
    token: localStorage.getItem('token')
  };

  return ApiService.request(serviceDef);
};
/**
 * Request quote by ID
 * @param quoteId -> Quote ID.
 * @returns {Promise<Quote>}
 */
export const getQuoteByIdService = (quoteId) => {
  const serviceDef = {
    url: `shipment/show`,
    method: 'get',
    params: { id: quoteId },
    token: localStorage.getItem('token')
  };
  return ApiService.request(serviceDef);
};
/**
 * Requests cost for given quote.
 * @param quotation
 * @returns {Promise<any>}
 */
export const refreshQuoteCosts = (quotation) => {
  const serviceDef = {
    url: `get-shipment-costs`,
    method: 'post',
    params: quotation
  };
  return ApiService.request(serviceDef);
};
/**
 * Request especial requirements by shipment ID.
 * @param shipmentId
 * @returns {Promise<any>}
 */
export const getEspecialRequirements = (shipmentId) => {
  const serviceDef = {
    url: `special-requirements`,
    method: 'get',
    params: { shipment_type_id: shipmentId }
  };
  return ApiService.request(serviceDef);
};
/**
 * Update quote status.
 * @param quoteId
 * @param status
 * @returns {Promise<any>}
 */
export const updateQuoteStatusService = (quoteId, status) => {
  const serviceDef = {
    url: `shipment/update-status`,
    method: 'post',
    params: {
      id: quoteId,
      shipment_status_id: status
    }
  };
  return ApiService.request(serviceDef);
};
/**
 * Assign given quote to a given user.
 * @param quoteId
 * @param user_id
 * @returns {Promise<any>}
 */
export const assignQuoteStatusService = (quoteId, user_id) => {
  const serviceDef = {
    url: `shipment/assign`,
    method: 'post',
    params: {
      id: quoteId,
      user_id: user_id
    }
  };
  return ApiService.request(serviceDef);
};
/**
 * Apply for credit extension service.
 * @returns {Promise<*>}
 * @param shipmentId
 */
export const applyForCreditExtensionService = (shipmentId) => {
  const serviceDef = {
    url: `shipment/request-credit`,
    method: 'post',
    params: {
      id: shipmentId
    }
  };
  return ApiService.request(serviceDef);
};
/**
 * Request payment types for client without available credit.
 * @returns {Promise<*>}
 */
export const requestPaymentTypes = (shipmentId) => {
  const serviceDef = {
    url: `payment-types`,
    method: 'post',
    params: { shipment_id: shipmentId }
  };
  return ApiService.request(serviceDef);
};
/**
 * Set given shipment as billable.
 * @param shipmentId
 * @param status
 * @returns {Promise<*>}
 */
export const setShipmentAsBillable = (shipmentId, status) => {
  const serviceDef = {
    url: `shipment/approve-billable`,
    method: 'post',
    params: {
      id: shipmentId,
      status: status
    }
  };
  return ApiService.request(serviceDef);
};
/**
 * Change shipment to closed.
 * @param shipmentId
 * @returns {Promise<*>}
 */
export const closeShipmentService = (shipmentId) => {
  const serviceDef = {
    url: `shipment/close`,
    method: 'post',
    params: { id: shipmentId }
  };
  return ApiService.request(serviceDef);
};

/**
 * Set shipment as delivered.
 * @param shipmentId
 * @param deliveryDate
 * @returns {Promise<*>}
 */
export const setShipmentAsDelivered = (shipmentId, deliveryDate) => {
  const serviceDef = {
    url: `shipment/delivered`,
    method: 'post',
    params: {
      id: shipmentId,
      delivery_date: deliveryDate
    }
  };
  return ApiService.request(serviceDef);
};

/**
 * Update shipment discount %.
 * @param shipmentId
 * @param discount
 * @returns {Promise<*>}
 */
export const updateShipmentDiscount = (shipmentId, discount) => {
  const serviceDef = {
    url: `shipment/update-discount`,
    method: 'post',
    params: {
      id: shipmentId,
      discount: discount
    }
  };
  return ApiService.request(serviceDef);
};
