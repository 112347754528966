/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import { Card, CardContent, CardHeader } from '@material-ui/core';

/** Import styles section **/
import './DestinationFormStyles.scss';

/** Import components section **/
import MUITextField from '../../UI/inputs/MUITextField';
import MUISelect from '../../UI/inputs/MUISelect';
import ContactSearch from '../../ContactSearch';
import { DESTINATION } from '../../ContactSearch/contactConstants';

const DestinationFormView = ({
  showSearch,
  destination,
  states,
  municipalities,
  cities,
  compact,
  complete,
  onInputChange,
  onSelectChange,
  onContactSelect
}) => {
  return (
    <Card>
      <CardHeader
        title="Destino"
        action={
          showSearch ? (
            <ContactSearch
              mode={DESTINATION}
              complete={complete}
              onChange={onContactSelect}
            />
          ) : null
        }
      />
      <CardContent>
        <div className={'destinationForm' + (compact ? ' compact' : '')}>
          <MUITextField
            required
            id="destination-recipient"
            name="recipient"
            data-cy="cy-destination-recipient"
            label="Destinatario"
            value={destination?.recipient}
            onChange={onInputChange}
          />

          <MUITextField
            required
            id="destination-contact"
            name="contact"
            data-cy="cy-destination-contact"
            type="text"
            label="Contacto"
            value={destination?.contact}
            onChange={onInputChange}
          />

          <MUITextField
            required
            id="destination-phone"
            name="phone"
            data-cy="cy-destination-phone"
            label="Teléfono"
            value={destination?.phone}
            onChange={onInputChange}
          />

          <MUITextField
            required
            id="destination-email"
            name="email"
            data-cy="cy-destination-email"
            type="email"
            label="Email"
            value={destination?.email}
            onChange={onInputChange}
          />

          <div className="destinationForm_location">
            <MUITextField
              id="destination-country"
              name="country"
              className="country"
              label="País"
              value={'México'}
              onChange={onInputChange}
            />

            <MUITextField
              required
              id="destination-cp"
              name="cp"
              data-cy="cy-destination-cp"
              type="number"
              label="CP"
              value={destination?.cp}
              onChange={onInputChange}
            />
          </div>

          <MUISelect
            id="destination-state"
            name="state_id"
            label="Estado"
            dataCy="cy-destination-state"
            defaultValue={destination?.state_id}
            items={states}
            idSelector="id"
            selector="name"
            noSelectedLabel="Selecciona"
            onChange={onSelectChange}
          />

          <MUISelect
            id="destination-municipality"
            name="municipality_id"
            label="Municipio"
            dataCy="cy-destination-municipality"
            defaultValue={destination?.municipality_id}
            items={municipalities}
            idSelector="id"
            selector="name"
            noSelectedLabel="Selecciona"
            onChange={onSelectChange}
          />

          <MUISelect
            id="destination-city"
            name="city_id"
            label="Ciudad"
            dataCy="cy-destination-city"
            defaultValue={destination?.city_id}
            items={cities}
            idSelector="id"
            selector="name"
            noSelectedLabel="Selecciona"
            onChange={onSelectChange}
          />

          <MUITextField
            required
            id="destination-street"
            name="street"
            data-cy="cy-destination-street"
            label="Dirección"
            value={destination?.street}
            onChange={onInputChange}
          />

          <MUITextField
            required
            id="destination-suburb"
            name="suburb"
            data-cy="cy-destination-suburb"
            type="text"
            label="Colonia"
            value={destination?.suburb}
            onChange={onInputChange}
          />

          <MUITextField
            required
            id="destination-number"
            name="internal_number"
            data-cy="cy-destination-number"
            type="text"
            label="Número interior"
            value={destination?.internal_number}
            onChange={onInputChange}
          />
          <MUITextField
            required
            id="destination-external_number"
            name="external_number"
            data-cy="cy-destination-external_number"
            type="text"
            label="Número exterior"
            value={destination?.external_number}
            onChange={onInputChange}
          />

          <MUITextField
            required
            id="destination-references"
            name="references"
            data-cy="cy-destination-references"
            label="Referencias (entre que calles)"
            value={destination?.references}
            onChange={onInputChange}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default DestinationFormView;
