import React from 'react';
import './DashboardScoreStyles.scss';

/** Import component section **/
import {Pie} from '@reactchartjs/react-chart.js';

/** Import helpers section **/
import {COLOR_COMPLEMENTARY, COLOR_COMPLEMENTARY_3, COLOR_PRIMARY, COLOR_SECONDARY} from '../../../styles/colors';

const DashboardScoreView = ({label = '', data = []}) => {
	const options = {
		legend: {
			display: false,
		},
	};
	
	const colors = [
		COLOR_PRIMARY,
		COLOR_SECONDARY,
		COLOR_COMPLEMENTARY,
		COLOR_COMPLEMENTARY_3,
	];
	
	const graphData = {
		labels: [],
		datasets: [
			{
				label: label,
				data: [],
				backgroundColor: colors,
				
				borderWidth: 2,
			},
		],
	};
	
	for (var i = 0; i < data.length; i++) {
		graphData.labels.push( data[i].label );
		graphData.datasets[0].data.push( data[i].value );
	}
	
	return (
		<>
			{data.length > 0 && (
				<div className="dashboard-score">
					<div className="dashboard-score_label">{label}</div>
					<div className="dashboard-score_table">
						{data.map( (item, index) => (
							<div key={index} className="dashboard-score_table_item">
								<div className="dashboard-score_table_item_field">
									{item.label}
								</div>
								<div className="dashboard-score_table_item_color"
								     style={{backgroundColor: colors[index]}}
								/>
								<div className="dashboard-score_table_item_value">
									{item.value}
								</div>
							</div>
						) )}
					</div>
					<div className="dashboard-score_graph">
						<Pie data={graphData} options={options}/>
					</div>
				</div>
			)}
		</>
	);
};

export default DashboardScoreView;
