import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { PropTypes } from 'prop-types';

const tabsStyles = makeStyles({
  root: {
    minHeight: 'auto',
    height: '40px',
    marginLeft: '20px',
    marginRight: '20px'
  },
  indicator: {
    height: 3,
    maxWidth: 80,
    textAlign: 'center'
  }
});

const tabStyles = makeStyles({
  root: {
    minWidth: '0',
    padding: 0,
    marginLeft: '15px',
    marginRight: '15px',
    textTransform: 'none',
    fontSize: '15px',
    '&:focus': {
      fontWeight: 'bold'
    },
    '&.Mui-selected': {
      fontWeight: 'bold'
    }
  },
  wrapper: {
    alignItems: 'flex-start'
  }
});

export const TabsNavigation = ({ tabs = [], active = 0, onChange }) => {
  const [value, setValue] = useState(active);
  const tabsClasses = tabsStyles();
  const tabClasses = tabStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      classes={tabsClasses}
    >
      {tabs.map((tab, index) => (
        <Tab key={index} data-cy={tab} classes={tabClasses} label={tab} />
      ))}
    </Tabs>
  );
};

TabsNavigation.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  active: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default TabsNavigation;
