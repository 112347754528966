import ApiService from './api-service';

export const getCoverageCitiesService = (state_id) => {
  const serviceDef = {
    url: 'reachable-cities',
    method: 'get',
    params: { state_id: state_id }
  };
  return ApiService.request(serviceDef);
};

export const addCoverageCityService = ({
  state_id,
  city_id,
  reachable,
  ms_coverage
}) => {
  const serviceDef = {
    url: 'reachable-cities/add',
    method: 'post',
    params: { state_id, city_id, reachable, ms_coverage }
  };
  return ApiService.request(serviceDef);
};

export const deleteCoverageCityService = ({ city_id }) => {
  const serviceDef = {
    url: 'reachable-cities/delete',
    method: 'post',
    params: { city_id }
  };
  return ApiService.request(serviceDef);
};

export const changeCoverageModeService = (cityId, msCoverage) => {
  const serviceDef = {
    url: 'reachable-cities/update',
    method: 'post',
    params: {
      city_id: cityId,
      ms_coverage: msCoverage
    }
  };
  return ApiService.request(serviceDef);
};

export const uploadCityPostalCodesFileService = ({ city_id, file }) => {
  const dataForm = new FormData();
  dataForm.append('city_id', city_id);
  dataForm.append('file', file);
  const serviceDef = {
    url: 'postal-codes/import-postal-codes-cities',
    method: 'file',
    params: dataForm
  };

  return ApiService.request(serviceDef);
};

export const uploadExtendedPostalCodesFileService = ({
  municipality_id,
  file
}) => {
  const dataForm = new FormData();
  dataForm.append('municipality_id', municipality_id);
  dataForm.append('file', file);
  const serviceDef = {
    url: 'postal-codes/import-postal-codes-municipalities',
    method: 'file',
    params: dataForm
  };

  return ApiService.request(serviceDef);
};

export const getPostalCodesService = (cityId) => {
  const serviceDef = {
    url: 'postal-codes',
    method: 'post',
    params: { city_id: cityId }
  };
  return ApiService.request(serviceDef);
};

/**
 * Add city postal code.
 * @param cityId
 * @param postalCode
 * @param neighborhood
 * @returns {Promise<*>}
 */
export const addPostalCodeService = (cityId, postalCode, neighborhood) => {
  const serviceDef = {
    url: 'postal-codes/add',
    method: 'post',
    params: {
      city_id: cityId,
      postal_code: postalCode,
      suburb: neighborhood
    }
  };
  return ApiService.request(serviceDef);
};

export const updatePostalCodeByCityService = (cpData) => {
  let data = {
    city_id: cpData.city_id,
    postal_code: cpData.postal_code,
    description: '',
    alert: '',
    suburb: cpData.neighborhood
  };
  const serviceDef = {
    url: 'postal-codes/update',
    method: 'post',
    params: data
  };
  return ApiService.request(serviceDef);
};

export const deletePostalCodeService = ({ city_id, postal_code }) => {
  const serviceDef = {
    url: 'postal-codes/delete',
    method: 'post',
    params: { city_id, postal_code }
  };
  return ApiService.request(serviceDef);
};

/**
 * Request postal codes by city id.
 * @param municipalityId
 * @returns {Promise<*>}
 */
export const getExtendedPostalCodesByCityService = (municipalityId) => {
  const serviceDef = {
    url: 'postal-codes/by-city',
    method: 'get',
    params: { city_id: municipalityId }
  };
  return ApiService.request(serviceDef);
};

/**
 * Add a postal code to an extended route by city.
 * @param cityId
 * @param postal_code
 * @param neighborhood
 * @returns {Promise<*>}
 */
export const addExtendedPostalCodeByCityService = (
  cityId,
  postal_code,
  neighborhood
) => {
  const serviceDef = {
    url: 'postal-codes/add',
    method: 'post',
    params: {
      city_id: cityId,
      postal_code: postal_code,
      suburb: neighborhood
    }
  };
  return ApiService.request(serviceDef);
};

/**
 * Delete a postal code to an extended route by city.
 * @param cityId
 * @param postalCode
 * @returns {Promise<*>}
 */
export const deleteExtendedPostalCodeByCityService = (cityId, postalCode) => {
  const serviceDef = {
    url: 'postal-codes/delete',
    method: 'post',
    params: { municipality_id: cityId, postal_code: postalCode }
  };
  return ApiService.request(serviceDef);
};

/***********************************************************************************/
/*       BY MUNICIPALITY SECTION                                                   */
/***********************************************************************************/

/**
 * Request postal codes by municipality id.
 * @param municipalityId
 * @returns {Promise<*>}
 */
export const getExtendedPostalCodesByMunicipalityService = (municipalityId) => {
  const serviceDef = {
    url: 'postal-codes/by-municipality',
    method: 'get',
    params: { municipality_id: municipalityId }
  };
  return ApiService.request(serviceDef);
};

export const addExtendedPostalCodeByMunicipalityService = (
  municipalityId,
  postal_code,
  neighborhood
) => {
  const serviceDef = {
    url: 'postal-codes/by-municipality/add',
    method: 'post',
    params: {
      municipality_id: municipalityId,
      postal_code: postal_code,
      suburb: neighborhood
    }
  };
  return ApiService.request(serviceDef);
};

export const updatePostalCodeByMunicipalityService = (cpData) => {
  let data = {
    municipality_id: cpData.city_id,
    postal_code: cpData.postal_code,
    description: '',
    alert: '',
    suburb: cpData.neighborhood
  };
  const serviceDef = {
    url: 'postal-codes/by-municipality/update',
    method: 'post',
    params: data
  };
  return ApiService.request(serviceDef);
};

export const deleteExtendedPostalCodeByMunicipalityService = (
  municipalityId,
  postalCode
) => {
  const serviceDef = {
    url: 'postal-codes/by-municipality/delete',
    method: 'post',
    params: {
      municipality_id: municipalityId,
      postal_code: postalCode
    }
  };
  return ApiService.request(serviceDef);
};

export const getCoverageMunicipalitiesService = (state_id) => {
  const serviceDef = {
    url: 'reachable-municipalities',
    method: 'get',
    params: { state_id: state_id }
  };
  return ApiService.request(serviceDef);
};

export const deleteCoverageMunicipalityService = ({ municipality_id }) => {
  const serviceDef = {
    url: 'reachable-municipalities/delete',
    method: 'post',
    params: { municipality_id }
  };
  return ApiService.request(serviceDef);
};

export const changeCoverageMunicipalityService = ({ id }) => {
  const serviceDef = {
    url: 'postal-codes/municipality/active-coverage',
    method: 'post',
    params: { id }
  };
  return ApiService.request(serviceDef);
};

export const addCoverageMunicipalityService = ({
  state_id,
  municipality_id,
  reachable,
  ms_coverage
}) => {
  const serviceDef = {
    url: 'reachable-municipalities/add',
    method: 'post',
    params: { state_id, municipality_id, reachable, ms_coverage }
  };
  return ApiService.request(serviceDef);
};
