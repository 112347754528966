/** Import react/libraries section **/
import React from 'react';
import { PropTypes } from 'prop-types';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import MUIDataTable from '../../components/MUIDataTable';
import FilterBy from '../../components/UI/FilterBy';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import MUIDateField from '../../components/UI/inputs/MUIDateField';
// import MUISelect from '../../components/UI/inputs/MUISelect';
import { Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

/** Import helpers section **/

const ReceivableReportsView = ({
	receivables,
	loading,
	onClear,
	onFilter,
	// dateRange,
	showClear
	// selectedStatus,
	// handleDateChange,
	// handleOnStatusChange
}) => {
	// const authorizationStatus = [
	// 	{ id: 2, title: 'Aceptado' },
	// 	{ id: 3, title: 'Rechazado' },
	// 	{ id: 4, title: 'Cancelado' },
	// 	{ id: 5, title: 'Entregado' }
	// ];

	const tableColumns = [
		{
			name: 'Nombre del cliente',
			selector: 'business_name',
			sortable: true,
			center: true,
			wrap: true
		},
		{
			name: 'Asesor',
			selector: 'assigned',
			sortable: true,
			center: true,
			wrap: true
		},
		{
			name: 'Pendiente',
			selector: 'pending_total',
			sortable: true,
			center: true,
			wrap: true
		},
		{
			name: 'Vencido',
			selector: 'total_expired',
			sortable: true,
			center: true,
			wrap: true
		},
		{
			name: 'Días vencimiento',
			selector: 'days_expired',
			sortable: true,
			center: true,
			wrap: true
		},
		{
			name: 'Total',
			selector: 'total',
			sortable: true,
			center: true
		}
	];
	// console.log(receivables);
	return (
		<div className="reports">
			<PageTitle title="Reporte de cuentas por cobrar / cuentas por pagar" />
			<Paper className="g-page-header reports_header">
				<div className="reports-header-filters">
					<div className="reports_header_filters_switch">
						<FormControlLabel
							label=""
							control={<FilterBy onChange={onFilter} />}
						/>

						{/* <p>Del</p>

						<FormControlLabel
							control={
								<MUIDateField
									onChange={handleDateChange}
									name="start"
									value={dateRange?.start}
								/>
							}
							label=" Al "
						/>

						<FormControlLabel
							control={
								<MUIDateField
									onChange={handleDateChange}
									name="end"
									value={dateRange?.end}
								/>
							}
							label=""
						/>

						<MUISelect
							label="Estatus"
							selector="title"
							idSelector="id"
							name="client-status"
							noSelectedLabel="Todos"
							defaultValue={selectedStatus}
							items={authorizationStatus}
							onChange={(event) =>
								handleOnStatusChange(event.value)
							}
						/> */}

						{showClear === true ? (
							<Tooltip title="Limpiar filtros">
								<ClearIcon onClick={(e) => onClear(e)} />
							</Tooltip>
						) : null}
					</div>
				</div>
			</Paper>

			<MUIDataTable
				loading={loading}
				columns={tableColumns}
				data={receivables}
				showDownload={true}
			/>
		</div>
	);
};

ReceivableReportsView.propTypes = {
	loading: PropTypes.bool.isRequired,
	receivables: PropTypes.array.isRequired
};

export default ReceivableReportsView;
