/** Import react/libraries section **/
import React, { Fragment, useMemo } from 'react';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/UI/FilterBy';
import MUIDataTable from '../../components/MUIDataTable';
import AddButton from '../../components/UI/AddButton';

/** Import helpers section **/
/** Import resources section **/
import './UsersStyles.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Paper from '@material-ui/core/Paper';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

const UsersView = ({
  users,
  loading,
  onDeleteUser,
  onEditUser,
  onAddUser,
  onFilterUsers
}) => {
  const ActiveCell = ({ row }) => {
    if (row.active)
      return <CheckCircleOutlineIcon className="activeicon active" />;
    return <NotInterestedIcon className="activeicon" />;
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          data-cy="edit-button"
          className="g-row-action-button"
          onClick={(e) => onEditUser(row)}
        />
        <DeleteIcon
          data-cy="delete-button"
          className="g-row-action-button delete"
          onClick={(e) => onDeleteUser(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Identificado',
        selector: 'id',
        sortable: true,
        center: true
      },
      {
        name: 'Nombre',
        selector: 'name',
        sortable: true,
        center: true
      },
      {
        name: 'Apellido',
        selector: 'last_name',
        sortable: true,
        center: true
      },
      {
        name: 'Correo',
        selector: 'email',
        sortable: true,
        center: true,
        wrap: true
      },
      {
        name: 'Sucursal',
        selector: 'branch_office',
        sortable: true,
        center: true
      },
      {
        name: 'Rol',
        selector: 'role_title',
        sortable: true,
        center: true
      },
      {
        name: 'Activo',
        selector: 'active',
        cell: (row) => <ActiveCell row={row} />,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <Fragment>
      <PageTitle title="Usuarios" />
      <Paper className="g-page-header">
        <div className="providers-header-filterby">
          <FilterBy onChange={onFilterUsers} />
        </div>
        <AddButton onClick={onAddUser} />
      </Paper>

      <MUIDataTable data={users} columns={tableColumns} loading={loading} />
    </Fragment>
  );
};

export default UsersView;
