/** Import react/libraries section **/
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/UI/FilterBy';
import AddButton from '../../components/UI/AddButton';
import MUIDataTable from '../../components/MUIDataTable';

/** Import helpers section **/
import {formatNumberToCurrency} from '../../utils/formats';
import {CITIES_ROUTE} from '../../URL-Routes';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EditIcon from '@material-ui/icons/Edit';


const ExtendedRoutesView = ({routes, loading, handleFilter, handleAddRoute, onEditRoute, onDelete, onPCClick}) => {
	
	let location = useLocation();
	
	const ActionsCell = ({row}) => {
		return (
			<Fragment>
				<EditIcon
					data-cy="edit-button"
					className="g-row-action-button"
					onClick={() => onEditRoute( row )}
				/>
				<DeleteIcon
					data-cy="delete-button"
					className="g-row-action-button delete"
					onClick={() => onDelete( row )}
				/>
			
			</Fragment>
		);
	};
	
	const CityCell = ({name}) => {
		return (
			<div className="routes-city-wrapper">
				<div className="routes-city">{name}</div>
			</div>
		);
	};
	
	const CPCell = ({row}) => {
		return <LocationOnIcon
			className='g-row-action-button'
			onClick={(e) => onPCClick( e, row )}
		/>;
	};
	
	const tableColumns = [
		{
			name: 'Ciudad',
			cell: (row) => <CityCell name={row?.city_name}/>,
			sortable: true,
			ignoreRowClick: true,
			allowOverflow: true,
			left: true,
		},
		{
			name: 'Municipío',
			cell: (row) => <CityCell name={row?.municipality_name}/>,
			ignoreRowClick: true,
			allowOverflow: true,
			left: true,
		},
		{
			name: 'cp',
			cell: (row) => <CPCell row={row}/>,
			ignoreRowClick: true,
			allowOverflow: true,
			left: true,
		},
		{
			name: 'KM',
			cell: (row) => <CityCell name={row?.km}/>,
			ignoreRowClick: true,
			allowOverflow: true,
			left: true,
		},
		{
			name: 'Pallet 1-4',
			cell: (row) => <CityCell name={formatNumberToCurrency( row?.price_1 )}/>,
			ignoreRowClick: true,
			allowOverflow: true,
			left: true,
		},
		{
			name: 'Pallet 5-8',
			cell: (row) => <CityCell name={formatNumberToCurrency( row?.price_2 )}/>,
			ignoreRowClick: true,
			allowOverflow: true,
			left: true,
		},
		{
			name: 'Pallet 9-12',
			cell: (row) => <CityCell name={formatNumberToCurrency( row?.price_3 )}/>,
			ignoreRowClick: true,
			allowOverflow: true,
			left: true,
		},
		{
			name: '',
			cell: (row) => <ActionsCell row={row}/>,
			ignoreRowClick: true,
			allowOverflow: true,
			center: true,
		},
	];
	
	return (
		<Fragment>
			<PageTitle title={`Rutas extendidas -  ${location.state?.city_name}`} backUrl={CITIES_ROUTE}/>
			<Paper className="g-page-header providers-header">
				<div className="providers-header-filterby">
					<FilterBy onChange={handleFilter}/>
				</div>
				<AddButton onClick={handleAddRoute}/>
			</Paper>
			
			<MUIDataTable data={routes} columns={tableColumns} loading={loading}/>
		</Fragment>
	);
};

ExtendedRoutesView.propTypes = {
	handleAddRoute: PropTypes.func.isRequired,
	handleFilter: PropTypes.func.isRequired,
};

export default ExtendedRoutesView;
