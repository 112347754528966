import ApiService from './api-service';

/**
 * Save a new quote request.
 * @param quotation -> Quotation object
 * @returns {Promise<any>}
 */
export const saveQuotationRequestService = (quotation) =>  {
	const serviceDef = {
		url: 'application/create',
		method: 'post',
		params: quotation,
	};
	
	return ApiService.request(serviceDef);
};

/**
 * Get all request from API.
 * @returns {Promise<unknown>}
 */
export const getRequestService = () =>  {
	const serviceDef = {
		url: 'application',
		method: 'get',
		params: null,
	};
	
	return ApiService.request(serviceDef);
};

export const getRequestByIdService = (requestId) =>  {
	const serviceDef = {
		url: 'application/show',
		method: 'get',
		params: { id: requestId },
	};
	
	return ApiService.request(serviceDef);
};

/**
 * Save request assignation.
 * @returns {Promise<any>}
 */
export const saveAssignRequest = (requestId, userId) =>  {
	const serviceDef = {
		url: 'shipment/assign',
		method: 'post',
		params: {
			id: requestId,
			user_id: userId
		},
	};
	
	return ApiService.request(serviceDef);
};

/**
 * Update request.
 * @param request
 * @returns {Promise<unknown>}
 */
export const updateRequestService = (request) =>  {
	const serviceDef = {
		url: 'application/update',
		method: 'post',
		params: request,
	};
	
	return ApiService.request(serviceDef);
};

/**
 * Delete request by given ID
 * @param requestId
 * @returns {Promise<any>}
 */
export const deleteAssignRequest = (requestId) =>  {
	const serviceDef = {
		url: 'application/delete',
		method: 'post',
		params: {
			id: requestId
		},
	};
	
	return ApiService.request(serviceDef);
};