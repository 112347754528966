/** Import react/libraries section **/
import React from 'react';
import PropTypes from 'prop-types';

/** Import component section **/
import EditForm from '../../../components/UI/EditForm';
import MUISelect from '../../../components/UI/inputs/MUISelect';
import MUITextField from '../../../components/UI/inputs/MUITextField';

/** Import helpers section **/

/** Import resources section **/

const ExtendedRouteAddModalView = ({
  onClose,
  modalMode,
  onSave,
  municipalities,
  route,
  onInputChange
}) => {
  console.log(route);
  return (
    <EditForm
      open={true}
      title={modalMode === 'add' ? 'Alta de ruta' : 'Editar ruta:'}
      onClose={onClose}
      onSave={onSave}
    >
      <div className="route-modal">
        <div className="route-modal-row">
          <span className="route-modal-label">Municipio</span>

          <MUISelect
            label="Municipio"
            dataCy="cy-municipality"
            value={route?.municipality_id}
            items={municipalities}
            idSelector="id"
            selector="name"
            noSelectedLabel="Seleccione"
            name="municipality_id"
            onChange={onInputChange}
          />

          <MUITextField
            label="Kilometros"
            data-cy="cy-km"
            value={route?.km}
            type="number"
            name="km"
            onChange={onInputChange}
          />
        </div>

        <div className="route-modal-row">
          <span className="route-modal-label">Pallet 1 - 4 </span>
          <MUITextField
            label="Precio"
            data-cy="cy-km"
            value={route?.price_1}
            type="number"
            name="price_1"
            onChange={onInputChange}
          />
        </div>

        <div className="route-modal-row">
          <span className="route-modal-label">Pallet 5 - 8 </span>
          <MUITextField
            label="Precio"
            data-cy="cy-km"
            value={route?.price_2}
            type="number"
            name="price_2"
            onChange={onInputChange}
          />
        </div>

        <div className="route-modal-row">
          <span className="route-modal-label">Pallet 9 - 12 </span>
          <MUITextField
            label="Precio"
            data-cy="cy-km"
            value={route?.price_3}
            type="number"
            name="price_3"
            onChange={onInputChange}
          />
        </div>
      </div>
    </EditForm>
  );
};

ExtendedRouteAddModalView.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default ExtendedRouteAddModalView;
