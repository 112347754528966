/* eslint-disable react-hooks/exhaustive-deps */

/** Import react/libraries section **/
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

/** Import component section **/
import UnitAddModalView from './UnitAddModalView';
import {addUnitService, getUnitById, updateUnitService} from '../../../services/units-services';
import {toast} from 'react-toastify';
import {showErrorMessages} from '../../../utils/utils';

/** Import helpers section **/

/** Import resources section **/

const unitModel = {
	name: '',
	long: null,
	width: null,
	height: null,
	volume: null,
	load: null,
	capacity_pallet_simple_bed: null,
	capacity_pallet_double_bed: '',
};

const UnitAddModalComponent = ({
	                               onClose,
	                               unitId,
	                               modalMode,
	                               pushNewUnitToList,
	                               updateUnitOnList,
                               }) => {
	const [unit, setUnit] = useState( {} );
	const [loading, setLoading] = useState( false );
	
	useEffect( () => {
		if (modalMode === 'edit') {
			setLoading( true );
			setLoading( true );
			getUnitById( unitId ).then( (response) => {
				setUnit( response );
				setLoading( false );
			} ).catch( (error) => {
				console.error( 'ClientAddCom - Error getting client: ', error );
			} );
		} else {
			setUnit( unitModel );
		}
	}, [] );
	
	const onInputChange = (data) => {
		const {value, name} = data;
		setUnit( (prevState) => ({
			...prevState,
			[name]: value,
		}) );
	};
	
	const onSave = () => {
		if (modalMode === 'add') {
			addUnitService( unit ).then( (response) => {
				toast( 'Unidad agregada', {type: 'success'} );
				pushNewUnitToList( response );
				onClose();
			} ).catch( (error) => {
				toast( 'Error al egregar unidad', {type: 'error'} );
				// console.error( 'InitAddModalComp - Add unit error: ', error );
				showErrorMessages( error );
			} );
		} else {
			updateUnitService( unit ).then( (response) => {
				toast( 'Unidad actualizada', {type: 'success'} );
				updateUnitOnList( response );
				onClose();
			} ).catch( (error) => {
				// console.error( 'InitAddModalComp - Update unit error: ', error );
				toast( 'Error al actualizar', {type: 'error'} );
				showErrorMessages( error );
			} );
		}
	};
	
	return (
		<UnitAddModalView
			unit={unit}
			onSave={onSave}
			onClose={onClose}
			loading={loading}
			modalMode={modalMode}
			onInputChange={onInputChange}
		/>
	);
};

UnitAddModalComponent.propTypes = {
	onClose: PropTypes.func.isRequired,
	modalMode: PropTypes.string.isRequired,
	unitId: PropTypes.number,
	pushNewUnitToList: PropTypes.func.isRequired,
	updateUnitOnList: PropTypes.func.isRequired,
};

export default UnitAddModalComponent;
