import ApiService from './api-service';

export const getGoodsService = (shipmentId) => {
  const serviceDef = {
    url: 'goods/list',
    method: 'get',
    params: { shipment_id: shipmentId }
  };
  return ApiService.request(serviceDef);
};

export const addGoodsService = ({
  quantity,
  code,
  description,
  shipment_id,
  container_id,
  unit_code,
  weigth,
  value,
  currency,
  dangerous_material,
  dangerous_material_code,
  packing_code
}) => {
  const serviceDef = {
    url: 'goods/create',
    method: 'post',
    params: {
      quantity,
      code,
      description,
      shipment_id,
      container_id,
      unit_code,
      weigth,
      value,
      currency,
      dangerous_material,
      dangerous_material_code,
      packing_code
    }
  };
  return ApiService.request(serviceDef);
};

export const updateGoodService = ({
  id,
  quantity,
  code,
  description,
  shipment_id,
  container_id,
  unit_code,
  weigth,
  value,
  currency,
  dangerous_material,
  dangerous_material_code,
  packing_code
}) => {
  const serviceDef = {
    url: 'goods/update',
    method: 'post',
    params: {
      id,
      quantity,
      code,
      description,
      shipment_id,
      container_id,
      unit_code,
      weigth,
      value,
      currency,
      dangerous_material,
      dangerous_material_code,
      packing_code
    }
  };
  return ApiService.request(serviceDef);
};

export const deleteGoodService = (shipmentContainerId) => {
  const serviceDef = {
    url: 'goods/delete',
    method: 'post',
    params: { id: shipmentContainerId }
  };
  return ApiService.request(serviceDef);
};
