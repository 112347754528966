/** Import react/libraries section **/
import cuid from 'cuid';
import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';

/** Import helpers section **/
/** Import resources section **/
import './DeliveryStyles.scss';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import CardHeader from '@material-ui/core/CardHeader';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Card, CardContent, Fab, Radio, Tooltip } from '@material-ui/core';
import { STATUS_DELIVERED } from '../../../utils/enumerates';

/** Import component section **/
import MUIDateField from '../../../components/UI/inputs/MUIDateField';
import MUITextField from '../../../components/UI/inputs/MUITextField';

const DeliveryView = ({
	onDrop,
	accuse,
	incidents,
	addedFiles,
	amPreguide,
	onAMPreguideChange,
	realDeliveryDate,
	onDateChange,
	onEvidencesSave,
	onInputChange,
	shipmentStatusId,
	onIncidentChange
}) => {
	const recollectionDate = useSelector(
		(store) => store.quote.quotation.recollection_date
	);

	// -> Initialize hook for dropzone
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: true
	});

	const composeIncidents = () => {
		if (shipmentStatusId === STATUS_DELIVERED && incidents.length > 0) {
			return (
				<>
					{incidents.map((incident, index) => (
						<div className="delivery_left_incidences-content_item">
							<div className="delivery_left_incidences-content_item_label">
								{incident.title}
							</div>
							<RadioGroup
								row
								aria-label="incidentes"
								name="delivery"
								value={incident.option}
								onChange={(event) => {
									onIncidentChange(event, index);
								}}
							>
								<FormControlLabel
									value={0}
									control={<Radio />}
									label="No"
								/>
								<FormControlLabel
									value={1}
									control={<Radio />}
									label="Si"
								/>
							</RadioGroup>
						</div>
					))}
				</>
			);
		} else {
			return null;
		}
	};

	const composeEvidences = () => {
		let file = accuse?.accuses[0];
		if (!file) return <></>;
		let fileUrl = file.address + file.file_address;
		if (file)
			return (
				<div
					key={cuid()}
					className="delivery_right_accuses_files-list-item"
				>
					<img alt={file.file_name} src={fileUrl} />
					<div>{file.file_name}</div>
					<div>
						{moment(file.created_at).format(
							'DD / MMMM / YYYY, h:mm:ss a'
						)}
					</div>
					<a
						href={fileUrl}
						target="_blank"
						rel="noreferrer"
						className="delivery_right_accuses_files-list-item-fab"
					>
						<Tooltip title="Descargar">
							<Fab size="small" color="primary">
								<GetAppIcon />
							</Fab>
						</Tooltip>
					</a>
				</div>
			);
	};

	const composeThumbnail = () => {
		return addedFiles.map((file) => (
			<div className="delivery_right_accuses_thumbnail">
				<img src={file.src} alt={file.name} />
				<div className="delivery_right_accuses_thumbnail-save">
					<Button
						onClick={onEvidencesSave}
						disabled={addedFiles.length === 0}
					>
						Guardar acuse
					</Button>
				</div>
			</div>
		));
	};

	return (
		<div className="delivery">
			<div className="delivery_left">
				{shipmentStatusId === STATUS_DELIVERED && incidents.length > 0 && (
					<Card className="delivery_left_incidences">
						<CardHeader title="Incidencias" />
						<CardContent className="delivery_left_incidences-content">
							{composeIncidents()}
						</CardContent>
					</Card>
				)}

				<Card className="delivery_left_date">
					<CardHeader title="Entrega" />
					<CardContent>
						<div className="delivery_left_date_content">
							<MUIDateField
								id="realDeliveryDate"
								name="real_delivery_date"
								label="Fecha de entrega real"
								value={realDeliveryDate}
								onChange={onDateChange}
								minDate={recollectionDate}
								disabled={recollectionDate === null}
							/>
							<MUITextField
								id="am_preguide"
								name="am_preguide"
								label="AM / Preguía"
								type="text"
								onChange={onAMPreguideChange}
								value={amPreguide}
							/>
						</div>
					</CardContent>
				</Card>

				<Card className="delivery_left_info">
					<CardHeader title="Información de acuse" />
					<CardContent>
						<div className="delivery_left_info_content">
							<MUITextField
								id="folio"
								name="folio"
								label="Folio de acuse"
								type="text"
								onChange={onInputChange}
								value={accuse.folio}
							/>
							<MUITextField
								id="CD"
								name="cd"
								label="CD"
								type="text"
								onChange={onInputChange}
								value={accuse.cd}
							/>

							<MUIDateField
								id="date_accuse"
								name="accuse_load_date"
								label="Fecha de carga de acuse"
								onChange={onDateChange}
								value={accuse.accuse_load_date}
								minDate={realDeliveryDate}
								disabled={realDeliveryDate === null}
							/>
						</div>
					</CardContent>
				</Card>
			</div>

			<div className="delivery_right">
				<Card className="delivery_right_accuses">
					<CardContent>
						<div
							{...getRootProps({
								className: 'delivery_right_accuses_dropzone'
							})}
						>
							<input {...getInputProps()} />
							Arrastre y suelte sus archivos o haga click para
							selecionar archivos
						</div>
						<div className="delivery_right_accuses_files">
							<div className="delivery_right_accuses_files-thumbnails">
								{composeThumbnail()}
							</div>
							<div className="delivery_right_accuses_files-list">
								{composeEvidences()}
							</div>
						</div>
					</CardContent>
				</Card>
			</div>
		</div>
	);
};

export default DeliveryView;
