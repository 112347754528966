import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { InputLabel, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    marginLeft: '5px',
    marginRight: '5px',
    margin: '0px',
    minWidth: '120px',
    flexGrow: '1',
    flex: '1'
  },
  label: {
    marginBottom: '5px'
  }
}));

const styles = () => ({
  inputLabel: {
    fontSize: '10px',
    height: '15px'
  },
  select: {
    margin: 0,
    verticalAlign: 'center',
    backgroundColor: '#fff',
    height: '32px',
    placeholderTextColor: '#ced4da',
    padding: '10px',
    fontSize: '12px'
  },
  menuItem: { fontSize: '12px' }
});

export const MUISelect = ({
  id,
  label = '',
  name,
  labelId,
  value,
  defaultValue,
  items,
  idSelector,
  selector,
  noSelectedLabel = 'Seleccionar',
  onChange,
  dataCy,
  disabled = false,
  readOnly,
  ...otherProps
}) => {
  // let readOnly = useSelector((store) => store.quote?.quotation?.is_shipment);
  //pasarle al mui select readOnly en shipment
  const classes = useStyles();
  const customClasses = styles();
  const inputLabelId = labelId !== null ? labelId : label + '__InputLabel';

  const [selectValue, setSelectValue] = useState('');
  const [selectItems, setSelectItems] = useState([]);

  useEffect(() => {
    let propValue =
      value !== null && value !== undefined
        ? value
        : defaultValue !== null && defaultValue !== undefined
        ? defaultValue
        : null;

    if (propValue !== null) {
      let valueFound = items.find((i) => i[idSelector] === propValue);
      if (valueFound) {
        setSelectItems(items);
        setSelectValue(propValue);
        return;
      }
    }
    setSelectItems(items);
    setSelectValue('');
  }, [defaultValue, value, items, idSelector]);

  useEffect(() => {}, [selectItems]);

  const handleOnSelect = (e) => {
    const newValue = e.target.value;
    const returnedValue = newValue === '' || newValue === -1 ? null : newValue;
    setSelectValue(newValue);
    onChange({ id: id, name: name, value: returnedValue });
  };

  return (
    <div id={'MUI_' + id} className={classes.root}>
      <InputLabel id={inputLabelId} style={customClasses.inputLabel}>
        {label}
      </InputLabel>
      <Select
        labelId={inputLabelId}
        data-cy={dataCy === undefined ? 'select' : dataCy}
        id={id}
        value={selectValue}
        onChange={handleOnSelect}
        style={customClasses.select}
        disabled={disabled}
        fullWidth
        inputProps={{ readOnly: readOnly }}
        {...otherProps}
      >
        <MenuItem value={-1} style={customClasses.menuItem}>
          <em>{noSelectedLabel}</em>
        </MenuItem>

        {selectItems?.map((item, index) => (
          <MenuItem
            data-cy="menu-item"
            key={index}
            value={item[idSelector]}
            style={customClasses.menuItem}
          >
            {item[selector]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default MUISelect;
