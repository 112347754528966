import ApiService from './api-service';

/**
 * Get unit by id from API.
 * @returns {Promise<*>}
 */
export const getUnitById = (unitId) => {
	const serviceDef = {
		url: 'unit/show',
		method: 'post',
		params: { id: unitId }
	};
	return ApiService.request(serviceDef);
};

/**
 * Get units catalog..
 * @returns {Promise<*>}
 */
export const getUnitsService = () => {
	const serviceDef = {
		url: 'unit',
		method: 'get',
		params: null,
	};
	return ApiService.request( serviceDef );
};

/**
 * Add a new unit.
 * @param unit
 * @returns {Promise<*>}
 */
export const addUnitService = (unit) => {
	const serviceDef = {
		url: 'unit/create',
		method: 'post',
		params: unit
	};
	return ApiService.request(serviceDef);
};


export const updateUnitService = (unit) => {
	const serviceDef = {
		url: 'unit/update',
		method: 'post',
		params: unit
	};
	return ApiService.request(serviceDef);
};

/**
 * Delete unit by given ID
 * @param unitId
 * @returns {Promise<*>}
 */
export const deleteUnitService = (unitId) => {
	const serviceDef = {
		url: 'unit/delete',
		method: 'post',
		params: { id: unitId }
	};
	return ApiService.request(serviceDef);
};
