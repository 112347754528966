import React from 'react';
import './DedicatedPriceModalStyles.scss';

import ModalComponent from '../../../components/UI/ModalComponent';
import MUISelect from '../../../components/UI/inputs/MUISelect';
import MUITextField from '../../../components/UI/inputs/MUITextField';
import RoutesList from '../../../components/UI/RoutesList';

const DedicatedPriceModalView = ({
  item,
  units,
  onSave,
  onClose,
  modalMode,
  onRouteSelect,
  onInputChange,
  selectedRoute
}) => {
  return (
    <ModalComponent
      open={true}
      title="Precio de viaje dedicado"
      onClose={onClose}
      onConfirm={onSave}
    >
      <div className="dedicated-price-modal">
        <div className="dedicated-price-modal-row">
          {selectedRoute === null ? (
            <RoutesList
              onSelect={onRouteSelect}
              defaultRouteId={selectedRoute}
            />
          ) : null}

          <MUISelect
            label="Unidad"
            idSelector="id"
            selector="name"
            name="unit_id"
            onChange={onInputChange}
            items={units}
            value={item?.unit_id}
            disabled={modalMode === 'edit'}
          />
        </div>
        <div className="dedicated-price-modal-row">
          <MUITextField
            id="cost"
            label="Costo"
            value={item?.cost}
            type="number"
            name="cost"
            onChange={onInputChange}
          />
          <MUITextField
            id="min_price"
            label="Precio mínimo"
            value={item?.min_price}
            type="number"
            name="min_price"
            onChange={onInputChange}
          />
          <MUITextField
            id="public_price"
            label="Precio al público"
            value={item?.public_price}
            type="number"
            name="public_price"
            onChange={onInputChange}
          />
        </div>
      </div>
    </ModalComponent>
  );
};

export default DedicatedPriceModalView;
