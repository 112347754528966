/** Import react/libraries section **/
import React from 'react';

/** Import helpers section  **/
import {
  EDIT_CONTACT_PERMISSION,
  DELETE_CONTACT_PERMISSION
} from '../../access/PERMISSIONS';

/** Import styles section **/
import './ContactsStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessControl from '../../access/AccessControl';

/** Import component section **/
import PageTitle from './../../components/PageTitle';
import AddButton from '../../components/UI/AddButton';
import MUIDataTable from '../../components/MUIDataTable';
import MUIAutocomplete from '../../components/UI/inputs/MUIAutocomplete';

const ContactsView = ({
  loading,
  contacts,
  clients,
  selectedClient,
  onClientChange,
  onAdd,
  onEdit,
  onDelete
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <div className="contacts_actions">
        <AccessControl
          allowedPermissions={[EDIT_CONTACT_PERMISSION]}
          renderNoAccess={() => null}
        >
          <EditIcon
            className="g-row-action-button"
            onClick={() => onEdit(row)}
          />
        </AccessControl>

        <AccessControl
          allowedPermissions={[DELETE_CONTACT_PERMISSION]}
          renderNoAccess={() => null}
        >
          <DeleteIcon
            clients-cy="cy-delete-button"
            className="g-row-action-button delete"
            onClick={() => onDelete(row)}
          />
        </AccessControl>
      </div>
    );
  };

  const tableColumns = [
    {
      name: 'Título',
      selector: 'name',
      sortable: true,
      center: true
    },
    {
      name: 'Contact',
      selector: 'contact',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      center: true
    },
    {
      name: 'CP',
      selector: 'cp',
      sortable: true,
      center: true
    },
    {
      name: 'RFC',
      selector: 'rfc',
      sortable: true,
      center: true
    },
    {
      name: 'email',
      selector: 'email',
      sortable: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      right: true
    }
  ];

  return (
    <div className="contacts">
      <PageTitle title="Contactos" />
      <Paper className="contacts_header g-page-header">
        <div className="contacts_header_filters">
          <div className="contacts_header_filters_client">
            <MUIAutocomplete
              id="client"
              name="client"
              options={clients}
              label="Cliente"
              selector="name"
              value={selectedClient}
              onChange={onClientChange}
            />
          </div>
        </div>
        <AddButton onClick={onAdd} disabled={!selectedClient} />
      </Paper>

      <MUIDataTable data={contacts} columns={tableColumns} loading={loading} />
    </div>
  );
};

export default ContactsView;
