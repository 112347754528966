import ApiService from './api-service';

/**
 * Request all not payed bills.
 * @returns {Promise<any>}
 */
export const requestPaymentsService = () => {
  const serviceDef = {
    url: 'payment',
    method: 'get',
    params: null
  };
  return ApiService.request(serviceDef);
};

/**
 * Save and register payment for given bill.
 * @returns {Promise<any>}
 */
export const createPaymentsService = (payment) => {
  const serviceDef = {
    url: 'payment/create',
    method: 'post',
    params: payment
  };
  return ApiService.request(serviceDef);
};

/**
 * Revert payment service
 * @returns {Promise<*>}
 * @param paymentId
 */
export const revertPaymentService = (paymentId) => {
  const serviceDef = {
    url: 'payment/delete',
    method: 'post',
    params: { id: paymentId }
  };
  return ApiService.request(serviceDef);
};
