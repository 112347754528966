import ApiService from './api-service';

export const getContactsService = ({ client_id, search }) => {
  const serviceDef = {
    url: 'client-branch',
    method: 'post',
    params: { client_id, search }
  };
  return ApiService.request(serviceDef);
};

export const createContactService = ({
  title,
  contact,
  name,
  cp,
  rfc,
  email,
  phone,
  suburb,
  street,
  number,
  internal_number,
  reference_streets,
  city_id,
  municipality_id,
  state_id,
  client_id,
  neighborhood_id
}) => {
  const serviceDef = {
    url: 'client-branch/create',
    method: 'post',
    params: {
      title,
      contact,
      name,
      cp,
      rfc,
      email,
      phone,
      suburb,
      street,
      number,
      internal_number,
      reference_streets,
      city_id,
      municipality_id,
      state_id,
      client_id,
      neighborhood_id
    }
  };
  return ApiService.request(serviceDef);
};

export const getContactByIdService = (clientId) => {
  const serviceDef = {
    url: 'client-branch/read',
    method: 'post',
    params: { id: clientId }
  };
  return ApiService.request(serviceDef);
};

export const updateContactService = ({
  id,
  title,
  contact,
  name,
  cp,
  rfc,
  email,
  phone,
  suburb,
  street,
  number,
  internal_number,
  reference_streets,
  city_id,
  municipality_id,
  state_id,
  client_id,
  neighborhood_id
}) => {
  const serviceDef = {
    url: 'client-branch/update',
    method: 'post',
    params: {
      id,
      title,
      contact,
      name,
      cp,
      rfc,
      email,
      phone,
      suburb,
      street,
      number,
      internal_number,
      reference_streets,
      city_id,
      municipality_id,
      state_id,
      client_id,
      neighborhood_id
    }
  };
  return ApiService.request(serviceDef);
};

export const deleteContactService = (id) => {
  const serviceDef = {
    url: 'client-branch/delete',
    method: 'post',
    params: { id }
  };
  console.log(id);
  return ApiService.request(serviceDef);
};
