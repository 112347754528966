import ApiService from './api-service';

/**
 * Request shipments with accepted status and assigned to logged user.
 * @returns {Promise<unknown>}
 */
export const getShipmentsService = ({ billed }) => {
  const serviceDef = {
    url: 'shipment',
    method: 'get',
    params: { billed }
  };
  return ApiService.request(serviceDef);
};

/**
 * Generate operative request document.
 * @returns {Promise<*>}
 */
export const generateOperativeRequestsService = (quoteId) => {
  const serviceDef = {
    url: 'shipment-file/create-operation-request-pdf',
    method: 'post',
    params: {
      id: quoteId
    }
  };
  return ApiService.request(serviceDef);
};
export const setShipmentsAsBillables = (shipments) => {
  const serviceDef = {
    url: 'shipment/approve-multiple-billable',
    method: 'post',
    params: shipments
  };
  return ApiService.request(serviceDef);
};
