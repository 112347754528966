/** Import react/libraries section **/
import React from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

/** Import component section **/

/** Import helpers section **/

/** Import resources section **/
import './LoginStyles.scss';
import logo from '../../assets/images/logoms.jpg';
import truck_unit from '../../assets/images/truck_unit.jpeg';

const LoginView = ({ onSubmit, inProcess }) => {
  const { register, errors, handleSubmit } = useForm();

  const handleOnSubmit = (values) => onSubmit(values);

  return (
    <div className="login">
      <div className="login_container">
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className="login_container_form"
        >
          <img
            className="login_container_form_logo"
            src={logo}
            alt="logo_envios_ms"
            aria-labelledby="logo_envios_ms"
          />

          <div className="login_container_form_label">
            Usuario
            <ErrorMessage
              className="login_container_form_error"
              name="username"
              errors={errors}
              as="div"
            />
          </div>
          <input
            id="username"
            className={`form-control ${
              errors.username ? 'is-invalid' : 'login_container_form_input'
            }`}
            name="username"
            autoComplete="email"
            autoFocus
            ref={register({
              required: 'El correo es requerido',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Formato de correo no valido'
              }
            })}
          />

          <div className="login_container_form_label">
            Contraseña
            <ErrorMessage
              className="login_container_form_error"
              name="password"
              errors={errors}
              as="div"
            />
          </div>
          <input
            id="password"
            className="login_container_form_input"
            type="password"
            name="password"
            autoComplete="password"
            ref={register({
              required: 'Contraseña requerida'
            })}
          />

          <div className="login_container_form_button">
            <button onClick={handleSubmit}>
              {inProcess === true ? 'Entrando...' : 'Login'}
            </button>
          </div>
        </form>

        <div className="login_container_photo">
          <img
            className="login_container_photo_img"
            src={truck_unit}
            alt="Transport unit"
            aria-labelledby="Transport unit"
          />
        </div>
      </div>
    </div>
  );
};

export default LoginView;
