import ApiService from './api-service';

/**
 * Request products (container types).
 * @param shipment_type_id
 * @returns {Promise<unknown>}
 */
export const getContainerTypesService = (shipment_type_id) => {
	const serviceDef = {
		url: 'container',
		method: 'get',
		params: {
			shipment_type_id: shipment_type_id,
			// container_type_category_id: container_type_id
		},
	};
	return ApiService.request( serviceDef );
};

export const getContainerService = (containerTypeId) => {
	const serviceDef = {
		url: 'container',
		method: 'get',
		params: {container_category_id: containerTypeId},
	};
	return ApiService.request( serviceDef );
};

export const getShipmentRoutesService = () => {
	// Rutas de envio ej. QRO - BAJIO - QRO
	const serviceDef = {
		url: 'shipment-route',
		method: 'get',
		params: null,
	};
	return ApiService.request( serviceDef );
};

export const getRoutePricesService = ({routeId}) => {
	// Catalogo de precios por contenedor
	const serviceDef = {
		url: 'route-price',
		method: 'get',
		params: {shipment_route_id: routeId},
	};
	return ApiService.request( serviceDef );
};

export const getDedicatedTripsService = () => {
	const serviceDef = {
		url: 'dedicated-trip',
		method: 'get',
		params: null,
	};
	return ApiService.request( serviceDef );
};

/**
 * Request extra services catalog by ID
 * @returns {Promise<Array>}
 */
export const getExtraServices = () => {
	const serviceDef = {
		url: 'additional-services',
		method: 'get',
		params: null,
	};
	return ApiService.request( serviceDef );
};

/**
 * Request shipment type catalog by ID
 * @returns {Promise<Array>}
 */
export const getShipmentTypeService = () => {
	const serviceDef = {
		url: 'shipment-type',
		method: 'get',
		params: null,
	};
	return ApiService.request( serviceDef );
};

/**
 * Get additional services for given shipment type id.
 * @returns {Promise<*>}
 */
export const getAdditionalServicesByShipmentId = (shipmentId) => {
	const serviceDef = {
		url: `additional-services`,
		method: 'get',
		params: {shipment_type_id: shipmentId},
		token: localStorage.getItem( 'token' ),
	};
	return ApiService.request( serviceDef );
};

/**
 * Get container types for given shipment type id.
 * @returns {Promise<*>}
 */
export const getContainerTypeByShipmentId = (shipmentId) => {
	const serviceDef = {
		url: `container-category`,
		method: 'post',
		params: {shipment_type_id: shipmentId},
		token: localStorage.getItem( 'token' ),
	};
	return ApiService.request( serviceDef );
};

/**
 * Get all categories types.
 * @returns {Promise<*>}
 */
export const getAllContainerCategories = () => {
	const serviceDef = {
		url: `container-category`,
		method: 'get',
		params: null,
	};
	return ApiService.request( serviceDef );
};
/**
 * Get all packages by container category id
 * @returns {Promise<*>}
 */
export const getPackagesCategories = (packageId) => {
	const serviceDef = {
		url: `container`,
		method: 'post',
		params: {
			container_category_id: packageId,
		},
	};
	return ApiService.request( serviceDef );
};

/**
 * Get catalog of roles.
 * @returns {Promise<*>}
 */
export const getRolesCatalog = () => {
	const serviceDef = {
		url: 'role',
		method: 'get',
		params: null,
	};
	return ApiService.request( serviceDef );
};

/**
 * Create a new container category.
 * @returns {Promise<*>}
 * @param category
 */
export const createContainerCategory = (category) => {
	const serviceDef = {
		url: 'container-category/create',
		method: 'post',
		params: category,
	};
	return ApiService.request( serviceDef );
};

export const updateContainerCategory = (category) => {
	const serviceDef = {
		url: 'container-category/update',
		method: 'post',
		params: category,
	};
	return ApiService.request( serviceDef );
};

/**
 * Delete a categories.
 * @returns {Promise<*>}
 */
export const deleteContainerCategoriesService = (categoryId) => {
	const serviceDef = {
		url: `container-category/delete`,
		method: 'post',
		params: {id: categoryId},
	};
	return ApiService.request( serviceDef );
};

/**
 * Get all container categories.
 * @returns {Promise<*>}
 */
export const getContainerCategories = () => {
	const serviceDef = {
		url: 'container-category',
		method: 'get',
		params: null,
	};
	return ApiService.request( serviceDef );
};

/**
 * Create a new container.
 * @param container
 * @returns {Promise<*>}
 */
export const createContainerService = (container) => {
	const serviceDef = {
		url: 'container/create',
		method: 'post',
		params: container,
	};
	return ApiService.request( serviceDef );
};

/**
 * Update a new container.
 * @param container
 * @returns {Promise<*>}
 */
export const updateContainerService = (container) => {
	const serviceDef = {
		url: 'container/update',
		method: 'post',
		params: {
			active: container?.active,
			id: container?.id,
			max_weight: container?.max_weight,
			min_weight: container?.min_weight,
		},
	};
	return ApiService.request( serviceDef );
};

/**
 * delete a container.
 * @returns {Promise<*>}
 * @param containerId
 */
export const deleteContainerService = (containerId) => {
	const serviceDef = {
		url: 'container/delete',
		method: 'post',
		params: {id: containerId},
	};
	return ApiService.request( serviceDef );
};
