/** Import react/libraries section **/
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

/** Import helpers section **/
import { ORIGIN, DESTINATION } from '../../ContactSearch/contactConstants';
import {
  updateOriginField,
  updateOriginLocation,
  updateDestinationField,
  updateDestinationLocation
} from '../../../redux/quotation/quoteSlice';

/** Import services section **/

/** Import component section **/
import ContactFormView from './ContactFormView';

export const ContactFormComponent = ({ mode = ORIGIN, noSearch = false }) => {
  let dispatch = useDispatch();
  let origin = useSelector((store) => store.quote.quotation.origin);
  let destination = useSelector((store) => store.quote.quotation.destination);
  // let quoteId = useSelector((store) => store.quote.quotation.id);

  let contact = mode === ORIGIN ? origin : destination;

  const [complete, setComplete] = useState(false);

  const handleOnInputChange = (data) => {
    let { name, value } = data;

    if (mode === ORIGIN) {
      dispatch(updateOriginField({ field: name, value: value }));
    } else {
      dispatch(updateDestinationField({ field: name, value: value }));
    }
  };

  const handleOnContactSelect = (contactData) => {
    // setContact(contactData);
  };

  const handleOnLocationSelect = useCallback(
    (locationData, contact) => {
      const location = {
        cp: locationData?.postal_code,
        state_id: locationData?.state_id,
        municipality_id: locationData?.municipality_id,
        city_id: locationData?.city_id,
        neighborhood_id: locationData?.neighborhood_id,
        name: locationData?.name,
        internal_number: locationData?.internal_number,
        external_number: locationData?.number,
        references: locationData?.reference_streets,
        suburb: locationData?.suburb,
        street: locationData?.street
      };
      if (mode === ORIGIN) {
        dispatch(updateOriginLocation({ location }));
      } else {
        dispatch(updateDestinationLocation({ location }));
      }
    },
    [dispatch, mode]
  );

  useEffect(() => {
    const name = contact?.name;
    const contactForm = contact?.contact;
    const phone = contact?.phone;
    const cp = contact?.cp;
    const rfc = contact?.rfc;
    const state_id = contact?.state_id;
    const municipality_id = contact?.municipality_id;
    const city_id = contact?.city_id;

    let completeValidated = false;
    if (
      name &&
      contactForm &&
      phone &&
      cp &&
      rfc &&
      state_id &&
      (municipality_id || city_id)
    ) {
      completeValidated = true;
    }
    setComplete(completeValidated);
  }, [contact]);

  return (
    <ContactFormView
      showSearch={!noSearch}
      mode={mode}
      contact={contact}
      complete={complete}
      onContactSelect={handleOnContactSelect}
      onInputChange={handleOnInputChange}
      onLocationSelect={handleOnLocationSelect}
    />
  );
};

ContactFormComponent.propTypes = {
  mode: PropTypes.oneOf([ORIGIN, DESTINATION]),
  noSearch: PropTypes.bool
};

export default ContactFormComponent;
