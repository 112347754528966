/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PropTypes } from 'prop-types';

/** Import component section **/
import ShipDetailsView from './ShipDetailsView';

/** Import helpers section **/
import {
  getContainerTypeByShipmentId,
  getPackagesCategories
} from '../../../services/catalogues-services';
import {
  appendPackage,
  setDeliveryTypePackages,
  updateDeliveryType
} from '../../../redux/quotation/quoteSlice';
import { getUnitsService } from '../../../services/units-services';
import {
  CONTAINER_TYPE_MESSAGE,
  CONTAINER_TYPE_PARCEL
} from '../../../utils/enumerates';
import { packageModel } from './detailforms/formModels';

/** Import resources section **/

const ShipDetailsComponent = ({ modeType }) => {
  let dispatch = useDispatch();
  let deliveryTypeData = useSelector(
    (store) => store.quote.quotation.delivery_type
  );

  const [containers, setContainers] = useState([]);
  const [products, setProducts] = useState([]);
  const [volWeight, setVolWeight] = useState(0);

  useEffect(() => {
    let shipmentId =
      deliveryTypeData.shipment_type_id === null
        ? 1
        : deliveryTypeData.shipment_type_id;

    if (shipmentId === 4) {
      getUnitsService()
        .then((response) => {
          setContainers(response);
        })
        .catch((error) => {
          console.error(
            'QuoteServicesComponent - Error fetching units : ',
            error
          );
        });
    } else {
      getContainerTypeByShipmentId(shipmentId)
        .then((response) => {
          setContainers(response);
        })
        .catch((error) => {
          console.error(
            'QuoteServicesComponent - Error fetching container : ',
            error
          );
        });
    }

    // if (deliveryTypeData.shipment_type_id === 2 || deliveryTypeData.shipment_type_id === 3) {
    // 	setProducts( [] );
    //
    // 	deliveryTypeData.containers.forEach( (container, index) => {
    // 		console.debug( 'ShipDetailsCompo: ', index );
    // 		getContainerTypes( index, container.product_id );
    // 	} );
    // }
  }, [deliveryTypeData.shipment_type_id]);

  const calculateVolumetricWeight = (index) => {
    // Calculate volumetric weight for parcel container type
    // paquetería es:  (Largo cm x ancho cm x alto cm)/(6000)
    if (deliveryTypeData.shipment_type_id === 2) {
      let volWeight = (
        (deliveryTypeData.containers[index].long *
          deliveryTypeData.containers[index].width *
          deliveryTypeData.containers[index].height) /
        6000
      ).toFixed(2);
      setVolWeight(volWeight);
      // Update field on package
      dispatch(
        updateDeliveryType({
          index: index,
          field: 'volumetric_weight',
          value: volWeight
        })
      );
    }
    // Calculate volumetric weight for consolidated load type.
    // carga consolidada es: (Largo x ancho x alto)x (400)
    if (deliveryTypeData.shipment_type_id === 3) {
      if (deliveryTypeData.containers[index].stackable === 1) {
        let volWeight = parseFloat(
          (
            deliveryTypeData.containers[index].long *
            deliveryTypeData.containers[index].width *
            2.0 *
            400
          ).toFixed(2)
        );
        setVolWeight(volWeight);
        dispatch(
          updateDeliveryType({
            index: index,
            field: 'volumetric_weight',
            value: volWeight
          })
        );
      } else {
        let volWeight =
          deliveryTypeData.containers[index].long *
          deliveryTypeData.containers[index].width *
          deliveryTypeData.containers[index].height *
          400;
        setVolWeight(volWeight);
        dispatch(
          updateDeliveryType({
            index: index,
            field: 'volumetric_weight',
            value: volWeight
          })
        );
      }
    }
    if (
      deliveryTypeData.shipment_type_id !== 1 &&
      deliveryTypeData.shipment_type_id !== 4
    ) {
      let selectedProduct = products.filter(
        (x) =>
          x.id === deliveryTypeData.containers[index].container_type_category_id
      );
      if (volWeight > selectedProduct[0]?.max_weight)
        toast(
          'El peso volumetrico excede el máximo del producto seleccionado!  ',
          { type: 'warning' }
        );
    }
  };

  const handleOnServiceDetailsChange = (data, index) => {
    let { name, value } = data;

    // if (name === 'weight') {
    // 	if (value > deliveryTypeData.products[index].weight)
    // 		debugger;
    // 		toast( 'El peso excede el máximo del producto seleccionado!  ', {type: 'warning'} );
    // }

    if (
      deliveryTypeData.shipment_type_id === CONTAINER_TYPE_MESSAGE &&
      name === 'weight' &&
      value > 1
    ) {
      if (value <= 0)
        dispatch(updateDeliveryType({ index: index, field: name, value: 0 }));
      toast('Alerta: ¡el peso no puede ser 0!', {
        type: 'warning',
        autoClose: true
      });

      if (value > 1) {
        dispatch(updateDeliveryType({ index: index, field: name, value: 1 }));
        toast('Alerta: ¡el peso no puede ser mayor a 1Kg!', {
          type: 'warning',
          autoClose: true
        });
      }
    } else if (
      deliveryTypeData.shipment_type_id === CONTAINER_TYPE_PARCEL &&
      name === 'weight' &&
      value !== null
    ) {
      // let containerCategoryId = deliveryTypeData.containers[index].container_category_id;
      // let product = products.filter( x => x[0].id === containerCategoryId );
      // debugger
      // if (value > product.max_weight)
      // 	toast( 'El peso excede el máximo del producto seleccionado!  ', {type: 'warning'} );

      if (value < 1) {
        dispatch(updateDeliveryType({ index: index, field: name, value: 1 }));
        toast('Alerta: ¡el peso no puede ser menor a 1Kg!', {
          type: 'warning'
        });
      } else if (value > 70) {
        dispatch(updateDeliveryType({ index: index, field: name, value: 70 }));
        toast('Alerta: ¡el peso no puede ser mayor a 70Kg!', {
          type: 'warning'
        });
      } else {
        dispatch(
          updateDeliveryType({ index: index, field: name, value: value })
        );
      }
    } else {
      dispatch(updateDeliveryType({ index: index, field: name, value: value }));
    }
    calculateVolumetricWeight(index);
  };

  const handleOnContainerChange = (index, data) => {
    let { value } = data.target;
    dispatch(
      updateDeliveryType({
        index: index,
        field: 'container_type_id',
        value: parseFloat(value)
      })
    );
  };

  const onContainerSelectChange = (data, index) => {
    let { name, value } = data;
    // setProducts( [] );
    dispatch(updateDeliveryType({ index: index, field: name, value: value }));

    if (name === 'product_id') {
      getPackagesCategories(value)
        .then((response) => {
          let copy = [...products];
          copy[index] = response;
          setProducts(copy);
        })
        .catch((error) => {
          console.error(
            'ShipDetailComp -> Error getting containers type: ',
            error
          );
        });
    } else {
      dispatch(
        updateDeliveryType({ index: index, field: 'package_id', value: value })
      );
    }
  };

  const onAddNewPackage = () => {
    let packageObj = packageModel(deliveryTypeData);

    dispatch(
      appendPackage({
        index: deliveryTypeData?.containers.length,
        packageObj: packageObj
      })
    );
  };

  const onDeletePackage = (event, index) => {
    event.preventDefault();
    let newList = [...deliveryTypeData.containers];
    if (newList.length > 1) {
      newList.splice(index, 1);
      dispatch(setDeliveryTypePackages({ packages: newList }));
    }
  };

  const onClearForm = (index) => {
    let packageObj = packageModel(deliveryTypeData);
    dispatch(appendPackage({ index: index, packageObj: packageObj }));
  };

  return (
    <ShipDetailsView
      modeType={modeType}
      products={products}
      volWeight={volWeight}
      containers={containers}
      onClearForm={onClearForm}
      onAddNewPackage={onAddNewPackage}
      onDeletePackage={onDeletePackage}
      deliveryTypeData={deliveryTypeData}
      onContainerChange={handleOnContainerChange}
      onContainerSelectChange={onContainerSelectChange}
      onServiceDetailsChange={handleOnServiceDetailsChange}
    />
  );
};

ShipDetailsView.propTypes = {
  modeType: PropTypes.string.isRequired
};

export default ShipDetailsComponent;
