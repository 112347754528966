/** Import react/libraries section **/
import React, { Fragment, useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

/** Import component section **/
import MainMenu from './../MainMenu';
import MainHeader from './../MainHeader';
import ContentSwitch from './../ContentSwitch';
import LoginComponent from '../../views/Login';

/** Import helpers section **/
import * as ROUTES from '../../URL-Routes';

/** Import resources section **/
import './MainContainer.scss';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import RequestComponent from '../../views/Request';

/**
 * Help us to detect if user already logged in, in that case we stay, if not redirect to Login page.
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
function PrivateRoute({ children, ...rest }) {
  let token = localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const MainContainer = () => {
  const [open, setOpen] = useState(false);

  const handleOnMenuToggle = () => {
    setOpen(!open);
  };

  return (
    <Router>
      <div className="main-container">
        <Switch>
          <Route exact path={ROUTES.LOGIN_ROUTE} component={LoginComponent} />
          <Route path={ROUTES.LOGIN_ROUTE} component={RequestComponent} />

          <PrivateRoute>
            <Fragment>
              <AppBar
                position="fixed"
                className={'appbar' + (open ? '' : ' appbar-drawer-close')}
              >
                <Toolbar className="app-toolbar">
                  <MainHeader handleOnMenuToggle={handleOnMenuToggle} />
                </Toolbar>
              </AppBar>

              <Drawer
                className={'drawer' + (open ? '' : ' drawer-close')}
                variant="permanent"
                anchor="left"
                open={open}
                classes={{
                  paper: 'drawer-paper' + (open ? '' : ' drawer-paper-close')
                }}
              >
                <MainMenu open={open} handleOnMenuToggle={handleOnMenuToggle} />
              </Drawer>

              <div
                className={
                  'content-container' + (open ? '' : ' content-drawer-close')
                }
              >
                <ContentSwitch />
              </div>
            </Fragment>
          </PrivateRoute>
        </Switch>
      </div>
    </Router>
  );
};

export default MainContainer;
