/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import component section **/
import ClientModalView from './ClientModalView';

/** Import helpers section **/
import {
  createClientService,
  getClientByIdService,
  updateClientService
} from '../../../services/clients-services';
import { toast } from 'react-toastify';

import { getTaxRegimeSelectService } from '../../../services/tax-regime-services';
import { billingBusinessListSelectService } from '../../../services/billing-business-services';
import { getCFDIService } from '../../../services/paymen-cfdi-services';
import { getPaymentTypesService } from '../../../services/paymen-cfdi-services';
import { satPaymentMethodListSelectServices } from '../../../services/sat-payment-method-services';

/** Import resources section **/
export const clientModel = {
  id: null,
  name: '',
  rfc: '',
  contact_name: '',
  email: '',
  phone: '',
  state: '',
  city: '',
  cp: '',
  business_name: '',
  address: '',
  fisic_moral: 1,
  client_type: 0,
  client_number: '',
  credit_amount: '',
  credit_days: null,
  credit: 0,
  city_id: null,
  state_id: null,
  tax_regime_id: null,
  billing_business_id: null,
  active: 1,
  reference: '',
  external_number: 0,
  internal_number: 0,
  reference_streets: '',
  street: '',
  sat_payment_method_id: null
};

const ClientModalComponent = ({ item, onClose, onConfirm }) => {
  const [client, setClient] = useState(item);
  const [loading, setLoading] = useState(false);
  const [taxRegime, setTaxRegime] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [billingBusiness, setBillingBusiness] = useState([]);
  const [cfdi, setCdfi] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);

  const getClientById = (id) => {
    setLoading(true);
    getClientByIdService(id)
      .then((response) => {
        setClient(response);
      })
      .catch((error) => {
        console.error('ClientAddCom - Error getting client: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPaymentType = useCallback(() => {
    getPaymentTypesService().then((response) => {
      const paymentTypes = response;
      if (paymentTypes) {
        setPaymentTypes(paymentTypes);
      }
    });
  }, []);

  const getUseCfdiCatalog = useCallback(() => {
    getCFDIService().then((response) => {
      const cfdiCatalog = response;
      if (cfdiCatalog) {
        setCdfi(cfdiCatalog);
      }
    });
  }, []);

  const getBillingBusiness = useCallback(() => {
    billingBusinessListSelectService().then((response) => {
      const billingBusinessArr = response?.billing_business;
      if (billingBusinessArr) {
        setBillingBusiness(billingBusinessArr);
      }
    });
  }, []);

  const getTaxRegime = useCallback(() => {
    const token = '';
    setLoading(true);
    getTaxRegimeSelectService({ token })
      .then((response) => {
        setTaxRegime(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getSatPaymentMethod = useCallback(() => {
    satPaymentMethodListSelectServices().then((response) => {
      const paymenthMethodsArr = response?.sat_payment_method;
      if (paymenthMethodsArr) {
        setPaymentMethods(paymenthMethodsArr);
      }
    });
  }, []);

  useEffect(() => {
    getTaxRegime();
    getPaymentType();
    getUseCfdiCatalog();
    getBillingBusiness();
    getSatPaymentMethod();
    if (item?.id) {
      getClientById(item.id);
    } else {
      setClient(clientModel);
    }
  }, [
    item.id,
    getUseCfdiCatalog,
    getBillingBusiness,
    getTaxRegime,
    getPaymentType,
    getSatPaymentMethod
  ]);

  useEffect(() => {
    console.log('en este momento cambia ');
  }, [client?.credit_days]);

  const onInputChange = (data) => {
    let { name, value } = data;
    if (name === 'credit_amount') value = parseFloat(value);
    setClient((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSwitchChange = (event) => {
    let { name, checked } = event.target;
    setClient((prevState) => ({
      ...prevState,
      [name]: checked === true ? 1 : 0
    }));
  };

  const onRadioChange = (event) => {
    let { name, value } = event.target;
    setClient((prevState) => ({
      ...prevState,
      [name]: parseInt(value)
    }));
  };

  const onSave = () => {
    if (client?.id) {
      updateClientService(client)
        .then((response) => {
          toast('Cliente actualizado correctamente', { type: 'success' });
          onConfirm();
        })
        .catch((error) => {
          toast('Error al actualizar cliente', { type: 'error' });
          console.error('ClientAddModal - Update error: ', error);
        });
    } else {
      createClientService(client)
        .then((response) => {
          toast('Cliente agregado correctamente', { type: 'success' });
          onConfirm();
        })
        .catch((error) => {
          toast('Error al agregar cliente', { type: 'error' });
          console.error('ClientAddModal - OnSave error: ', error);
        });
    }
  };

  const handleOnLocationLoaded = useCallback((location) => {
    setClient((prevState) => ({
      ...prevState,
      cp: location?.postal_code,
      state_id: location?.state_id,
      state_name: location?.state_name,
      municipality_id: location?.municipality_id,
      municipality_name: location?.municipality_name,
      city_id: location?.city_id,
      city_name: location?.city_name,
      neighborhood_id: location?.neighborhood_id,
      neighborhood_name: location?.neighborhood_name,
      address: location?.address,
      reference_streets: location?.reference_streets,
      street: location?.street,
      external_number: location?.number,
      internal_number: location?.internal_number
    }));
  }, []);

  return (
    <ClientModalView
      taxRegime={taxRegime}
      client={client}
      loading={loading}
      onClose={onClose}
      onSave={onSave}
      onRadioChange={onRadioChange}
      onInputChange={onInputChange}
      onSwitchChange={onSwitchChange}
      onLocationLoaded={handleOnLocationLoaded}
      billingBusiness={billingBusiness}
      cfdi={cfdi}
      paymentTypes={paymentTypes}
      paymentMethods={paymentMethods}
    />
  );
};

export default ClientModalComponent;
