import ApiService from './api-service';

export const satPaymentMethodListSelectServices = () => {
  const serviceDef = {
    url: 'sat-payment-method/list/select',
    method: 'get',
    params: null
  };
  return ApiService.request(serviceDef);
};
