/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/
import { formatNumberToCurrency } from '../../utils/formats';
import './BillingSupportStyles.scss';

/** Import resources section **/
import {
  Chip,
  InputAdornment,
  Paper,
  TextField,
  Tooltip
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/UI/FilterBy';
import AddButton from '../../components/UI/AddButton';
import MUIDateField from '../../components/UI/inputs/MUIDateField';
import MUIDataTable from '../../components/MUIDataTable';
import MUICheckBox from '../../components/UI/inputs/MUICheckBox';
import MUISelect from '../../components/UI/inputs/MUISelect';

const BillingSupportView = ({
  loading,
  shipments,
  selectedClientId,
  dateRange,
  providers,

  showClear,
  onClear,

  totalRange,
  toggledClearRows,
  selectedAccuseType,
  selectedProvider,
  clients,
  selectedClient,
  onAccuseTypeChange,

  onCreateManifest,
  onFilterBy,
  onDateChange,
  onSelectedChange,
  onManifestNumberClick,
  onClientChange,
  onChangeTotal,
  onProviderChange
}) => {
  const ManifestNumber = ({ row }) => {
    if (row.manifest_number) {
      return (
        <Tooltip title="Manifiesto">
          <Chip
            className="chips_manifest"
            label={row.manifest_number}
            avatar={<CheckCircleIcon />}
            onClick={() => onManifestNumberClick(row)}
          ></Chip>
        </Tooltip>
      );
    } else {
      return null;
    }
  };
  const accuseType = [
    { id: 1, name: 'Acuse digital' },
    { id: 2, name: 'Acuse original con costo' },
    { id: 3, name: 'Acuse original sin costo' }
  ];
  const tableColumns = [
    {
      name: 'Número de guía MS',
      selector: 'tracking_guide',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Estado destino',
      selector: 'state',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Guías de proveedores',
      selector: (row) =>
        row.selected_provider_tracking_guide && row.cd
          ? row.selected_provider_tracking_guide + ' / ' + row.cd
          : row.selected_provider_tracking_guide ?? row.cd,
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Nombre del proveedor',
      selector: 'provider_name',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'No. Manifiesto',
      selector: 'manifest_number',
      cell: (row) => <ManifestNumber row={row} />,
      sortable: true,
      center: true,
      wrap: true
    },

    {
      name: 'Cliente',
      selector: 'client_business_name',
      sortable: true,
      center: true
    },
    {
      name: 'Monto',
      selector: 'total',
      format: (row) => formatNumberToCurrency(row.total),
      sortable: true,
      right: true
    },
    {
      name: 'Fecha de emisión',
      selector: 'bill_date',
      sortable: true,
      center: true
    },
    {
      name: 'Número de factura',
      selector: 'bill_number',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Fecha de envio de factura',
      selector: 'bill_send_date',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Tiempo desde envio acuse',
      selector: 'days_since_accuse_sended',
      sortable: true,
      center: true
    },
    {
      name: 'Tipo de acuse',
      selector: 'accuse_type',
      sortable: true,
      center: true
    }
  ];

  const isNotSelectable = (row) => {
    return (
      row?.manifest === true ||
      row?.bill_id ||
      (selectedClientId !== null && row?.client_id !== selectedClientId)
    );
  };

  return (
    <div className="billingsupport">
      <PageTitle title="Soporte de facturación" />
      <Paper className="g-page-header manifests-header">
        <div className="billingsupport-header-filters">
          <div className="authorizations_header_filters_switch">
            <FormControlLabel
              label=""
              control={<FilterBy onChange={onFilterBy} />}
            />

            <p>Del</p>

            <FormControlLabel
              control={
                <MUIDateField
                  onChange={onDateChange}
                  name="start"
                  value={dateRange?.start}
                />
              }
              label=" Al "
            />

            <FormControlLabel
              control={
                <MUIDateField
                  onChange={onDateChange}
                  name="end"
                  value={dateRange?.end}
                />
              }
              label=""
            />

            <MUISelect
              label="Tipo de acuse"
              selector="name"
              idSelector="id"
              name="accuse-type"
              noSelectedLabel="select"
              defaultValue={selectedAccuseType}
              items={accuseType}
              onChange={onAccuseTypeChange}
            />
            <MUISelect
              label="Cliente"
              selector="business_name"
              idSelector="id"
              name="client_id"
              noSelectedLabel="select"
              defaultValue={selectedClient}
              items={clients}
              onChange={(event) => onClientChange(event.value)}
            />

            <TextField
              className="billingsupport_totalRange"
              name="from"
              label="De"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
              onChange={onChangeTotal}
              value={totalRange?.from || ''}
              type="number"
            />
            <TextField
              name="to"
              label="A"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
              onChange={onChangeTotal}
              value={totalRange?.to || ''}
              type="number"
            />
            <MUISelect
              label="Proveedor"
              selector="name"
              idSelector="id"
              name="client_id"
              noSelectedLabel="select"
              defaultValue={selectedProvider}
              items={providers}
              onChange={(event) => onProviderChange(event.value)}
            />

            {showClear === true ? (
              <Tooltip title="Limpiar filtros">
                <ClearIcon onClick={(e) => onClear(e)} />
              </Tooltip>
            ) : null}
          </div>
        </div>

        <AddButton
          text="Crear manifiesto"
          onClick={onCreateManifest}
          disabled={shipments.length === 0}
        />
      </Paper>

      <MUIDataTable
        loading={loading}
        columns={tableColumns}
        data={shipments}
        selectableRows={true}
        selectableRowsComponent={MUICheckBox}
        selectableRowsNoSelectAll={false}
        selectableRowsHighlight={true}
        selectableRowDisabled={(row) => isNotSelectable(row)}
        onSelectedRowsChange={onSelectedChange}
        clearSelectedRows={toggledClearRows}
      />
    </div>
  );
};

export default BillingSupportView;
