import ApiService from './api-service';

export const getBillableShipmentsService = () => {
	const serviceDef = {
		url: 'shipment/ready-to-billing',
		method: 'get',
		params: null
	};
	return ApiService.request(serviceDef);
};

export const listShipmentsByBillService = ({ bill_id }) => {
	const serviceDef = {
		url: 'shipment/list-shipments-bill',
		method: 'get',
		params: { bill_id }
	};
	return ApiService.request(serviceDef);
};

export let createBillService = ({ shipment_id, bill_number, bill_date }) => {
	const serviceDef = {
		url: 'bill/create',
		method: 'post',
		params: {
			shipment_id,
			bill_number,
			bill_date
		}
	};
	return ApiService.request(serviceDef);
};

export let updateBillService = ({ id, bill_number, bill_date }) => {
	const serviceDef = {
		url: 'bill/update',
		method: 'post',
		params: {
			id,
			bill_number,
			bill_date
		}
	};
	return ApiService.request(serviceDef);
};

export const clearBillingService = ({ id }) => {
	const serviceDef = {
		url: 'bill/delete',
		method: 'post',
		params: {
			id
		}
	};
	return ApiService.request(serviceDef);
};
