/** Import react/libraries section **/
import React from 'react';
import { useForm } from 'react-hook-form';

/** Import styles section **/
import './BillingBusinessModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUITextField from '../../../components/UI/inputs/MUITextField';
import MUISelect from '../../../components/UI/inputs/MUISelect';

const BusinessDataModalView = ({
  onClose,
  onSave,
  businessData,
  taxRegime,
  loading,
  onInputChange
}) => {
  const { handleSubmit } = useForm();

  const onSubmit = (values) => {
    onSave(values);
  };
  const title = businessData?.id
    ? 'Editar empresa de facturación'
    : 'Agregar empresa de facturación';
  return (
    <ModalComponent
      title={title}
      open={true}
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
    >
      <div className="businessBillingModal">
        <MUITextField
          label="Nombre del negocio"
          data-cy="cy-business_name"
          name="business_name"
          value={businessData?.business_name}
          type="text"
          onChange={onInputChange}
        />
        <MUITextField
          label="RFC"
          data-cy="cy-rfc"
          name="rfc"
          value={businessData?.rfc}
          type="text"
          onChange={onInputChange}
        />
        <MUISelect
          label="Régimen fiscal"
          idSelector="id"
          selector="title"
          name="tax_regime_id"
          onChange={onInputChange}
          items={taxRegime}
          defaultValue={businessData?.tax_regime_id}
        />
        <MUITextField
          label="Código postal"
          data-cy="cy-postal_code"
          name="postal_code"
          value={businessData?.postal_code}
          type="text"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default BusinessDataModalView;
