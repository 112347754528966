import ApiService from './api-service';

export const getDedicatedPricesService = (routeId) => {
	const serviceDef = {
		url: 'route-price/dedicated',
		method: 'post',
		params: {origin_destination_id: routeId},
	};
	return ApiService.request( serviceDef );
};

export const updateDedicatedPriceService = ({id, cost, min_price, public_price}) => {
	const serviceDef = {
		url: 'route-price/update',
		method: 'post',
		params: {
			id,
			cost,
			min_price,
			public_price,
		},
	};
	return ApiService.request( serviceDef );
};

export const addDedicatedPriceService = (productPrice) => {
	const serviceDef = {
		url: 'route-price/create',
		method: 'post',
		params: productPrice,
	};
	return ApiService.request( serviceDef );
};

export const deleteDedicatedPriceService = ({id}) => {
	const serviceDef = {
		url: 'route-price/delete',
		method: 'post',
		params: {id},
	};
	return ApiService.request( serviceDef );
};

export const getProductsPricesService = (routeId) => {
	const serviceDef = {
		url: 'route-price/products',
		method: 'post',
		params: {
			origin_destination_id: routeId,
		},
	};
	return ApiService.request( serviceDef );
};

export const updateProductPriceService = (productPrice) => {
	const serviceDef = {
		url: 'route-price/update',
		method: 'post',
		params: productPrice,
	};
	return ApiService.request( serviceDef );
};

export const addProductPriceService = (productPrice) => {
	const serviceDef = {
		url: 'route-price/create',
		method: 'post',
		params: productPrice,
	};
	return ApiService.request( serviceDef );
};

export const requestProductPriceService = (productId) => {
	const serviceDef = {
		url: 'route-price/show',
		method: 'post',
		params: {id: productId},
	};
	return ApiService.request( serviceDef );
};
/**
 * Delete product by given ID
 * @returns {Promise<*>}
 * @param product
 */
export const deleteProductPriceService = (product) => {
	const serviceDef = {
		url: 'route-price/delete',
		method: 'post',
		params: product,
	};
	return ApiService.request( serviceDef );
};
/**
 * Request extra costs data.
 */
export const requestExtraCostsService = () => {
	const serviceDef = {
		url: 'extra-costs',
		method: 'get',
		params: null,
	};
	return ApiService.request( serviceDef );
};
/**
 * Update extra costs data.
 * @returns {Promise<*>}
 */
export const updateExtraCostsService = (extraCosts) => {
	const serviceDef = {
		url: 'extra-costs/update',
		method: 'post',
		params: extraCosts,
	};
	return ApiService.request( serviceDef );
};


