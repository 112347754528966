/* eslint-disable react-hooks/exhaustive-deps */

/** Import react/libraries section **/
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

/** Import component section **/
import QuoteServicesView from './QuoteServicesView';

/** Import helpers section **/
import {setAllServices} from '../../../redux/quotation/quoteSlice';
import {getAdditionalServicesByShipmentId} from '../../../services/catalogues-services';
import PageTitle from '../../../components/PageTitle';

/** Import resources section **/

const QuoteServicesComponent = () => {
	let dispatch = useDispatch();
	
	let selectedServices = useSelector( (store) => store.quote.quotation.additional_services );
	let deliveryTypeData = useSelector( (store) => store.quote.quotation.delivery_type );
	let hasCatalogs = useSelector( (store) => store.catalogs.has_catalogs );
	
	useEffect( () => {
		let shipmentId = deliveryTypeData.shipment_type_id === null ? 1 : deliveryTypeData.shipment_type_id;
		
		if (selectedServices.length === 0)
			getAdditionalServicesByShipmentId( shipmentId ).then( (response) => {
				dispatch( setAllServices( {services: response} ) );
			} ).catch( (error) => {
				console.error( 'QuoteServicesComponent - Error: ', error );
			} ).finally( () => {
			
			} );
	}, [deliveryTypeData.shipment_type_id] );
	
	if (hasCatalogs === true) {
		return (
			<QuoteServicesView/>
		);
	} else {
		return <PageTitle title="Cargando..."/>;
	}
};

export default QuoteServicesComponent;
