import React, { useEffect, useState } from 'react';

import { t as typy } from 'typy';

import CityEditView from './CityEditView';

import {
  getMunicipalitiesByStateService,
  getStatesService
} from '../../../services/location-services';
import { showErrorMessages } from '../../../utils/utils';
import { toast } from 'react-toastify';
import {
  addCoverageMunicipalityService,
  changeCoverageModeService
} from '../../../services/coverage-services';

const CityEditComponent = ({ onClose, onSave, city, modalMode }) => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [msCoverage, setMsCoverage] = useState(false);

  const onCitySelect = (data) => {
    const { value } = data;
    setSelectedCity(value);
  };

  const getMunicipalities = (selectedState) => {
    getMunicipalitiesByStateService(selectedState)
      .then((response) => {
        const responseArr = typy(response).safeArray;
        setCities(responseArr);
      })
      .catch((error) => {
        showErrorMessages(error);
      });
  };

  const onStateSelect = (data) => {
    const { value } = data;
    setSelectedState(value);
  };

  useEffect(() => {
    if (selectedState) {
      getMunicipalities(selectedState);
    }
  }, [selectedState]);

  const getStates = () => {
    getStatesService()
      .then((response) => {
        const responseArr = typy(response).safeArray;
        setStates(responseArr);
      })
      .catch((error) => {
        showErrorMessages(error);
      });
  };

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    getMunicipalities(city?.state_id);
    setSelectedState(city?.state_id);
    setSelectedCity(city?.id);
    setMsCoverage(city?.ms_coverage === 1);
  }, [city]);

  const handleOnSave = () => {
    const city = {
      state_id: selectedState,
      municipality_id: selectedCity,
      reachable: msCoverage === true ? 1 : 0,
      ms_coverage: msCoverage
    };
    if (modalMode === 'add') {
      addCoverageMunicipalityService(city)
        .then(() => {
          toast('Municipio agregado correctamente', { type: 'success' });
        })
        .catch((error) => {
          toast('No se puedo agregar el municipio correctamente', {
            type: 'success'
          });
        })
        .finally(() => {
          onClose();
        });
    } else {
      onClose();
    }
  };

  const onSwitchChange = (event) => {
    let { checked } = event.target;
    setMsCoverage(checked);
    if (modalMode === 'edit')
      changeCoverageModeService(selectedCity, checked === true ? 1 : 0)
        .then((response) => {
          toast('Cobertura MS actualizada', { type: 'success' });
        })
        .catch((errors) => {
          showErrorMessages(errors);
        });
  };

  return (
    <CityEditView
      states={states}
      cities={cities}
      onClose={onClose}
      onSave={handleOnSave}
      modalMode={modalMode}
      msCoverage={msCoverage}
      selectedCity={selectedCity}
      onCitySelect={onCitySelect}
      selectedState={selectedState}
      onStateSelect={onStateSelect}
      onSwitchChange={onSwitchChange}
    />
  );
};

export default CityEditComponent;
