/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import component section **/

/** Import helpers section **/

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import './PostalCodesStyles.scss';




import { ROUTES_ROUTE_EXTENDED } from '../../URL-Routes';

import PageTitle from '../../components/PageTitle';
import AddButton from '../../components/UI/AddButton';
import MUIDataTable from '../../components/MUIDataTable';
import FilterBy from '../../components/UI/FilterBy';

const ExtendedPostalCodesView = ({ data, loading, cityName, onAddPostalCode, onDeletePostalCode, onFilterUsers }) => {
  const ActionsCell = ({ row }) => {
    return (
      <DeleteIcon
        className="g-row-action-button delete"
        onClick={() => onDeletePostalCode(row)}
      />
    );
  };
  
  const tableColumns = useMemo(
    () => [
      {
        name: 'Código Postal',
        selector: 'postal_code',
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ], []
  );
  
  
  return (
    <>
      <PageTitle title={`Códigos postales - Rutas extendidas - ${cityName}`} backUrl={ROUTES_ROUTE_EXTENDED} />
      <Paper className="g-page-header">
        <div className='providers-header-filterby'>
          <FilterBy onChange={onFilterUsers} />
        </div>
        <AddButton onClick={onAddPostalCode} />
      </Paper>
      <MUIDataTable data={data} columns={tableColumns} loading={loading} />
    </>
  );
};

export default ExtendedPostalCodesView;
