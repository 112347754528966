/** Import react/libraries section **/
import React, {Fragment, useMemo} from 'react';
import Paper from '@material-ui/core/Paper';

/** Import helpers section **/
/** Import resources section **/
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterBy from '../../components/UI/FilterBy';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import './AgreementsStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import MUIDataTable from '../../components/MUIDataTable';
import AddButton from '../../components/UI/AddButton';
import {formatNumberToCurrency} from '../../utils/formats';

const AgreementsView = ({
  agreements,
  loading,
  onFilterByAgreements,
  onAddAgreement,
  onEditAgreement,
  onDeleteAgreement,
  onShowAgreementsPrices
}) => {
  const ActiveCell = ({ row }) => {
    if (row.agreement_active)
      return (
        <CheckCircleOutlineIcon className="agreements_activeicon agreements_activeicon-active" />
      );
    return <NotInterestedIcon className="agreements_activeicon" />;
  };
  const ActionsCell = ({ row }) => {
    return (
      <>
        <MonetizationOnIcon
          data-cy="edit-button"
          className="g-row-action-button"
          onClick={() => onShowAgreementsPrices(row)}
        />
        <EditIcon
          data-cy="edit-button"
          className="g-row-action-button"
          onClick={() => onEditAgreement(row)}
        />
        <DeleteIcon
          data-cy="delete-button"
          className="g-row-action-button delete"
          onClick={() => onDeleteAgreement(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Nombre',
        selector: 'name',
        sortable: true,
        center: true
      },
      {
        name: 'Tipo de cliente',
        cell: (row) => <>{row.client_type ? 'Especial' : 'Normal'}</>,
        sortable: true,
        center: true
      },
      {
        name: 'Crédito',
        cell: (row) => <>{row.credit ? 'Sí' : 'No'}</>,
        sortable: true,
        center: true
      },
      {
        name: 'Días de crédito ',
        cell: (row) => <>{row.credit_days !== null ? row?.credit_days : '---'}</>,
        sortable: true,
        center: true
      },
      {
        name: 'Asignado a ',
        selector: 'assessor_name',
        sortable: true,
        center: true
      },
      {
        name: 'Vencimiento ',
        selector: 'end_date_agreement',
        sortable: true,
        center: true
      },
      {
        name: 'Facturación mensual acordada ',
        cell: (row => formatNumberToCurrency(row?.monthly_billing)),
        sortable: true,
        center: true
      },
      {
        name: 'Activo',
        selector: 'active',
        cell: (row) => <ActiveCell row={row} />,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <Fragment>
      <PageTitle title="Convenios" />
      <Paper className="g-page-header">
        <div className="agreements_header_filterby">
          <FilterBy onChange={onFilterByAgreements} />
        </div>
        <AddButton onClick={onAddAgreement} />
      </Paper>

      <MUIDataTable
        data={agreements}
        columns={tableColumns}
        loading={loading}
      />
    </Fragment>
  );
};

export default AgreementsView;
