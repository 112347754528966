/** Import react/libraries section **/
import React, { Fragment, useMemo } from 'react';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import MUIDataTable from '../../components/MUIDataTable';
import FilterBy from '../../components/UI/FilterBy';
import AddButton from '../../components/UI/AddButton';
import ShipmentTypesSelect from '../../components/ShipmentTypesSelect';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import './ContainerTypesStyles.scss';

const ContainerTypesView = ({
  containers,
  loading,
  onFilterBy,
  onShipmentTypesFilterChange,
  onAdd,
  onEdit,
  onDelete,
  onProductsClick
}) => {
  const ActiveCell = ({ row }) => {
    if (row.active)
      return <CheckCircleOutlineIcon className="activeicon active" />;
    return <NotInterestedIcon className="activeicon" />;
  };

  const ServiceCell = ({ row, shipmentTypeId }) => {
    const hasService =
      row.shipment_types?.find((element) => element.id === shipmentTypeId) !==
      undefined;
    if (!hasService) return <></>;
    return (
      <div className="containertypes_check-cell">
        <CheckCircleIcon className="containertypes_check-cell_icon" />
      </div>
    );
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <Tooltip title="Ver productos">
          <LocalMallIcon
            className="g-row-action-button complementary-3"
            onClick={() => onProductsClick(row)}
          />
        </Tooltip>
        <EditIcon
          data-cy="edit-button"
          className="g-row-action-button"
          onClick={() => onEdit(row)}
        />

        <DeleteIcon
          data-cy="delete-button"
          className="g-row-action-button delete"
          onClick={() => onDelete(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Titulo',
        selector: 'title',
        sortable: true,
        center: true
      },
      {
        name: 'Activo',
        selector: 'active',
        cell: (row) => <ActiveCell row={row} />,
        sortable: true,
        center: true
      },
      {
        name: 'Paqueteria',
        cell: (row) => <ServiceCell row={row} shipmentTypeId={2} />,
        center: true
      },
      {
        name: 'Carga consolidada',
        cell: (row) => <ServiceCell row={row} shipmentTypeId={3} />,
        center: true
      },
      {
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <Fragment>
      <PageTitle title="Empaques" />
      <Paper className="g-page-header containertypes_header">
        <div className="g-page-header containertypes_header_filters">
          <div className="containertypes_header_filters_filterby">
            <FilterBy onChange={onFilterBy} />
          </div>

          <ShipmentTypesSelect
            onlyConfigurables
            onChange={onShipmentTypesFilterChange}
          />
        </div>

        <AddButton onClick={onAdd} />
      </Paper>

      <MUIDataTable
        data={containers}
        columns={tableColumns}
        loading={loading}
      />
    </Fragment>
  );
};

export default ContainerTypesView;
