/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import component section **/
import ModalComponent from '../../components/UI/ModalComponent';
import ProvidersView from './ProvidersView';
import ProviderModal from './ProviderModal';

/** Import helpers section **/
import { t as typy } from 'typy';
import {
	deleteProviderService,
	getProvidersService
} from '../../services/providers-services';
import { showErrorMessages } from '../../utils/utils';

const providerModel = {
	id: null,
	provider_number: null,
	name: null,
	rfc: null,
	contact_name: null,
	phone: null,
	email: null,
	address: null,
	profit_percentage: null,
	shipment_types: []
};

/**
 * Providers component
 * @returns {JSX.Element}
 * @constructor
 */
const ProvidersComponent = () => {
	const [loadingData, setLoadingData] = useState(false);
	const [originalData, setOriginalData] = useState([]);
	const [providers, setProviders] = useState([]);

	const [focusedItem, setFocusedItem] = useState(null);
	const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);

	const [textFilter, setTextFilter] = useState('');
	const [shipmentTypesFilters, setShipmentTypesFilters] = useState([]);

	const getProviders = () => {
		setLoadingData(true);
		getProvidersService()
			.then((response) => {
				const responseArr = typy(response).safeArray;
				setOriginalData(responseArr);
				setProviders(responseArr);
				setLoadingData(false);
			})
			.catch((error) => {
				console.error(
					'ProvidersComponent - Error getting providers: ',
					error
				);
			})
			.finally(() => {
				setLoadingData(false);
			});
	};

	useEffect(() => {
		getProviders();
	}, []);

	const filterData = () => {
		let filteredItems = [...originalData];

		if (textFilter.length > 0) {
			const filteringText = textFilter.toLowerCase();
			filteredItems = filteredItems.filter((item) => {
				const field1 = item.provider_number?.toString().toLowerCase();
				const field2 = item.name?.toString().toLowerCase();
				const field3 = item.rfc?.toString().toLowerCase();
				const field4 = item.contact_name?.toString().toLowerCase();
				const field5 = item.phone?.toString().toLowerCase();
				const field6 = item.email?.toString().toLowerCase();
				const field7 = item.address?.toString().toLowerCase();
				return (
					field1?.includes(filteringText) ||
					field2?.includes(filteringText) ||
					field3?.includes(filteringText) ||
					field4?.includes(filteringText) ||
					field5?.includes(filteringText) ||
					field6?.includes(filteringText) ||
					(field7?.includes(filteringText) && true)
				);
			});
		}
		if (shipmentTypesFilters.length > 0) {
			const stFilters = [...shipmentTypesFilters];
			filteredItems = filteredItems.filter((item) => {
				const itemShipmentTypes = [...item.shipment_types];
				return stFilters.every((ist) => {
					return (
						itemShipmentTypes.filter((stf) => {
							return ist.id === stf.id;
						}).length > 0
					);
				});
			});
		}

		setProviders(filteredItems);
	};

	const handleOnFilterBy = (filterTexted) => {
		setTextFilter(filterTexted);
	};

	const handleOnShipmentTypesFilterChange = (shipmentTypesSelected) => {
		setShipmentTypesFilters(shipmentTypesSelected);
	};

	useEffect(() => {
		filterData();
	}, [textFilter, shipmentTypesFilters]);

	const handleOnAddProvider = () => {
		setFocusedItem(providerModel);
		setDeleteAlertOpen(false);
	};

	const handleOnEditProvider = (item) => {
		const editItem = JSON.parse(JSON.stringify(item));
		setFocusedItem(editItem);
	};

	const handleOnModalClose = () => {
		setFocusedItem(null);
		setDeleteAlertOpen(false);
	};

	const handleOnDeleteProvider = (row) => {
		const deleteItem = {
			id: row.id,
			name: row.name
		};
		setFocusedItem(deleteItem);
		setDeleteAlertOpen(true);
	};

	const handleOnDeleteConfirm = () => {
		setLoadingData(false);
		deleteProviderService(focusedItem?.id)
			.then(() => {
				getProviders();
				toast('Proveedor eliminado', { type: 'success' });
			})
			.catch((error) => {
				toast('Error, no pudo eliminar el proveedor', {
					type: 'error'
				});
				showErrorMessages(error);
			});
		setFocusedItem(null);
		setDeleteAlertOpen(false);
	};

	const handleOnSaveConfirm = () => {
		setFocusedItem(null);
		setDeleteAlertOpen(false);
		getProviders();
	};

	return (
		<Fragment>
			<ProvidersView
				providers={providers}
				loading={loadingData}
				onFilterBy={handleOnFilterBy}
				onShipmentTypesFilterChange={handleOnShipmentTypesFilterChange}
				onAdd={handleOnAddProvider}
				onEdit={handleOnEditProvider}
				onDelete={handleOnDeleteProvider}
				filteringHandler={handleOnFilterBy}
			/>
			{focusedItem && !deleteAlertOpen && (
				<ProviderModal
					item={focusedItem}
					onClose={handleOnModalClose}
					onConfirm={handleOnSaveConfirm}
				/>
			)}
			{focusedItem && deleteAlertOpen && (
				<ModalComponent
					open={deleteAlertOpen}
					onClose={handleOnModalClose}
					onConfirm={handleOnDeleteConfirm}
					title="Borrar proveedor "
				>
					{'¿Estas seguro de borrar ' + focusedItem.name + '?'}
				</ModalComponent>
			)}
		</Fragment>
	);
};

export default ProvidersComponent;
