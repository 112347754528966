import React from 'react';
import './DedicatedPricesStyles.scss';

import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import PageTitle from './../../components/PageTitle';
import AddButton from '../../components/UI/AddButton';
import MUIDataTable from '../../components/MUIDataTable';

import { formatNumberToCurrency } from '../../utils/formats';
import RoutesList from '../../components/UI/RoutesList';

const DedicatedPricesView = ({
  data,
  onAdd,
  onEdit,
  loading,
  onDelete,
  onRouteSelect,
  selectedRoute
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon className="g-row-action-button" onClick={() => onEdit(row)} />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDelete(row)}
        />
      </>
    );
  };

  const tableColumns = [
    {
      name: 'id',
      selector: 'id',
      omit: true
    },
    {
      name: 'Origen',
      selector: 'origin_municipality_name',
      sortable: true,
      center: true
    },
    {
      name: 'Destino',
      selector: 'destination_municipality_name',
      sortable: true,
      center: true
    },
    {
      name: 'Unidad',
      selector: 'unit_name',
      sortable: true,
      center: true
    },
    {
      name: 'Costo',
      selector: 'cost',
      format: (row) => formatNumberToCurrency(row.cost),
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    },
    {
      name: 'Mínimo',
      selector: 'min_price',
      format: (row) => formatNumberToCurrency(row.min_price),
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    },
    {
      name: 'Precio',
      selector: 'public_price',
      format: (row) => formatNumberToCurrency(row.public_price),
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    }
  ];

  return (
    <>
      <PageTitle title="Precios de viajes dedicados.." />
      <Paper className="g-page-header">
        <RoutesList onSelect={onRouteSelect} defaultRouteId={selectedRoute} />
        <AddButton onClick={onAdd} />
      </Paper>
      <MUIDataTable data={data} columns={tableColumns} loading={loading} />
    </>
  );
};

export default DedicatedPricesView;
