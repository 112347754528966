/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Import component section **/
import QuoteProvidersView from './QuoteProvidersView';

/** Import helpers section **/
import { getProvidersService } from '../../../services/providers-services';
import {
  setCosts,
  setListOfProviders,
  setMSProvider,
  updateListOfProviders,
  updateMSProvider,
  updateMSProviderField,
  updateProdDetailsField
} from '../../../redux/quotation/quoteSlice';
import { refreshQuoteCosts } from '../../../services/quotes-services';
import { objectLength } from '../../../redux/utils';
import { toast } from 'react-toastify';

const QuoteProvidersComponent = (quoteId) => {
  let dispatch = useDispatch();

  let addedProviders = useSelector(
    (store) => store.quote.quotation.providers.list
  );
  let msprovider = useSelector(
    (store) => store.quote.quotation.providers.msprovider
  );
  let productsDetails = useSelector(
    (store) => store.quote.quotation.products_details
  );
  let isShipment = useSelector((store) => store.quote?.quotation?.is_shipment);
  let costs = useSelector((store) => store.quote.quotation.costs.cost);

  const [providers, setProviders] = useState([]);
  const [msPrices, setMsPrices] = useState(null);
  const [hasCosts, setHasCosts] = useState(false);

  useEffect(() => {
    getProvidersService()
      .then((response) => {
        setProviders(response);
      })
      .catch((error) => {
        console.error('QuoteProvider -> Error getting providers:', error);
      });
  }, []);

  useEffect(() => {
    let selectedProv = addedProviders.filter((x) => x.selected === 1);
    if (selectedProv.length > 0) {
      // dispatch( updateCost( {field: 'servicio', value: selectedProv[0]?.final_price} ) );
      // if (selectedProv[0].profit_percentage < selectedProv[0].limit_profit_percentage){
      //   toast(`El % de ganancia no puede ser menor a ${selectedProv[0].limit_profit_percentage}.`)
      // }
    }
  }, [costs, addedProviders, dispatch]);

  useEffect(() => {
    if (objectLength(costs) > 0) setHasCosts(true);
  }, [costs]);

  const onAddNewProvider = () => {
    let newProvider = {
      id: null,
      price: 0.0,
      selected: 1,
      tracking_guide: null,
      name: '',
      profit_percentage: 0.0,
      final_price: 0.0
    };
    let freightCost = {
      cost: {
        servicio: {
          id: 13,
          cost: 0,
          title: 'servicio'
        }
      }
    };

    if (hasCosts === false) dispatch(setCosts({ costs: freightCost }));
    let newList = [...addedProviders];
    newList[addedProviders.length] = newProvider;
    dispatch(setListOfProviders({ providersList: newList }));
    dispatch(updateMSProvider({ selected: 0 }));
  };

  const onDeleteProvider = (event, index) => {
    event.preventDefault();
    let newList = [...addedProviders];
    newList.splice(index, 1);
    dispatch(setListOfProviders({ providersList: newList }));
  };

  /**
   * Handle input change
   * @param event
   * @param index
   * @param price
   * @param profit
   */
  const handleInputChange = (event, index, price, profit) => {
    event.persist();
    let { name, value } = event.target;
    let finalPrice = 0;

    if (name === 'price') {
      finalPrice = parseFloat(value) + (value * parseFloat(profit)) / 100;
      // dispatch( updateCost( {field: 'servicio', value: parseFloat( finalPrice )} ) );

      dispatch(
        updateListOfProviders({
          index: index,
          field: 'final_price',
          value: finalPrice
        })
      );
      dispatch(
        updateListOfProviders({
          index: index,
          field: name,
          value: parseFloat(value)
        })
      );
    } else if (name === 'profit_percentage') {
      if (value < addedProviders[index].limit_profit_percentage) {
        // provider?.profit_percentage < provider.limit_profit_percentage ? provider.limit_profit_percentage : provider.profit_percentage
        toast(
          `El % de ganancia no puede ser menor a ${addedProviders[index].limit_profit_percentage}.`
        );
      }

      if (value === '') value = null;
      finalPrice = parseFloat(price) + (price * parseFloat(value)) / 100;

      // dispatch( updateCost( {field: 'servicio', value: parseFloat( finalPrice )} ) );

      dispatch(
        updateListOfProviders({
          index: index,
          field: 'final_price',
          value: finalPrice
        })
      );
      dispatch(
        updateListOfProviders({
          index: index,
          field: name,
          value: parseFloat(value)
        })
      );
    } else if (name === 'tracking_guide') {
      dispatch(
        updateListOfProviders({
          index: index,
          field: 'tracking_guide',
          value: value
        })
      );
    } else {
      dispatch(
        updateListOfProviders({
          index: index,
          field: name,
          value: parseFloat(value)
        })
      );
    }
  };

  /**
   * Here we handle inputs change for MS provider calculate profit and then update costs service value.
   * @param event
   */
  const onMSInputChange = (event) => {
    let { name, value } = event;
    dispatch(updateMSProviderField({ field: name, value: value }));
    let finalPrice = 0;
    if (name === 'price') {
      finalPrice =
        parseFloat(value) +
        (value * parseFloat(msprovider?.profit_percentage)) / 100;
      dispatch(
        updateMSProviderField({ field: 'final_price', value: finalPrice })
      );
      // dispatch( updateCost( {field: 'servicio', value: parseFloat( finalPrice )} ) );
    } else if (name === 'profit_percentage') {
      finalPrice =
        parseFloat(msprovider?.price) +
        (msprovider?.price * parseFloat(value)) / 100;
      dispatch(
        updateMSProviderField({ field: 'final_price', value: finalPrice })
      );
      // dispatch( updateCost( {field: 'servicio', value: parseFloat( finalPrice )} ) );
    }
  };

  const handleProductDetailsInputChange = (event, index) => {
    let { name, value } = event;
    dispatch(
      updateProdDetailsField({
        field: name,
        index: index,
        value: parseFloat(value)
      })
    );
  };

  const handleSelectChange = (event, index, name) => {
    let { value } = event;
    dispatch(
      updateListOfProviders({ index: index, field: name, value: value })
    );

    let selectedProv = addedProviders.filter((x) => x.selected === 1);
    if (
      selectedProv[0].profit_percentage <
      selectedProv[0].limit_profit_percentage
    ) {
      toast(
        `El % de ganancia no puede ser menor a ${selectedProv[0].limit_profit_percentage}.`
      );
    }
  };

  /**
   * Here we iterate over added providers, if index match with someone,
   * we set selected state to 1 and all others to 0, this way allow us
   * to only have one provider selected at once.
   * @param event
   * @param index
   */
  const handleRadioChange = (event, index) => {
    let { checked } = event.target;
    let selected = checked ? 1 : 0;
    setMsPrices(null);

    if (msprovider !== null) {
      dispatch(setMSProvider({ provider: msprovider }));
      dispatch(updateMSProviderField({ field: 'selected', value: 0 }));
    }

    let provs = [];
    // let finalPrice = 0;
    addedProviders.forEach((prov, idx) => {
      let obj = {
        id: prov.id,
        name: prov.name,
        final_price: prov.final_price,
        price: prov.price,
        has_agreement: prov.has_agreement,
        profit_percentage: prov.profit_percentage,
        limit_profit_percentage: prov.limit_profit_percentage,
        selected: prov.selected,
        tracking_guide: prov.tracking_guide
      };

      // Calculate final price for selected provider
      if (idx === index) {
        obj.selected = selected;
        // finalPrice = parseFloat( obj.price ) + (obj.price * parseFloat( obj.profit_percentage ) / 100);
      } else {
        obj.selected = 1;
      }
      provs.push(obj);
    });
    // Update 'servicio' on breakdown costs card.
    dispatch(setListOfProviders({ providersList: provs }));
    if (selected === 1 && objectLength(costs) > 0) {
      // dispatch( updateCost( {field: 'servicio', value: finalPrice} ) );
    } else {
      refreshQuoteCosts(quoteId, false)
        .then((response) => {
          dispatch(setCosts({ costs: response }));
        })
        .catch((error) => {
          console.error('QuoteCostComponent - Error updating costs: ', error);
        })
        .finally(() => {});
    }
  };

  const handleMSSelection = (event) => {
    // let { checked } = event.target;
    // let selected = checked ? 1 : 0;

    let provs = [];
    addedProviders.forEach((prov) => {
      let obj = {
        id: prov.id,
        selected: 0,
        name: prov.name,
        price: prov.price,
        final_price: prov.final_price,
        has_agreement: prov.has_agreement,
        tracking_guide: prov.tracking_guide,
        profit_percentage: prov.profit_percentage
      };
      provs.push(obj);
    });

    dispatch(setListOfProviders({ providersList: provs }));
    dispatch(setMSProvider({ provider: msprovider }));
    dispatch(updateMSProviderField({ field: 'selected', value: 1 }));

    let finalPrice =
      parseFloat(msprovider?.price) +
      (msprovider?.price * parseFloat(msprovider?.profit_percentage)) / 100;
    dispatch(
      updateMSProviderField({ field: 'final_price', value: finalPrice })
    );
    // dispatch( updateCost( {field: 'servicio', value: finalPrice} ) );
  };

  return (
    <QuoteProvidersView
      hasCosts={hasCosts}
      msPrices={msPrices}
      providers={providers}
      isShipment={isShipment}
      msprovider={msprovider}
      addProviders={onAddNewProvider}
      productsDetails={productsDetails}
      addedProviders={addedProviders}
      onMSInputChange={onMSInputChange}
      onDeleteProvider={onDeleteProvider}
      handleMSSelection={handleMSSelection}
      handleInputChange={handleInputChange}
      handleSelectChange={handleSelectChange}
      handleRadioChange={handleRadioChange}
      handleProductDetailsInputChange={handleProductDetailsInputChange}
    />
  );
};

export default QuoteProvidersComponent;
