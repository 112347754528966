import ApiService from './api-service';

/**
 * Get dashboard data from API.
 * @returns {Promise<*>}
 */
export const getDashboardService = (monthDate = null) => {
	const serviceDef = {
		url: 'shipment/dashboard',
		method: 'post',
		params: {
			monthDate: monthDate
		},
	};
	
	return ApiService.request( serviceDef );
};

/**
 * Get cleints report data from API.
 * @returns {Promise<*>}
 */
export const getClientReportService = () => {
	const serviceDef = {
		url: 'client/report',
		method: 'get',
		params: null,
	};
	
	return ApiService.request( serviceDef );
};

/**
 * Get users report from API.
 * @returns {Promise<*>}
 */
export const getUserReportService = () => {
	const serviceDef = {
		url: 'user/report',
		method: 'get',
		params: null,
	};
	
	return ApiService.request( serviceDef );
};
