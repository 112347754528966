import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export function useAction(
  action,
  payloadFn = undefined,
  extraPayload = undefined,
) {
  const dispatch = useDispatch();
  
  return useCallback(
    (payload) => {
      const finalPayload = extraPayload
        ? { ...extraPayload, ...payloadFn(payload) }
        : payloadFn(payload);
      return dispatch(action(finalPayload));
    },
    [ action, dispatch, extraPayload, payloadFn ],
  );
}

export function useAsyncAction(action) {
  const dispatch = useDispatch();
  
  return useCallback((payload) => dispatch(action(payload)), [
    action,
    dispatch,
  ]);
}


/**
 * Return the length of given object.
 * @param obj
 * @returns {number}
 */
export function objectLength(obj) {
  let size = 0, key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
}
