/** Import react/libraries section **/
import React from 'react';

/** Import styles section **/
import './ContactModalStyles.scss';

/** Import resources section **/

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUITextField from '../../../components/UI/inputs/MUITextField';
import LocationSelect from '../../../components/forms/LocationSelect';

const ContactModalView = ({
  contact,
  onInputChange,
  onSelectChange,
  onClose,
  onSave,
  onLocationLoaded
}) => {
  const locationData = {
    postal_code: contact?.cp,
    state_id: null,
    state_name: '',
    municipality_id: null,
    municipality_name: '',
    city_id: null,
    city_name: '',
    neighborhood_id: contact?.neighborhood?.id,
    neighborhood_name: contact?.neighborhood?.name,
    street: contact?.street,
    internal_number: contact?.internal_number,
    number: contact?.number,
    reference_streets: contact?.reference_streets || ''
  };

  return (
    <ModalComponent
      open={true}
      title={contact?.id ? 'Editar contacto' : 'Nuevo contacto'}
      width={800}
      onClose={onClose}
      onConfirm={onSave}
    >
      <div className="contactModal">
        <div className="contactModal_contact">
          <MUITextField
            required
            id="name"
            name="name"
            type="text"
            label="Compañía"
            value={contact?.name}
            onChange={onInputChange}
          />
          <MUITextField
            required
            id="contact"
            name="contact"
            type="text"
            label="Contacto"
            value={contact?.contact}
            onChange={onInputChange}
          />

          <MUITextField
            required
            id="email"
            name="email"
            label="Email"
            value={contact?.email}
            onChange={onInputChange}
          />
          <MUITextField
            required
            id="phone"
            name="phone"
            label="Teléfono"
            value={contact?.phone}
            onChange={onInputChange}
          />
          <MUITextField
            required
            id="rfc"
            name="rfc"
            type="text"
            label="RFC"
            value={contact?.rfc}
            onChange={onInputChange}
          />
        </div>
        <LocationSelect locationData={locationData} onLoad={onLocationLoaded} />
      </div>
    </ModalComponent>
  );
};

export default ContactModalView;
