import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MUIDialogTitle from './elements/MUIDialogTitle';
import MUIDialogActions from './elements/MUIDialogActions';

const EditForm = (props) => {
  const { title, children, open, disabled = false, onClose, onSave } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <MUIDialogTitle onClose={onClose}>{title}</MUIDialogTitle>
      <DialogContent>{children}</DialogContent>
      <MUIDialogActions
        onClose={onClose}
        onConfirm={onSave}
        confirmText="Guardar"
        cancelText="Cancelar"
        disabled={disabled}
      />
    </Dialog>
  );
};
export default EditForm;
