/** Import react/libraries section **/
import React from 'react';
import { formatNumberToCurrency } from '../../../utils/formats';
/** Import resources section **/

/** Import styles section **/
import './ShipmentBillModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUIDataTable from '../../../components/MUIDataTable';

const ShipmentBillModalView = ({
  data,
  onInputChange,
  onClose,
  onSave,
  onDelete
}) => {
  const tableColumns = [
    {
      name: 'Número de guía MS',
      selector: 'shipment.tracking_guide',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Estado destino',
      selector: 'state',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Guías de proveedores',
      selector: (row) =>
        row.selected_provider_tracking_guide
          ? row.selected_provider_tracking_guide
          : 'N/A',
      sortable: true,
      center: true,
      wrap: true,
      width: '150px'
    },
    {
      name: 'Nombre del proveedor',
      selector: 'provider_name',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'No. Manifiesto',
      selector: 'manifest_number',
      cell: (row) => row.bill_id,
      sortable: true,
      center: true,
      wrap: true
    },

    {
      name: 'Cliente',
      selector: 'client_business_name',
      sortable: true,
      center: true
    },
    {
      name: 'Monto',
      selector: 'total',
      format: (row) => formatNumberToCurrency(row?.shipment?.total),
      sortable: true,
      right: true
    },
    {
      name: 'Fecha de emisión',
      selector: 'shipment.accuse_load_date',
      sortable: true,
      center: true
    },
    {
      name: 'Número de factura',
      selector: 'bill.bill_number',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Fecha de envio de factura',
      selector: 'bill.invoice_send_date',
      sortable: true,
      center: true,
      wrap: true
    }
  ];
  return (
    <ModalComponent
      open={true}
      onClose={onClose}
      onConfirm={onSave}
      title={'Envios del manifiesto'}
      width={1000}
    >
      {/* <div className="shipmentBillModal">
				<>
					<span>Numero de guía</span>
				</>
				{data.map((item, idex) => (
					<span>{item?.shipment?.tracking_guide}</span>
				))}
			</div> */}
      <MUIDataTable
        // loading={loading}
        columns={tableColumns}
        data={data}
      />
    </ModalComponent>
  );
};

export default ShipmentBillModalView;
