/** Import react/libraries section **/
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import React from 'react';

/** Import component section **/

/** Import helpers section **/
import rootReducer from './rootReducer';

/** Import resources section **/


/**
 * Create root and apply middleware.
 */
const store = configureStore({
	reducer: rootReducer,
});

// expose store when run in Cypress
if (window.Cypress) {
	window.store = store;
}

/**
 * Compose and wrap everything within providers.
 * @param props
 * @returns {JSX.Element}
 */
const Root =  ({ children }) => (
	<Provider store={store}>
		{
			children
		}
	</Provider>
);

export default Root;