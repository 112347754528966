/** Import react/libraries section **/
import React, { Fragment } from 'react';

/** Import component section **/
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { CardContent } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

import MUISelect from '../../../UI/inputs/MUISelect';
import MUITextField from '../../../UI/inputs/MUITextField';

/** Import helpers section **/
/** Import resources section **/
import './DedicatedStyles.scss';

const DedicatedDetail = ({
  containers,
  deliveryTypeData,
  onServiceDetailsChange,
  onContainerSelectChange
}) => {
  return (
    <Fragment>
      <CardContent className="dedicatedDetail">
        <MUISelect
          aria-label="tipos de unidades"
          label="Unidad"
          name="unit_id"
          id="unit_id"
          idSelector="id"
          selector="name"
          onChange={onContainerSelectChange}
          items={containers}
          value={deliveryTypeData.unit_id}
        />

        <FormControl component="fieldset" id="radios">
          <FormLabel component="legend" labelPlacement="start">
            Mudanza
          </FormLabel>

          <RadioGroup
            className="dedicatedDetail_radios"
            name="move"
            aria-label="¿mudanza?"
            value={deliveryTypeData.move}
            onChange={(event) =>
              onServiceDetailsChange({
                name: 'move',
                value: parseInt(event.target.value)
              })
            }
          >
            <FormControlLabel value={0} control={<Radio />} label="No" />
            <FormControlLabel value={1} control={<Radio />} label="Si" />
          </RadioGroup>
        </FormControl>

        <MUITextField
          id="content"
          required
          className="services_details_content-input"
          name="content"
          label="Material o contenido"
          value={deliveryTypeData.content}
          onChange={onServiceDetailsChange}
        />

        <MUITextField
          id="comments"
          required
          className="services_details_content-input"
          name="comments"
          type="textarea"
          rows={3}
          label="Comentarios"
          value={deliveryTypeData.comments}
          onChange={onServiceDetailsChange}
        />
      </CardContent>
    </Fragment>
  );
};

export default DedicatedDetail;
