import React, {Fragment, useEffect, useState} from 'react';

import {t as typy} from 'typy';
import {toast} from 'react-toastify';

import EditFieldModal from '../../../components/UI/EditFieldModal';
import StaysView from './StaysView';
import {getStaysCostService, updateStayCostService} from '../../../services/extra-costs-services';
import {showErrorMessages} from '../../../utils/utils';

const StaysComponent = () => {
  const [ loadingData, setLoadingData ] = useState(false);
  const [ stays, setStays ] = useState([]);
  const [ editItem, setEditItem ] = useState(null);
  
  const handleOnEditPrice = (row) => {
    const editableElement = {
      data: row,
      title: 'Costo de estadía de ' + row.unit_name,
      service: 'cost',
      fields: [
        {
          id: 'cost',
          label: 'Costo',
          type: 'number',
          value: row.cost
        }
      ]
    };
    setEditItem(editableElement);
  };
  
  const handleOnEditModalClose = () => {
    setEditItem(null);
  };
  
  const handleOnEditModalSave = (editedRow) => {
    updateStayCostService(editedRow.unit_id, editedRow.cost ).then(response => {
      updateDelayOnList(response);
      toast('Costo actualizado', { type: 'success' });
    }).catch(error => {
      showErrorMessages( error );
      toast('Arror al actualizar precio de estadia', { type: 'error' });
    }).finally(() => {
      setEditItem(null);
    });
  };
  
  useEffect(() => {
    setLoadingData(true);
    getStaysCostService().then((response) => {
      const dataArr = typy(response.units).safeArray;
      setStays(dataArr);
    }).catch(error => {
      showErrorMessages( error );
    }).finally(() => {
      setLoadingData(false);
    });
  }, []);
  
  /**
   * We update current stays list, with new data returned from update.
   * @param stay
   */
  const updateDelayOnList = (stay) => {
    for (let u in stays){
      if (stays[u].unit_id === stay.unit_id ){
        let newStays = [ ...stays ];
        newStays[u] = stay;
        setStays(newStays);
      }
    }
  };
  
  return (
    <Fragment>
      <StaysView
        data={stays}
        loading={loadingData}
        onEditPrice={handleOnEditPrice}
      />
      {
        editItem && (
          <EditFieldModal
            open={true}
            title={editItem.title}
            service={editItem.service}
            data={editItem.data}
            fields={editItem.fields}
            handleOnClose={handleOnEditModalClose}
            handleOnSave={handleOnEditModalSave}
          />
        )
      }
    </Fragment>
  );
};

export default StaysComponent;
