/**
 * Represents client model data structure.
 * @type {{phone: string, contact: string, name: string, rfc: string, email: string}}
 */
import cuid from 'cuid';

export const clientModel = {
  id: null,
  name: '',
  rfc: '',
  contact_name: '',
  business_name: '',
  email: '',
  phone: '',
  same_data: false,
  client_number: '',
  client_type: 1,
  fisic_moral: 1,
  credit: 1,
  credit_amount: 0,
  balance: 0,
  credit_days: 0,
  start_credit_date: null,
  cpac: null
};

/**
 * Billing model.
 * @type {{client_data: '', payment_type: string, address: string, city_id: string, cfdi: string, name: string, bill: boolean, state: string, cp: string, rfc: string, email: string}}
 */
export const billingModel = {
  client_data: true,
  bill: 0,
  name: '',
  rfc: '',
  state: {
    name: 'Selecciona'
  },
  city: {
    name: 'Selecciona'
  },
  cfdi: {
    title: 'Selecciona'
  },
  city_id: null,
  neighborhood_id: null,
  state_id: null,
  street: '',
  cp: '',
  cfdi_id: '',
  email: '',
  contact_name: '',
  id: 1,
  phone: null,
  quotation_id: null,
  payment_type_id: 0
};

/**
 * Origin model structure.
 * @type {{country: string, address: string, city_id: '', state: string, cp: ''}}
 */
export const originModel = {
  country_id: null,
  country: 'México',
  state_name: '',
  cp: null,
  rfc: '',
  state_id: null,
  city_id: null,
  neighborhood_id: null,
  city_name: '',
  address: '',
  references: '',
  suburb: null,
  external_number: null,
  email: '',
  remitter: '',
  internal_number: '',
  name: '',
  number: null,
  contact: ''
};

/**
 * Destination data model
 * @type {{country: string, address: string, city_id: string, references: string, phone: '', recipient: string, state: string, cp: '', email: string}}
 */
export const destinationModel = {
  country: 'Mexico',
  cp: null,
  rfc: '',
  state_id: null,
  city_id: null,
  neighborhood_id: null,
  postal_code: '',
  state: { name: 'Selecciona' },
  city: { name: 'Selecciona' },
  address: '',
  references: '',
  name: '',
  contact: '',
  email: '',
  phone: '',
  suburb: '',
  number: null,
  internal_number: '',
  external_number: ''
};

export const deliveryTypeModel = {
  quantity: '',
  content: '',
  long: '',
  width: '',
  height: '',
  high: '',
  weight: 1,
  comments: '',
  shipment_type_id: 1,
  container_type_id: null,
  delivery_type_id: null,
  route_price_id: null,
  unit_id: null,
  move: 0,
  container_type_category_id: null,
  other_value: '',
  containers: []
};

export const packageTypeModel = {
  move: 1,
  long: null,
  unit: null,
  width: null,
  price: null,
  height: null,
  weight: null,
  exists: false,
  unit_id: null,
  content: '',
  quantity: null,
  comments: '',
  fragile: 0,
  stackable: 0,
  product_name: '',
  shipment_type_id: 1,
  volumetric_weight: null,
  shipment_id: 1,
  container_id: 1,
  shipment_route_id: null,
  container_category_id: null
};

/**
 * Compose quotation object to be send to API.
 * @param quoteId
 * @param client
 * @param billing
 * @param origin
 * @param destination
 * @param pickDate
 * @param pickTimeStart
 * @param pickTimeEnd
 * @param stdNext
 * @param services
 * @param deliveryType
 * @returns {{total: number, recollection_date: *, subtotal: number, delivery_type: {weigth: string, shipment_type_id: number, high: string, quantity: number, comments: string, container_type_id: number, width: string, route_price_id: number, content: string, long: string}, additional_services: [{id: number, value: string}], destination: {reference: *, phone: *, street: *, state_id: *, recipient_name: *, cp: *, email: *, city_id: (string|''|string)}, client: {business_name: string, contact_name: *, address: {street: *, state_id: *, cp: (string|''|number|string), city_id: (string|''|string)}, phone: *, bill_required: (''|boolean), name: *, rfc: *, email: *, billing: {street: *, state_id: *, cp: (string|''|number|string), city_id: (string|''|string)}}, next_day: *, origen: {street: *, state_id: *, cp: (string|''|number|string), city_id: (string|''|string)}, retention: string}}
 */
export const composeQuotation = (
  quoteId = null,
  client,
  billing,
  origin,
  destination,
  pickDate,
  stdNext,
  services,
  deliveryType,
  pickTimeStart = null,
  pickTimeEnd = null
) => {
  return {
    id: quoteId,
    uid: cuid(),
    recollection_date: pickDate,
    collection_start_time: pickTimeStart,
    collection_end_time: pickTimeEnd,
    next_day: stdNext,
    total: 0.0,
    subtotal: 0.0,
    retention: 0.0,
    iva: 0.0,
    discount: null,
    approved_credit: 1,
    can_apply_credit: 1,
    has_payment: 0,
    payment_type_id: null,
    delivery_date: null,
    am_preguide: null,
    real_delivery_date: null,
    is_shipment: false,
    delivery_type: {
      shipment_type: '',
      shipment_type_id: 1,
      containers: [packageTypeModel]
    },
    billing: billingModel,
    client: {
      id: client?.id,
      name: client.name,
      business_name: client.name,
      rfc: client.rfc,
      contact_name: client.contact_name,
      email: client.email,
      phone: client.email,
      bill_required: billing.bill,
      client_number: client.client_number,
      client_type: client.client_type,
      fisic_moral: client.fisic_moral,
      credit: client.credit,
      credit_amount: client.credit_amount,
      balance: client.balance,
      credit_days: client.credit_days,
      start_credit_date: client.start_credit_date,
      cpac: client.cpac,
      same_data: false
    },
    additional_services: services,
    origin: {
      state_id: origin.state?.id,
      city_id: origin.city_id,
      neighborhood_id: origin.neighborhood_id,
      municipality_id: origin.municipality_id,
      street: origin.address,
      references: origin.references,
      address: origin.address,
      cp: origin.cp,
      rfc: origin.fc,
      suburb: origin.suburb,
      external_number: origin.external_number,
      internal_number: origin.internal_number,
      email: origin.email,
      name: origin.name,
      contact_name: origin.contact_name
    },
    destination: {
      state_id: destination.state.id,
      city_id: destination.city_id,
      neighborhood_id: destination.neighborhood_id,
      street: destination.address,
      address: destination.address,
      cp: destination.cp,
      rfc: destination.rfc,
      name: destination.name,
      references: destination.references,
      email: destination.email,
      phone: destination.phone,
      suburb: destination.suburb,
      number: destination.number,
      external_number: destination.external_number,
      internal_number: destination.internal_number
    },
    incidents: [
      {
        id: 1,
        value: 0,
        option: 1,
        title: 'Incurrió en demora'
      },
      {
        id: 2,
        value: 0,
        option: 1,
        title: 'Tuvo retornos'
      }
    ],
    special_requirements: [],
    costs: {
      cost: []
    },
    providers: {
      msprovider: {
        id: 1,
        selected: 1,
        price: 0,
        extended_route_price: 0,
        profit_percentage: 0,
        extended_route_prices: [],
        final_price: 0,
        products: []
      },
      list: []
    },
    shipment_files: [],
    shipment_evidences: [],
    accuse: {
      accuses: [],
      accuse_load_date: null,
      folio: null,
      cd: null
    }
  };
};
