/** Import react/libraries section **/
import React from 'react';
import PropTypes from 'prop-types';

/** Import component section **/
import MUISelect from '../../UI/inputs/MUISelect';
import MUIDateTimeField from '../../UI/inputs/MUIDateTimeField';

/** Import helpers section **/
/** Import resources section **/
import {Card, CardContent, CardHeader} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import './EspecialConditionsStyles.scss';

const EspecialConditionsView = ({
	                                selectedServices,
	                                onExtraServicesChange,
	                                onExtraServicesTextChange,
	                                onManeuverChange,
	                                isShipment,
                                }) => {
	const getInput = (index, service) => {
		if (selectedServices[index] !== undefined) {
			if (service.has_text_value) {
				if (service.id === 1) {
					if (service.selected === 3)
						return (
							<TextField
								className="services_conditions_content-row-input"
								name="extra"
								type="number"
								data-cy="cy-bill-value"
								label={selectedServices[index].options[1].text_value}
								value={selectedServices[index].value}
								disabled={selectedServices[index].selected <= 2}
								onChange={(event) =>
									onExtraServicesTextChange( event.target.value, index )
								}
							/>
						);
				} else if (service.id === 5) {
					if (service.selected === 2)
						return (
							<MUIDateTimeField
								value={selectedServices[index].value}
								onChange={(data) =>
									onExtraServicesTextChange( data.value, index )
								}
							/>
						);
				} else if (service.id === 6) {
					if (service.selected === 2)
						return (
							<TextField
								className="services_conditions_content-row-input"
								name="extra"
								label={service.options[1].text_value}
								value={service.value}
								disabled={service.selected === 1}
								onChange={(event) =>
									onExtraServicesTextChange( event.target.value, index )
								}
							/>
						);
				} else if (service.id === 7) {
					if (service.selected === 2)
						return (
							<div className="conditions-content-row-maneuver">
								<MUISelect
									aria-label="tipos de maniobra"
									label="Maniobra"
									id="container_id"
									idSelector="id"
									selector="title"
									onChange={(response) => onManeuverChange( response, index )}
									items={service.options[1]?.catalog}
									value={service.catalog_value_selected}
								/>
								<TextField
									className="conditions-content-row-maneuver-input"
									name="extra"
									label="Costo"
									value={service.value}
									disabled={service.selected === 1}
									onChange={(event) =>
										onExtraServicesTextChange( event.target.value, index )
									}
								/>
							</div>
						);
				}
			}
		}
	};
	
	const composeServices = () => {
		return selectedServices.map( (service, index) => (
			<div key={service.id} className="conditions-content-row">
				<p>{service.title}</p>
				
				<RadioGroup
					className="conditions-content-row-radio"
					aria-label="entrega"
					name="delivery"
					value={selectedServices[index]?.selected}
					onChange={(event) => {
						onExtraServicesChange( event, service, index );
					}}
				>
					{
						service.options.map( (option, index) => (
							<FormControlLabel
								value={index + 1}
								control={<Radio data-cy={option.label}/>}
								label={option.label}
								disabled={isShipment}
							/>
						) )
					}
				</RadioGroup>
				
				{
					getInput( index, service )
				}
			</div>
		) );
	};
	
	return (
		<Card className="conditions">
			<CardHeader
				title="Condiciones especiales"
				className="conditions-header"
			/>
			
			{selectedServices.length > 0 ? (
				<CardContent className="conditions-content">
					{composeServices()}
				</CardContent>
			) : null}
		</Card>
	);
};

EspecialConditionsView.propTypes = {
	selectedServices: PropTypes.array.isRequired,
	onExtraServicesChange: PropTypes.array.isRequired,
	onExtraServicesTextChange: PropTypes.array.isRequired,
	onManeuverChange: PropTypes.array.isRequired,
};

export default EspecialConditionsView;
