import CryptoJS from 'crypto-js';
import { objectLength } from '../redux/utils';
import { toast } from 'react-toastify';

const SECRET_KEY = 'BwaL4N36pdS2';

export const setJSONToLocalSession = (llave, valor) => {
  if (llave && valor) {
    const textoCifrado = CryptoJS.AES.encrypt(
      JSON.stringify(valor),
      SECRET_KEY
    ).toString();

    window.sessionStorage.setItem(llave, textoCifrado);
    return true;
  }

  return false;
};

export const removeFromLocalSession = (llave) => {
  if (llave) {
    window.sessionStorage.removeItem(llave);
    return true;
  }

  return false;
};

export const getLocalSessionToJSON = (llave) => {
  if (llave) {
    const textoCifrado = window.sessionStorage.getItem(llave);
    if (textoCifrado) {
      const textoUtf8 = CryptoJS.AES.decrypt(textoCifrado, SECRET_KEY).toString(
        CryptoJS.enc.Utf8
      );
      return JSON.parse(textoUtf8);
    }
  }

  return undefined;
};

export const range = (start, end, step) => {
  const result = [];
  const typeofStart = typeof start;
  const typeofEnd = typeof end;

  if (step === 0) {
    throw TypeError('Step cannot be zero.');
  }

  if (typeofStart === 'undefined' || typeofEnd === 'undefined') {
    throw TypeError('Must pass start and end arguments.');
  } else if (typeofStart !== typeofEnd) {
    throw TypeError('Start and end arguments must be of same type.');
  }

  typeof step === 'undefined' && (step = 1);

  if (end < start) {
    step = -step;
  }

  if (typeofStart === 'number') {
    while (step > 0 ? end >= start : end <= start) {
      result.push(start);
      start += step;
    }
  } else if (typeofStart === 'string') {
    if (start.length !== 1 || end.length !== 1) {
      throw TypeError('Only strings with one character are supported.');
    }

    start = start.charCodeAt(0);
    end = end.charCodeAt(0);

    while (step > 0 ? end >= start : end <= start) {
      result.push(String.fromCharCode(start));
      start += step;
    }
  } else {
    throw TypeError('Only string and number types are supported');
  }

  return result;
};
/**
 * Show toast for error messages.
 * @param errors
 */
export const showErrorMessages = (errors) => {
  if (errors.errors !== undefined)
    if (errors?.errors?.length === 1) {
      toast('Advertencia: ' + errors.errors[0].Error, { type: 'success' });
    } else {
      for (let i in errors) {
        if (objectLength(errors[i]) > 0) {
          for (let err in errors[i]) {
            toast('Advertencia: ' + errors[i][err], { type: 'success' });
          }
        }
      }
    }
};
