/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import MUIAmountField from '../../../components/UI/inputs/MUIAmountField';

/** Import helpers section **/
/** Import resources section **/
import { Card, CardContent, CardHeader } from '@material-ui/core';
import './costeditStyles.scss';
import AddButton from '../../../components/UI/AddButton';

/**
 * Extra costs configuration tabs.
 * @returns {JSX.Element}
 * @constructor
 */
const CostsEditView = ({ onInputChange, extraCosts, saveExtraCosts }) => {
  return (
    <div className="costedit">
      <Card className="costedit_card">
        <CardHeader title="Edicion de costos extras" />

        <CardContent className="costedit_card_content">
          <div>
            <div className="costedit_card_content-row">
              <p>Seguro básico </p>
              <MUIAmountField
                id="base_insurance"
                prefix="$ "
                name="base_insurance"
                value={extraCosts?.base_insurance}
                onChange={onInputChange}
              />
            </div>

            <div className="costedit_card_content-row">
              <p>% Seguro valor declarado </p>
              <MUIAmountField
                id="declared_value_insurance"
                prefix="% "
                name="declared_value_insurance"
                value={extraCosts?.declared_value_insurance}
                onChange={onInputChange}
              />
            </div>

            <div className="costedit_card_content-row">
              <p>% CPAC </p>
              <MUIAmountField
                id="cpac"
                prefix="% "
                name="cpac"
                value={extraCosts?.cpac}
                onChange={onInputChange}
              />
            </div>

            <div className="costedit_card_content-row">
              <p>Almacenaje </p>
              <MUIAmountField
                id="storage"
                prefix="$ "
                name="storage"
                value={extraCosts?.storage}
                onChange={onInputChange}
              />
            </div>

            <div className="costedit_card_content-row">
              <p>Evidencia original </p>
              <MUIAmountField
                id="original_evidence"
                prefix="$ "
                name="original_evidence"
                value={extraCosts?.original_evidence}
                onChange={onInputChange}
              />
            </div>

            <div className="costedit_card_content-row">
              <p>Recolección a domicilio </p>
              <MUIAmountField
                id="home_recollection"
                prefix="$ "
                name="home_recollection"
                value={extraCosts?.home_recollection}
                onChange={onInputChange}
              />
            </div>

            <div className="costedit_card_content-row">
              <p>Entrega a domiciío (EAD) </p>
              <MUIAmountField
                id="home_delivery"
                prefix="$ "
                name="home_delivery"
                value={extraCosts?.home_delivery}
                onChange={onInputChange}
              />
            </div>

            <div className="costedit_card_content-row">
              <p>Cambio de domicilio </p>
              <MUIAmountField
                id="delivery_address_change"
                prefix="$ "
                name="delivery_address_change"
                value={extraCosts?.delivery_address_change}
                onChange={onInputChange}
              />
            </div>

            <div className="costedit_card_content-row">
              <p> Cita </p>
              <MUIAmountField
                id="date"
                prefix="$ "
                name="date"
                value={extraCosts?.date}
                onChange={onInputChange}
              />
            </div>
          </div>
          <AddButton text="Guardar" onClick={saveExtraCosts} />
        </CardContent>
      </Card>
    </div>
  );
};

export default CostsEditView;
