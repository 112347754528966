/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/
import { formatNumberToCurrency } from '../../utils/formats';

/** Import styles section **/
import './PaymentsStyles.scss';

/** Import resources section **/
import ClearIcon from '@material-ui/icons/Clear';
import MUISelect from '../../components/UI/inputs/MUISelect';
import { ButtonBase, Paper, Tooltip } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import RestoreIcon from '@material-ui/icons/Restore';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/UI/FilterBy';
import MUIDataTable from '../../components/MUIDataTable';
import MUIDateField from '../../components/UI/inputs/MUIDateField';

const PaymentsView = ({
  onClear,
  loading,
  payments,
  showClear,
  dateRange,
  inProcess,
  selectedStatus,
  onFilterBy,
  onEdit,
  onRevertPayment,
  onDateChange,
  onStatusChange
}) => {
  const paymentStatus = [
    { id: 0, title: 'No Pagado' },
    { id: 1, title: 'Pagado' }
  ];

  const ActionsCell = ({ row }) => {
    return (
      <>
        <Tooltip title="Registrar">
          <ButtonBase>
            <MonetizationOnIcon
              className="g-row-action-button actionMenu"
              onClick={() => onEdit(row)}
            />
          </ButtonBase>
        </Tooltip>
        <Tooltip title="Eliminar pago">
          <span>
            <ButtonBase disabled={inProcess || row.payment === 0}>
              <RestoreIcon
                className={`g-row-action-button ${
                  row.payment === 0 ? 'disabled' : 'complementary-4'
                }`}
                onClick={() => onRevertPayment(row.id)}
              />
            </ButtonBase>
          </span>
        </Tooltip>
      </>
    );
  };

  const tableColumns = [
    {
      name: 'Cliente',
      selector: (row) => row.client?.name,
      cell: (row) => row.client?.name,
      sortable: true,
      center: false,
      wrap: true
    },
    {
      name: 'Operador',
      selector: (row) => row?.operator_assigned,
      cell: (row) => row?.operator_assigned,
      sortable: true,
      center: false,
      wrap: true
    },
    {
      name: 'No. Factura',
      selector: 'bill_number',
      cell: (row) => row.bill_number,
      sortable: true,
      center: false,
      wrap: true
    },
    {
      name: 'No. Guía / No. Manifiesto',
      selector: 'tracking_guide',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Monto / Factura',
      selector: 'amount',
      format: (row) => formatNumberToCurrency(row.amount),
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Fecha de facturación',
      selector: 'bill_date',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Tipo de pago',
      selector: 'payment_type',
      sortable: true,
      center: true
    },

    {
      name: 'Fecha limite de pago',
      selector: 'limit_payment_date',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Fecha de pago',
      selector: 'payment_date',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Método de pago',
      selector: 'payment_method_title',
      sortable: true,
      center: true
    },
    {
      name: 'Días de vencimiento',
      selector: 'expiration_days',
      sortable: true,
      center: true
    },
    {
      name: 'Estatus',
      selector: (row) => (row.payment === 1 ? 'Pagado' : 'No pagado'),
      sortable: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    }
  ];

  return (
    <div className="payments">
      <PageTitle title="Cobranza" />
      <Paper className="g-page-header payments_header">
        <div className="payments_header-filters">
          <div className="payments_header_filters_switch">
            <FormControlLabel
              label=""
              control={<FilterBy onChange={onFilterBy} />}
            />

            <p>Del</p>

            <FormControlLabel
              control={
                <MUIDateField
                  onChange={onDateChange}
                  name="start"
                  value={dateRange?.start}
                />
              }
              label=" Al "
            />

            <FormControlLabel
              control={
                <MUIDateField
                  onChange={onDateChange}
                  name="end"
                  value={dateRange?.end}
                />
              }
              label=""
            />

            <MUISelect
              label="Estatus"
              selector="title"
              idSelector="id"
              name="payment-status"
              noSelectedLabel="Todos"
              defaultValue={selectedStatus}
              items={paymentStatus}
              onChange={onStatusChange}
            />

            {showClear === true ? (
              <Tooltip title="Limpiar filtros">
                <ClearIcon onClick={(e) => onClear(e)} />
              </Tooltip>
            ) : null}
          </div>
        </div>
      </Paper>

      <MUIDataTable
        loading={loading}
        columns={tableColumns}
        data={payments}
        showDownload={true}
      />
    </div>
  );
};

export default PaymentsView;
