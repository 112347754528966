/** Import react/libraries section **/
import React, { useCallback, useEffect, useState } from 'react';

/** Import component section **/
import DeliveryView from './DeliveryView';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAccuseProperty,
  setIncidents,
  updateAccuseField,
  updateField,
  updateIncident
} from '../../../redux/quotation/quoteSlice';
import { STATUS_DELIVERED } from '../../../utils/enumerates';
import { saveAccuseService } from '../../../services/file-services';
import { toast } from 'react-toastify';
import moment from 'moment';

/** Import helpers section **/

/** Import resources section **/

const DeliveryComponent = () => {
  const dispatch = useDispatch();

  const shipmenId = useSelector((store) => store.quote.quotation.id);
  const incidents = useSelector((store) => store.quote.quotation.incidents);
  const realDeliveryDate = useSelector(
    (store) => store.quote.quotation.real_delivery_date
  );
  const amPreguide = useSelector((store) => store.quote.quotation.am_preguide);
  const evidences = useSelector(
    (store) => store.quote.quotation.shipment_evidences
  );
  const recollectionDate = useSelector(
    (store) => store.quote.quotation.recollection_date
  );
  const shipmentStatusId = useSelector(
    (store) => store.quote.quotation.shipment_status_id
  );
  const shipmentTypeId = useSelector(
    (store) => store.quote.quotation.delivery_type.shipment_type_id
  );
  const accuse = useSelector((store) => store.quote.quotation.accuse);

  const [addedFiles, setAddedFiles] = useState([]);

  /**
   * In this useEffect we only update incidents on quote redux/store if there a re not data,
   * and shipment status is equal to 'delivered (5)'.
   */
  useEffect(() => {
    if (shipmentStatusId === STATUS_DELIVERED && incidents.length === 0) {
      let incidents = [
        {
          id: 1,
          value: 0,
          option: 1,
          title: 'Incurrió en demora'
        },
        {
          id: 2,
          value: 0,
          option: 1,
          title: 'Tuvo retornos'
        }
      ];
      dispatch(setIncidents({ incidents: incidents }));
    }
  }, [dispatch, shipmentStatusId, incidents]);

  const handleOnIncidentChange = (event, index) => {
    event.preventDefault();
    let value = parseInt(event.target.value);
    dispatch(updateIncident({ index, value }));
  };

  const handleOnDateChange = (data) => {
    let { name, value } = data;
    if (moment(value).isBefore(recollectionDate) === true) {
      toast(
        '!La decha de entrega no puede ser menor a la fecha de recolleción!',
        { type: 'warning' }
      );
    } else if (name === 'accuse_load_date') {
      dispatch(updateAccuseField({ field: name, value: value }));
    } else {
      dispatch(updateField({ field: name, value: value }));
    }
  };

  const handleOnAMPreguideChange = (data) => {
    let { name, value } = data;
    dispatch(updateField({ field: name, value: value }));
  };

  const handleOnDrop = useCallback((file) => {
    file.map((file) => {
      // Initialize FileReader browser API
      const reader = new FileReader();
      // onload callback gets called after the reader reads the file data
      reader.onload = function (e) {
        // add the image into the state. Since FileReader reading process is asynchronous, its better to get the latest snapshot state (i.e., prevState) and update it.
        setAddedFiles((prevState) => [
          {
            src: e.target.result,
            name: file.name
          }
        ]);
      };

      reader.onerror = () => {
        console.error('QuoteGilesView -> Error reading file:', reader.error);
      };

      // Read the file as Data URL (since we accept only images)
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const handleOnRemoveFile = (chipId) => {
    let preAddedFiles = [...addedFiles];
    let remaind = preAddedFiles.filter((x) => x.id !== chipId);
    setAddedFiles(remaind);
  };

  const handleOnSaveAccuse = () => {
    saveAccuseService(
      addedFiles,
      shipmenId,
      accuse.accuse_load_date,
      accuse.folio
    )
      .then((response) => {
        toast('Acuse guardado correctamente.', { type: 'success' });
        //TODO - Wait for API implementation.
        dispatch(
          setAccuseProperty({ field: 'accuses', value: response.accuses })
        );
        setAddedFiles([]);
      })
      .catch((error) => {
        toast('Error al guardar.', { type: 'error' });
        console.error('QuoteFilesComp -> Error saving attachments: ', error);
      });
  };

  const handleOnInputChange = (data) => {
    let { name, value } = data;
    dispatch(updateAccuseField({ field: name, value: value }));
  };

  return (
    <DeliveryView
      accuse={accuse}
      incidents={incidents}
      evidences={evidences}
      addedFiles={addedFiles}
      realDeliveryDate={realDeliveryDate}
      amPreguide={amPreguide}
      onRemoveFile={handleOnRemoveFile}
      onDateChange={handleOnDateChange}
      onAMPreguideChange={handleOnAMPreguideChange}
      onDrop={handleOnDrop}
      onInputChange={handleOnInputChange}
      onEvidencesSave={handleOnSaveAccuse}
      shipmentTypeId={shipmentTypeId}
      shipmentStatusId={shipmentStatusId}
      onIncidentChange={handleOnIncidentChange}
    />
  );
};

export default DeliveryComponent;
