
/** Import react/libraries section **/
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Import component section **/
import EspecialRequirementsView from './EspecialRequirementsView';
import { setNewReq, updateEspecialReq } from '../../../redux/quotation/quoteSlice';

/** Import helpers section **/

/** Import resources section **/


const EspecialRequirementsComponent = () => {
	let dispatch = useDispatch();
	
	let deliveryTypeData = useSelector( (store)=> store.quote.quotation.delivery_type );
	let specialRequirements = useSelector( (store) => store.quote.quotation.special_requirements );
	
	const onChipClick = (event, req, index) => {
		event.preventDefault();
		dispatch(	updateEspecialReq({
				req: req.selected === 0 ? 1 : 0,
				index: index
			}) );
	};
	
	const onOtherSpecialChange = (text) => {
		let newReq = {
			id: 11,
			title: text,
			selected: 1
		};
		dispatch(setNewReq({ req: newReq, index: specialRequirements.length }));
	};
	
	return <EspecialRequirementsView deliveryTypeData     ={deliveryTypeData}
	                                 onChipClick          ={onChipClick}
	                                 onOtherSpecialChange ={onOtherSpecialChange}
	                                 specialRequirements  ={specialRequirements}
	/>;
};

export default EspecialRequirementsComponent;
