/** Import react/libraries section **/
import { useCallback } from 'react';
import { createSlice } from '@reduxjs/toolkit';

/** Import helpers section **/
import { useAction } from '../utils';



const initialState =
  {
    inProcess: false,
    loggedIn: false,
    token: '' ,
    username: '',
    hasError: false,
    error: null,
  };


/**
 * Session slice
 * @type {
 * Slice<any, {
 * setSession: reducers.setSession,
 * clearSession: reducers.clearSession},
 * string>
 * }
 */
const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    startLogin: (state) => {
      state.inProcess = true;
    },
    setSession: (state, { payload: { token, user } }) => {
      state.token     = token;
      state.username  = user;
      state.loggedIn  = true;
      state.inProcess = false;
      
      localStorage.setItem('session', state);
    },
    
    clearSession: (state) => {
      state.loggedIn  = false;
      state.token     = '';
      state.username  = '';
      state.inProcess = false;
      
      localStorage.clear();
    },
    setError: (state, { payload: { error } }) => {
      state.hasError = true;
      state.error = error;
      state.inProcess = false;
    }
  },
});

/**
 * Use session object.
 * @returns {{appIsUserLoggedIn: boolean, appSessionKey: string, appUserEmail: string}}
 */
export const useSession = () => {
  return {
    appIsUserLoggedIn: false,
    appSessionKey: '',
    appUserEmail: '',
  };
};

/*********************************************
 * Sección de Dispatch Actions
 */
const { actions } = sessionSlice;

export function useSessionSignOut() {
  const payloadFn = useCallback(() => {}, []);
  return useAction(actions.clearSession, payloadFn);
}

export const { setSession, clearSession, setError, startLogin } = sessionSlice.actions;
export default sessionSlice.reducer;
