import React from 'react';

import './PageTitle.scss';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {Link} from 'react-router-dom';
import {ButtonBase} from '@material-ui/core';

export const PageTitle = ({title, backUrl = null, onBackward = null, path = null}) => {
	return (
		<div className="pagetitle">
			{
				backUrl && (
					<Link to={backUrl} className="pagetitle_link">
						<NavigateBeforeIcon className="page_title_back-icon"/>
					</Link>
				)
			}
			
			{
				onBackward !== null ?
					<ButtonBase onClick={onBackward} data-cy="cy-close">
						<NavigateBeforeIcon className="pagetitle_back-icon"/>
					</ButtonBase>
					: null
			}
			{
				path !== null ?
					<p> {path}
						{
							title !== '' ? <span className="pagetitle_chip">{title}</span> : null
						}
					</p>
					:
					title
			}
		</div>
	);
};

export default PageTitle;
