/** Import react/libraries section **/
import React, { useCallback, useEffect, useState } from 'react';

/** Import component section **/
import QuotationReportsView from './QuotationReportsView';

/** Import helpers section **/
import { getQuotationReportsService } from '../../services/reports-service';
import { formatNumberToCurrency } from '../../utils/formats';
import moment from 'moment';

/** Import resources section **/

const QuotationReportsComponent = () => {
	const [loading, setLoading] = useState(false);
	const [reports, setReports] = useState([]);
	const [originalData, setOriginalData] = useState([]);
	const [filteredText, setFilteredText] = useState('');
	const [showClear, setShowClear] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [dateRange, setDateRange] = useState({ start: null, end: null });

	useEffect(() => {
		setLoading(true);
		getQuotationReportsService()
			.then((response) => {
				let data = [];
				response.forEach((item) => {
					// if (item.shipment_status_id === STATUS_QUOTATION)
					data.push({
						id: item.id,
						tracking_guide: item.tracking_guide,
						quotation_date: item.quotation_date,
						shipment_status: item.shipment_status,
						shipment_status_id: item.shipment_status_id,
						name: item?.assigned
							? item?.assigned?.name +
							  ' ' +
							  item?.assigned?.last_name
							: '',
						client: item.client?.name,
						service_type: item.shipment_type,
						cpac: formatNumberToCurrency(item.cpac),
						seguro: formatNumberToCurrency(item.seguro),
						extra: formatNumberToCurrency(item.extra),
						subtotal: formatNumberToCurrency(item.subtotal),
						iva: formatNumberToCurrency(item.iva),
						retention: formatNumberToCurrency(item.retention),
						total: formatNumberToCurrency(item.total)
					});
				});
				setReports(data);
				setOriginalData(data);
			})
			.catch((error) => {
				console.error(
					'ReportsComp -> Error requesting reports: ',
					error
				);
			})
			.finally(() => setLoading(false));
	}, []);

	const filterQuotation = useCallback(() => {
		let filteredItems = [...originalData];
		const filterText = filteredText.toLocaleLowerCase();
		if (filterText.length > 0) {
			filteredItems = filteredItems.filter((item) => {
				const field1 = item.tracking_guide.toLowerCase();
				const field2 = item.quotation_date?.toString();
				const field3 = item.shipment_status?.toString();
				const field4 = item.name?.toString().toLowerCase();
				const field5 = item.client?.toString().toLowerCase();
				const field6 = item.service_type?.toString().toLowerCase();

				return (
					field1?.includes(filterText) ||
					field2?.includes(filterText) ||
					field3?.includes(filterText) ||
					field4?.includes(filterText) ||
					field5?.includes(filterText) ||
					(field6?.includes(filterText) && true)
				);
			});
		}

		if (dateRange.start !== null && dateRange.end !== null) {
			let start = dateRange.start;
			let end = moment(dateRange.end).add(12, 'h');
			filteredItems = filteredItems.filter((x) =>
				moment(x.quotation_date).isBetween(
					start,
					moment(end).add(12, 'hour')
				)
			);
		}

		if (selectedStatus !== null) {
			setShowClear(true);
			filteredItems = filteredItems.filter(
				(item) => item.shipment_status_id === selectedStatus
			);
		}

		setReports(filteredItems);
	}, [
		originalData,
		filteredText,
		dateRange.start,
		dateRange.end,
		selectedStatus
	]);

	useEffect(() => {
		filterQuotation();
	}, [filterQuotation, filteredText, dateRange, selectedStatus]);

	const handleOnFilterBy = (filterText) => {
		setFilteredText(filterText);
		console.log(filterText);
	};

	const handleOnStatusChange = (value) => {
		setSelectedStatus(value);
		console.log(value);
	};

	const handleDateChange = (event) => {
		if (event !== null) {
			setShowClear(true);
			let { value, name } = event;
			if (name === 'start') {
				setDateRange((prevState) => ({
					...prevState,
					start: value
				}));
			} else {
				setDateRange((prevState) => ({
					...prevState,
					end: value
				}));
			}
		}
	};

	const onClear = (event) => {
		event.preventDefault();
		setReports(originalData);
		setDateRange({ start: null, end: null });
		setSelectedStatus(null);
		setShowClear(false);
	};

	return (
		<QuotationReportsView
			reports={reports}
			loading={loading}
			onFilter={handleOnFilterBy}
			showClear={showClear}
			onClear={onClear}
			selectedStatus={selectedStatus}
			handleDateChange={handleDateChange}
			handleOnStatusChange={handleOnStatusChange}
			dateRange={dateRange}
		/>
	);
};

export default QuotationReportsComponent;
