/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';

import { t as typy } from 'typy';
import { toast } from 'react-toastify';

import CoverageView from './CoverageView';
import CityEditComponent from './CityEditModal';
import ModalComponent from '../../components/UI/ModalComponent';

import {
  deleteCoverageMunicipalityService,
  getCoverageMunicipalitiesService
} from '../../services/coverage-services';
import {
  CITIES_ROUTE,
  MPIO_POSTAL_CODES_ROUTE,
  ROUTES_ROUTE_EXTENDED
} from '../../URL-Routes';
import { Redirect } from 'react-router';
import { showErrorMessages } from '../../utils/utils';

const CoverageComponent = () => {
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [focusedItem, setFocusedItem] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [showExtended, setShowExtended] = useState(false);
  const [showPCs, setShowPCs] = useState(false);
  const [showExtendedRoutes, setShowExtendedRoutes] = useState(false);
  const [showMSCoverage, setShowMSCoverage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalMode, setModalMode] = useState('');

  const getData = useCallback(() => {
    setLoadingData(false);
    getCoverageMunicipalitiesService()
      .then((response) => {
        const responseArr = typy(response).safeArray;
        const statesArr = getStates(responseArr);
        setStates(statesArr);
        setOriginalData(responseArr);
        setCities(responseArr);
      })
      .catch((error) => {
        showErrorMessages(error);
      })
      .finally(() => {
        setLoadingData(false);
        setShowMSCoverage(true);
        restoreFilters();
      });
  }, []);

  const getStates = (array) => {
    const result = [];
    const map = new Map();
    for (const item of array) {
      if (!map.has(item.state_id)) {
        map.set(item.state_id, true);
        result.push({
          id: item.state_id,
          name: item.state_name
        });
      }
    }
    return result;
  };

  const restoreFilters = () => {
    if (showMSCoverage === true)
      setCities(originalData.filter((x) => x.ms_coverage === 1));
    if (showExtended === true)
      setCities(originalData.filter((x) => x.has_extended_route === true));
    if (selectedState) {
      const newData = originalData.filter(
        (item) => item.state_id === selectedState
      );
      setCities(newData);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (selectedState) {
      const newData = originalData.filter(
        (item) => item.state_id === selectedState
      );
      setCities(newData);
    } else {
      setCities(originalData);
    }
  }, [originalData, selectedState]);

  useEffect(() => {
    if (selectedCity) {
      const newData = originalData.filter(
        (item) => item.state_id === selectedState
      );
      setCities(newData);
    } else {
      setCities(originalData);
    }
  }, [originalData, selectedCity]);

  useEffect(() => {
    if (showExtended === true) {
      setCities(originalData.filter((x) => x.has_extended_route === true));
    } else if (showMSCoverage === true) {
      setCities(originalData.filter((x) => x.ms_coverage === 1));
    } else {
      setCities(originalData);
    }
  }, [showExtended, showMSCoverage]);

  const handleOnStateSelect = (data) => {
    const { value, name } = data;
    if (name === 'state') setSelectedState(value);
    if (name === 'city') setSelectedCity(value);
  };

  const handleOnShowCityPostalCodes = (data) => {
    setShowPCs(true);
    setSelectedCity(data);
  };

  const handleOnAddCity = () => {
    setShowAlert(true);
    setFocusedItem({});
    setModalMode('add');
  };

  const onClose = () => {
    setFocusedItem(null);
    setShowAlert(false);
    setModalMode('');
    setLoadingData(false);
    getData();
  };

  const handleOnEditCity = (row) => {
    setShowAlert(true);
    setFocusedItem(row);
    setModalMode('edit');
  };

  const handleOnDeleteCity = (item) => {
    setFocusedItem(item);
    setDeleteAlertOpen(true);
  };

  const handleOnDeleteClose = () => {
    setFocusedItem(null);
    setDeleteAlertOpen(false);
  };

  const handleOnDeleteConfirm = () => {
    deleteCoverageMunicipality({ municipality_id: focusedItem.id });
    setFocusedItem(null);
    setDeleteAlertOpen(false);
  };

  const deleteCoverageMunicipality = (data) => {
    deleteCoverageMunicipalityService(data)
      .then(() => {
        toast('Cobertura eliminada');
        setLoadingData(false);
        getData();
        setLoadingData(false);
      })
      .catch((error) => {
        console.error('CitiesComp -> Error getting cites: ', error);
        showErrorMessages(error);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const handleOnFilterBy = (filterText) => {
    let filteredItems;

    if (filterText.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterText.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item.name?.toString().toLowerCase();
        const field2 = item.state_name?.toString().toLowerCase();

        return (
          field1?.includes(filteringText) ||
          (field2?.includes(filteringText) && true)
        );
      });
    }

    setCities(filteredItems);
  };

  const onShowExtendedRoutes = (city) => {
    setShowExtendedRoutes(true);
    setSelectedCity(city);
  };

  const onSwitchChange = (event) => {
    let { checked, name } = event.target;
    if (name === 'show-pendents') {
      setShowExtended(checked);
      setShowMSCoverage(false);
      setSelectedState(null);
    } else if (name === 'show-ms-coverage') {
      setShowMSCoverage(checked);
      setShowExtended(false);
      setSelectedState(null);
    }
  };

  return (
    <>
      {showPCs === true ? (
        <Redirect
          to={{
            pathname: `${MPIO_POSTAL_CODES_ROUTE}/${selectedCity.id}`,
            state: {
              municipalityName: selectedCity.name,
              backUrl: CITIES_ROUTE
            }
          }}
        />
      ) : null}
      {showExtendedRoutes === true ? (
        <Redirect
          to={{
            pathname: `${ROUTES_ROUTE_EXTENDED}/${selectedCity.state_id}/${selectedCity.id}`,
            state: {
              city_name: selectedCity.name,
              backUrl: CITIES_ROUTE
            }
          }}
        />
      ) : null}

      <CoverageView
        data={cities}
        states={states}
        loading={loadingData}
        onAddCity={handleOnAddCity}
        onEditCity={handleOnEditCity}
        showExtended={showExtended}
        onDeleteCity={handleOnDeleteCity}
        selectedState={selectedState}
        onSwitchChange={onSwitchChange}
        showMSCoverage={showMSCoverage}
        onStateSelect={handleOnStateSelect}
        onShowExtended={onShowExtendedRoutes}
        handleOnFilterBy={handleOnFilterBy}
        onPostalCodesClick={handleOnShowCityPostalCodes}
      />

      {showAlert === true ? (
        <CityEditComponent
          city={focusedItem}
          modalMode={modalMode}
          onClose={onClose}
        />
      ) : null}

      {focusedItem && deleteAlertOpen && (
        <ModalComponent
          open={deleteAlertOpen}
          title="Borrar municipalidad "
          onClose={handleOnDeleteClose}
          onConfirm={handleOnDeleteConfirm}
        >
          {'Estas seguro de borrar ' + focusedItem.name + '?'}
        </ModalComponent>
      )}
    </>
  );
};

export default CoverageComponent;
