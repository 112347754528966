/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

/** Import resources section **/
import {
	deleteClientService,
	getClientsService
} from '../../services/clients-services';
import { requestUsersAssign } from '../../services/users-services';

/** Import helpers section **/

/** Import component section **/
import ModalComponent from '../../components/UI/ModalComponent';
import ClientsView from './ClientsView';
import ClientModal from './ClientModal';
import AssignModal from './AssignModal';
import CPACModal from './CPACModal';

import { showErrorMessages } from '../../utils/utils';

const ClientsComponent = () => {
	const [loadingData, setLoadingData] = useState(false);
	const [originalData, setOriginalData] = useState([]);
	const [clients, setClients] = useState([]);
	const [users, setUsers] = useState([]);

	const [textFilter, setTextFilter] = useState('');
	const [selectedClientType, setSelectedClientType] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedCreditType, setSelectedCreditType] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState(null);

	const [selectedItem, setSelectedItem] = useState(null);
	const [modalMode, setModalMode] = useState('');

	const getClients = () => {
		setLoadingData(true);
		getClientsService()
			.then((response) => {
				setClients(response);
				setOriginalData(response);
			})
			.catch((error) => {
				console.error('ClientsComp -> Error getting clients: ', error);
			})
			.finally(() => setLoadingData(false));
	};

	const getUsers = () => {
		requestUsersAssign()
			.then((response) => {
				setUsers(response);
			})
			.catch((error) => {
				console.error(
					'ClientsComponent - Error requesting users: ',
					error
				);
			});
	};

	useEffect(() => {
		getClients();
		getUsers();
	}, []);

	const filterClients = useCallback(() => {
		let filteredItems = [...originalData];
		const filteringText = textFilter.toLowerCase();

		if (filteringText.length > 0) {
			filteredItems = filteredItems.filter((item) => {
				const field1 = item.client_number?.toString().toLowerCase();
				const field2 = item.name?.toString().toLowerCase();
				const field3 = item.rfc?.toString().toLowerCase();

				return (
					field1?.includes(filteringText) ||
					field2?.includes(filteringText) ||
					(field3?.includes(filteringText) && true)
				);
			});
		}

		if (selectedUser !== null) {
			filteredItems = filteredItems.filter((item) => {
				return item.user_id === selectedUser;
			});
		}

		if (selectedClientType !== null) {
			filteredItems = filteredItems.filter((item) => {
				return item.client_type === selectedClientType;
			});
		}

		if (selectedCreditType !== null) {
			filteredItems = filteredItems.filter((item) => {
				return item.credit === selectedCreditType;
			});
		}

		if (selectedStatus !== null) {
			filteredItems = filteredItems.filter((item) => {
				return item.active === selectedStatus;
			});
		}

		setClients(filteredItems);
	}, [
		originalData,
		selectedClientType,
		selectedCreditType,
		selectedStatus,
		selectedUser,
		textFilter
	]);

	useEffect(() => {
		filterClients();
	}, [
		textFilter,
		selectedUser,
		selectedClientType,
		selectedCreditType,
		selectedStatus,
		filterClients
	]);

	const handleOnFilterBy = (filterStr) => {
		setTextFilter(filterStr.trim());
	};

	const handleOnUserChange = (value) => {
		setSelectedUser(value);
	};

	const handleOnClientTypeChange = (value) => {
		setSelectedClientType(value);
	};

	const handleOnCreditTypeChange = (value) => {
		setSelectedCreditType(value);
	};

	const handleOnStatusChange = (value) => {
		setSelectedStatus(value);
	};

	const handleOnAddClient = () => {
		setSelectedItem({ id: null });
		setModalMode('client');
	};

	const handleOnEditClient = (item) => {
		const editItem = JSON.parse(JSON.stringify(item));
		setSelectedItem(editItem);
		setModalMode('client');
	};

	const handleOnAssign = (item) => {
		setSelectedItem(item);
		setModalMode('assign');
	};

	const handleOnCpacEdit = (item) => {
		const editItem = JSON.parse(JSON.stringify(item));
		setSelectedItem(editItem);
		setModalMode('cpac');
	};

	const handleOnDeleteClient = (row) => {
		const deleteItem = {
			id: row.id,
			name: row.business_name
		};
		setSelectedItem(deleteItem);
		setModalMode('delete');
	};

	const handleOnDeleteConfirm = () => {
		deleteClientService(selectedItem?.id)
			.then(() => {
				toast('Cliente eliminado correctamente', { type: 'success' });
				handleOnRefreshList();
			})
			.catch((error) => {
				// toast( 'Error al eliminar cliente', {type: 'error'} );
				showErrorMessages(error);
			})
			.finally(() => {});
	};

	const handleOnModalClose = () => {
		clearStates();
	};

	const handleOnRefreshList = () => {
		clearStates();
		getClients();
	};

	const clearStates = () => {
		setSelectedItem(null);
		setModalMode('');
	};

	return (
		<>
			<ClientsView
				clients={clients}
				users={users}
				loading={loadingData}
				onAddClient={handleOnAddClient}
				onCpacEdit={handleOnCpacEdit}
				onEditClient={handleOnEditClient}
				onAssign={handleOnAssign}
				onDeleteClient={handleOnDeleteClient}
				onFilterBy={handleOnFilterBy}
				onUserChange={handleOnUserChange}
				onClientTypeChange={handleOnClientTypeChange}
				onCreditTypeChange={handleOnCreditTypeChange}
				onStatusChange={handleOnStatusChange}
			/>

			{selectedItem && modalMode === 'client' && (
				<ClientModal
					item={selectedItem}
					onClose={handleOnModalClose}
					onConfirm={handleOnRefreshList}
				/>
			)}
			{selectedItem && modalMode === 'assign' && (
				<AssignModal
					open={true}
					item={selectedItem}
					users={users}
					onClose={handleOnModalClose}
					onConfirm={handleOnRefreshList}
				/>
			)}
			{selectedItem && modalMode === 'cpac' && (
				<CPACModal
					open={true}
					item={selectedItem}
					onClose={handleOnModalClose}
					onConfirm={handleOnRefreshList}
				/>
			)}
			{selectedItem && modalMode === 'delete' && (
				<ModalComponent
					open={true}
					onClose={handleOnModalClose}
					onConfirm={handleOnDeleteConfirm}
					title="Borrar cliente "
				>
					{'¿Estas seguro de borrar ' + selectedItem.name + '?'}
				</ModalComponent>
			)}
		</>
	);
};

export default ClientsComponent;
