import React, { useMemo } from 'react';
import './DelaysStyles.scss';

import EditIcon from '@material-ui/icons/Edit';
import MUIDataTable from '../../../components/MUIDataTable';
import { formatNumberToCurrency } from '../../../utils/formats';

const DelaysView = ({ data, loading, onEditPrice }) => {
  
  const EditCell = ({ row }) => (
    <EditIcon
      className="g-row-action-button"
      onClick={() => onEditPrice(row)}
    />
  );
  
  const PriceCell = ({ row }) => {
    return (
      <>
        <div className="g-row-action-title">
          {row.cost
            ? formatNumberToCurrency(row.cost)
            : null}
        </div>
      </>
    );
  };
  
  const tableColumns = useMemo(
    () => [
      {
        name: 'Unidad',
        selector: 'unit_name',
        sortable: true,
        center: true
      },
      {
        name: 'Demora por hora',
        cell: (row) => <PriceCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <EditCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );
  
  return <MUIDataTable data={data} columns={tableColumns} loading={loading} />;
};

export default DelaysView;
