/** Import react/libraries section **/
import { getPermissionsByUserRole } from './role_permissions';

/** Import component section **/

/** Import helpers section **/

/** Import resources section **/

const checkPermissions = (allowedPermissions) => {
  let userPermissions = getPermissionsByUserRole();
  if (allowedPermissions.length === 0) {
    // no especificó ningún permiso por lo que se permite
    return true;
  }

  if (userPermissions.length === 0) {
    // no tiene ningun permiso por lo que no se permite
    return false;
  }

  return userPermissions.some((permission) =>
    allowedPermissions.includes(permission)
  );
};

const AccessControl = ({ allowedPermissions, children, renderNoAccess }) => {
  const permitted = checkPermissions(allowedPermissions);
  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  permissions: [],
  renderNoAccess: () => null
};

export default AccessControl;
