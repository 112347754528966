/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import helpers section **/
import { getClientsService } from '../../../services/clients-services';
import {
  createAgreementService,
  updateAgreementService
} from '../../../services/agreement-services';
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import component section **/
import AgreementModalView from './AgreementModalView';

const agreementModel = {
  id: null,
  client_name: null,
  user_name: null,
  end_date_agreement: null,
  monthly_billing: null,
  agreement_active: 1
};

const AgreementModalComponent = ({ item, onClose, onConfirm }) => {
  const [agreement, setAgreement] = useState(agreementModel);
  const [clients, setClients] = useState([]);

  const getClients = () => {
    getClientsService().then((response) => {
      setClients(response);
    });
  };

  useEffect(() => {
    getClients();
    if (item?.id) {
      setAgreement(item);
    } else {
      setAgreement(agreementModel);
    }
  }, [item]);

  const handleOnInputChange = (data) => {
    const { value, name } = data;
    setAgreement((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnClientChange = (event) => {
    setAgreement((prevState) => ({
      ...prevState,
      client_id: event.value
    }));
  };

  const handleOnSwitchChange = (event) => {
    let { name, checked } = event.target;
    setAgreement((prevState) => ({
      ...prevState,
      [name]: checked === true ? 1 : 0
    }));
  };

  const handleOnSave = (event) => {
    if (item?.id) {
      updateAgreementService(agreement)
        .then(() => {
          toast('Convenio actualizado correctamente', { type: 'success' });
          onConfirm();
        })
        .catch((error) => {
          console.error(
            'AgreementAddModalCom - Error updating new agreement: ',
            error
          );
        });
    } else {
      createAgreementService(agreement)
        .then((response) => {
          toast('Convenio agregado correctamente', { type: 'success' });
          onConfirm();
        })
        .catch((error) => {
          console.error(
            'AgreementAddModalCom - Error creating new agreement: ',
            error
          );
        });
    }
  };

  return (
    <AgreementModalView
      agreement={agreement}
      clients={clients}
      onSave={handleOnSave}
      onClose={onClose}
      onInputChange={handleOnInputChange}
      onClientChange={handleOnClientChange}
      onSwitchChange={handleOnSwitchChange}
    />
  );
};

export default AgreementModalComponent;
