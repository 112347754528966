/** Import react/libraries section **/
import React from 'react';

/** Import styles section **/
import './ClientModalStyles.scss';

/** Import resources section **/
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUITextField from '../../../components/UI/inputs/MUITextField';
import LocationSelect from '../../../components/forms/LocationSelect';
import MUISelect from '../../../components/UI/inputs/MUISelect';

const ClientModalView = ({
  taxRegime,
  client,
  billingBusiness,
  cfdi,
  paymentTypes,
  paymentMethods,
  onInputChange,
  onRadioChange,
  onSwitchChange,
  onLocationLoaded,
  onClose,
  onSave
}) => {
  const locationData = {
    postal_code: client?.cp,
    state_id: null,
    state_name: '',
    municipality_id: null,
    municipality_name: '',
    city_id: null,
    city_name: '',
    neighborhood_id: client?.neighborhood_id,
    neighborhood_name: client?.neighborhood_name,
    street: client?.street,
    internal_number: client?.internal_number,
    number: client?.external_number,
    reference_streets: client?.reference_streets || ''
  };

  return (
    <ModalComponent
      open={true}
      title={client?.id ? 'Editar cliente' : 'Nuevo Cliente'}
      width={850}
      onClose={onClose}
      onConfirm={onSave}
    >
      <div className="clientmodal">
        <div className="clientmodal_info">
          <div className="clientmodal_info_general">
            <div id="MUI_client_number">
              <MUITextField
                label="Número de cliente"
                id="client_number"
                name="client_number"
                value={client?.client_number}
                type="text"
                onChange={onInputChange}
              />
            </div>

            <div id="MUI_name">
              <MUITextField
                label="Nombre"
                name="name"
                value={client?.name}
                type="text"
                variant="outlined"
                onChange={onInputChange}
              />
            </div>

            <div id="MUI_rfc">
              <MUITextField
                label="RFC"
                name="rfc"
                value={client?.rfc}
                type="text"
                onChange={onInputChange}
              />
            </div>

            <div id="MUI_business_name">
              <MUITextField
                label="Razón social"
                id="business_name"
                name="business_name"
                value={client?.business_name}
                type="text"
                onChange={onInputChange}
              />
            </div>

            <div id="MUI_contact_name">
              <MUITextField
                label="Contacto"
                id="contact_name"
                name="contact_name"
                value={client?.contact_name}
                type="text"
                onChange={onInputChange}
              />
            </div>

            <div id="MUI_email">
              <MUITextField
                label="Correo"
                id="email"
                name="email"
                value={client?.email}
                type="text"
                onChange={onInputChange}
              />
            </div>

            <div id="MUI_phone">
              <MUITextField
                label="Teléfono"
                id="phone"
                name="phone"
                value={client?.phone}
                type="text"
                onChange={onInputChange}
              />
            </div>
          </div>
          <LocationSelect
            locationData={locationData}
            onLoad={onLocationLoaded}
            singleAddress
          />
          <div className="clientmodal_info_fiscal">
            <MUISelect
              label="Uso de CFDI"
              idSelector="id"
              selector="description"
              name="cfdi_id"
              onChange={onInputChange}
              items={cfdi}
              value={client?.cfdi_id}
            />
            <MUISelect
              label="Tipo de Pago"
              idSelector="id"
              selector="title"
              name="payment_type_id"
              onChange={onInputChange}
              items={paymentTypes}
              value={client?.payment_type_id}
            />
            <MUISelect
              label="Régimen fiscal"
              idSelector="id"
              selector="title"
              name="tax_regime_id"
              onChange={onInputChange}
              items={taxRegime}
              value={client?.tax_regime_id}
            />
            <MUISelect
              label="Metodo de Pago"
              idSelector="id"
              selector="type"
              name="sat_payment_method_id"
              onChange={onInputChange}
              items={paymentMethods}
              value={client?.sat_payment_method_id}
            />
            <MUISelect
              label="Empresa de facturación"
              idSelector="id"
              selector="rfc"
              name="billing_business_id"
              onChange={onInputChange}
              items={billingBusiness}
              value={client?.billing_business_id}
            />
          </div>
        </div>

        <div className="clientmodal_settings">
          <FormControlLabel
            id="MUI_active"
            labelPlacement="start"
            label="Activo"
            control={
              <Switch
                checked={client?.active === 1}
                onChange={onSwitchChange}
                name="active"
                data-cy="cy-credit"
                color="primary"
                inputProps={{ 'aria-label': 'credito' }}
              />
            }
          />
          <RadioGroup
            aria-label="tipo de cliente"
            id="MUI_type"
            name="client_type"
            value={client?.client_type}
            onChange={onRadioChange}
            row
          >
            <FormControlLabel
              id="MUI_normal"
              value={0}
              control={<Radio data-cy="cy-normal" />}
              label="Normal"
            />
            <FormControlLabel
              id="MUI_especial"
              value={1}
              control={<Radio data-cy="cy-special" />}
              label="Especial"
            />
          </RadioGroup>
          <RadioGroup
            aria-label="fisica o moral"
            name="fisic_moral"
            value={client?.fisic_moral}
            onChange={onRadioChange}
            row
            id="MUI_regimen"
          >
            <FormControlLabel
              id="MUI_fisica"
              value={0}
              control={<Radio data-cy="cy-fisic" />}
              label="Fisíca"
            />
            <FormControlLabel
              id="MUI_moral"
              value={1}
              control={<Radio data-cy="cy-moral" />}
              label="Moral"
            />
          </RadioGroup>

          <div id="MUI_credit">
            <FormControlLabel
              id="MUI_credit_switch"
              labelPlacement="start"
              label="Crédito"
              control={
                <Switch
                  checked={client?.credit === 1}
                  onChange={onSwitchChange}
                  name="credit"
                  data-cy="cy-credit"
                  color="primary"
                  inputProps={{ 'aria-label': 'credito' }}
                />
              }
            />

            <div id="MUI_credit_amount">
              <MUITextField
                label="Monto del credito"
                id="credit_amount"
                name="credit_amount"
                value={client?.credit_amount}
                type="number"
                onChange={onInputChange}
                disabled={client?.credit === 0}
              />
            </div>

            <div id="MUI_credit_days">
              <MUITextField
                label="Días de crédito"
                id="credit_days"
                name="credit_days"
                value={client?.credit_days}
                type="number"
                onChange={onInputChange}
                disabled={client?.credit === 0}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default ClientModalView;
