/** Import react/libraries section **/
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';

/** Import component section **/
import DeliveryTypeFormView from './DeliveryTypeFormView';

/** Import helpers section **/
import {updateField} from '../../../redux/quotation/quoteSlice';
import {toast} from 'react-toastify';

/** Import resources section **/


export const DeliveryTypeFormComponent = () => {
	let dispatch = useDispatch();
	
	let stdNext = useSelector( (store) => store.quote.quotation.next_day );
	let deliveryDate = useSelector( (store) => store.quote.quotation.delivery_date );
	let recollectionDate = useSelector( (store) => store.quote.quotation.recollection_date );
	let isShipment = useSelector( (store) => store.quote?.quotation?.is_shipment );
	
	/**
	 * Handler for delivery type radio button
	 * @param event
	 */
	const onChange = (event) => {
		dispatch( updateField( {field: 'next_day', value: parseInt( event.target.value )} ) );
	};
	
	
	const onDateChange = (event) => {
		let {name, value} = event;
		if (moment( value ).isBefore( recollectionDate ) === true) {
			toast( '!La decha de entrega no puede ser menor a la fecha de recolleción!', {type: 'warning'} );
		} else {
			dispatch( updateField( {field: name, value: value} ) );
		}
	};
	
	return (
		<DeliveryTypeFormView
			value={stdNext}
			onChange={onChange}
			isShipment={isShipment}
			deliveryDate={deliveryDate}
			onDateChange={onDateChange}
			recollectionDate={recollectionDate}
		/>
	);
};

export default DeliveryTypeFormComponent;
