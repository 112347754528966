/* eslint-disable react-hooks/exhaustive-deps */

/** Import react/libraries section **/
import React from 'react';
import { useSelector } from 'react-redux';

/** Import component section **/
import QuoteGeneralView from './QuoteGeneralView';

const QuoteGeneralComponent = ({ backward }) => {
  let hasCatalogs = useSelector((store) => store.catalogs.has_catalogs);

  return <QuoteGeneralView hasCatalogs={hasCatalogs} backward={backward} />;
};

export default QuoteGeneralComponent;
