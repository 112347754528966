import { combineReducers } from 'redux';

import sessionReducer from './session/sessionSlice';
import catalogsReducer from './catalogs/catalogsSlice';
import quotationReducer from './quotation/quoteSlice.js';
// import requestSlice from './request/requestSlice';

export default combineReducers({
	session: sessionReducer,
	catalogs: catalogsReducer,
	quote: quotationReducer,
	// request: requestSlice,
});