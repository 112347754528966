/** Import react/libraries section **/
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';

/** Import component section **/
/** Import helpers section **/
/** Import resources section **/
import './ActionsMenu.scss';
import Menu from '@material-ui/core/Menu';
import UndoIcon from '@material-ui/icons/Undo';
import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';
import BlockIcon from '@material-ui/icons/Block';
import MenuItem from '@material-ui/core/MenuItem';
import ApproveIcon from '@material-ui/icons/Check';
import ArchiveIcon from '@material-ui/icons/Archive';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export const ActionsMenu = ({ actions }) => {
  const allowedOptions = useSelector(
    (store) => store.quote.quotation.action_options
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action) => {
    setAnchorEl(null);
    action.call();
  };

  const ActionItem = ({ action, main }) => {
    const actionDefObj = actionsDef.find(
      (item) => item.action === action.action
    );
    const colorClass = main ? '' : actionDefObj?.action + 'Color';
    if (actionDefObj) {
      return (
        <>
          <actionDefObj.icon className={'actionMenu_item_icon ' + colorClass} />
          <div className={'actionMenu_item_label ' + colorClass}>
            {action.inProcess === true ? 'Guardando...' : action.title}
          </div>
        </>
      );
    }
  };

  const getMenuItem = (index) => {
    return (
      <MenuItem
        key={index}
        data-cy={`cy-${actions[index]?.action}`}
        onClick={() => handleAction(actions[index]?.callback)}
        className="actionMenu_item"
      >
        <ActionItem action={actions[index]} />
      </MenuItem>
    );
  };

  const composeMenuActions = () => {
    let options = [];
    if (allowedOptions !== undefined) {
      actions.forEach((action, index) => {
        let isAllowed = allowedOptions.filter((x) => x === action.action);
        if (isAllowed.length > 0) {
          options.push(getMenuItem(index));
        }
      });
    }
    return options;
  };

  return (
    <>
      {actions.length > 0 && (
        <>
          <div className="actionMenu_button">
            <div
              className="actionMenu_button_main"
              onClick={() => handleAction(actions[0].callback)}
            >
              <ActionItem action={actions[0]} main data-cy="cy-save" />
            </div>

            {actions.length > 1 ? (
              <div className="actionMenu_button_dropdown">
                <ArrowDropDownIcon
                  data-cy="cy-menu-options"
                  onClick={handleClick}
                />
              </div>
            ) : null}
          </div>

          {actions.length > 1 && (
            <Menu
              id="actions-menu"
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              transformOrigin={{
                vertical: -40,
                horizontal: -40
              }}
            >
              {composeMenuActions()}
            </Menu>
          )}
        </>
      )}
    </>
  );
};

const actionsDef = [
  { action: 'save', icon: SaveIcon },
  { action: 'print', icon: PrintIcon },
  { action: 'approve', icon: ApproveIcon },
  { action: 'disapprove', icon: UndoIcon },
  { action: 'delivered', icon: LocalShippingIcon },
  { action: 'closed', icon: ArchiveIcon },
  { action: 'reject', icon: BlockIcon }
];

const actionsArr = () => {
  return actionsDef.map(({ action }) => action);
};

ActionsMenu.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.oneOf(actionsArr()).isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.any,
      callback: PropTypes.func.isRequired
    }).isRequired
  )
};

export default ActionsMenu;
