
/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Import component section **/

/** Import helpers section **/
import { getStatesService } from '../services/location-services';
import {
	finishRequest,
	setCFDICatalog, setError, setExtraServicesCatalog,
	setPaymentTypesCatalog,
	setShipmentTypesCatalog,
	setStateCatalog,
	startRequest,
} from '../redux/catalogs/catalogsSlice';
import { getCFDIService, getPaymentTypesService } from '../services/paymen-cfdi-services';
import { getExtraServices, getShipmentTypeService } from '../services/catalogues-services';

/** Import resources section **/



const useCatalogs = () => {
	let dispatch = useDispatch();
	let hasCatalogs   = useSelector((store) => store.catalogs.has_catalogs);
	
	const getCatalogs = () => {
		if (!hasCatalogs) {
			dispatch(startRequest());
			
			getStatesService()
				.then((states) => {
					getPaymentTypesService()
						.then((payments) => {
							getCFDIService()
								.then((response) => {
									getShipmentTypeService()
										.then((shipments) => {
											getExtraServices()
												.then((services) => {
													dispatch(setStateCatalog({ catalog: states }));
													dispatch(
														setPaymentTypesCatalog({ catalog: payments })
													);
													dispatch(setCFDICatalog({ catalog: response }));
													dispatch(
														setShipmentTypesCatalog({ catalog: shipments })
													);
													dispatch(
														setExtraServicesCatalog({ catalog: services })
													);
													dispatch(finishRequest());
												})
												.catch((error) => {
													console.error('QEComp - Error: ', error);
												});
										})
										.catch((error) => {
											console.error('QEComp - Shipment types error: ', error);
										});
								})
								.catch((error) => {
									dispatch(setError(error));
								});
						})
						.catch((error) => {
							dispatch(setError(error));
						});
				})
				.catch((error) => {
					dispatch(setError(error));
				});
		}
	};
	
	
	useEffect(() => {
		getCatalogs();
	}, [ hasCatalogs, dispatch ]);
};

export default useCatalogs;
