/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

/** Import resources section **/
import { getClientsService } from '../../services/clients-services';
import {
  getContactsService,
  deleteContactService
} from '../../services/contacts-services';

/** Import helpers section **/

/** Import component section **/
import ModalComponent from '../../components/UI/ModalComponent';
import ContactsView from './ContactsView';
import ContactModal from './ContactModal';

export const contactModel = {
  id: null,
  title: '',
  name: '',
  contact: '',
  cp: null,
  rfc: '',
  email: '',
  phone: '',
  suburb: '',
  street: '',
  number: '',
  internal_number: '',
  reference_streets: '',
  city_id: null,
  municipality_id: null,
  neighborhood_id: null,
  state_id: null,
  client_id: null
};

const ContactsComponent = () => {
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [contacts, setContacts] = useState([]);

  const [textFilter, setTextFilter] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);

  const [selectedItem, setSelectedItem] = useState(null);
  const [modalMode, setModalMode] = useState('');

  const getClients = useCallback(() => {
    getClientsService()
      .then((response) => {
        setClients(response);
      })
      .catch((error) => {
        console.error('ContactsComp -> Error getting clients: ', error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getClients();
  }, [getClients]);

  const getContacts = useCallback(() => {
    setLoading(true);
    let params = {
      client_id: selectedClient?.id,
      search: ''
    };
    getContactsService(params)
      .then((response) => {
        setContacts(response);
        setOriginalData(response);
      })
      .catch((error) => {
        console.error('ContactsComp -> Error getting contacts: ', error);
      })
      .finally(() => setLoading(false));
  }, [selectedClient?.id]);

  useEffect(() => {
    if (selectedClient) {
      getContacts();
    } else {
      setContacts([]);
    }
  }, [selectedClient, getContacts]);

  const handleOnClientChange = (value) => {
    setSelectedClient(value);
  };

  const filterContacts = useCallback(() => {
    let filteredItems = [...originalData];
    const filteringText = textFilter.toLowerCase();

    if (filteringText.length > 0) {
      filteredItems = filteredItems.filter((item) => {
        const field1 = item.client_number?.toString().toLowerCase();
        const field2 = item.name?.toString().toLowerCase();
        const field3 = item.rfc?.toString().toLowerCase();

        return (
          field1?.includes(filteringText) ||
          field2?.includes(filteringText) ||
          (field3?.includes(filteringText) && true)
        );
      });
    }

    setContacts(filteredItems);
  }, [originalData, textFilter]);

  useEffect(() => {
    filterContacts();
  }, [textFilter, filterContacts]);

  const handleOnFilterBy = (filterStr) => {
    setTextFilter(filterStr.trim());
  };

  const handleOnAdd = () => {
    const editItem = JSON.parse(JSON.stringify(contactModel));
    editItem.client_id = selectedClient?.id;
    setSelectedItem(editItem);
    setModalMode('contact');
  };

  const handleOnEdit = (item) => {
    const editItem = JSON.parse(JSON.stringify(item));
    editItem.client_id = selectedClient?.id;
    setSelectedItem(editItem);
    setModalMode('contact');
  };

  const handleOnDelete = (row) => {
    const deleteItem = {
      id: row.id,
      name: row.name
    };
    setSelectedItem(deleteItem);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    console.log(selectedItem);
    deleteContactService(selectedItem?.id)
      .then(() => {
        toast.success('Contacto eliminado correctamente');
        handleOnRefreshList();
      })
      .catch((error) => {
        toast.error('Error al eliminar contacto');
      })
      .finally(() => {});
  };

  const handleOnModalClose = () => {
    clearStates();
  };

  const handleOnRefreshList = () => {
    clearStates();
    getContacts();
  };

  const clearStates = () => {
    setSelectedItem(null);
    setModalMode('');
  };

  return (
    <>
      <ContactsView
        loading={loading}
        contacts={contacts}
        clients={clients}
        selectedClient={selectedClient}
        onClientChange={handleOnClientChange}
        onFilterBy={handleOnFilterBy}
        onAdd={handleOnAdd}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
      />

      {selectedItem && modalMode === 'contact' && (
        <ContactModal
          item={selectedItem}
          onClose={handleOnModalClose}
          onConfirm={handleOnRefreshList}
        />
      )}
      {selectedItem && modalMode === 'delete' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar contacto "
        >
          {'¿Estas seguro de borrar ' + selectedItem.name + '?'}
        </ModalComponent>
      )}
    </>
  );
};

export default ContactsComponent;
