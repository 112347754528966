/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import './CPACModalStyles.scss';

/** Import resources section **/
import FormControlLabel from '@material-ui/core/FormControlLabel';

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUICheckBox from '../../../components/UI/inputs/MUICheckBox';
import MUITextField from '../../../components/UI/inputs/MUITextField';

export const CPACModalView = ({
  item,
  isLoaded,
  clientCPAC,
  onInputChange,
  onClose,
  onSave
}) => {
  return (
    <ModalComponent
      open={true}
      title={'Editar CPAC para cliente ' + item?.name}
      onClose={onClose}
      onConfirm={onSave}
    >
      <div className="cpac">
        {!isLoaded && <>Loading..</>}
        {clientCPAC &&
          clientCPAC.map((cpac) => (
            <>
              <FormControlLabel
                key={cpac.shipment_type_id}
                label={cpac.shipment_type}
                control={
                  <MUICheckBox
                    checked={cpac.checked}
                    name={cpac.shipment_type}
                    onChange={(checked) =>
                      onInputChange(cpac.shipment_type_id, 'checked', checked)
                    }
                  />
                }
              />
              <MUITextField
                name={'cpac_' + cpac?.shipment_type_id}
                value={cpac?.percentage}
                type="number"
                disabled={!cpac.checked}
                onChange={(data) =>
                  onInputChange(
                    cpac.shipment_type_id,
                    'percentage',
                    data?.value
                  )
                }
              />
            </>
          ))}
      </div>
    </ModalComponent>
  );
};

export default CPACModalView;
