/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUITextField from '../../../components/UI/inputs/MUITextField';

/** Import resources section **/
import './ProductPriceModalStyles.scss';

const ProductPriceModalView = ({
  onClose,
  onSaveUpdate,
  productPrice,
  onInputChange
}) => {
  const composeServices = () => {
    let services = [];
    let shipmentTypes = productPrice.shipment_type_prices;
    for (let service in shipmentTypes) {
      if (shipmentTypes.hasOwnProperty(service))
        services.push(
          <div className="product_price_modal-service">
            <p>
              {service.charAt(0).toUpperCase() +
                service.slice(1).replace('_', ' ')}{' '}
            </p>

            <MUITextField
              id="cost"
              label="Costo"
              value={shipmentTypes[service].cost}
              type="number"
              name="cost"
              onChange={(event) => onInputChange(event, service)}
              disabled={shipmentTypes[service].min_price === 'NA'}
            />

            <MUITextField
              id="minPrice"
              label="Precio mínimo"
              value={shipmentTypes[service].min_price}
              type="number"
              name="min_price"
              onChange={(event) => onInputChange(event, service)}
              disabled={shipmentTypes[service].min_price === 'NA'}
            />

            <MUITextField
              id="pubPrice"
              label="Precio al público"
              value={shipmentTypes[service].public_price}
              type="number"
              name="public_price"
              onChange={(event) => onInputChange(event, service)}
              disabled={shipmentTypes[service].min_price === 'NA'}
            />

            <MUITextField
              id="weight"
              label="Precio Kg. Excedente"
              value={shipmentTypes[service].extra_kilogram_price}
              type="number"
              name="extra_kilogram_price"
              onChange={(event) => onInputChange(event, service)}
              disabled={shipmentTypes[service].min_price === 'NA'}
            />
          </div>
        );
    }
    return services;
  };

  return (
    <ModalComponent
      open={true}
      title="Edición de  precios"
      onClose={onClose}
      onConfirm={onSaveUpdate}
    >
      <div className="product_price_modal">
        <div className="product_price_modal-meta">
          <h4>
            Ruta: {productPrice.origin_municipality_name} -{' '}
            {productPrice.destination_municipality_name}
          </h4>
          <p>Empaque: {productPrice.product_name}</p>
        </div>

        {composeServices()}
      </div>
    </ModalComponent>
  );
};

export default ProductPriceModalView;
