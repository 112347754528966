/**
 * Messenger details model
 * @type {function(*): {stackable: number, move: number, shipment_type_id: *, quantity: null, comments: string, weight: null, shipment_id: *, product_name: string, shipment_route_id: null|*, long: null, content: string, unit: null, volumetric_weight: null, container_category_id: null|number|*, price: null, width: null, exists: boolean, unit_id: null, container_id: number|null|*, height: null}}
 */
export const packageModel = (deliveryTypeData) => {
  console.log(deliveryTypeData);
  let container =
    deliveryTypeData.length > 0
      ? JSON.parse(JSON.stringify(deliveryTypeData.containers[0]))
      : null;
  return {
    move: 1,
    long: 0,
    unit: 0,
    width: 0,
    price: 0,
    height: 0,
    weight: 0,
    quantity: 0,
    unit_id: null,
    exists: false,
    content: '',
    fragile: 0,
    comments: '',
    stackable: 0,
    product_name: '',
    shipment_type_id: deliveryTypeData?.shipment_type_id,
    volumetric_weight: null,
    shipment_id: deliveryTypeData?.shipment_type_id,
    container_id: container?.container_id,
    shipment_route_id: container?.shipment_route_id,
    container_category_id: container?.container_category_id
  };
};
