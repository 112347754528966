/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const SearchInputView = ({
  open,
  loading,
  setOpen,
  options,
  handleInputChange,
  onOptionSelected,
  optionLabel,
  selectorWidth
}) => {
  return (
    <div>
      <Autocomplete
        id="search-autocomplete"
        open={open}
        style={{ width: selectorWidth + 'px' }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        data-cy="cy-search-autocomplete"
        options={options}
        loading={loading}
        onChange={(option, value) => onOptionSelected(option, value)}
        getOptionLabel={(option) => option[optionLabel]}
        getOptionSelected={(option, value) => option.search === value.search}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            onChange={(event) => {
              handleInputChange(event);
            }}
            InputProps={{ ...params.InputProps }}
          />
        )}
      />
    </div>
  );
};

export default SearchInputView;
