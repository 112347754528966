import ApiService from './api-service';

export const getStatesService = () => {
  const serviceDef = {
    url: 'states',
    method: 'get',
    params: null
  };
  return ApiService.request(serviceDef);
};

export const getMunicipalitiesByStateService = (stateId) => {
  const serviceDef = {
    url: 'municipalities',
    method: 'post',
    params: { state_id: stateId }
  };
  return ApiService.request(serviceDef);
};

export const getCitiesByStateService = (stateId) => {
  const serviceDef = {
    url: 'cities',
    method: 'post',
    params: { state_id: stateId }
  };
  return ApiService.request(serviceDef);
};

export const getCitiesByMunicipalitiesService = (municipalityId) => {
  const serviceDef = {
    url: 'cities',
    method: 'post',
    params: { municipality_id: municipalityId }
  };
  return ApiService.request(serviceDef);
};

export const getOriginDestinationService = (originCityId, destCityId) => {
  const serviceDef = {
    url: 'origin-destination',
    method: 'post',
    params: {
      origin_city_id: originCityId,
      destination_city_id: destCityId
    }
  };
  return ApiService.request(serviceDef);
};

export const requestLocationDataService = (cp) => {
  const serviceDef = {
    url: 'postal-codes/get-cities',
    method: 'post',
    params: {
      postal_code: cp
    }
  };
  return ApiService.request(serviceDef);
};

export const locationDataService = ({ cp }) => {
  const serviceDef = {
    url: 'postal-codes/location',
    method: 'post',
    params: {
      postal_code: cp
    }
  };
  return ApiService.request(serviceDef);
};
