/** Import react/libraries section **/
import React from 'react';

/** Import resources **/
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

/** Import styles section **/
import './GoodsStyles.scss';

/** Import component section **/
import PageTitle from '../../../../components/PageTitle';
import MUIDataTable from '../../../../components/MUIDataTable';
import AddButton from '../../../../components/UI/AddButton';

const GoodsView = ({
  goods,
  loading,
  onAddConsigmentNote,
  onEditConsigmentNote,
  onDeleteConsigmentNote,
  consigmentNoteSent
}) => {
  const ActiveCell = ({ row }) => {
    if (row.dangerous_material)
      return (
        <CheckCircleOutlineIcon className="consigmentNoteHeader_activeicon consigmentNoteHeader_activeicon-active" />
      );
    return <NotInterestedIcon className="consigmentNoteHeader_activeicon" />;
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          data-cy="edit-button"
          className="g-row-action-button"
          onClick={() => onEditConsigmentNote(row)}
        />
        <DeleteIcon
          data-cy="delete-button"
          className="g-row-action-button delete"
          onClick={() => onDeleteConsigmentNote(row)}
        />
      </>
    );
  };
  const tableColumns = [
    {
      name: 'Cantidad',
      selector: 'quantity',
      sortable: true,
      center: true
    },
    {
      name: 'Clave de bienes',
      selector: 'code',
      sortable: true,
      center: true
    },
    {
      name: 'Clave de unidad',
      selector: 'code',
      sortable: true,
      center: true
    },
    {
      name: 'Peso',
      selector: 'weigth',
      sortable: true,
      center: true
    },
    {
      name: 'Valor',
      selector: 'value',
      sortable: true,
      center: true
    },
    {
      name: 'Moneda',
      selector: 'currency',
      sortable: true,
      center: true
    },
    {
      name: 'Clave de unidad',
      selector: 'unit_code',
      sortable: true,
      center: true
    },
    {
      name: 'Material peligroso',
      selector: 'dangerous_material',
      cell: (row) => <ActiveCell row={row} />,
      sortable: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    }
  ];
  return (
    <>
      <PageTitle title="Bienes transportados" />
      <Paper className="g-page-header goods_header">
        <AddButton
          onClick={onAddConsigmentNote}
          text="Agregar Item"
          disabled={consigmentNoteSent}
        />
      </Paper>
      <MUIDataTable data={goods} columns={tableColumns} loading={loading} />
    </>
  );
};
export default GoodsView;
