/** Import react/libraries section **/
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/** Import component section **/
import MUIDataTable from '../../components/MUIDataTable';
import PageTitle from './../../components/PageTitle';
import FilterBy from '../../components/UI/FilterBy';
import HowToRegIcon from '@material-ui/icons/HowToReg';

/** Import helpers section **/
/** Import resources section **/
import './RequestsStyles.scss';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUIDateField from '../../components/UI/inputs/MUIDateField';
import EditIcon from '@material-ui/icons/Edit';
import {
  ADMINISTRADOR,
  ASESOR_DE_TRAFICO,
  DIRECTOR_FINANZAS,
  DIRECTOR_GENERAL,
  EJECUTIVO_DE_VENTAS,
  GERENTE_DE_VENTA
} from '../../access/ROLES';
import { Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const RequestsView = ({
  roleId,
  loading,
  showClear,
  requests,
  onClear,
  dateRange,
  showPendent,
  onAddRequest,
  showAssigned,
  onSwitchChange,
  onFilterRequest,
  onAssignRequest,
  handleDateChange,
  onDeleteRequest,
  handleOnEditQuote
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <Fragment>
        <EditIcon
          className="g-row-action-button"
          onClick={() => handleOnEditQuote(row.id)}
        />
        {roleId === EJECUTIVO_DE_VENTAS ||
        roleId === ASESOR_DE_TRAFICO ||
        roleId === DIRECTOR_GENERAL ||
        roleId === GERENTE_DE_VENTA ||
        roleId === ADMINISTRADOR ? (
          <HowToRegIcon
            data-cy="assign-button"
            className="g-row-action-button"
            onClick={() => onAssignRequest(row.id)}
          />
        ) : null}

        {roleId === DIRECTOR_FINANZAS ||
        roleId === DIRECTOR_GENERAL ||
        roleId === ADMINISTRADOR ? (
          <DeleteIcon
            data-cy="delete-button"
            className="g-row-action-button delete"
            onClick={() => onDeleteRequest(row.id)}
          />
        ) : null}
      </Fragment>
    );
  };

  const CityCell = ({ stateName, cityName }) => {
    return (
      <div className="routes-city-wrapper">
        <div className="routes-city">{cityName}</div>
        <div className="routes-state">{stateName}</div>
      </div>
    );
  };

  const tableColumns = [
    // {
    //   name: 'Nombre',
    //   selector: 'name',
    //   sortable: true,
    //   center: true
    // },
    {
      name: 'Asignado',
      selector: 'assigned',
      sortable: true,
      center: true
    },
    {
      name: 'Fecha creación',
      selector: 'created_at',
      sortable: true,
      center: true,
      wrap: true
    },
    {
      name: 'Ciudad origen',
      cell: (row) => (
        <CityCell
          stateName={row?.origin_state_name}
          cityName={row?.origin_city_name}
        />
      ),
      sortable: true,
      ignoreRowClick: true,
      allowOverflow: true,
      left: true
    },
    {
      name: 'Ciudad destino',
      cell: (row) => (
        <CityCell
          stateName={row?.destination_state_name}
          cityName={row?.destination_city_name}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      left: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    }
  ];

  return (
    <div className="requests">
      <PageTitle title="Listado de solicitudes" />
      <Paper className="g-page-header requests_header">
        <div className="requests_header_filters">
          <div className="requests_header_filters_switch">
            <FormControlLabel
              label=""
              control={<FilterBy onChange={onFilterRequest} />}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={showAssigned}
                  onChange={onSwitchChange}
                  name="show-assigned"
                  color="primary"
                  inputProps={{ 'aria-label': 'filtrar asignadas' }}
                />
              }
              label="Asignadas"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={showPendent}
                  onChange={onSwitchChange}
                  name="show-pendents"
                  color="primary"
                  inputProps={{ 'aria-label': '' }}
                />
              }
              label="Pendientes"
            />

            <p>Del</p>

            <FormControlLabel
              control={
                <MUIDateField
                  onChange={handleDateChange}
                  name="start"
                  value={dateRange?.start}
                />
              }
              label=" Al "
            />

            <FormControlLabel
              control={
                <MUIDateField
                  onChange={handleDateChange}
                  name="end"
                  value={dateRange?.end}
                />
              }
              label=""
            />

            {showClear === true ? (
              <Tooltip title="Limpiar filtros">
                <ClearIcon onClick={(e) => onClear(e)} />
              </Tooltip>
            ) : null}
          </div>
        </div>

        {/*<AddButton onClick={onAddRequest}/>*/}
      </Paper>

      <MUIDataTable data={requests} columns={tableColumns} loading={loading} />
    </div>
  );
};

RequestsView.propTypes = {
  requests: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  showClear: PropTypes.bool.isRequired,
  showPendent: PropTypes.bool.isRequired,
  onAddRequest: PropTypes.func.isRequired,
  showAssigned: PropTypes.bool.isRequired,
  onSwitchChange: PropTypes.func.isRequired,
  onFilterRequest: PropTypes.func.isRequired,
  onDeleteRequest: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleOnEditQuote: PropTypes.func.isRequired
};

export default RequestsView;
