/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import component section **/
import ShipmentBillModalView from './ShipmentBillModalView';

/** Import helpers section **/

/** Import resources section **/

const ShipmentBillModalComponent = ({ data, onClose }) => {
	const [billData, setBillData] = useState(data);

	useEffect(() => {
		setBillData(data);
	}, [data]);

	return <ShipmentBillModalView data={billData} onClose={onClose} />;
};

export default ShipmentBillModalComponent;
