/** Import react/libraries section **/
import React, {Fragment} from 'react';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import MUIDataTable from '../../components/MUIDataTable';
import FilterBy from '../../components/UI/FilterBy';
import AddButton from '../../components/UI/AddButton';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import './ContainersStyles.scss';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {CONTAINERS_TYPES_ROUTE} from '../../URL-Routes';

const ContainersView = ({
  title,
  containers,
  loading,
  onFilterBy,
  onAdd,
  onEdit,
  onDelete
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          data-cy="edit-button"
          className="g-row-action-button"
          onClick={() => onEdit(row)}
        />
        <DeleteIcon
          data-cy="delete-button"
          className="g-row-action-button delete"
          onClick={() => onDelete(row)}
        />
      </>
    );
  };

  const tableColumns = [
    {
      name: 'Empaque',
      selector: 'container_category_name',
      sortable: true,
      center: true
    },
    {
      name: 'Título',
      selector: 'title',
      sortable: true,
      center: true
    },
    {
      name: 'Peso mínimo (kg)',
      selector: 'min_weight',
      sortable: true,
      center: true
    },
    {
      name: 'Peso máximo (kg)',
      selector: 'max_weight',
      sortable: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    }
  ];

  return (
    <Fragment>
      <PageTitle
        title={'Productos del empaque ' + title}
        backUrl={CONTAINERS_TYPES_ROUTE}
      />
      <Paper className="g-page-header containers-header">
        <div className="containers-header-filterby">
          <FilterBy onChange={onFilterBy} />
        </div>
        <AddButton onClick={onAdd} />
      </Paper>

      <MUIDataTable
        data={containers}
        columns={tableColumns}
        loading={loading}
      />
    </Fragment>
  );
};

export default ContainersView;
