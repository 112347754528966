/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import component section **/
import FilterBy from '../../components/UI/FilterBy';
import PageTitle from './../../components/PageTitle';
import AddButton from '../../components/UI/AddButton';
import MUIDataTable from '../../components/MUIDataTable';

/** Import helpers section **/
/** Import resources section **/
import './RoutesStyles.scss';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import { Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

/**
 * Routes list component
 * @param data -> Routes
 * @param loading
 * @param onFilterBy
 * @param onAddRoute
 * @param onDeleteRoute
 * @param destination
 * @param showClear
 * @param onClear
 * @returns {JSX.Element}
 * @constructor
 */
const RoutesView = ({
  data,
  loading,
  onFilterBy,
  onAddRoute,
  onDeleteRoute,
  // destination,
  // origin,
  // onSelectOption,
  showClear,
  onClear
  // originOpt,
  // destOpt,
}) => {
  const CityCell = ({ stateName, cityName }) => {
    return (
      <div className="routes-city-wrapper">
        <div className="routes-city">{cityName}</div>
        <div className="routes-state">{stateName}</div>
      </div>
    );
  };

  const ActionsCell = ({ row }) => {
    return (
      <DeleteIcon
        className="g-row-action-button delete"
        onClick={() => onDeleteRoute(row)}
      />
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Municipio origen',
        cell: (row) => (
          <CityCell
            stateName={row?.origin_state_name}
            cityName={row?.origin_municipality_name}
          />
        ),
        sortable: true,
        ignoreRowClick: true,
        allowOverflow: true,
        left: true
      },
      {
        name: 'Municipio destino',
        cell: (row) => (
          <CityCell
            stateName={row?.destination_state_name}
            cityName={row?.destination_municipality_name}
          />
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        left: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <div className="routes">
      <PageTitle title="Rutas" />
      <Paper className="g-page-header routes-header">
        <div className="requests_header_filters">
          <FilterBy onChange={onFilterBy} />

          {/*<MUISelect*/}
          {/*	label="Origen"*/}
          {/*	name="origin"*/}
          {/*	items={origin}*/}
          {/*	idSelector="id"*/}
          {/*	selector="name"*/}
          {/*	noSelectedLabel="Todos"*/}
          {/*	onChange={onSelectOption}*/}
          {/*	defaultValue={originOpt}*/}
          {/*/>*/}

          {/*<MUISelect*/}
          {/*	label="Origen"*/}
          {/*	name="destination"*/}
          {/*	items={destination}*/}
          {/*	idSelector="id"*/}
          {/*	selector="name"*/}
          {/*	noSelectedLabel="Todos"*/}
          {/*	onChange={onSelectOption}*/}
          {/*	defaultValue={destOpt}*/}
          {/*/>*/}

          {showClear === true ? (
            <Tooltip title="Limpiar filtros">
              <ClearIcon
                className="requests_header_filters-fab"
                onClick={(e) => onClear(e)}
              />
            </Tooltip>
          ) : null}
        </div>

        <AddButton onClick={onAddRoute} />
      </Paper>
      <MUIDataTable data={data} columns={tableColumns} loading={loading} />
    </div>
  );
};

export default RoutesView;
