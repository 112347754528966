/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

/** Import resources section **/
import './ShipmentTypesSelect.scss';

/** Import resources section **/
import FormControlLabel from '@material-ui/core/FormControlLabel';

/** Import component section **/
import MUICheckBox from '../UI/inputs/MUICheckBox';

export const ShipmentTypesSelect = ({
  label,
  selected = [],
  onChange,
  onlyConfigurables = false
}) => {
  const [shipmentTypes, setShipmentTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  let shipmentTypesCat = useSelector((store) => store.catalogs?.shipmentTypes);

  useEffect(() => {
    setSelectedTypes(selected);
  }, []);

  useEffect(() => {
    if (shipmentTypesCat !== null && onlyConfigurables) {
      shipmentTypesCat = shipmentTypesCat.filter((st) => st.configurable === 1);
    }
    setShipmentTypes(shipmentTypesCat);
  }, [shipmentTypesCat]);

  const handleOnCheck = (checked, type) => {
    let newSelectedTypes = JSON.parse(JSON.stringify(selectedTypes));
    const found = newSelectedTypes.find((st) => st.id === type.id);
    if (checked && !found) newSelectedTypes.push({ id: type.id });
    if (!checked && found)
      newSelectedTypes = newSelectedTypes.filter((st) => st.id !== type.id);
    setSelectedTypes(newSelectedTypes);
    if (onChange) onChange(newSelectedTypes);
  };

  return (
    <>
      {shipmentTypes && (
        <div className="shipmenttypes">
          {label && <div className="shipmenttypes_title">{label}</div>}
          <div className="shipmenttypes_select">
            {shipmentTypes.map((type, index) => (
              <FormControlLabel
                label={type.title}
                key={index}
                control={
                  <MUICheckBox
                    checked={
                      selectedTypes.filter((st) => st.id === type.id).length > 0
                    }
                    name={'shipment_type_' + type.title}
                    data-cy={`cy-${type.title}`}
                    onChange={(checked) => handleOnCheck(checked, type)}
                  />
                }
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

ShipmentTypesSelect.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  ),
  onChange: PropTypes.func,
  onlyConfigurables: PropTypes.bool
};

export default ShipmentTypesSelect;
