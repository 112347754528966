/* eslint-disable react-hooks/exhaustive-deps */

/** Import react/libraries section **/
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';

/** Import component section **/
import DedicatedPricesView from './DedicatedPricesView';
import DedicatedPriceModalComponent from './DedicatedPriceModal';
import ModalComponent from '../../components/UI/ModalComponent';

/** Import helpers section **/
import {deleteDedicatedPriceService, getDedicatedPricesService} from '../../services/prices-services';
import {showErrorMessages} from '../../utils/utils';

/** Import resources section **/


const DedicatedCostsComponent = () => {
	const [selectedRoute, setSelectedRoute] = useState( null );
	const [loadingData, setLoadingData] = useState( false );
	const [prices, setPrices] = useState( [] );
	const [focusedItem, setFocusedItem] = useState( null );
	const [modalMode, setModalMode] = useState( null );
	
	const getData = () => {
		setLoadingData( true );
		const routeId = selectedRoute ? selectedRoute : null;
		getDedicatedPricesService( routeId ).then( (response) => {
			setPrices( response );
			setLoadingData( false );
		} ).catch( error => {
			console.error( 'DedicatedPricesComponent - Error getting dedicated prices: ', error );
		} );
	};
	
	useEffect( () => {
		if (selectedRoute) {
			getData();
		}
	}, [selectedRoute] );
	
	const updatePricesList = (routeId) => {
		setSelectedRoute( routeId );
		if (routeId === selectedRoute)
			getData();
	};
	
	const handleOnEdit = (item) => {
		const itemData = {
			id: item.id,
			cost: item.cost,
			unit_id: item.unit_id,
			min_price: item.min_price,
			public_price: item.public_price,
			origin_destination_id: item.origin_destination_id,
		};
		setModalMode( 'edit' );
		setFocusedItem( itemData );
	};
	
	const handleOnAdd = () => {
		setModalMode( 'add' );
	};
	
	const handleOnDelete = (item) => {
		setFocusedItem( item );
		setModalMode( 'delete' );
	};
	
	const handleOnDeleteConfirm = () => {
		setLoadingData( false );
		deleteDedicatedPriceService( {id: focusedItem.id} ).then( () => {
			getData();
			toast( 'Precio eliminado correctamnete', {type: 'success'} );
		} ).catch( error => {
			toast( 'Error al eliminar precio', {type: 'error'} );
			showErrorMessages( error );
			console.error( 'DedicatedPriceComp - Error al eliminar precio: ', error );
		} ).finally( () => {
			setFocusedItem( null );
			setModalMode( null );
		} );
	};
	
	const handleOnRouteSelect = (routeId) => {
		setSelectedRoute( routeId );
	};
	
	const onModalClose = () => {
		setModalMode( null );
		setFocusedItem( null );
		setModalMode( '' );
	};
	
	return (
		<>
			<DedicatedPricesView
				data={prices}
				onAdd={handleOnAdd}
				onEdit={handleOnEdit}
				loading={loadingData}
				onDelete={handleOnDelete}
				selectedRoute={selectedRoute}
				onRouteSelect={handleOnRouteSelect}
			/>
			{
				modalMode === 'add' || modalMode === 'edit' ?
					<DedicatedPriceModalComponent
						item={focusedItem}
						onClose={onModalClose}
						modalMode={modalMode}
						selectedRoute={selectedRoute}
						updatePricesList={updatePricesList}
					/> : null
			}
			
			{focusedItem && modalMode === 'delete' && (
				<ModalComponent
					open={true}
					onClose={onModalClose}
					onConfirm={handleOnDeleteConfirm}
					title="Borrar precio "
				>
					{'Estas seguro de borrar el precio seleccionado?'}
				</ModalComponent>
			)}
		</>
	);
};

export default DedicatedCostsComponent;
