/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUITextField from '../../../components/UI/inputs/MUITextField';
import ShipmentTypesSelect from '../../../components/ShipmentTypesSelect';

/** Import resources section **/
import './ProviderModalStyles.scss';
import MUIAmountField from '../../../components/UI/inputs/MUIAmountField';

const ProviderModalView = ({
  provider,
  selectedTypes,
  onInputChange,
  onShipmentTypesChange,
  onClose,
  onSave
}) => {
  return (
    <ModalComponent
      open={true}
      title={(provider?.id ? 'Edición de' : 'Nuevo') + ' proveedor'}
      onClose={onClose}
      onConfirm={onSave}
    >
      <div className="providersmodal">
        <div className="providersmodal_fields">
          <MUITextField
            id="provider_number"
            name="provider_number"
            label="Número"
            value={provider?.provider_number}
            type="text"
            onChange={onInputChange}
          />
          <MUITextField
            id="name"
            name="name"
            label="Nombre"
            value={provider?.name}
            type="text"
            onChange={onInputChange}
          />
          <MUITextField
            id="rfc"
            name="rfc"
            label="RFC"
            value={provider?.rfc}
            type="text"
            onChange={onInputChange}
          />
          <MUITextField
            id="contact_name"
            name="contact_name"
            label="Contacto"
            value={provider?.contact_name}
            type="text"
            onChange={onInputChange}
          />
          <MUITextField
            id="email"
            name="email"
            label="Correo electrónico"
            value={provider?.email}
            type="text"
            onChange={onInputChange}
          />
          <MUITextField
            id="phone"
            name="phone"
            label="Teléfono"
            value={provider?.phone}
            type="text"
            onChange={onInputChange}
          />
          <MUITextField
            id="address"
            name="address"
            label="Dirección"
            value={provider?.address}
            type="text"
            onChange={onInputChange}
          />
          <MUIAmountField
            id="profit_percentage"
            name="profit_percentage"
            label="% de utilidad"
            defaultValue={provider?.profit_percentage}
            onChange={onInputChange}
            suffix=" % "
          />
        </div>
      </div>
      <div className="containertype-modal_shipmentstypes">
        <ShipmentTypesSelect
          label="Servicios a los que aplica:"
          selected={selectedTypes}
          onChange={onShipmentTypesChange}
        />
      </div>
    </ModalComponent>
  );
};

export default ProviderModalView;
