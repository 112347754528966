/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUISelect from '../../../components/UI/inputs/MUISelect';

/** Import resources section **/
import './AssignModalStyles.scss';

const AssignModalView = ({
  users,
  selectedUser,
  onUserChange,
  onClose,
  onConfirm
}) => {
  return (
    <ModalComponent
      open={true}
      title="Asignar asesor"
      onClose={onClose}
      onConfirm={onConfirm}
      disabled={!selectedUser}
    >
      <div className="assignmodal">
        <MUISelect
          name="user_id"
          label="Asesor"
          items={users}
          value={selectedUser}
          idSelector="id"
          selector="name"
          noSelectedLabel="Seleccione"
          onChange={(event) => onUserChange(event.value)}
          dataCy="cy-user"
        />
      </div>
    </ModalComponent>
  );
};

export default AssignModalView;
