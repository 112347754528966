/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';

import RoutesView from './RoutesView';
import RouteAddComponent from './RouteAddModal';
import ModalComponent from '../../components/UI/ModalComponent';

import {
  addRouteService,
  deleteRouteService,
  getRoutesService
} from '../../services/routes-services';
import { toast } from 'react-toastify';

const RoutesComponent = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [routesData, setRoutesData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);

  const [origin, setOrigin] = useState([]);
  const [destination, setDestination] = useState([]);
  const [showClear, setShowClear] = useState(false);
  const [originOpt, setOriginOpt] = useState('');
  const [destOpt, setDestOpt] = useState('');

  const getData = useCallback(() => {
    setLoadingData(true);
    getRoutesService()
      .then((response) => {
        setOriginalData(response);
        setRoutesData(response);
        setLoadingData(false);
        separateOriginDestination(response);
      })
      .catch((error) => {
        console.error('RoutesComponent - Error getting routes: ', error);
        setLoadingData(false);
      });
  }, []);

  const separateOriginDestination = (routes) => {
    let origin = [];
    let destination = [];

    routes.forEach((route) => {
      let originName = route.origin_city_name + ' - ' + route.origin_state_name;
      let destinationName =
        route.destination_city_name + ' - ' + route.destination_state_name;
      // console.debug('RoutesView ---> ', originName);

      origin.push({
        id: route.id,
        name: originName
      });
      destination.push({
        id: route.id,
        name: destinationName
      });
    });
    setOrigin(origin);
    setDestination(destination);
  };

  const onClear = (event) => {
    event.preventDefault();
    setShowClear(false);
    setOriginOpt('');
    setDestOpt('');
  };

  useEffect(() => getData(), []);

  const onSelectOption = (option) => {
    setShowClear(true);
    if (option.name === 'origin') setOriginOpt(option.value);

    if (option.name === 'destination') setDestOpt(option.value);

    handleOnFilterBy(option.value.toString());
  };

  const handleOnAddRoute = () => {
    setSelectedItem({});
  };

  const handleOnAddRouteClose = () => {
    setSelectedItem(null);
  };

  const handleOnAddRouteSave = (data) => {
    const addRouteObj = {
      originMunicipalityId: data.origin_municipality,
      destinationMunicipalityId: data.destination_municipality
    };
    addRouteService(addRouteObj)
      .then(() => {
        getData();
        setLoadingData(false);
        toast('Ruta agregada correctamente', {
          type: 'success',
          autoClose: true
        });
      })
      .catch(() => {
        toast('No se pudo agregar correctamente', {
          type: 'error',
          autoClose: true
        });
      })
      .finally(() => {
        setLoadingData(false);
      });
    setSelectedItem(null);
  };

  const handleOnDeleteRoute = (item) => {
    setSelectedItem(item);
    setDeleteAlertOpen(true);
  };

  const handleOnDeleteClose = () => {
    setSelectedItem(null);
    setDeleteAlertOpen(false);
  };

  const handleOnDeleteConfirm = () => {
    const deleteRouteObj = {
      routeId: selectedItem.id
    };

    deleteRouteService(deleteRouteObj)
      .then(() => {
        getData();
        toast('Ruta eliminada', { type: 'success', autoClose: true });
        setLoadingData(false);
      })
      .catch((error) => {
        toast('No se pudo eliminar', {
          type: 'success',
          autoClose: true
        });
        console.error('RoutesComp -> Erro deleting route: ', error);
      });

    setSelectedItem(null);
    setDeleteAlertOpen(false);
  };

  const handleOnFilterBy = (filterText) => {
    let filteredItems;

    if (filterText.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterText.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item.origin_state_name.toString().toLowerCase();
        const field2 = item.origin_municipality_name.toString().toLowerCase();
        const field3 = item.destination_state_name.toString().toLowerCase();
        const field4 = item.destination_municipality_name
          .toString()
          .toLowerCase();
        const field5 = item.id.toString();

        return (
          field1.includes(filteringText) ||
          field2.includes(filteringText) ||
          field3.includes(filteringText) ||
          field4.includes(filteringText) ||
          (field5.includes(filteringText) && true)
        );
      });
    }

    setRoutesData(filteredItems);
  };

  return (
    <>
      <RoutesView
        data={routesData}
        origin={origin}
        loading={loadingData}
        showClear={showClear}
        onClear={onClear}
        onFilterBy={handleOnFilterBy}
        onAddRoute={handleOnAddRoute}
        destination={destination}
        onDeleteRoute={handleOnDeleteRoute}
        onSelectOption={onSelectOption}
        originOpt={originOpt}
        destOpt={destOpt}
      />

      {selectedItem && !deleteAlertOpen && (
        <RouteAddComponent
          onClose={handleOnAddRouteClose}
          onSave={handleOnAddRouteSave}
          element={selectedItem}
        />
      )}
      {selectedItem && deleteAlertOpen && (
        <ModalComponent
          open={deleteAlertOpen}
          onClose={handleOnDeleteClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar ruta "
        >
          {'Estas seguro de borrar la ruta ' +
            selectedItem.origin_municipality_name +
            ' a ' +
            selectedItem.destination_municipality_name +
            '?'}
        </ModalComponent>
      )}
    </>
  );
};

export default RoutesComponent;
