/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Import component section **/
import BillingFormView from './BillingFormView';

/** Import helpers section **/
import {
  setBilling,
  updateBilling,
  updateClient
} from '../../../redux/quotation/quoteSlice';
import { billingModel } from '../../../models/quoteModels';
import { getCitiesByStateService } from '../../../services/location-services';

export const BillingFormComponent = () => {
  let dispatch = useDispatch();

  let client = useSelector((store) => store.quote.quotation.client);
  let billing = useSelector((store) => store.quote.quotation.billing);

  let cfdi = useSelector((store) => store.catalogs.cfdi);
  let states = useSelector((store) => store.catalogs.states);
  let paymentTypes = useSelector((store) => store.catalogs.payment_type);

  const [stateId, setStateId] = useState(null);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (billing?.state_id !== null) {
      setStateId(billing.state_id);
    }
  }, []);

  useEffect(() => {
    if (stateId !== null) {
      getCitiesByStateId(stateId);
    }
  }, [stateId]);

  const getCitiesByStateId = (stateID) => {
    getCitiesByStateService(stateID)
      .then((response) => {
        setCities(response);
      })
      .catch((error) => {
        console.debug('BillingFormComp -> Error getting cities: ', error);
      });
  };

  /**
   * Billing text fields change handler
   * @param data
   */
  const handleOnBillingChanges = (data) => {
    const { name, value } = data;
    dispatch(updateBilling({ field: name, value: value }));
  };

  /**
   * Handler for require billing radio button.
   * @param event
   */
  const handleOnRequireBillChange = (event) => {
    let value = parseInt(event.target.value);
    dispatch(setBilling({ billing: billingModel }));
    dispatch(updateClient({ field: 'bill_required', value: value }));
    if (value === 1)
      dispatch(updateClient({ field: 'same_data', value: false }));
  };

  /**
   * Handler for payment type select.
   * @param data
   */
  const handleOnBillingSelectChange = (data) => {
    const { value, name } = data;
    dispatch(updateBilling({ field: name, value: value }));
  };

  /**
   * Handler for use same client data checkbox.
   * if checked we bind client data to billing and show form,
   * else we unbind data and hide inputs
   * @param event
   */
  const handleUseSameClientData = (event) => {
    let checked = event.target.checked;

    if (checked === true) {
      let street = client?.street ? client?.street : '';
      let internalNumber = client?.external_number
        ? ' interior: ' + client?.external_number
        : '';
      let externalNumber = client?.internal_number
        ? ' exterior: ' + client?.internal_number
        : '';
      let address = street + internalNumber + externalNumber;
      // ADD payment_type_id FROM CLIENT
      dispatch(updateBilling({ field: 'cfdi_id', value: client?.cfdi_id }));
      dispatch(
        updateBilling({
          field: 'payment_type_id',
          value: client?.payment_type_id
        })
      );
      dispatch(updateBilling({ field: 'name', value: client?.business_name }));
      dispatch(updateBilling({ field: 'rfc', value: client?.rfc }));
      dispatch(updateBilling({ field: 'email', value: client?.email }));
      dispatch(updateBilling({ field: 'phone', value: client?.phone }));
      dispatch(updateBilling({ field: 'state_id', value: client?.state_id }));
      dispatch(updateBilling({ field: 'city_id', value: client?.city_id }));
      dispatch(updateBilling({ field: 'cp', value: client?.cp }));
      dispatch(
        updateBilling({
          field: 'street',
          value: address
        })
      );

      if (client?.state_id !== null) setStateId(client?.state_id);

      dispatch(updateClient({ field: 'bill_required', value: 1 }));
      dispatch(updateClient({ field: 'same_data', value: checked }));
    } else if (checked === false) {
      dispatch(updateBilling({ field: 'name', value: '' }));
      dispatch(updateBilling({ field: 'rfc', value: '' }));
      dispatch(updateBilling({ field: 'email', value: '' }));
      dispatch(updateBilling({ field: 'phone', value: '' }));
      dispatch(updateBilling({ field: 'state_id', value: null }));
      dispatch(updateBilling({ field: 'city_id', value: null }));
      dispatch(updateBilling({ field: 'cp', value: '' }));
      dispatch(updateBilling({ field: 'street', value: '' }));

      dispatch(updateClient({ field: 'same_data', value: checked }));
      setStateId(null);
    }
  };

  /**
   * Handler for payment type select.
   * @param event -> Have to values, state_id and city_id
   */
  const onStateSelectChange = (event) => {
    let { name, value } = event;
    if (name === 'state_id') {
      dispatch(updateBilling({ field: 'state_id', value: value }));
      getCitiesByStateId(value);
    }
    if (name === 'city_id') {
      dispatch(updateBilling({ field: 'cp', value: client?.cp }));
      dispatch(updateBilling({ field: 'city_id', value: value }));
    }
  };

  return (
    <BillingFormView
      cfdi={cfdi}
      states={states}
      cities={cities}
      client={client}
      billing={billing}
      paymentTypes={paymentTypes}
      onBillingChanges={handleOnBillingChanges}
      useSameClientData={handleUseSameClientData}
      onStateSelectChange={onStateSelectChange}
      onRequireBillChange={handleOnRequireBillChange}
      onBillingSelectChange={handleOnBillingSelectChange}
    />
  );
};

export default BillingFormComponent;
