/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUITextField from '../../../components/UI/inputs/MUITextField';
import MUISelect from '../../../components/UI/inputs/MUISelect';
import MUIDateField from '../../../components/UI/inputs/MUIDateField';

/** Import helpers section **/
/** Import resources section **/
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import './AgreementModalStyles.scss';

const AgreementModalView = ({
  agreement,
  clients,
  onInputChange,
  onClientChange,
  onSwitchChange,
  onClose,
  onSave
}) => {
  const getInputs = () => {
    return (
      <div className="agreementmodal">
        <MUISelect
          id="client"
          name="client"
          label="Cliente"
          dataCy="cy-client"
          value={agreement?.client_id}
          items={clients}
          idSelector="id"
          selector="name"
          noSelectedLabel="Seleccione"
          onChange={onClientChange}
          disabled={agreement?.id ? true : false}
        />

        <MUIDateField
          id="end_date_agreement"
          name="end_date_agreement"
          label="Vencimiento"
          value={agreement?.end_date_agreement}
          onChange={onInputChange}
        />

        <MUITextField
          label="Facturación mensual acordada"
          id="monthly_billing"
          name="monthly_billing"
          type="number"
          value={agreement?.monthly_billing}
          onChange={onInputChange}
          data-cy="cy-monthly_billing"
        />

        <FormControlLabel
          id="MUI_active"
          labelPlacement="top"
          label="Activo"
          control={
            <Switch
              checked={agreement?.agreement_active === 1}
              onChange={onSwitchChange}
              name="agreement_active"
              data-cy="cy-active"
              color="primary"
              inputProps={{ 'aria-label': 'active' }}
            />
          }
        />
      </div>
    );
  };

  return (
    <ModalComponent
      open={true}
      title={agreement?.id ? 'Edición de convenio' : 'Nuevo convenio'}
      onClose={onClose}
      onConfirm={onSave}
    >
      {getInputs()}
    </ModalComponent>
  );
};

AgreementModalView.propTypes = {};

export default AgreementModalView;
