/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { Fragment, useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

/** Import component section **/
import ExtendedRoutesView from './ExtendedRoutesView';
import ExtendedRouteAddModalComponent from './ExtendedRoutesModal';

/** Import helpers section **/
import {
  MPIO_POSTAL_CODES_ROUTE,
  ROUTES_ROUTE_EXTENDED
} from '../../URL-Routes';
import {
  deleteExtendedRouteService,
  requestExtendedRouteService
} from '../../services/routes-services';
import ModalComponent from '../../components/UI/ModalComponent';
import { showErrorMessages } from '../../utils/utils';

/** Import resources section **/

const ExtendedRoutesComponent = () => {
  let { municipalityStateId, stateId } = useParams();
  const location = useLocation();

  const [loadingData, setLoadingData] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [routes, setRoutes] = useState([]);

  const [modalMode, setModalMode] = useState('');
  const [routeData, setRouteData] = useState(null);
  const [redirect, setShowPostalCodes] = useState(false);
  const [municipalityId, setMunicipalityId] = useState(null);
  const [pickedCityID, setPickedCityID] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const getRoutes = () => {
    setLoadingData(true);
    requestExtendedRouteService(municipalityStateId)
      .then((response) => {
        setOriginalData(response);
        if (response.length > 0)
          location.state = {
            city_name:
              response[0]?.city_name === undefined ? '' : response[0]?.city_name
          };
        setRoutes(response);
      })
      .catch((error) => {
        showErrorMessages(error);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    setShowPostalCodes(false);
    getRoutes();
  }, [municipalityStateId]);

  const onEditRoute = (route) => {
    setModalMode('edit');
    setRouteData(route);
  };

  const handleAddRoute = () => setModalMode('add');

  const onClose = () => {
    setModalMode('');
    setRouteData(null);
  };

  const onDeleteRoute = (row) => {
    setShowAlert(true);
    setRouteData(row);
  };

  const handleOnDeleteConfirm = () => {
    setLoadingData(false);
    deleteExtendedRouteService(routeData.id)
      .then(() => {
        toast('Ruta eliminada corretamente', { type: 'success' });
        getRoutes();
        setLoadingData(false);
      })
      .catch((error) => {
        toast('No se pudo eliminar la ruta', { type: 'error' });
        console.error('ExdRoutesCmp - Error deleting route: ', error);
      })
      .finally(() => {
        setLoadingData(false);
        setShowAlert(false);
      });
  };

  /**
   * We add new provider to list.
   * @param unit
   */
  const pushNewProviderToList = (unit) => {
    setRoutes((prevState) => [
      ...prevState.slice(0, routes.length),
      unit,
      ...prevState.slice(routes.length + 1)
    ]);
  };

  /**
   * We update current providers list, whith new data returned from update.
   * @param routeUpdated
   */
  const updateRouteOnList = (routeUpdated) => {
    for (let r in routes) {
      if (routes[r].id === routeUpdated.id) {
        let newRoutes = [...routes];
        newRoutes[r] = routeUpdated;
        setRoutes(newRoutes);
      }
    }
  };

  const handleOnFilterBy = (filterText) => {
    let filteredItems;

    if (filterText.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterText.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item.city_name.toString().toLowerCase();
        const field2 = item.municipality_name.toString().toLowerCase();
        const field3 = item.price_1?.toString();
        const field4 = item.price_2?.toString();
        const field5 = item.price_3?.toString();
        const field6 = item.km?.toString();

        return (
          field1?.includes(filteringText) ||
          field2?.includes(filteringText) ||
          field3?.includes(filteringText) ||
          field4?.includes(filteringText) ||
          field5?.includes(filteringText) ||
          (field6?.includes(filteringText) && true)
        );
      });
    }

    setRoutes(filteredItems);
  };

  /**
   * Set municipalityId on local state, then enable redirection.
   * @param event
   * @param row
   */
  const onPCClick = (event, row) => {
    event.preventDefault();
    setMunicipalityId(row.municipality_id);
    setPickedCityID(row);
    setShowPostalCodes(true);
  };

  return (
    <Fragment>
      {redirect === true ? (
        <Redirect
          to={{
            pathname: `${MPIO_POSTAL_CODES_ROUTE}/${municipalityId}`,
            state: {
              municipality_id: municipalityId,
              city_id: pickedCityID.id,
              city_name: pickedCityID.city_name,
              municipalityName: pickedCityID.municipality_name,
              backUrl: `${ROUTES_ROUTE_EXTENDED}/${stateId}/${municipalityStateId}/`
            }
          }}
        />
      ) : (
        <ExtendedRoutesView
          routes={routes}
          loading={loadingData}
          handleFilter={handleOnFilterBy}
          handleAddRoute={handleAddRoute}
          onDelete={onDeleteRoute}
          onPCClick={onPCClick}
          onEditRoute={onEditRoute}
        />
      )}

      {modalMode === 'add' || modalMode === 'edit' ? (
        <ExtendedRouteAddModalComponent
          municipalityId={municipalityStateId}
          onClose={onClose}
          stateId={stateId}
          modalMode={modalMode}
          routeData={routeData}
          updateRouteOnList={updateRouteOnList}
          pushNewProviderToList={pushNewProviderToList}
        />
      ) : null}

      {showAlert === true ? (
        <ModalComponent
          open={showAlert}
          title="Borrar ruta extendida "
          onClose={() => setShowAlert(false)}
          onConfirm={handleOnDeleteConfirm}
        >
          {'¿Estás seguro de borrar ' + routeData.city_name + '?'}
        </ModalComponent>
      ) : null}
    </Fragment>
  );
};

export default ExtendedRoutesComponent;
