import React from 'react';
import './CityEditStyles.scss';

import ModalComponent from '../../../components/UI/ModalComponent';
import MUISelect from '../../../components/UI/inputs/MUISelect';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CityEditView = ({
  states = [],
  onClose,
  onSave,
  cities = [],
  modalMode,
  msCoverage,
  onStateSelect,
  onCitySelect,
  selectedCity = null,
  selectedState = null,
  onSwitchChange
}) => {
  return (
    <ModalComponent
      open={true}
      title={
        modalMode === 'add'
          ? 'Alta de ciudad de cobertura'
          : 'Editar de ciudad de cobertura'
      }
      onClose={onClose}
      onConfirm={onSave}
    >
      <div className="city-edit-modal">
        <MUISelect
          label="Estado"
          id="state_id"
          idSelector="id"
          selector="name"
          onChange={onStateSelect}
          items={states}
          value={selectedState}
          disabled={modalMode === 'edit'}
        />

        <MUISelect
          label="Municipio"
          id="city_id"
          idSelector="id"
          selector="name"
          onChange={onCitySelect}
          items={cities}
          value={selectedCity}
          disabled={modalMode === 'edit'}
        />

        <FormControlLabel
          className="city-edit-modal-switch"
          control={
            <Switch
              checked={msCoverage}
              onChange={onSwitchChange}
              name="msCoverage"
              color="primary"
            />
          }
          label="Cobertura MS"
        />
      </div>
    </ModalComponent>
  );
};

export default CityEditView;
