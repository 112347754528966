/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

/** Import helpers section **/
import { makeStyles } from '@material-ui/core/styles';

/** Import resources section **/

const textFieldStyles = makeStyles(() => ({
  input: {
    textAlign: 'right',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: '12px'
  }
}));

const customTextField = (props) => {
  const classes = textFieldStyles();
  return <TextField InputProps={{ classes }} {...props} />;
};

const MUIAmountField = ({
  id,
  labelId,
  label,
  name,
  defaultValue,
  onChange,
  className,
  ...props
}) => {
  const classes = textFieldStyles();

  const handleOnValueChange = (e) => {
    if (onChange) {
      if (!onChange) return;
      onChange({
        id: id,
        name: name,
        value: e.floatValue
      });
    }
  };

  return (
    <div id={'MUI_' + id} className={classes.root}>
      {label && (
        <InputLabel id={labelId ? labelId : label + '__InputLabel'}>
          {label}
        </InputLabel>
      )}
      <NumberFormat
        className={className ? className : ''}
        defaultValue={defaultValue}
        thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale
        customInput={customTextField}
        onValueChange={handleOnValueChange}
        {...props}
      />
    </div>
  );
};

export default MUIAmountField;
