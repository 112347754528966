/*********************************************************************
 *  ROLES DESCRIPTION ENUMERATE **************************************
 *********************************************************************/

export const ADMINISTRADOR = 1;
export const DIRECTOR_GENERAL = 2;
export const DIRECTOR_FINANZAS = 3;
export const GERENTE_DE_VENTA = 4;
export const COORDINADOR_DE_VENTAS = 5;
export const ASESOR_DE_TRAFICO = 6;
export const EJECUTIVO_DE_VENTAS = 7;
export const FACTURACION_Y_COBRANZA = 8;
export const CUENTAS_POR_PAGAR = 9;
export const GERENTE_DE_OPERACIONES = 10;
export const COORDINADOR_DE_OPERACIONES = 11;
