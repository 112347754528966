import ApiService from './api-service';

export const getRoutesService = (pattern) => {
  const serviceDef = {
    url: 'shipment-route',
    method: 'get',
    params: { search: pattern }
  };
  return ApiService.request(serviceDef);
};

export const addRouteService = ({
  originMunicipalityId,
  destinationMunicipalityId
}) => {
  const serviceDef = {
    url: 'shipment-route/create',
    method: 'post',
    params: {
      origin_municipality_id: originMunicipalityId,
      destination_municipality_id: destinationMunicipalityId
    }
  };
  return ApiService.request(serviceDef);
};

export const deleteRouteService = ({ routeId }) => {
  const serviceDef = {
    url: 'shipment-route/delete',
    method: 'post',
    params: { id: routeId }
  };
  return ApiService.request(serviceDef);
};

/**
 * Extended routes section.
 */

/**
 * Request all extended routes.
 * @returns {Promise<*>}
 */
export const requestExtendedRouteService = (municipalityStateId) => {
  const serviceDef = {
    url: 'extended-route',
    method: 'get',
    params: { municipality_id: municipalityStateId }
  };
  return ApiService.request(serviceDef);
};

/**
 * Request rote by given ID.
 * @returns {Promise<*>}
 */
export const requestRouteById = (routeId) => {
  const serviceDef = {
    url: 'extended-route',
    method: 'post',
    params: { id: routeId }
  };
  return ApiService.request(serviceDef);
};

/**
 * Create new extended route.
 * @param route
 * @returns {Promise<unknown>}
 */
export const createExtendedRouteService = (route) => {
  const serviceDef = {
    url: 'extended-route/create',
    method: 'post',
    params: route
  };
  return ApiService.request(serviceDef);
};

/**
 * Update existing extended route.
 * @param route
 * @returns {Promise<unknown>}
 */
export const updateExtendedRouteService = (route) => {
  const serviceDef = {
    url: 'extended-route/update',
    method: 'post',
    params: route
  };
  return ApiService.request(serviceDef);
};

/**
 * Delete extended route by given ID.
 * @returns {Promise<unknown>}
 */
export const deleteExtendedRouteService = (routeId) => {
  const serviceDef = {
    url: 'extended-route/delete',
    method: 'post',
    params: { id: routeId }
  };
  return ApiService.request(serviceDef);
};
