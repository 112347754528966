import ApiService from './api-service';


/**
 * Request CFDi catalogo to API
 * @returns {Promise<*>}
 */
export const getCFDIService = () => {
	const serviceDef = {
		url: 'cfdi-use-catalog',
		method: 'get',
		params: null,
		token: localStorage.getItem('token'),
	};
	return ApiService.request(serviceDef);
};


/**
 * Request SAT payment types catalog.
 * @returns {Promise<*>}
 */
export const getPaymentTypesService = () => {
	const serviceDef = {
		url: 'sat-catalog',
		method: 'get',
		params: null,
		token: localStorage.getItem('token'),
	};
	return ApiService.request(serviceDef);
};