/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import './ContainerModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUITextField from '../../../components/UI/inputs/MUITextField';

const ContainerModalView = ({ container, onInputChange, onClose, onSave }) => {
  return (
    <ModalComponent
      open={true}
      title={
        (container?.id ? 'Edición de ' : 'Nuevo ') +
        container?.container_category_name
      }
      onClose={onClose}
      onConfirm={onSave}
    >
      <div className="container-modal">
        <MUITextField
          label="Peso minimo (kg)"
          data-cy="cy-min_weight"
          name="min_weight"
          value={container?.min_weight}
          type="number"
          onChange={onInputChange}
        />

        <MUITextField
          label="Peso máximo (kg)"
          data-cy="cy-max_weight"
          name="max_weight"
          value={container?.max_weight}
          type="number"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default ContainerModalView;
