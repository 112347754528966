/** Import react/libraries section **/
import React, { useCallback, useEffect, useState } from 'react';

/** Import component section **/
import ShipmentsReportsView from './ShipmentsReportsView';
import { getShipmentReportsService } from '../../services/reports-service';
import { formatNumberToCurrency } from '../../utils/formats';
import moment from 'moment';

/** Import helpers section **/

/** Import resources section **/

const ShipmentsReportsComponent = () => {
  const [loading, setLoading] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [filteredText, setFilteredText] = useState('');
  const [showClear, setShowClear] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [dateRange, setDateRange] = useState({ start: null, end: null });

  useEffect(() => {
    setLoading(true);
    getShipmentReportsService()
      .then((response) => {
        let data = [];
        response.forEach((item) => {
          data.push({
            id: item.id,
            tracking_guide: item.tracking_guide,
            quotation_date: item.quotation_date,
            shipment_status: item.shipment_status,
            name: item.assigned?.name + ' ' + item.assigned?.last_name,
            client: item.client?.name,
            service_type: item.shipment_type,
            shipment_status_id: item.shipment_status_id,
            flete: formatNumberToCurrency(item.flete),
            rad_ead: formatNumberToCurrency(item.rad_ead),
            seguro_valor: formatNumberToCurrency(item.seguro_valor),
            demora: formatNumberToCurrency(item.demora),
            maniobra: formatNumberToCurrency(item.maniobra),
            cita: formatNumberToCurrency(item.cita),
            cpac: formatNumberToCurrency(item.cpac),
            seguro: formatNumberToCurrency(item.seguro),
            extra: formatNumberToCurrency(item.extra),
            other: item.other,
            subtotal: formatNumberToCurrency(item.subtotal),
            iva: formatNumberToCurrency(item.iva),
            retention: formatNumberToCurrency(item.retention),
            total: formatNumberToCurrency(item.total)
          });
        });
        setShipments(data);
        setOriginalData(data);
      })
      .catch((error) => {
        console.error('ReportsComp -> Error requesting reports: ', error);
      })
      .finally(() => setLoading(false));
  }, []);

  //new implementation for filtering
  const filterShipments = useCallback(() => {
    let filteredItems = [...originalData];
    const filterText = filteredText.toLocaleLowerCase();
    if (filterText.length > 0) {
      filteredItems = filteredItems.filter((item) => {
        const field1 = item?.tracking_guide
          ?.toString()
          .toLowerCase()
          .includes(filterText);
        const field2 = item?.shipment_status
          ?.toString()
          .toLowerCase()
          .includes(filterText);
        const field3 = item?.name
          ?.toString()
          .toLowerCase()
          .includes(filterText);
        const field4 = item?.client
          ?.toString()
          .toLowerCase()
          .includes(filterText);
        const field5 = item?.service_type
          ?.toString()
          .toLowerCase()
          .includes(filterText);

        return field1 || field2 || field3 || field4 || (field5 && true);
      });
    }

    if (dateRange.start !== null && dateRange.end !== null) {
      let start = dateRange.start;
      let end = moment(dateRange.end).add(12, 'h');
      filteredItems = filteredItems.filter((x) =>
        moment(x.quotation_date).isBetween(start, moment(end).add(12, 'hour'))
      );
    }

    if (selectedStatus !== null) {
      setShowClear(true);
      filteredItems = filteredItems.filter(
        (item) => item.shipment_status_id === selectedStatus
      );
    }

    setShipments(filteredItems);
  }, [
    originalData,
    filteredText,
    dateRange.start,
    dateRange.end,
    selectedStatus
  ]);

  useEffect(() => {
    filterShipments();
  }, [filterShipments, filteredText, dateRange, selectedStatus]);

  const handleOnFilterBy = (filterText) => {
    setFilteredText(filterText);
    // console.log(filterText);
  };

  const handleDateChange = (event) => {
    if (event !== null) {
      setShowClear(true);
      let { value, name } = event;
      if (name === 'start') {
        setDateRange((prevState) => ({
          ...prevState,
          start: value
        }));
      } else {
        setDateRange((prevState) => ({
          ...prevState,
          end: value
        }));
      }
    }
    // console.log(dateRange);
  };

  const handleOnStatusChange = (value) => {
    setSelectedStatus(value);
    // console.log(value);
  };

  const onClear = (event) => {
    event.preventDefault();
    setShipments(originalData);
    setDateRange({ start: null, end: null });
    setSelectedStatus(null);
    setShowClear(false);
  };
  return (
    <ShipmentsReportsView
      loading={loading}
      shipments={shipments}
      onClear={onClear}
      dateRange={dateRange}
      showClear={showClear}
      onFilter={handleOnFilterBy}
      // onFilter={onFilterHandler}
      selectedStatus={selectedStatus}
      handleDateChange={handleDateChange}
      handleOnStatusChange={handleOnStatusChange}
    />
  );
};

export default ShipmentsReportsComponent;
