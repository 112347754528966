import React, {useEffect, useState} from 'react';

import {t as typy} from 'typy';
import {toast} from 'react-toastify';

import EditFieldModal from '../../../components/UI/EditFieldModal';
import NightDeliveryView from './NightDeliveryView';
import {getNightDeliveryCostService, updateNightDeliveryCostService} from '../../../services/extra-costs-services';
import {showErrorMessages} from '../../../utils/utils';

const NightDeliveryComponent = () => {
	const [loadingData, setLoadingData] = useState( false );
	const [nighCosts, setNighCosts] = useState( [] );
	const [editItem, setEditItem] = useState( null );
	
	const getData = () => {
		const token = '';
		setLoadingData( true );
		getNightDeliveryCostService( {token} )
			.then( (response) => {
				const dataArr = typy( response.units ).safeArray;
				setNighCosts( dataArr );
			} ).catch( error => {
				console.error( ' NightDeliveryComp -> Error getting dates data: ', error );
				showErrorMessages( error );
			} )
			.finally( () => {
				setLoadingData( false );
			} );
	};
	
	useEffect( () => {
		getData();
	}, [] );
	
	const handleOnEditPrice = (data) => {
		const editableElement = {
			data: data,
			title: 'Costo de entrega nocturna de ' + data.unit_name,
			service: 'cost',
			fields: [
				{
					id: 'cost',
					label: 'Nocturno',
					type: 'number',
					value: data.cost,
				},
				{
					id: 'daytime_cost',
					label: 'Costo diurno',
					type: 'number',
					value: data.daytime_cost,
				},
			],
		};
		setEditItem( editableElement );
	};
	
	const handleOnEditModalClose = () => {
		setEditItem( null );
	};
	
	const handleOnEditModalSave = (editedRow) => {
		const updateObject = {
			unitId: editedRow.unit_id,
			cost: editedRow.cost,
			daytimeCost: editedRow.daytime_cost,
		};
		updateNightDeliveryCost( updateObject );
		setEditItem( null );
	};
	
	const updateNightDeliveryCost = ({unitId, cost, daytimeCost}) => {
		updateNightDeliveryCostService( {unitId, cost, daytimeCost} ).then( response => {
			updateNightCostsOnList( response );
			toast( 'Costo actualizado', {type: 'success'} );
		} ).catch( error => {
			showErrorMessages( error );
			toast( 'Error al actualiza costo.', {type: 'error'} );
		} );
	};
	
	/**
	 * We update current delays list, with new data returned from update.
	 * @param cost
	 */
	const updateNightCostsOnList = (cost) => {
		for (let u in nighCosts) {
			if (nighCosts[u].unit_id === cost.unit_id) {
				let newCosts = [...nighCosts];
				newCosts[u] = cost;
				setNighCosts( newCosts );
			}
		}
	};
	
	return (
		<>
			<NightDeliveryView
				data={nighCosts}
				loading={loadingData}
				onEditPrice={handleOnEditPrice}
			/>
			{editItem && (
				<EditFieldModal
					open={true}
					title={editItem.title}
					service={editItem.service}
					data={editItem.data}
					fields={editItem.fields}
					handleOnClose={handleOnEditModalClose}
					handleOnSave={handleOnEditModalSave}
				/>
			)}
		</>
	);
};

export default NightDeliveryComponent;
