/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import {Card, CardContent, CardHeader} from '@material-ui/core';
import './PickupFormStyles.scss';
import MUIDateField from '../../UI/inputs/MUIDateField';
import MUITimeField from '../../UI/inputs/MUITimeField';

export const PickupFormView = ({recollectionDate, collectionStartTime, collectionEndTime, onChange, today, isShipment}) => {
	return (
		<Card className="pickupForm">
			<CardHeader title="Recolección"/>
			<CardContent className="pickupForm_content">
				
				<MUIDateField
					id="recollection_date"
					name="recollection_date"
					data-cy="cy-recollection"
					label="Fecha"
					value={recollectionDate}
					onChange={onChange}
					minDate={today}
					disabled={isShipment}
				/>
				
				<MUITimeField
					id="start"
					name="collection_start_time"
					label="De:"
					value={collectionStartTime?.slice( 0, 5 )}
					onChange={onChange}
					minTime='16:00'
					disabled={isShipment}
				/>
				
				<MUITimeField
					id="end"
					label="A:"
					name="collection_end_time"
					value={collectionEndTime?.slice( 0, 5 )}
					onChange={onChange}
					disabled={isShipment}
				/>
			
			</CardContent>
		</Card>
	);
};

export default PickupFormView;
