import React, { useMemo } from 'react';
import './NightDeliveryStyles.scss';

import EditIcon from '@material-ui/icons/Edit';
import MUIDataTable from '../../../components/MUIDataTable';
import { formatNumberToCurrency } from '../../../utils/formats';

const NightDeliveryView = ({ data, loading, onEditPrice }) => {
  
  const PriceCell = ({ row }) => {
    return (
      <>
        <div className="g-row-action-title">
          {row
            ? formatNumberToCurrency(row)
            : null}
        </div>
      </>
    );
  };
  
  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          data-cy="edit-button"
          className="g-row-action-button"
          onClick={() => onEditPrice(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Unidad',
        selector: 'unit_name',
        sortable: true,
        center: true
      },
      {
        name: 'Diurno',
        cell: (row) => <PriceCell row={row.daytime_cost} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      },
      {
        name: 'Nocturno',
        cell: (row) => <PriceCell row={row.cost} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return <MUIDataTable data={data} columns={tableColumns} loading={loading} />;
};

export default NightDeliveryView;
