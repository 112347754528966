/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { Fragment, useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

/** Import component section **/
import RequestsView from './RequestsView';
import MUISelect from '../../components/UI/inputs/MUISelect';
import ModalComponent from '../../components/UI/ModalComponent';

/** Import helpers section **/
import { REQUESTS_CREATE_ROUTE, REQUESTS_EDIT_ROUTE } from '../../URL-Routes';
import { STATUS_APPROVED, STATUS_QUOTATION } from '../../utils/enumerates';
import { setQuote } from '../../redux/quotation/quoteSlice';
import { requestUsersAssign } from '../../services/users-services';
import {
	deleteAssignRequest,
	getRequestService,
	saveAssignRequest
} from '../../services/request-services';
import { showErrorMessages } from '../../utils/utils';

const RequestsComponent = () => {
	let dispatch = useDispatch();
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	const [mode, setMode] = useState('');
	const [users, setUsers] = useState([]);
	const [roleId, setRoleId] = useState(null);
	const [loading, setLoading] = useState(false);
	const [requests, setRequests] = useState([]);
	const [showClear, setShowClear] = useState(false);
	const [dateRange, setDateRange] = useState({ start: null, end: null });
	const [requestId, setRequestId] = useState(null);
	const [originalData, setOriginalData] = useState([]);
	const [assignedUser, setAssignedUser] = useState(null);
	const [showAssigned, setShowAssigned] = useState(false);
	const [showPendents, setShowPendents] = useState(false);

	const getRequests = () => {
		getRequestService()
			.then((response) => {
				let requests = [];
				response.forEach((request) => {
					requests.push({
						id: request?.id,
						name: request.client?.name,
						business_name: request.client?.business_name,
						origin_state_name: request.origin?.state_name,
						origin_city_name: request.origin?.city_name,
						destination_state_name: request.destination?.state_name,
						destination_city_name: request.destination?.city_name,
						assigned:
							request.assigned?.name +
							' ' +
							request.assigned?.last_name,
						status: request.status,
						status_title: request.status_title,
						created_at: request.created_at
					});
				});
				setRequests(requests);
				setOriginalData(requests);
			})
			.catch((error) => {
				console.error(
					'RequestsComponent - Error getting requests list: ',
					error
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getRequests();
		let profile = JSON.parse(localStorage.getItem('user'));
		setRoleId(profile.role_id);
	}, []);

	useEffect(() => {
		if (showPendents === true) {
			setRequests(
				originalData.filter((x) => x.status === STATUS_APPROVED)
			);
		} else if (showAssigned === true) {
			let assigned = originalData.filter(
				(x) => x.status === STATUS_QUOTATION
			);
			setRequests(assigned);
		} else if (dateRange.start !== null && dateRange.end !== null) {
			let start = dateRange.start;
			let end = moment(dateRange.end).add(12, 'h');
			let filtered = originalData.filter((x) =>
				moment(x.created_at).isBetween(
					start,
					moment(end).add(12, 'hour')
				)
			);
			setRequests(filtered);
		} else {
			setRequests(originalData);
		}
	}, [showPendents, showAssigned, dateRange.start, dateRange.end]);

	const handleOnFilterBy = (filterText) => {
		let filteredItems;

		if (filterText.length === 0) {
			filteredItems = [...originalData];
		} else {
			const filteringText = filterText.toLowerCase();

			filteredItems = originalData.filter((item) => {
				const field0 = item.business_name?.toString().toLowerCase();
				const field1 = item.name?.toString().toLowerCase();
				const field2 = item.assigned?.toString().toLowerCase();
				const field3 = item.shipment_status?.toString();
				const field4 = item.rfc?.toString();
				const field5 = item.created_at?.toString();

				return (
					field0?.includes(filteringText) ||
					field1?.includes(filteringText) ||
					field2?.includes(filteringText) ||
					field3?.includes(filteringText) ||
					field5?.includes(filteringText) ||
					(field4?.includes(filteringText) && true)
				);
			});
		}

		setRequests(filteredItems);
	};

	const onSwitchChange = (event) => {
		let { name, checked } = event.target;
		if (name === 'show-assigned') {
			setShowAssigned(checked);
		} else if (name === 'show-pendents') {
			setShowPendents(checked);
		}
	};

	const handleDateChange = (event) => {
		if (event !== null) {
			setShowClear(true);
			let { value, name } = event;
			if (name === 'start') {
				setDateRange((prevState) => ({
					...prevState,
					start: value
				}));
				// setStartDate(value);
			} else {
				setDateRange((prevState) => ({
					...prevState,
					end: value
				}));
				// setEndDate(value);
			}
		}
	};

	const onClear = (event) => {
		event.preventDefault();
		setDateRange({ start: null, end: null });
		setShowClear(false);
	};

	const onAddRequest = () => {
		// history.push('/solicitud');
		setMode('new');
	};

	const onAssignRequest = (requestId) => {
		setRequestId(requestId);
		setOpenDeleteDialog(true);

		requestUsersAssign()
			.then((response) => {
				setUsers(response);
			})
			.catch((error) => {
				console.error(
					'RequestsComponent - Error requesting users: ',
					error
				);
				showErrorMessages(error);
			});
	};

	const handleOnEditQuote = (requestId) => {
		setMode('edit');
		setRequestId(requestId);
	};

	const onClose = () => {
		setOpenDeleteDialog(false);
	};

	const onSaveAssignRequest = () => {
		saveAssignRequest(requestId, assignedUser)
			.then((response) => {
				dispatch(setQuote({ quote: response }));
				toast('Cotización asignada', { type: 'success' });
				getRequests();
			})
			.catch((error) => {
				// console.error( 'Error al asignar cotización: ', error );
				toast('Error, nos se pudo asignar.', { type: 'error' });
				showErrorMessages(error);
			})
			.finally(() => setOpenDeleteDialog(false));
	};

	const handleOnDeleteQuote = (quoteId) => {
		deleteAssignRequest(quoteId)
			.then(() => {
				setLoading(false);
				getRequests();
				setLoading(false);
				toast('Solicitud borrada', { type: 'success' });
			})
			.catch((error) => {
				toast('Error al borrar.', { type: 'error' });
				// console.error( 'RequestsComp - Error deleting request: ', error );
				showErrorMessages(error);
			});
	};

	return (
		<Fragment>
			{mode === 'edit' || mode === 'new' ? (
				<Redirect
					to={
						mode === 'edit'
							? `${REQUESTS_EDIT_ROUTE}/${requestId}`
							: REQUESTS_CREATE_ROUTE
					}
				/>
			) : null}

			<RequestsView
				roleId={roleId}
				onClear={onClear}
				loading={loading}
				requests={requests}
				dateRange={dateRange}
				showClear={showClear}
				showPendent={showPendents}
				onAddRequest={onAddRequest}
				showAssigned={showAssigned}
				onSwitchChange={onSwitchChange}
				onDeleteRequest={handleOnDeleteQuote}
				onFilterRequest={handleOnFilterBy}
				onAssignRequest={onAssignRequest}
				handleDateChange={handleDateChange}
				handleOnEditQuote={handleOnEditQuote}
			/>

			<ModalComponent
				title="Asignar solicitud"
				open={openDeleteDialog}
				onClose={onClose}
				onConfirm={onSaveAssignRequest}
			>
				<MUISelect
					label="Usuario"
					dataCy="cy-user"
					items={users}
					idSelector="id"
					selector="name"
					noSelectedLabel="Seleccione"
					name="client_id"
					onChange={(event) => setAssignedUser(event.value)}
				/>
			</ModalComponent>
		</Fragment>
	);
};

export default withRouter(RequestsComponent);
