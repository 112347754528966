import React from 'react';
import './DashboardStyles.scss';

/** Import helpers section **/
import cuid from 'cuid';
import { formatNumberToCurrency } from '../../utils/formats';
// import {
//   COLOR_COMPLEMENTARY,
//   COLOR_COMPLEMENTARY_3,
//   COLOR_PRIMARY,
//   COLOR_SECONDARY
// } from '../../styles/colors';

/** Import resources section **/
import PageTitle from './../../components/PageTitle';
import Card from '@material-ui/core/Card';
// import { Pie } from '@reactchartjs/react-chart.js';

/** Import component section **/
import Score from './DashboardScore';
import MUIDateField from '../../components/UI/inputs/MUIDateField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const DashboardView = ({
  quotationsData = [],
  shipmentsData = [],
  clientsClosed = [],
  usersClosed = [],
  handleDateChange,
  dateRange
}) => {
  // const options = {
  //   legend: {
  //     display: false
  //   }
  // };

  // const colors = [
  //   COLOR_PRIMARY,
  //   COLOR_SECONDARY,
  //   COLOR_COMPLEMENTARY,
  //   COLOR_COMPLEMENTARY_3
  // ];

  // const graphData = {
  //   labels: ['Clientes', 'Asesores'],
  //   datasets: [
  //     {
  //       label: '',
  //       data: [clientsClosed.length, usersClosed.length],
  //       backgroundColor: colors,
  //       borderWidth: 2
  //     }
  //   ]
  // };

  return (
    <div className="dashboard">
      <PageTitle title="Dashboard" />
      <Card className="dashboard_date">
        <FormControlLabel
          labelPlacement="start"
          label="Mes"
          control={
            <MUIDateField
              views={['year', 'month']}
              onChange={handleDateChange}
              format="MMMM YY"
              name="start"
              value={dateRange?.start}
              showClear={false}
            />
          }
        />
      </Card>

      <div className="dashboard_container">
        {quotationsData.length > 0 && (
          <Card className="dashboard_container_indicators">
            <Score label="Cotizaciones" data={quotationsData} />
          </Card>
        )}

        {shipmentsData.length > 0 && (
          <Card className="dashboard_container_indicators">
            <Score label="Envíos" data={shipmentsData} />
          </Card>
        )}

        <div className="dashboard_container_tops">
          <Card className="dashboard_container_tops_table">
            <h4>Clientes</h4>
            <div className="dashboard_container_tops_table-items">
              {clientsClosed.map((client) => (
                <div
                  key={cuid()}
                  className="dashboard_container_tops_table-items-item"
                >
                  <p>{client.clien_name}</p>
                  <p>{formatNumberToCurrency(client.total)}</p>
                </div>
              ))}
            </div>
          </Card>

          <Card className="dashboard_container_tops_table">
            <h4>Asesores</h4>
            <div className="dashboard_container_tops_table-items">
              {usersClosed.map((user) => (
                <div
                  key={cuid()}
                  className="dashboard_container_tops_table-items-item"
                >
                  <p>{user.user_name}</p>
                  <p>{formatNumberToCurrency(user.total)}</p>
                </div>
              ))}
            </div>
          </Card>
          {/*
          <div className="dashboard_container_tops_graph">
            <Pie data={graphData} options={options} type="pie" />
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default DashboardView;
