import React, { useEffect, useState, useCallback } from 'react';

import DestinationFormView from './DestinationFormView';
import { useDispatch, useSelector } from 'react-redux';

import { updateDestinationField } from '../../../redux/quotation/quoteSlice';
import {
  getCitiesByStateService,
  getMunicipalitiesByStateService,
  requestLocationDataService
} from '../../../services/location-services';

export const DestinationFormComponent = ({ search }) => {
  let dispatch = useDispatch();

  let destination = useSelector((store) => store.quote.quotation.destination);
  let statesCatalog = useSelector((store) => store.catalogs.states);
  let quoteId = useSelector((store) => store.quote.quotation.id);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [hasCatalogs, setHasCatalogs] = useState(false);
  const [complete, setComplete] = useState(false);

  const requestLocationData = useCallback(
    (cp) => {
      if (cp.toString().length === 5) {
        requestLocationDataService(cp.toString())
          .then((response) => {
            if (response.has_catalogs) {
              if (
                response.states.length === 0 ||
                response.cities.length === 0 ||
                response.municipalities.length === 0
              ) {
                setStates(statesCatalog);
                getMunicipalitiesByState(origin?.state_id);
                getCitiesCatalog(origin?.state_id);
                setHasCatalogs(false);
              } else {
                setCatalogs(
                  response.states,
                  response.cities,
                  response.municipalities
                );
                setHasCatalogs(response.has_catalogs);
              }
            } else {
              setStates(statesCatalog);
              getMunicipalitiesByState(origin?.state_id);
              getCitiesCatalog(origin?.state_id);
              setHasCatalogs(false);
            }
          })
          .catch((error) => {
            console.error(
              'DestFormComp -> Error requesting municipalities: ',
              error
            );
          });
      }
    },
    [statesCatalog]
  );

  /**
   * We need have quote data for request location data,
   * in this case we use quoteId for ensure that quote has be loaded
   */
  useEffect(() => {
    if (quoteId !== null) {
      if (destination !== undefined) {
        if (destination.cp !== null) {
          if (destination.cp !== '') {
            requestLocationData(destination.cp);
          } else {
            setStates(statesCatalog);
          }
        } else {
          setStates(statesCatalog);
          if (destination.state_id !== undefined) {
            getCitiesCatalog(destination.state_id);
            getMunicipalitiesByState(destination.state_id);
          }
        }
      }
    } else {
      setStates(statesCatalog);
      getCitiesCatalog(destination.state_id);
      getMunicipalitiesByState(destination.state_id);
    }
  }, [quoteId, destination, requestLocationData, statesCatalog]);

  useEffect(() => {
    const recipient = destination?.recipient;
    const contact = destination?.contact;
    const phone = destination?.phone;
    const cp = destination?.cp;
    const state_id = destination?.state_id;
    const municipality_id = destination?.municipality_id;
    // const city_id = destination?.city_id;
    const street = destination?.street;
    const external_number = destination?.external_number;

    let completeValidated = false;

    if (
      recipient &&
      contact &&
      phone &&
      cp &&
      state_id &&
      municipality_id &&
      // city_id &&
      street &&
      external_number
    ) {
      completeValidated = true;
    }
    setComplete(completeValidated);
  }, [destination]);

  const getCitiesCatalog = (stateId) => {
    getCitiesByStateService(stateId)
      .then((response) => {
        setCities(response);
      })
      .catch((error) => {
        console.error('originFormComp -> Error retrieving cities: ', error);
      });
  };

  const getMunicipalitiesByState = (stateId) => {
    getMunicipalitiesByStateService(stateId)
      .then((response) => {
        setMunicipalities(response);
      })
      .catch((error) => {
        console.error(
          'DestFormComp -> Error requesting municipalities: ',
          error
        );
      });
  };

  const setCatalogs = (states, cities, municipalities) => {
    setCities(cities);
    setMunicipalities(municipalities);
    setStates(states);
  };

  const handleOnInputChange = (event) => {
    const { name, value } = event;
    if (name === 'cp' && value?.toString().length < 5) {
      // dispatch(updateDestinationField({ field: 'state_id', value: null }));
      dispatch(updateDestinationField({ field: 'city_id', value: null }));
      dispatch(
        updateDestinationField({ field: 'municipality_id', value: null })
      );
      setCatalogs(statesCatalog, [], []);
      setHasCatalogs(false);
    }
    if (name === 'cp' && value?.toString().length === 5) {
      requestLocationData(value);
      dispatch(updateDestinationField({ field: name, value: value }));
    }
    dispatch(updateDestinationField({ field: name, value: value }));
  };

  const onSelectChange = (event) => {
    let { name, value } = event;
    if (name === 'state_id') {
      if (hasCatalogs === false) {
        getMunicipalitiesByState(value);
        getCitiesCatalog(value);
      }
      dispatch(updateDestinationField({ field: 'state_id', value: value }));
    } else if (name === 'municipality_id') {
      dispatch(
        updateDestinationField({ field: 'municipality_id', value: value })
      );
    } else {
      dispatch(updateDestinationField({ field: name, value: value }));
    }
  };

  const handleOnContactSelect = (contact) => {
    if (contact?.cp) requestLocationData(contact?.cp);
  };

  return (
    <DestinationFormView
      showSearch={search}
      destination={destination}
      states={states}
      municipalities={municipalities}
      cities={cities}
      complete={complete}
      onInputChange={handleOnInputChange}
      onSelectChange={onSelectChange}
      onContactSelect={handleOnContactSelect}
    />
  );
};

export default DestinationFormComponent;
