/** Import react/libraries section **/
import React from 'react';
import {useSelector} from 'react-redux';

/** Import component section **/
import PageTitle from './../../components/PageTitle';
import FilterBy from '../../components/UI/FilterBy';
import RoutesList from '../../components/UI/RoutesList';
import MUIDataTable from '../../components/MUIDataTable';
import MUISelect from '../../components/UI/inputs/MUISelect';

/** Import helpers section **/
import {formatNumberToCurrency} from '../../utils/formats';

/** Import resources section **/
import './AgreementPricesStyles.scss';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {CLIENT_AGREEMENTS} from '../../URL-Routes';

const AgreementPricesView = ({
	                             title,
	                             data,
	                             onFilterBy,
	                             onEdit,
	                             loading,
	                             selectedShipmentType,
	                             onShipmentTypeChange,
	                             onDelete,
	                             onRouteSelect,
                             }) => {
	let shipmentTypes = useSelector( (store) => store.catalogs?.shipmentTypes );
	
	/**
	 * Cell for actions column.
	 * @param row
	 * @returns {JSX.Element}
	 * @constructor
	 */
	const ActionsCell = ({row}) => {
		return (
			<>
				<EditIcon className="g-row-action-button" onClick={() => onEdit( row )}/>
				<DeleteIcon
					className={
						'g-row-action-button delete ' +
						(!row.client_agreement_price_id ? 'disabled' : '')
					}
					onClick={() => {
						if (row.client_agreement_price_id) onDelete( row );
					}}
				/>
			</>
		);
	};
	
	const tableColumns = [
		{
			name: 'Empaque / Unidad',
			selector: 'product_name',
			sortable: true,
			center: true,
			grow: 2,
		},
		{
			name: 'Peso Min',
			selector: (row) =>
				row.min_weight !== null ? row?.min_weight + ' Kg' : 'N/D',
			sortable: true,
			center: true,
			grow: 2,
		},
		{
			name: 'Peso Max',
			selector: (row) =>
				row.max_weight !== null ? row?.max_weight + ' Kg' : 'N/D',
			sortable: true,
			center: true,
			grow: 2,
		},
		
		{
			name: 'Precio',
			selector: (row) => formatNumberToCurrency( row?.agreement_price ),
			sortable: true,
			center: true,
			grow: 2,
		},
		{
			name: 'Precio Kg. Excedente',
			selector: (row) =>
				row.agreement_extra_kilogram_price !== null
					? formatNumberToCurrency(row?.agreement_extra_kilogram_price)
					: 'N/D',
			ignoreRowClick: true,
			allowOverflow: true,
			center: true,
		},
		{
			name: '',
			cell: (row) => <ActionsCell row={row}/>,
			ignoreRowClick: true,
			allowOverflow: true,
			center: true,
		},
	];
	
	return (
		<>
			<PageTitle
				title={'Precios del convenio con el cliente ' + title}
				backUrl={CLIENT_AGREEMENTS}
			/>
			<Paper className="g-page-header">
				<div className="agreementsprices_header">
					<RoutesList onSelect={onRouteSelect}/>
					
					<MUISelect
						name="ship-type"
						label="Tipo de envío"
						dataCy="cy-ship-type"
						defaultValue={selectedShipmentType}
						items={shipmentTypes ? shipmentTypes : []}
						idSelector="id"
						selector="title"
						noSelectedLabel="Seleccione"
						onChange={onShipmentTypeChange}
					/>
					<div className="aagreementsprices_header_filterby">
						<FilterBy onChange={onFilterBy}/>
					</div>
				</div>
			</Paper>
			<MUIDataTable data={data} columns={tableColumns} loading={loading}/>
		</>
	);
};

export default AgreementPricesView;
