export const COLOR_PRIMARY = '#80C355';
export const COLOR_SECONDARY = '#414243';
export const COLOR_COMPLEMENTARY = '#ffdd00';
export const COLOR_COMPLEMENTARY_2 = '#76b06e';
export const COLOR_COMPLEMENTARY_3 = '#006d7b';
export const COLOR_COMPLEMENTARY_4 = '#d4d700';
export const COLOR_LIGHT = '#f2f2f2';
export const COLOR_MEDIUM = '#ADADAD';
export const COLOR_NORMAL = '#707070';
export const COLOR_DARK = '#28292b';
export const COLOR_DISABLED = '#e4e4e4';
export const COLOR_DELETE = '#fa6347';
export const COLOR_CHECK = '#07b31c';
