/** Import react/libraries section **/
import React, { useState } from 'react';

/** Import resources section **/
import {
  uploadCityPostalCodesFileService,
  uploadExtendedPostalCodesFileService
} from '../../../services/coverage-services';

/** Import helpers section **/
import { t as typy } from 'typy';
import { toast } from 'react-toastify';

/** Import components section **/
import LoadFileView from './LoadFileView';

const LoadFileComponent = ({
  locationMode,
  locationId,
  onClose,
  onRefreshList
}) => {
  const [file, setFile] = useState(null);

  const handleOnUploadFile = (target) => {
    const loadParams = {
      file: file
    };
    if (locationMode === 'ciudad') {
      loadParams.city_id = locationId;
      uploadCityPostalCodesFileService(loadParams).then(() => {
        toast('Archivo cargado');
        onClose();
        onRefreshList();
      });
    }
    if (locationMode === 'municipio') {
      loadParams.municipality_id = locationId;
      uploadExtendedPostalCodesFileService(loadParams).then(() => {
        toast('Archivo cargado');
        onClose();
        onRefreshList();
      });
    }
  };

  const handleOnClose = (target) => {
    onClose();
  };

  const handleOnFileSet = (target) => {
    const files = typy(target, 'files').safeArray;
    if (files.length > 0) {
      const tmpFiles = files[0];
      if (tmpFiles.length > 0) {
        setFile(tmpFiles[0]);
      }
    }
  };

  const isCompleted = () => {
    if (!file) {
      return false;
    }

    const name = typy(file, 'name').safeString;

    const extension = name.split('.').pop();
    if (!file || extension !== 'xlsx') {
      return false;
    }

    return true;
  };

  return (
    <LoadFileView
      file={file}
      isCompleted={isCompleted()}
      onFileSet={handleOnFileSet}
      onUploadFile={handleOnUploadFile}
      onClose={handleOnClose}
    />
  );
};

export default LoadFileComponent;
