import ApiService from './api-service';

export const getProvidersService = () => {
	const serviceDef = {
		url: 'provider',
		method: 'get',
		params: null
	};
	return ApiService.request(serviceDef);
};

export const getProvidersSelectService = () => {
	const serviceDef = {
		url: 'provider/list/select',
		method: 'get',
		params: null
	};
	return ApiService.request(serviceDef);
};

/**
 * Request provider by given ID.
 * @param providerId
 * @returns {Promise<*>}
 */
export const getProviderByIdService = (providerId) => {
	const serviceDef = {
		url: 'provider/show',
		method: 'get',
		params: {
			id: providerId,
			with_shipment_types: true
		}
	};
	return ApiService.request(serviceDef);
};

/**
 * Save a give provider
 * @param provider
 * @returns {Promise<unknown>}
 */
export const saveProviderService = (provider) => {
	const serviceDef = {
		url: 'provider/create',
		method: 'post',
		params: provider
	};
	return ApiService.request(serviceDef);
};

/**
 * Save a give provider
 * @param provider
 * @returns {Promise<unknown>}
 */
export const updateProviderService = (provider) => {
	const serviceDef = {
		url: 'provider/update',
		method: 'post',
		params: provider
	};
	return ApiService.request(serviceDef);
};

/**
 * Delete given provider
 * @param providerId
 * @returns {Promise<unknown>}
 */
export const deleteProviderService = (providerId) => {
	const serviceDef = {
		url: 'provider/delete',
		method: 'post',
		params: {
			id: providerId
		}
	};
	return ApiService.request(serviceDef);
};
