/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';

import { t as typy } from 'typy';
import { toast } from 'react-toastify';

import ModalComponent from '../../components/UI/ModalComponent';

import {
	addProductPriceService,
	deleteProductPriceService,
	getProductsPricesService,
	updateProductPriceService
} from '../../services/prices-services';

import ProductsPricesView from './ProductsPricesView';
import ProductPriceModal from './ProductPriceModal';
import { Redirect } from 'react-router-dom';
import { CLIENT_AGREEMENTS } from '../../URL-Routes';
import { showErrorMessages } from '../../utils/utils';

/**
 * Price edition.
 * @returns {JSX.Element}
 * @constructor
 */
const ProductsPricesComponent = () => {
	const [prices, setPrices] = useState([]);
	const [redirect, setRedirect] = useState(false);
	const [modalMode, setModalMode] = useState(null);
	const [focusedItem, setFocusedItem] = useState(null);
	const [loadingData, setLoadingData] = useState(false);
	const [agreementID, setAgreementID] = useState(null);
	const [originalData, setOriginalData] = useState([]);
	const [selectedRoute, setSelectedRoute] = useState(null);
	const [lastFilterText, setLastFilterText] = useState('');

	// -> Fetch data.

	const getData = () => {
		setLoadingData(true);
		const routeId = selectedRoute ? selectedRoute : null;
		getProductsPricesService(routeId)
			.then((response) => {
				const responseArr = typy(response).safeArray;
				setPrices(responseArr);
				setOriginalData(responseArr);
				setLoadingData(false);
			})
			.catch((error) => {
				console.error(
					'ProductPricesComponent - Error getting product prices: ',
					error
				);
			})
			.finally(() => {
				if (lastFilterText !== '') handleOnFilterBy(lastFilterText);
				setLoadingData(false);
			});
	};

	useEffect(() => {
		if (selectedRoute) {
			getData();
		}
	}, [selectedRoute]);

	const handleOnEdit = (item) => {
		setModalMode('edit');
		setFocusedItem(item);
	};

	const handleOnEditModalSave = (updateData) => {
		updateProductPriceService(updateData)
			.then(() => {
				setLoadingData(false);
				getData();
				toast('Precio actualizado');
			})
			.catch((error) => {
				toast('Error, no se pudo guardar.', { type: 'error' });
				console.error(
					'ProdPricesComp - Error saving product price: ',
					error
				);
			});
		setModalMode(null);
		setFocusedItem(null);
	};

	const handleOnAdd = () => {
		const item = {
			cost: 0,
			min_price: 0,
			public_price: 0,
			shipment_type_id: null,
			container_type_id: null,
			origin_destination_id: null,
			extra_kilogram_price: null
		};
		setModalMode('add');
		setFocusedItem(item);
	};

	const handleOnAddModalSave = (data) => {
		const addObject = {
			origin_destination_id: data.route_id,
			shipment_type_id: data.shipment_type_id,
			container_id: data.container_type_id,
			cost: data.cost,
			min_price: data.min_price,
			public_price: data.public_price,
			extra_kilogram_price: data.extra_kilogram_price
		};
		addProductPriceRecord(addObject);
		setModalMode(null);
		// setFocusedItem(null);
	};

	const addProductPriceRecord = (productPrice) => {
		addProductPriceService(productPrice)
			.then(() => {
				setLoadingData(false);
				getData();
				toast('Precio agregado', { type: 'success' });
			})
			.catch((error) => {
				toast('Error, no se guardo.', { type: 'error' });
				showErrorMessages(error);
			});
	};

	const handleOnDelete = (item) => {
		setFocusedItem(item);
		setModalMode('delete');
	};

	const handleOnDeleteConfirm = () => {
		const deleteObject = {
			id: focusedItem.id,
			origin_destination_id: focusedItem.origin_destination_id
		};

		deleteProductPriceRecord(deleteObject);
		setFocusedItem(null);
		setModalMode(null);
	};

	const deleteProductPriceRecord = (deleteObject) => {
		setLoadingData(false);
		deleteProductPriceService(deleteObject)
			.then(() => {
				getData();
				setLoadingData(false);
				toast('Costo eliminado', { type: 'success' });
			})
			.catch((error) => {
				toast('Error, no se pudo eliminar', { type: 'error' });
				showErrorMessages(error);
			});
	};

	const handleOnRouteSelect = (routeId) => {
		setSelectedRoute(routeId);
	};

	const handleOnModalClose = (saved = false) => {
		if (saved === true) getData();

		setModalMode(null);
		setFocusedItem(null);
	};

	const onAgreement = (agreementId) => {
		setRedirect(true);
		setAgreementID(agreementId);
	};

	const handleOnFilterBy = (filterText) => {
		let filteredItems;
		setLastFilterText(filterText);
		if (filterText.length === 0) {
			filteredItems = [...originalData];
		} else {
			const filteringText = filterText.toLowerCase();

			/*
				1) Servicio
				2) Producto
				3) Origen
				4) Destino
				*/

			filteredItems = originalData.filter((item) => {
				const field0 = item.product_name?.toString().toLowerCase();
				const field2 = item.origin_city_name
					?.toString()
					.toLocaleLowerCase();
				const field3 = item.destination_city_name
					?.toString()
					.toLocaleLowerCase();
				const field1 = item.title?.toString().toLowerCase();

				return (
					field0?.includes(filteringText) ||
					field1?.includes(filteringText) ||
					field2?.includes(filteringText) ||
					(field3?.includes(filteringText) && true)
				);
			});
		}

		setPrices(filteredItems);
	};

	return (
		<Fragment>
			{redirect && (
				<Redirect
					to={{
						pathname: CLIENT_AGREEMENTS,
						state: {
							productId: agreementID
						}
					}}
				/>
			)}

			<ProductsPricesView
				data={prices}
				onAdd={handleOnAdd}
				onEdit={handleOnEdit}
				loading={loadingData}
				onDelete={handleOnDelete}
				onAgreement={onAgreement}
				selectedRoute={selectedRoute}
				onRouteSelect={handleOnRouteSelect}
				onFilterRoutes={handleOnFilterBy}
			/>
			{focusedItem && modalMode === 'add' && (
				<ProductPriceModal
					item={focusedItem}
					editMode={false}
					modalMode={modalMode}
					onClose={handleOnModalClose}
					onSave={handleOnAddModalSave}
				/>
			)}
			{focusedItem && modalMode === 'edit' && (
				<ProductPriceModal
					item={focusedItem}
					editMode={true}
					modalMode={modalMode}
					onClose={handleOnModalClose}
					onSave={handleOnEditModalSave}
				/>
			)}
			{focusedItem && modalMode === 'delete' && (
				<ModalComponent
					open={true}
					onClose={handleOnModalClose}
					onConfirm={handleOnDeleteConfirm}
					title="Borrar precio"
				>
					{'Estas seguro de borrar el precio seleccionado?'}
				</ModalComponent>
			)}
		</Fragment>
	);
};

export default ProductsPricesComponent;
