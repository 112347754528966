import ApiService from './api-service';

export const getBillingBusinessService = () => {
  const serviceDef = {
    url: 'billing-business/list',
    method: 'get',
    params: null
  };
  return ApiService.request(serviceDef);
};

export const addBillingBusinessService = (business) => {
  const serviceDef = {
    url: 'billing-business/create',
    method: 'post',
    params: business
  };
  return ApiService.request(serviceDef);
};

export const updateBillingBusinessService = (business) => {
  const serviceDef = {
    url: 'billing-business/update',
    method: 'post',
    params: business
  };
  return ApiService.request(serviceDef);
};

export const deleteBillingBusinessService = (billing_business_id) => {
  const serviceDef = {
    url: 'billing-business/delete',
    method: 'post',
    params: billing_business_id
  };
  return ApiService.request(serviceDef);
};

export const billingBusinessListSelectService = () => {
  const serviceDef = {
    url: 'billing-business/list/select',
    method: 'post',
    params: null
  };
  return ApiService.request(serviceDef);
};
