/** Import react/libraries section **/
import React from 'react';
import ReactExport from 'react-export-excel';
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';

/** Import component section **/
/** Import helpers section **/
/** Import resources section **/
import ExcelIcon from '../../assets/images/excel_icon.png';
import { makeStyles } from '@material-ui/core/styles';
import * as COLORS from '../../styles/colors';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
	tableContainer: {
		marginTop: '5px',
		height: 'auto',
		padding: '10px',
		borderColor: theme.palette.primary.main
	},
	noData: {
		paddingTop: '8px',
		fontSize: '12px',
		color: '#808080'
	},
	exportButton: {
		height: '32px',
		position: 'absolute',
		zIndex: '10',
		bottom: '20px',
		cursor: 'pointer'
	}
}));

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const customStyles = () => ({
	rows: {
		style: {
			minHeight: '30px',
			backgroundColor: '#f2f2f2',
			color: '#808080',
			padding: '5px'
		}
	},
	headCells: {
		style: {
			paddingLeft: '8px',
			paddingRight: '8px',
			fontSize: '12px',
			fontWeight: 800,
			minHeight: '40px',
			color: '#f2f2f2',
			backgroundColor: COLORS.COLOR_NORMAL,
			textAlign: 'center'
		}
	},
	cells: {
		style: {
			paddingLeft: '8px',
			paddingRight: '8px',
			color: '#808080',
			fontSize: '11px'
		}
	},
	exportButton: {
		style: {
			height: '20px',
			position: 'absolute',
			zIndex: '10',
			left: '20px',
			bottom: '20px',
			cursor: 'pointer'
		}
	},
	exportPDF: {
		style: {
			height: '30px',
			position: 'absolute',
			zIndex: '10',
			left: '60px',
			bottom: '20px'
		}
	}
});

export const MUIDataTable = ({
	data = [],
	columns,
	handleClick,
	loading = false,
	paginationPerPage = 10,
	paginationRowsPerPageOptions = [10, 20, 30, 40, 50],
	rowCount = 0,
	striped = true,
	showDownload = false,
	showPDF = true,
	...otherProps
}) => {
	const location = useLocation();

	const classes = useStyles();
	const customClasses = customStyles();

	const customNoDataComponent = (
		<div className={classes.noData}>No hay registros para mostrar</div>
	);

	const customProgressComponent = (
		<div className={classes.noData}>Cargando registros...</div>
	);

	const doFunction = (e) => {
		if (handleClick) {
			handleClick(e);
		}
	};

	const getFileTitle = () => {
		const fileTitle = location.pathname.split('/').pop();
		return fileTitle + '_' + moment().format('YYYYMMDD_HHmm');
	};

	const ExportButton = () => {
		return <img src={ExcelIcon} alt="" className={classes.exportButton} />;
	};

	const ExportElement = () => {
		return (
			<ExcelFile element={<ExportButton />} filename={getFileTitle()}>
				<ExcelSheet data={data} name="Data export">
					{columns.map((column, index) => (
						<ExcelColumn
							key={index}
							label={column.name}
							value={column.selector}
						/>
					))}
				</ExcelSheet>
			</ExcelFile>
		);
	};

	return (
		<Paper className={classes.tableContainer}>
			<DataTable
				data-cy="data-table"
				onRowClicked={doFunction}
				columns={columns}
				data={data}
				customStyles={customClasses}
				noHeader
				pagination
				paginationComponentOptions={{
					rowsPerPageText: 'Registros por página:',
					rangeSeparatorText: 'de'
				}}
				paginationPerPage={paginationPerPage}
				paginationRowsPerPageOptions={paginationRowsPerPageOptions}
				striped
				persistTableHead
				noDataComponent={customNoDataComponent}
				progressPending={loading}
				progressComponent={customProgressComponent}
				{...otherProps}
			/>
			{/*<div>*/}
			{showDownload && data.length > 0 && <ExportElement />}
			{/*{showPDF && data.length > 0 && <ExportToPDF/>}*/}
			{/*</div>*/}
		</Paper>
	);
};

export default MUIDataTable;
