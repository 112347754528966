/** Import react/libraries section **/
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

/** Import resources section **/
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

/** Import component section **/
import MUIDialogTitle from './elements/MUIDialogTitle';
import MUIDialogActions from './elements/MUIDialogActions';

const ModalComponent = (props) => {
  const {
    open,
    title,
    width,
    children,
    onClose,
    onConfirm,
    confirmText,
    disabled
  } = props;

  const useStyles = makeStyles((theme) => ({
    paper: {
      width: width ? width + 'px' : '600px'
    },
    title: {
      '& .MuiTypography-root': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  }));

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={'md'}
      classes={{ paper: classes.paper }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onConfirm();
        }}
        noValidate
      >
        <MUIDialogTitle
          disableTypography={true}
          onClose={onClose}
          classes={{ root: classes.title }}
        >
          {title}
        </MUIDialogTitle>

        <DialogContent>{children}</DialogContent>

        <MUIDialogActions
          onClose={onClose}
          onConfirm={onConfirm}
          cancelText="Cancelar"
          confirmText={confirmText || 'Aceptar'}
          disabled={disabled}
        />
      </form>
    </Dialog>
  );
};

ModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  disabled: PropTypes.bool
};

export default ModalComponent;
