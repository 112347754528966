import ApiService from './api-service';

//************************//
//****** Agreements ******//
//************************//

export const getAgreementsService = () => {
  const serviceDef = {
    url: 'client-agreement/list-clients',
    method: 'post',
    params: null
  };
  return ApiService.request(serviceDef);
};

export const createAgreementService = ({
  client_id,
  end_date_agreement,
  monthly_billing,
  agreement_active
}) => {
  const serviceDef = {
    url: 'client-agreement/create',
    method: 'post',
    params: {
      client_id,
      end_date_agreement,
      monthly_billing,
      active: agreement_active
    }
  };
  return ApiService.request(serviceDef);
};

export const updateAgreementService = ({
  agreement_id,
  end_date_agreement,
  monthly_billing,
  agreement_active
}) => {
  const serviceDef = {
    url: 'client-agreement/update',
    method: 'post',
    params: {
      id: agreement_id,
      end_date_agreement,
      monthly_billing,
      active: agreement_active
    }
  };
  return ApiService.request(serviceDef);
};

export const deleteAgreementService = (id) => {
  const serviceDef = {
    url: 'client-agreement/delete',
    method: 'post',
    params: { id: id }
  };
  return ApiService.request(serviceDef);
};

//*************************//
//*** Agreements Prices ***//
//*************************//

/**
 * Get all clients agreements
 * @returns {Promise<unknown>}
 */
export const getAgreementPricesService = ({
  client_id,
  origin_destination_id,
  shipment_type_id
}) => {
  const serviceDef = {
    url: 'client-agreement/show-list',
    method: 'post',
    params: {
      client_id,
      origin_destination_id,
      shipment_type_id
    }
  };
  return ApiService.request(serviceDef);
};

/**
 * Get agreements by route price ID.
 * @returns {Promise<unknown>}
 */
export const getAgreementPricesByRoutePriceIdService = (routePriceId) => {
  const serviceDef = {
    url: 'client-agreement',
    method: 'post',
    params: { route_price_id: routePriceId }
  };
  return ApiService.request(serviceDef);
};

/**
 * Create  given agreement.
 * @returns {Promise<unknown>}
 * @param agreement
 */
export const createAgreementPriceService = ({
  product_route_price_id,
  client_id,
  price,
  extra_kilogram_price
}) => {
  const serviceDef = {
    url: 'client-agreement-price/create',
    method: 'post',
    params: {
      product_route_price_id,
      client_id,
      price,
      extra_kilogram_price
    }
  };
  return ApiService.request(serviceDef);
};

/**
 * Update price for given agreement.
 * @returns {Promise<unknown>}
 * @param data
 */
export const updateAgreementPriceService = ({
  agreement_price_id,
  price,
  extra_kilogram_price
}) => {
  const serviceDef = {
    url: 'client-agreement-price/update',
    method: 'post',
    params: {
      id: agreement_price_id,
      price,
      extra_kilogram_price
    }
  };
  return ApiService.request(serviceDef);
};

/**
 * Delete agreement by given ID
 * @returns {Promise<unknown>}
 */
export const deleteAgreementPriceService = (agreementPriceId) => {
  const serviceDef = {
    url: 'client-agreement-price/delete',
    method: 'post',
    params: { id: agreementPriceId }
  };
  return ApiService.request(serviceDef);
};
