/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import {
  requestPaymentsService,
  revertPaymentService
} from '../../services/payment-services';
import { toast } from 'react-toastify';
import moment from 'moment';

/** Import resources section **/

/** Import component section **/
import PaymentsView from './PaymentsView';
import PaymentModal from './PaymentModal';

const PaymentsComponent = () => {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [showClear, setShowClear] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [filterText, setFilterText] = useState(null);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [inProcess, setInProcess] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const getPayments = useCallback(() => {
    setLoading(true);
    requestPaymentsService()
      .then((response) => {
        setPayments(response);
        setOriginalData(response);
      })
      .catch((error) => {
        console.error(
          'BillingsComp -> Error getting billable shipments ',
          error
        );
      })
      .finally(() => setLoading(false));
  }, []);

  const filterLists = useCallback(() => {
    let filteredItems = [...originalData];

    if (dateRange.start !== null && dateRange.end !== null) {
      let start = dateRange.start;
      let end = moment(dateRange.end).add(12, 'h');
      // let filtered = originalData.filter(x => moment(x.created_at).isBetween(start, end));
      filteredItems = filteredItems.filter((item) =>
        moment(item.bill_date).isBetween(start, moment(end))
      );
    }

    const filteringText = filterText?.toLowerCase().trim();
    if (filteringText && filteringText?.length > 0) {
      filteredItems = filteredItems.filter((item) => {
        const field1 = item?.client?.name?.toString().toLowerCase();
        const field2 = item?.bill_number?.toString().toLowerCase();
        const field3 = item?.tracking_guide?.toString().toLowerCase();
        const field4 = item?.payment_type?.toString().toLowerCase();
        const field5 = item?.payment_method_title?.toString().toLowerCase();
        const field6 = item?.operator_assigned?.toString().toLowerCase();

        return (
          field1?.includes(filterText) ||
          field2?.includes(filterText) ||
          field3?.includes(filterText) ||
          field4?.includes(filterText) ||
          field6?.includes(filterText) ||
          (field5?.includes(filterText) && true)
        );
      });
    }

    if (selectedStatus !== null) {
      filteredItems = filteredItems.filter((item) => {
        return item.payment === selectedStatus;
      });
    }

    setPayments(filteredItems);
  }, [
    originalData,
    filterText,
    dateRange.start,
    dateRange.end,
    selectedStatus
  ]);

  useEffect(() => getPayments(), [getPayments]);

  useEffect(() => {
    filterLists();
  }, [filterText, dateRange.start, dateRange.end, selectedStatus, filterLists]);

  const handleOnFilter = (textStr) => {
    textStr = textStr.toLowerCase().trim();
    setFilterText(textStr);
  };

  const handleOnStatusChange = (data) => {
    const { value } = data;
    setSelectedStatus(value);
  };

  const handleOnDateChange = (event) => {
    if (event !== null) {
      setShowClear(true);
      let { value, name } = event;
      if (name === 'start') {
        setDateRange((prevState) => ({
          ...prevState,
          start: value
        }));
      } else {
        setDateRange((prevState) => ({
          ...prevState,
          end: value
        }));
      }
    }
  };

  const onClear = (event) => {
    event.preventDefault();
    setDateRange({ start: null, end: null });
    setShowClear(false);
  };

  const onRevertPayment = (paymentId) => {
    setInProcess(true);
    revertPaymentService(paymentId)
      .then(() => {
        toast('Se ha eliminado el pago.', { type: 'error' });
        setLoading(false);
        getPayments();
      })
      .catch(() => {
        toast('Ha ocurrido un error, por favor intentelo nuevamente', {
          type: 'error'
        });
      })
      .finally(() => {
        setInProcess(true);
      });
  };

  const handleOnClose = () => {
    setSelectedItem(null);
  };

  const handleRefreshList = () => {
    setSelectedItem(null);
    getPayments();
  };

  const handleOnEdit = (row) => {
    setSelectedItem(row);
  };

  return (
    <>
      <PaymentsView
        onClear={onClear}
        loading={loading}
        payments={payments}
        dateRange={dateRange}
        showClear={showClear}
        inProcess={inProcess}
        onEdit={handleOnEdit}
        onRevertPayment={onRevertPayment}
        onFilterBy={handleOnFilter}
        onDateChange={handleOnDateChange}
        selectedStatus={selectedStatus}
        onStatusChange={handleOnStatusChange}
      />
      {selectedItem && (
        <PaymentModal
          payment={selectedItem}
          onClose={handleOnClose}
          refreshList={handleRefreshList}
        />
      )}
    </>
  );
};

export default PaymentsComponent;
