/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/** Import component section **/
import BillingBusinessModalView from './BillingBusinessModalView';
import {
  addBillingBusinessService,
  updateBillingBusinessService
} from '../../../services/billing-business-services';

import { getTaxRegimeSelectService } from '../../../services/tax-regime-services';

import { toast } from 'react-toastify';
import { showErrorMessages } from '../../../utils/utils';

/** Import helpers section **/

import { t as typy } from 'typy';

/** Import resources section **/

const BillingBusinessModalComponent = ({
  onClose,
  billingBusinessData,
  onUpdateList
}) => {
  const [billingBusiness, setBillingBusiness] = useState(null);
  const [taxRegime, setTaxRegime] = useState([]);
  const [loading, setLoadingData] = useState(false);

  const onInputChange = (data) => {
    const { value, name } = data;
    setBillingBusiness((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const getTaxRegime = () => {
    const token = '';
    setLoadingData(true);
    getTaxRegimeSelectService({ token })
      .then((taxRegime) => {
        const taxRegimeData = typy(taxRegime).safeArray;
        setTaxRegime(taxRegimeData);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    setBillingBusiness(billingBusinessData);
    getTaxRegime();
  }, [billingBusinessData]);

  const handleOnSave = () => {
    if (billingBusinessData?.id) {
      updateBillingBusiness();
    } else {
      createBillingBusiness();
    }
  };

  const createBillingBusiness = () => {
    addBillingBusinessService(billingBusiness)
      .then((response) => {
        toast('Empresa de facturación agregada', { type: 'success' });
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast('Error al egregar empresa de facturación', { type: 'error' });
        showErrorMessages(error);
      });
  };

  const updateBillingBusiness = () => {
    updateBillingBusinessService(billingBusiness)
      .then((response) => {
        toast('Empresa de facturación actualizada', { type: 'success' });
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast('Error al actualizar', { type: 'error' });
        showErrorMessages(error);
      });
  };

  return (
    <BillingBusinessModalView
      taxRegime={taxRegime}
      businessData={billingBusiness}
      onSave={handleOnSave}
      onClose={onClose}
      loading={loading}
      onInputChange={onInputChange}
    />
  );
};

BillingBusinessModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  billingBusinessData: PropTypes.object,
  onUpdateList: PropTypes.func.isRequired
};

export default BillingBusinessModalComponent;
