/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import services section **/
import {
  getUsersServices,
  deleteUserServices
} from '../../services/users-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import component section **/
import UsersView from './UsersView';
import UserModalComponent from './UserModal';
import ModalComponent from '../../components/UI/ModalComponent';

const UsersComponent = () => {
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalMode, setModalMode] = useState('');

  const getUsers = () => {
    setLoading(true);
    getUsersServices()
      .then((response) => {
        setUsers(response);
        setOriginalData(response);
      })
      .catch((error) => {
        console.error('UsersComponent - Error requesting users list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const onFilterUsers = (filterText) => {
    let filteredItems;

    if (filterText.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterText.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item?.name?.toString().toLowerCase();
        const field2 = item?.last_name?.toString().toLowerCase();
        const field3 = item?.email?.toString().toLowerCase();
        const field4 = item?.role_title?.toString().toLowerCase();

        return (
          field1?.includes(filteringText) ||
          field2?.includes(filteringText) ||
          field3?.includes(filteringText) ||
          (field4?.includes(filteringText) && true)
        );
      });
    }

    setUsers(filteredItems);
  };

  const handleOnAddUser = () => {
    const item = {
      id: null
    };
    setSelectedItem(item);
    setModalMode('add');
  };

  const handleOnEditUser = (data) => {
    let item = JSON.parse(JSON.stringify(data));
    setSelectedItem(item);
    setModalMode('edit');
  };

  const handleOnModalClose = () => {
    setSelectedItem(null);
    setModalMode('');
  };

  const handleOnDeleteUser = (data) => {
    let item = JSON.parse(JSON.stringify(data));
    setSelectedItem(item);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteUserServices({ id: selectedItem?.id })
      .then(() => {
        getUsers();
        toast.success('Usuario eliminado correctamente.');
      })
      .catch((error) => {
        if (error?.response_status === 406) {
          toast.error(error.errors);
        }
      })
      .finally(() => {
        handleOnUpdateList();
      });
  };

  const handleOnUpdateList = () => {
    setSelectedItem(null);
    setModalMode('');
    getUsers();
  };

  return (
    <>
      <UsersView
        users={users}
        loading={loading}
        onFilterUsers={onFilterUsers}
        onDeleteUser={handleOnDeleteUser}
        onAddUser={handleOnAddUser}
        onEditUser={handleOnEditUser}
      />
      {(modalMode === 'add' || modalMode === 'edit') && (
        <UserModalComponent
          data={selectedItem}
          onClose={handleOnModalClose}
          updateList={handleOnUpdateList}
        />
      )}
      {selectedItem && modalMode === 'delete' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar usuario "
        >
          {'¿Estas seguro de borrar ' +
            selectedItem.name +
            ' ' +
            selectedItem.last_name +
            '?'}
        </ModalComponent>
      )}
    </>
  );
};

export default UsersComponent;
