/** Import react/libraries section **/
import { createSlice } from '@reduxjs/toolkit';

/** Import component section **/

/** Import helpers section **/


/** Import resources section **/


let initialState = {
	loading: false,
	states: [],
	cities: null,
	payment_type: null,
	cfdi: null,
	extraServices: null,
	shipmentTypes: null,
	has_catalogs: false,
	hasError: false,
	error: null,
};


const catalogsSlice = createSlice({
	name: 'catalogs',
	initialState: initialState,
	
	reducers: {
		startRequest: (state) => {
			state.loading = true;
		},
		setStateCatalog: (state, { payload: { catalog } }) => {
			state.states = catalog;
		},
		setCFDICatalog: (state, { payload: { catalog } }) => {
			state.cfdi = catalog;
		},
		setPaymentTypesCatalog: (state, { payload:{ catalog } }) => {
			state.payment_type = catalog;
		},
		setExtraServicesCatalog: (state, { payload:{ catalog } }) => {
			state.extraServices = catalog;
		},
		setShipmentTypesCatalog: (state, { payload: { catalog } }) => {
			state.shipmentTypes = catalog;
		},
		finishRequest: (state) => {
			state.loading = false;
			state.has_catalogs = true;
		},
		setError: (state, { payload: { error } }) => {
			state.hasError = true;
			state.error = error;
		},
		resetCatalogs: (state) => {
			state.has_catalogs    = false;
		},
	}
});

export const {
	startRequest,
	finishRequest,
	setCFDICatalog,
	setPaymentTypesCatalog,
	setStateCatalog,
	setExtraServicesCatalog,
	setShipmentTypesCatalog,
	setError,
	resetCatalogs,
} = catalogsSlice.actions;
export default catalogsSlice.reducer;