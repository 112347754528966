/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

/** Import component section **/
import QuoteFilesView from './QuoteFilesView';

/** Import helpers section **/
import { setFiles } from '../../../redux/quotation/quoteSlice';
import {
  deletePDFService,
  getPDFsService,
  sendPDFService
} from '../../../services/file-services';

const QuoteFilesComponent = () => {
  let dispatch = useDispatch();
  let files = useSelector((store) => store.quote.quotation.shipment_files);
  let shipmenId = useSelector((store) => store.quote.quotation.id);

  const getPDFFiles = useCallback(() => {
    getPDFsService(shipmenId)
      .then((response) => {
        dispatch(setFiles({ files: response }));
      })
      .catch((error) => {
        console.error('QuoteGilesView -> Error getting files:', error);
      });
  }, []);

  useEffect(() => {
    if (shipmenId !== undefined) getPDFFiles(shipmenId);
  }, [shipmenId, getPDFFiles]);

  const handleOnDelete = (fileId) => {
    deletePDFService(fileId)
      .then(() => {
        toast('Archivo eliminado', { type: 'success' });
        getPDFFiles();
      })
      .catch((error) => {
        toast('No se pudo eliminar', { type: 'error' });
        console.error('Error deleting file: ', error);
      });
  };

  const handleOnSend = () => {
    sendPDFService(shipmenId)
      .then(() => {
        toast('Archivo enviado.', { type: 'success' });
      })
      .catch((error) => {
        toast('Error al enviar.', { type: 'error' });
        console.error('Error sending file: ', error);
      });
  };

  return (
    <QuoteFilesView
      files={files}
      onDelete={handleOnDelete}
      onSend={handleOnSend}
    />
  );
};

export default QuoteFilesComponent;
