/** Import react/libraries section **/
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

/** Import helpers section **/
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';

/** Import resources section **/
import theme from './styles/theme';
import './styles/index.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

/** Import component section **/
import App from './App';
import moment from 'moment';
import Root from './redux/Root';

moment.locale('es'); // it is required to select default locale manually

ReactDOM.render(
  <React.StrictMode>
    <Root>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Root>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
