/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import { Card, CardContent, CardHeader } from '@material-ui/core';

/** Import styles section **/
import './OriginFormStyles.scss';

/** Import component section **/
import MUITextField from '../../UI/inputs/MUITextField';
import MUISelect from '../../UI/inputs/MUISelect';
import ContactSearch from '../../ContactSearch';
import { ORIGIN } from '../../ContactSearch/contactConstants';

const OriginFormView = ({
  showSearch,
  origin,
  states,
  municipalities,
  cities,
  complete,
  onChange,
  onSelectChange,
  onContactSelect
}) => {
  console.log(showSearch);
  return (
    <Card>
      <CardHeader
        title="Origen"
        action={
          showSearch ? (
            <ContactSearch
              mode={ORIGIN}
              complete={complete}
              onChange={onContactSelect}
            />
          ) : null
        }
      />
      <CardContent>
        <div className="originForm">
          <MUITextField
            required
            id="origin-remitter"
            name="remitter"
            data-cy="cy-origin-remitter"
            type="text"
            label="Remitente"
            value={origin?.remitter}
            onChange={onChange}
          />
          <MUITextField
            required
            id="origin-contact_name"
            name="contact_name"
            data-cy="cy-origin-contact_name"
            type="text"
            label="Contacto"
            value={origin?.contact_name}
            onChange={onChange}
          />
          <MUITextField
            required
            id="origin-phone"
            name="phone"
            data-cy="cy-origin-phone"
            type="text"
            label="Telefono"
            value={origin?.phone}
            onChange={onChange}
          />
          <div className="originForm_location">
            <MUITextField
              id="origin-country"
              name="country"
              data-cy="cy-origin-country"
              className="country"
              label="País"
              value={'México'}
              onChange={onChange}
              readOnly
            />

            <MUITextField
              id="origin-cp"
              name="cp"
              data-cy="cy-origin-cp"
              type="number"
              label="CP"
              value={origin?.cp}
              onChange={onChange}
              error={origin?.cp?.length > 5}
            />
          </div>

          <MUISelect
            id="origin-state"
            name="state_id"
            label="Estado"
            dataCy="cy-origin-state"
            value={origin?.state_id}
            items={states}
            idSelector="id"
            selector="name"
            noSelectedLabel="Selecciona"
            onChange={onSelectChange}
          />

          <MUISelect
            id="origin-municipality"
            name="municipality_id"
            label="Municipio"
            dataCy="cy-origin-municipality"
            value={origin?.municipality_id}
            items={municipalities}
            idSelector="id"
            selector="name"
            noSelectedLabel="Selecciona"
            onChange={onSelectChange}
          />

          <MUISelect
            id="origin-city"
            name="city_id"
            label="Ciudad"
            dataCy="cy-origin-city"
            value={origin?.city_id}
            // defaultValue={origin?.city_id}
            items={cities}
            idSelector="id"
            selector="name"
            noSelectedLabel="Selecciona"
            onChange={onSelectChange}
          />

          <MUITextField
            required
            id="origin-street"
            name="street"
            data-cy="cy-origin-street"
            label="Dirección"
            value={origin?.street}
            onChange={onChange}
          />

          <MUITextField
            required
            id="origin-suburb"
            name="suburb"
            data-cy="cy-origin-suburb"
            type="text"
            label="Colonia"
            value={origin?.suburb}
            onChange={onChange}
          />

          <MUITextField
            required
            id="origin-number"
            name="internal_number"
            data-cy="cy-origin-internal-number"
            type="text"
            label="Número interior"
            value={origin?.internal_number}
            onChange={onChange}
          />

          <MUITextField
            required
            id="origin-external_number"
            name="external_number"
            data-cy="cy-origin-external-number"
            type="text"
            label="Número exterior"
            value={origin?.external_number}
            onChange={onChange}
          />

          <MUITextField
            required
            id="origin-references"
            name="references"
            data-cy="cy-origin-references"
            label="Referencias (entre que calles)"
            value={origin?.references}
            onChange={onChange}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default OriginFormView;
