/** Import react/libraries section **/
import React, { Fragment, useEffect, useState, useCallback } from 'react';

import {
  getBillableShipmentsService,
  listShipmentsByBillService
} from '../../services/billing-services';
import { getClientsSelectService } from '../../services/clients-services';

/** Import services section **/
import { createManifestService } from '../../services/manifest-services';
import { getProvidersSelectService } from '../../services/providers-services';

/** Import helpers section **/
import moment from 'moment';
import { toast } from 'react-toastify';
import { showErrorMessages } from '../../utils/utils';

/** Import resources section **/

/** Import components section **/
import BillingSupportView from './BillingSupportView';
import BillModal from './BillModal';
import ShipmentBillModal from './ShipmentBillModal';
import ModalComponent from '../../components/UI/ModalComponent';

const BillingSupportComponent = () => {
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [shipmentBills, setShipmentBills] = useState([]);
  const [clients, setClients] = useState([]);
  const [providers, setProviders] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);

  const [selectedShipments, setSelectedShipments] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [toggledClearRows, setToggledClearRows] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedAccuseType, setSelectedAccuseType] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showManifestDialog, setShowManifestDialog] = useState(false);
  const [showShipmentBill, setShowShipmentBill] = useState(false);
  const [showClear, setShowClear] = useState(false);

  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [filterText, setFilterText] = useState(null);

  const [totalRange, setTotalRange] = useState({ from: null, to: null });

  const getShipments = useCallback(() => {
    setLoading(true);
    getBillableShipmentsService()
      .then((response) => {
        const shipmentsArray = response;
        setShipments(shipmentsArray);
        setOriginalData(shipmentsArray);
      })
      .catch((error) => {
        console.error(
          'BillingsComp -> Error getting billable shipments ',
          error
        );
      })
      .finally(() => setLoading(false));
  }, []);

  const getClients = useCallback(() => {
    setLoading(true);
    getClientsSelectService()
      .then((response) => {
        setClients(response.clients);
      })
      .catch((error) => {
        console.error('BillingsComp -> Error getting clients list ', error);
      })
      .finally(() => setLoading(false));
  }, []);

  const getProviders = useCallback(() => {
    setLoading(true);
    getProvidersSelectService()
      .then((response) => {
        setProviders(response.providers);
      })
      .catch((error) => {
        console.error('BillingsComp -> Error getting providers list ', error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getShipments();
    getClients();
    getProviders();
  }, [getShipments, getClients, getProviders]);

  const handleOnManifestConfirm = () => {
    let params = {
      client_id: selectedClientId,
      shipments: selectedShipments
    };
    createManifestService(params)
      .then(() => {
        toast('Manifiesto creado correctamente.');
        clearRowsSelection();
        getShipments();
        setLoading(false);
      })
      .catch((error) => {
        toast('Error al crear el manifiesto.');
        showErrorMessages(error);
      })
      .finally(() => setShowManifestDialog(false));
  };

  const listShipmentsByBill = (bill_id) => {
    listShipmentsByBillService(bill_id)
      .then((response) => {
        setShipmentBills(response?.shipments);
        setShowShipmentBill(true);
      })
      .catch((error) => {
        toast('Error al obtener envios.');
        showErrorMessages(error);
      });
  };

  const filterLists = useCallback(() => {
    let filteredItems = [...originalData];
    const cleanFilterText = filterText
      ? filterText?.toString().toLowerCase().trim()
      : '';

    if (dateRange.start !== null && dateRange.end !== null) {
      let start = moment(dateRange.start);
      let end = moment(dateRange.end);

      filteredItems = filteredItems.filter((item) =>
        moment(item.bill_date).isBetween(start, moment(end), undefined, '[]')
      );
    }
    if (totalRange.from !== null && totalRange.to !== null) {
      filteredItems = filteredItems.filter(
        (item) => item.total >= totalRange.from && item.total <= totalRange.to
      );
    }

    if (selectedAccuseType !== null) {
      setShowClear(true);
      filteredItems = filteredItems.filter(
        (item) => item.accuse_type_id === selectedAccuseType
      );
    }

    if (selectedClient !== null) {
      setShowClear(true);
      filteredItems = filteredItems.filter(
        (item) => item.client_id === selectedClient
      );
    }

    if (selectedProvider !== null) {
      setShowClear(true);
      filteredItems = filteredItems.filter(
        (item) => item.provider_id === selectedProvider
      );
    }

    if (cleanFilterText && cleanFilterText?.length > 0) {
      filteredItems = originalData.filter((item) => {
        const field1 = item?.tracking_guide?.toString().toLowerCase();
        const field2 = item?.selected_provider_tracking_guide
          ?.toString()
          ?.toLowerCase();
        const field3 = item?.provider_name?.toString().toLowerCase();
        const field4 = item?.manifest_number?.toString().toLowerCase();
        const field5 = item?.delivery_type?.shipment_type
          ?.toString()
          .toLowerCase();
        const field6 = item?.client_business_name?.toString().toLowerCase();
        const field7 = item?.client_rfc?.toString().toLowerCase();
        const field8 = item?.total?.toString().toLowerCase();
        const field9 = item?.bill_number?.toString().toLowerCase();
        const field10 = item?.accuse_type?.toString().toLowerCase();
        return (
          field1?.includes(cleanFilterText) ||
          field2?.includes(cleanFilterText) ||
          field3?.includes(cleanFilterText) ||
          field4?.includes(cleanFilterText) ||
          field5?.includes(cleanFilterText) ||
          field6?.includes(cleanFilterText) ||
          field7?.includes(cleanFilterText) ||
          field8?.includes(cleanFilterText) ||
          field9?.includes(cleanFilterText) ||
          (field10?.includes(cleanFilterText) && true)
        );
      });
    }

    setShipments(filteredItems);
  }, [
    originalData,
    filterText,
    dateRange.start,
    dateRange.end,
    totalRange.from,
    totalRange.to,
    selectedAccuseType,
    selectedClient,
    selectedProvider
  ]);

  useEffect(() => {
    filterLists();
  }, [
    filterText,
    dateRange.start,
    dateRange.end,
    totalRange.from,
    totalRange.to,
    selectedAccuseType,
    selectedClient,
    selectedProvider,
    filterLists
  ]);

  const handleOnFilter = (textStr) => {
    textStr = textStr.toLowerCase().trim();
    setFilterText(textStr);
  };

  const handleOnAccuseTypeChange = (data) => {
    const { value } = data;
    setSelectedAccuseType(value);
  };

  const handleOnClientChange = (value) => {
    setSelectedClient(value);
  };

  const handleOnProviderChange = (value) => {
    setSelectedProvider(value);
  };

  const handleOnChangeTotal = (event) => {
    if (event !== null) {
      let value = event?.target?.value;
      let name = event?.target?.name;
      setShowClear(true);
      setTotalRange((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleOnShowManifestDialog = () => {
    setShowManifestDialog(true);
  };

  const onBillModalClose = () => {
    setSelectedItem(null);
  };

  const onBillModalConfirm = () => {
    setSelectedItem(null);
    getShipments();
  };

  const handleOnDateChange = (event) => {
    if (event !== null) {
      setShowClear(true);
      let { value, name } = event;
      setDateRange((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleOnClear = (event) => {
    event.preventDefault();
    setDateRange({ start: null, end: null });
    setTotalRange({ from: null, to: null });
    setSelectedProvider(null);
    setSelectedAccuseType(null);
    setSelectedClient(null);
    setShowClear(false);
  };

  const handleOnSelectedChange = (state) => {
    // { allSelected, selectedCount, selectedRows }
    let newSelected = state.selectedRows;

    if (newSelected.length === 0) {
      setSelectedShipments(newSelected);
      setSelectedClientId(null);
      return;
    }

    if (newSelected.length === 1) {
      setSelectedShipments(newSelected);
      setSelectedClientId(newSelected[0]?.client_id);
      return;
    }

    let difference = newSelected.filter((x) => !selectedShipments.includes(x));
    if (difference.length > 0) {
      const actualItemChecked = difference[0];

      if (selectedClientId !== actualItemChecked?.client_id) {
        toast('¡El cliente debe ser el mismo!', { type: 'warning' });
        return;
      }
    }

    setSelectedShipments(newSelected);
  };

  const clearRowsSelection = () => {
    setToggledClearRows({ toggledClearRows: !toggledClearRows });
  };

  const handleOnManifestNumberClick = (row) => {
    // console.log(row, row?.manifest_number);
    listShipmentsByBill({ bill_id: row?.manifest_number });
  };

  const handleOnCloseShipmentBillModal = () => {
    setShowShipmentBill(false);
    setShipmentBills([]);
  };

  return (
    <Fragment>
      <BillingSupportView
        providers={providers}
        loading={loading}
        shipments={shipments}
        selectedClientId={selectedClientId}
        showClear={showClear}
        onClear={handleOnClear}
        dateRange={dateRange}
        onFilterBy={handleOnFilter}
        onDateChange={handleOnDateChange}
        onCreateManifest={handleOnShowManifestDialog}
        onSelectedChange={handleOnSelectedChange}
        toggledClearRows={toggledClearRows}
        onManifestNumberClick={handleOnManifestNumberClick}
        onAccuseTypeChange={handleOnAccuseTypeChange}
        selectedAccuseType={selectedAccuseType}
        clients={clients}
        selectedClient={selectedClient}
        onClientChange={handleOnClientChange}
        totalRange={totalRange}
        onChangeTotal={handleOnChangeTotal}
        onProviderChange={handleOnProviderChange}
        selectedProvider={selectedProvider}
      />

      {showManifestDialog === true ? (
        <ModalComponent
          open={showManifestDialog}
          onClose={() => setShowManifestDialog(false)}
          onConfirm={handleOnManifestConfirm}
          title="Manifiesto"
        >
          <p>¿Desea crear el manifiesto de los envios seleccionados?</p>
        </ModalComponent>
      ) : null}
      {showShipmentBill && (
        <ShipmentBillModal
          data={shipmentBills}
          onClose={handleOnCloseShipmentBillModal}
        />
      )}
      {selectedItem && (
        <BillModal
          data={selectedItem}
          onClose={onBillModalClose}
          onUpdateList={onBillModalConfirm}
        />
      )}
    </Fragment>
  );
};

export default BillingSupportComponent;
