import React from 'react';
import './RouteAddStyles.scss';

import EditForm from '../../../components/UI/EditForm';
import StateCitySelect from '../../../components/UI/StateCitySelect';

const RouteAddView = ({
  open = false,
  onOriginMunicipalitySelected,
  onDestinationMunicipalitySelected,
  onClose,
  onSave
}) => {
  return (
    <EditForm
      open={true}
      title="Alta de ruta"
      onClose={onClose}
      onSave={onSave}
    >
      <div className="route-modal">
        <div className="route-modal-row">
          <span className="route-modal-label">Municipio origen</span>
          <StateCitySelect
            onSelect={onOriginMunicipalitySelected}
            coverageOnly
          />
        </div>
        <div className="route-modal-row">
          <span className="route-modal-label">Municipio destino</span>
          <StateCitySelect
            onSelect={onDestinationMunicipalitySelected}
            coverageOnly
          />
        </div>
      </div>
    </EditForm>
  );
};

export default RouteAddView;
