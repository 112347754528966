/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import PageTitle from './../../components/PageTitle';
import RoutesList from '../../components/UI/RoutesList';
import MUIDataTable from '../../components/MUIDataTable';

/** Import helpers section **/
import { formatNumberToCurrency } from '../../utils/formats';

/** Import resources section **/
import './ProductsPricesStyles.scss';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormControlLabel } from '@material-ui/core';
import FilterBy from '../../components/UI/FilterBy';

const ProductsPricesView = ({
  data,
  onEdit,
  loading,
  onDelete,
  onRouteSelect,
  onFilterRoutes
}) => {
  /**
   * Cell for actions column.
   * @param row
   * @returns {JSX.Element}
   * @constructor
   */
  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon className="g-row-action-button" onClick={() => onEdit(row)} />

        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDelete(row)}
        />
      </>
    );
  };

  const tableColumns = [
    {
      name: 'Origen',
      selector: 'origin_municipality_name',
      center: true
    },
    {
      name: 'Destino',
      selector: 'destination_municipality_name',
      sortable: true,
      center: true
    },
    {
      name: 'Empaque',
      selector: 'product_name',
      sortable: true,
      center: true,
      grow: 2
    },
    {
      name: 'Peso Min',
      selector: (row) =>
        row.min_weight !== null ? row?.min_weight + ' Kg' : 'N/A',
      sortable: true,
      center: true,
      grow: 2
    },
    {
      name: 'Peso Max',
      selector: (row) =>
        row.max_weight !== null ? row?.max_weight + ' Kg' : 'N/A',
      sortable: true,
      center: true,
      grow: 2
    },
    {
      name: 'Mensajería',
      selector: (row) =>
        row.shipment_type_prices?.mensajeria !== undefined
          ? formatNumberToCurrency(
              row.shipment_type_prices?.mensajeria?.public_price
            )
          : 'N/A',
      sortable: true,
      center: true,
      grow: 2
    },
    /*{
			name: 'Precio Kg. Excedente',
			selector: 'excess_kilogram_price',
			format: (row) => row.shipment_type_prices?.mensajeria !== undefined ?
				row.shipment_type_prices?.mensajeria?.extra_kilogram_price === null ?
					'' : formatNumberToCurrency( row.shipment_type_prices?.mensajeria?.extra_kilogram_price )
				: 'N/A',
			ignoreRowClick: true,
			allowOverflow: true,
			center: true,
		},*/
    {
      name: 'Paqueteria',
      selector: (row) =>
        row.shipment_type_prices?.paqueteria !== undefined
          ? row.shipment_type_prices?.paqueteria?.public_price === null
            ? ''
            : formatNumberToCurrency(
                row.shipment_type_prices?.paqueteria?.public_price
              )
          : 'N/A',
      sortable: true,
      center: true,
      grow: 2
    },
    {
      name: 'Precio Kg. Excedente',
      cell: (row) =>
        row.shipment_type_prices?.paqueteria !== undefined
          ? row.shipment_type_prices?.paqueteria?.extra_kilogram_price === null
            ? ''
            : formatNumberToCurrency(
                row.shipment_type_prices?.paqueteria?.extra_kilogram_price
              )
          : 'N/A',
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    },
    {
      name: 'Carga consolidada',
      selector: (row) =>
        row.shipment_type_prices?.carga_consolidada !== undefined
          ? formatNumberToCurrency(
              row.shipment_type_prices?.carga_consolidada?.public_price
            )
          : 'N/A',
      sortable: true,
      center: true,
      grow: 2
    },
    {
      name: 'Precio Kg. Excedente',
      selector: (row) =>
        row.shipment_type_prices?.carga_consolidada?.extra_kilogram_price !==
        undefined
          ? row.shipment_type_prices?.carga_consolidada
              ?.extra_kilogram_price === null
            ? ''
            : formatNumberToCurrency(
                row.shipment_type_prices?.carga_consolidada
                  ?.extra_kilogram_price
              )
          : 'N/A',
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    }
  ];

  return (
    <>
      <PageTitle title="Precios de productos" />
      <Paper className="g-page-header">
        <div className="requests_header_filters">
          <div className="requests_header_filters_switch">
            <FormControlLabel
              label=""
              control={<FilterBy onChange={onFilterRoutes} />}
            />
          </div>

          <RoutesList onSelect={onRouteSelect} />
        </div>

        {/*<AddButton onClick={onAdd} />*/}
      </Paper>
      <MUIDataTable
        data={data}
        columns={tableColumns}
        loading={loading}
        paginationPerPage={30}
      />
    </>
  );
};

export default ProductsPricesView;
