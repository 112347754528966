/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/
import { formatNumberToCurrency } from '../../../utils/formats';

/** Import resources section **/
import MUISelect from '../../../components/UI/inputs/MUISelect';

/** Import component section **/
import ModalComponent from '../../../components/UI/ModalComponent';
import MUIDateField from '../../../components/UI/inputs/MUIDateField';

const PaymentModalView = ({
  payment,
  onDateChange,
  onMethodChange,
  onClose,
  onConfirm
}) => {
  const paymentMethods = [
    { id: 1, title: 'Efectivo' },
    { id: 2, title: 'Depósito' },
    { id: 3, title: 'Transferencia' }
  ];

  return (
    <ModalComponent
      open={true}
      onClose={onClose}
      onConfirm={onConfirm}
      title="Registro de pago"
    >
      <p>¿Desea realizar el pago?</p>
      <br />
      <MUIDateField
        id="payment_date"
        name="payment_date"
        label="Fecha de pago"
        value={payment?.payment_date}
        onChange={(event) => onDateChange(event.value)}
      />
      <br />
      <p>
        Numero de factura: <b>{payment?.bill_number}</b>
      </p>
      <p>
        <MUISelect
          label="Método de pago"
          selector="title"
          idSelector="id"
          name="payment_method_id"
          defaultValue={payment?.payment_method_id}
          items={paymentMethods}
          onChange={(event) => onMethodChange(event.value)}
        />
      </p>
      <p>
        Monto: <b>{formatNumberToCurrency(payment?.total)}</b>
      </p>
    </ModalComponent>
  );
};

export default PaymentModalView;
