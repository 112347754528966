/** Import react/libraries section **/
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

/** Import component section **/
import LoginView from './LoginView';

/** Import helpers section **/
import { userSignInService } from '../../services/session-services';
import {
  clearSession,
  setError,
  setSession,
  startLogin
} from '../../redux/session/sessionSlice';
import { toast } from 'react-toastify';

function LoginComponent() {
  let dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: '/' } };

  let inProcess = useSelector((store) => store.session.inProcess);
  let hasError = useSelector((store) => store.session.hasError);
  let error = useSelector((store) => store.session.error);

  /**
   * We receive submit event, call login service, on success we set token on localstorage and set session on store,
   * else we set error and clean session.
   * @param values
   */
  const handleOnSubmit = (values) => {
    let { username, password } = values;
    dispatch(startLogin());

    userSignInService(username, password)
      .then((response) => {
        let { token, user } = response;
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));

        let payload = {
          token: token,
          user: user
        };
        dispatch(setSession(payload));
        history.replace(from);
      })
      .catch((error) => {
        if (error?.response_status === 403) {
          toast.warning('Usuario o contraseña incorrecta');
        } else {
          toast.error('Ocurrió un error');
        }
        dispatch(setError(error));
        dispatch(clearSession());
      });
  };

  return (
    <LoginView
      onSubmit={handleOnSubmit}
      hasError={hasError}
      error={error}
      inProcess={inProcess}
    />
  );
}

export default LoginComponent;
