/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/
import { ORIGIN, DESTINATION } from '../../ContactSearch/contactConstants';

/** Import resources section **/
import { Card, CardContent, CardHeader } from '@material-ui/core';

/** Import styles section **/
import './ContactFormStyles.scss';

/** Import component section **/
import MUITextField from '../../UI/inputs/MUITextField';
import ContactSearch from '../../ContactSearch';
import LocationSelect from '../../forms/LocationSelect';

const ContactFormView = ({
  showSearch,
  mode,
  contact,
  complete,
  onContactSelect,
  onInputChange,
  onLocationSelect
}) => {
  const locationData = {
    postal_code: contact?.cp,
    state_id: contact?.state?.id,
    state_name: contact?.state?.name || '',
    municipality_id: contact?.municipality?.id,
    municipality_name: contact?.municipality?.name || '',
    city_id: contact?.city_id,
    city_name: contact?.city?.name || '',
    neighborhood_id: contact?.neighborhood_id,
    neighborhood_name: contact?.neighborhood?.name || '',
    street: contact?.street || '',
    internal_number: contact?.internal_number || '',
    number: contact?.external_number || '',
    reference_streets: contact?.references || ''
  };

  const contactTitle = mode === ORIGIN ? 'Remitente' : 'Destinatario';
  const title =
    mode === ORIGIN ? 'Origen' : mode === DESTINATION ? 'Destino' : '';

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          showSearch ? (
            <ContactSearch
              mode={mode}
              complete={complete}
              onChange={onContactSelect}
            />
          ) : null
        }
      />
      <CardContent>
        <div className="contactForm">
          <div className="contactForm_user">
            <MUITextField
              required
              id={mode + '-name'}
              name="name"
              type="text"
              label={contactTitle}
              value={contact?.name}
              onChange={onInputChange}
            />
            <MUITextField
              required
              id={mode + '-contact'}
              name="contact"
              type="text"
              label="Contacto"
              value={contact?.contact}
              onChange={onInputChange}
            />
            <MUITextField
              required
              id={mode + '-phone'}
              name="phone"
              type="text"
              label="Telefono"
              value={contact?.phone}
              onChange={onInputChange}
            />
            <MUITextField
              required
              id={mode + '-rfc'}
              name="rfc"
              type="text"
              label="RFC"
              value={contact?.rfc}
              onChange={onInputChange}
            />
            <MUITextField
              required
              id={mode + '-email'}
              name="email"
              type="text"
              label="Email"
              value={contact?.email}
              onChange={onInputChange}
            />
          </div>
          <div className="contactForm_location">
            <LocationSelect
              locationData={locationData}
              onLoad={onLocationSelect}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ContactFormView;
