// import { createMuiTheme } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import * as COLORS from './colors';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        fontFamily: 'Roboto',
        height: '40px',
        color: 'white',
        fontSize: '12px',
        textTransform: 'none',
        borderRadius: '6px',
        backgroundColor: COLORS.COLOR_PRIMARY,
        lineHeight: '16px',
        '&:hover': {
          backgroundColor: COLORS.COLOR_PRIMARY
        },
        '&:disabled': {
          backgroundColor: COLORS.COLOR_DISABLED
        }
      },
      outlined: {
        backgroundColor: COLORS.COLOR_MEDIUM,
        border: 'none',
        '&:hover': {
          backgroundColor: COLORS.COLOR_MEDIUM
        }
      },
      text: {
        backgroundColor: COLORS.COLOR_PRIMARY,
        borderRadius: '6px',
        padding: '0 15px',
        '&:hover': {
          backgroundColor: COLORS.COLOR_PRIMARY
        }
      },
      label: {
        color: 'white'
      }
    },
    MuiPaper: {
      root: {
        borderRadius: 0
      }
    },
    MuiCard: {
      root: {
        borderRadius: 6
      }
    },
    MuiCardHeader: {
      root: {
        paddingBottom: 5
      }
    }
  },
  palette: {
    primary: {
      main: COLORS.COLOR_PRIMARY
    },
    secondary: {
      main: COLORS.COLOR_SECONDARY
    },
    light: COLORS.COLOR_LIGHT,
    complementary: {
      main: COLORS.COLOR_COMPLEMENTARY
    },
    complementary2: {
      main: COLORS.COLOR_COMPLEMENTARY_2
    },
    background: {
      default: 'white',
      paper: 'white'
    },
    success: {
      light: COLORS.COLOR_LIGHT,
      main: COLORS.COLOR_CHECK
    },
    error: {
      main: COLORS.COLOR_DELETE
    },
    dark: COLORS.COLOR_DARK,
    normal: COLORS.COLOR_NORMAL
  },
  typography: {
    fontFamily: [
      'Gotham-Book',
      '"Open Sans"',
      'Tahoma',
      '"Segoe UI"',
      'sans-serif'
    ].join(','),
    fontSize: 11,
    // fontWeight: 200,
    body2: {
      // fontWeight: 600,
    },
    h3: {
      fontSize: 22,
      fontWeight: 'bolder'
    },
    h4: {
      textTransform: 'uppercase',
      fontSize: 12,
      color: COLORS.COLOR_NORMAL
    },
    h5: {
      fontSize: 16
    }
  }
});

export default theme;
