/** Import react/libraries section **/
import React from 'react';

/** Import styles section **/
import './ContactSearchStyles.scss';

/** Import resources section **/
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';

/** Import components section **/
import MUIAutocomplete from '../../components/UI/inputs/MUIAutocomplete';

const ContactSearchView = ({
  mode,
  canSave,
  show,
  options,
  selector,
  value,
  onChange,
  onSave
}) => {
  const id = 'contactsearch-' + mode;

  return (
    <div className="contactsearch">
      {canSave && (
        <IconButton className="contactsearch_save" onClick={onSave}>
          <SaveIcon />
        </IconButton>
      )}
      {show && (
        <>
          <div className="contactsearch_field">
            <MUIAutocomplete
              id={id}
              name={id}
              options={options}
              selector={selector}
              value={value}
              onChange={onChange}
            />
          </div>

          <IconButton>
            <SearchIcon />
          </IconButton>
        </>
      )}
    </div>
  );
};

export default ContactSearchView;
