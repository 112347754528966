/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import component section **/
import CostsEditView from './CostsEditView';
import {
  requestExtraCostsService,
  updateExtraCostsService
} from '../../../services/prices-services';

/** Import helpers section **/
import { toast } from 'react-toastify';
import { showErrorMessages } from '../../../utils/utils';

/** Import resources section **/

/**
 * @type {{original_evidence: number,
 * cpac: number,
 * home_delivery: number,
 * declared_value_insurance: number,
 * home_recollection: number,
 * delivery_address_change: number,
 * storage: number,
 * base_insurance: number}}
 */
const extraCostsModel = {
  base_insurance: null,
  declared_value_insurance: null,
  cpac: null,
  storage: null,
  original_evidence: null,
  home_recollection: null,
  home_delivery: null,
  delivery_address_change: null,
  date: null
};

const CostsEditComponent = () => {
  const [extraCosts, setExtraCosts] = useState(extraCostsModel);

  useEffect(() => {
    requestExtraCostsService()
      .then((response) => {
        setExtraCosts(response);
      })
      .catch((error) => {
        console.error('CostsEditComp -> Error getting extra costs: ', error);
        showErrorMessages(error);
      });
  }, []);

  const onInputChange = (event) => {
    let { name, value } = event;
    setExtraCosts((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const saveExtraCosts = () => {
    updateExtraCostsService(extraCosts)
      .then((response) => {
        setExtraCosts(response);
        toast('Costos extras actualizados', {
          type: 'success',
          autoClose: true
        });
      })
      .catch((error) => {
        console.error('CostsEditComp -> Error updating extra costs: ', error);
        showErrorMessages(error);
      });
  };

  return (
    <div>
      <CostsEditView
        extraCosts={extraCosts}
        onInputChange={onInputChange}
        saveExtraCosts={saveExtraCosts}
      />
    </div>
  );
};

export default CostsEditComponent;
