/** Import react/libraries section **/
import React, {Fragment} from 'react';
import {PropTypes} from 'prop-types';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/UI/FilterBy';
import MUIDataTable from '../../components/MUIDataTable';

/** Import helpers section **/
import {CREDIT_APPROVED, CREDIT_REQUESTED} from '../../utils/enumerates';

/** Import resources section **/
import {ButtonBase, Paper, Tooltip} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import UndoIcon from '@material-ui/icons/Undo';
import './AuthorizationsStyles.scss';
import {formatNumberToCurrency} from '../../utils/formats';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUIDateField from '../../components/UI/inputs/MUIDateField';
import ClearIcon from '@material-ui/icons/Clear';
import MUISelect from '../../components/UI/inputs/MUISelect';
import moment from 'moment';

const AuthorizationsView = ({
	                            loading,
	                            onClear,
	                            showClear,
	                            dateRange,
	                            approveCredit,
	                            selectedStatus,
	                            handleOnFilterBy,
	                            disapproveCredit,
	                            handleDateChange,
	                            authorizationsList,
	                            handleOnStatusChange,
                            }) => {
	
	const authorizationStatus = [
		{id: 0, title: 'No Aprobado'},
		{id: 1, title: 'Aprobado'},
	];
	
	const ActionsCell = ({row}) => {
		
		return (
			<Fragment>
				
				<Tooltip title="Aprobar crédito">
					<ButtonBase id={row.approved_credit_status === CREDIT_APPROVED ? 'disabled' : null}
					            disabled={row.approved_credit_status === CREDIT_APPROVED}
					>
						<DoneIcon
							className="g-row-action-button"
							onClick={() => approveCredit( row.id )}
						/>
					</ButtonBase>
				</Tooltip>
				
				
				<Tooltip title="Desautorizar crédito">
					<ButtonBase id={row.approved_credit_status === CREDIT_REQUESTED ? 'disabled' : null}
					            disabled={row.approved_credit_status === CREDIT_REQUESTED}
					>
						<UndoIcon
							className="g-row-action-button"
							onClick={() => disapproveCredit( row.id )}
						/>
					</ButtonBase>
				</Tooltip>
			</Fragment>
		);
	};
	
	const tableColumns = [
		
		{
			name: 'Número de cotización',
			selector: 'tracking_guide',
			sortable: true,
			center: true,
			wrap:true
		},
		{
			name: 'Cliente',
			cell: (row => row?.client.business_name),
			sortable: true,
			center: true,
		},
		{
			name: 'Solicitante',
			cell: (row => row?.assigned?.name),
			sortable: true,
			center: true,
		},
		{
			name: 'Importe de la solicitud',
			selector: (row => formatNumberToCurrency( row.total )),
			sortable: true,
			center: true,
		},
		{
			name: 'Credito total',
			selector: (row => formatNumberToCurrency( row.client_credit.credit_amount )),
			sortable: true,
			center: true,
		},
		{
			name: 'Credito disponible',
			selector: (row => formatNumberToCurrency( row.client_credit.balance )),
			sortable: true,
			center: true,
		},
		{
			name: 'Monto excedente a autorizar',
			selector: (row => formatNumberToCurrency( row.total - row.client_credit.balance )),
			sortable: true,
			center: true,
		},
		{
			name: 'Fecha cotización',
			selector: 'created_at',
			cell: (row => row.created_at === null ? '' : moment(row.created_at).format('DD/MM/YY - HH:MM:ss')),
			sortable: true,
			center: true,
		},
		{
			name: 'Fecha autorización',
			cell: (row => row.approved_credit_date === null ? '' : moment(row.approved_credit_date).format('DD/MM/YY - HH:MM:ss')),
			// selector: 'approved_credit_date',
			sortable: true,
			center: true,
		},
		{
			name: 'Estatus',
			selector: 'require_credit_approval_status',
			sortable: true,
			center: true,
		},
		{
			name: '',
			cell: (row) => <ActionsCell row={row}/>,
			ignoreRowClick: true,
			allowOverflow: true,
			center: true,
		},
	];
	
	return (
		<div className="authorizations">
			<PageTitle title='Autorizaciones'/>
			<Paper className="g-page-header authorization_header">
				<div className="authorizations-header-filters">
					
					<div className="authorizations_header_filters_switch">
						<FormControlLabel label=""
						                  control={<FilterBy onChange={handleOnFilterBy}/>}
						/>
						
						<p>Del</p>
						
						<FormControlLabel
							control=
								{
									<MUIDateField onChange={handleDateChange} name='start' value={dateRange?.start}/>
								}
							label=" Al "
						/>
						
						<FormControlLabel
							control=
								{
									<MUIDateField onChange={handleDateChange} name='end' value={dateRange?.end}/>
								}
							label=""
						/>
						
						<MUISelect
							label="Estatus"
							selector="title"
							idSelector="id"
							name="client-status"
							noSelectedLabel="Todos"
							defaultValue={selectedStatus}
							items={authorizationStatus}
							onChange={(event) => handleOnStatusChange( event.value )}
						/>
						
						{
							showClear === true ?
								<Tooltip title="Limpiar filtros">
									<ClearIcon onClick={e => onClear( e )}/>
								</Tooltip> : null
						}
						
					</div>
				
				</div>
			</Paper>
			
			<MUIDataTable data={authorizationsList} columns={tableColumns} loading={loading}/>
		</div>
	);
};

AuthorizationsView.propTypes = {
	loading: PropTypes.bool.isRequired,
	approveCredit: PropTypes.array.isRequired,
	handleOnFilterBy: PropTypes.func.isRequired,
	disapproveCredit: PropTypes.func.isRequired,
	authorizationsList: PropTypes.array.isRequired,
};

export default AuthorizationsView;
