/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import moment from 'moment';
import { STATUS_QUOTATION } from '../../../utils/enumerates';
import { Redirect, useHistory } from 'react-router-dom';
import {
  QUOTE_NEW,
  SHIPMENTS_EDIT_ROUTE,
  CONTAINER_GOODS
} from '../../../URL-Routes';
import { toast } from 'react-toastify';
import { showErrorMessages } from '../../../utils/utils';
import { endProcess, startProcess } from '../../../redux/quotation/quoteSlice';
import { useDispatch, useSelector } from 'react-redux';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';

/** Import services section **/
import {
  generateOperativeRequestsService,
  getShipmentsService,
  setShipmentsAsBillables
} from '../../../services/shipment-services';

/** Import component section **/
import Filters from '../Filters';
import AddButton from '../../../components/UI/AddButton';
import ModalComponent from '../../../components/UI/ModalComponent';
import UnBilledShipmentsView from './UnBilledShipmentsView';

const UnBilledShipmentsComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let inProcess = useSelector((store) => store.quote.quotation.in_process);

  const [mode, setMode] = useState('');
  const [loading, setLoading] = useState(false);
  const [quoteID, setQuoteID] = useState(null);
  const [shipments, setShipments] = useState([]);
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filters, setFilters] = useState(null);
  const [modalMode, setModalMode] = useState('');
  const [toggleClearSelectedRows, setToggleClearSelectedRows] = useState(false);

  const getShipments = () => {
    setLoading(true);
    const params = {
      billed: false
    };
    getShipmentsService(params)
      .then((response) => {
        let filteredShipments = response.filter(
          (x) => x.shipment_status_id !== STATUS_QUOTATION
        );
        setShipments(filteredShipments);
        setOriginalData(filteredShipments);
      })
      .catch((error) => {
        console.error(
          'UnBilledShipmentsComponent - Error requesting shipments: ',
          error
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getShipments();
  }, []);

  const filterLists = useCallback(() => {
    let filteredItems = [...originalData];

    if (!!filters?.start_date && !!filters?.end_date) {
      let start = moment(filters?.start_date).startOf('day') || null;
      let end = moment(filters?.end_date).startOf('day') || null;
      filteredItems = filteredItems.filter((item) =>
        moment(item.quotation_date).isBetween(start, end, undefined, '[]')
      );
    }

    if (filters?.client) {
      filteredItems = filteredItems.filter(
        (item) => item.client_id === filters?.client
      );
    }

    // if (filters?.status) {
    //   filteredItems = filteredItems.filter((item) => {
    //     let status =
    //       item.bill && item.bill?.toString().trim().length > 0 ? 1 : 0;
    //     return status === filters?.status;
    //   });
    // }

    if (filters?.city) {
      const cityId = filters?.city;
      filteredItems = filteredItems.filter(
        (item) =>
          item?.origin?.city_id === cityId ||
          item?.destination?.city_id === cityId
      );
    }

    if (filters?.user) {
      const userId = filters?.user;
      filteredItems = filteredItems.filter((item) => item?.user_id === userId);
    }

    const filterText = filters?.text;
    if (filterText && filterText.length > 0) {
      filteredItems = filteredItems.filter((item) => {
        const field1 = item.client.business_name?.toString().toLowerCase();
        const field2 = item.shipment_status?.toString().toLowerCase();
        const field3 = item.client?.rfc?.toString().toLowerCase();
        const fullName = item.assigned?.name + '' + item.assigned?.last_name;
        const field4 = fullName.toString().toLowerCase();
        const field5 = item.tracking_guide?.toString().toLowerCase();

        return (
          field1?.includes(filterText) ||
          field2?.includes(filterText) ||
          field3?.includes(filterText) ||
          field5?.includes(filterText) ||
          (field4?.includes(filterText) && true)
        );
      });
    }

    setShipments(filteredItems);
  }, [filters, originalData]);

  useEffect(() => {
    filterLists();
  }, [filters, filterLists]);

  const handleOnEditShipment = (shipmentId) => {
    setQuoteID(shipmentId);
    setMode('edit');
    setRedirect(true);
  };

  const handleOnCreateOperativeRequest = (shipmentId) => {
    dispatch(startProcess());
    generateOperativeRequestsService(shipmentId)
      .then(() => {
        toast('Solicitud operativa creada correctamente.', {
          type: 'success'
        });
        setLoading(false);
        getShipments();
        setLoading(false);
      })
      .catch((error) => {
        toast('Error al crear solicitud operativa.', { type: 'error' });
        showErrorMessages(error);
      })
      .finally(() => {
        setLoading(false);
        dispatch(endProcess());
      });
  };

  const handleOnSelectedRowsChange = ({ selectedRows }) => {
    setSelectedShipments(selectedRows);
  };

  const handleOnModalClose = () => {
    setModalMode('');
  };

  const handleOnMakeBillables = () => {
    setModalMode('toBillables');
  };

  const handleOnMakeBillablesConfirm = () => {
    setModalMode('');
    const shipmentsToChange = selectedShipments.map((item) => {
      return { id: item.id };
    });
    setShipmentsAsBillables({ shipments: shipmentsToChange })
      .then(() => {
        toast.success('Solicitud de facturacion creada correctamente.');
        getShipments();
        setToggleClearSelectedRows(!toggleClearSelectedRows);
      })
      .catch((error) => {
        toast.error('Error al solicitar facturar.');
        showErrorMessages(error);
      });
  };

  const handleOnFiltersChange = (filtersObj) => {
    setFilters(filtersObj);
  };

  const handleOnContainerGoods = (row) => {
    let shipmentId = row;
    history.push({ pathname: CONTAINER_GOODS + '/' + shipmentId });
  };

  return (
    <>
      {redirect === true ? (
        <Redirect
          to={{
            pathname:
              mode === 'edit'
                ? `${SHIPMENTS_EDIT_ROUTE}/${quoteID}`
                : QUOTE_NEW,
            state: {
              quoteMode: mode
            }
          }}
        />
      ) : null}
      <Paper className="g-page-header">
        <Filters onFiltersChange={handleOnFiltersChange} />
        <AddButton
          text="Hacer facturables"
          onClick={handleOnMakeBillables}
          disabled={selectedShipments.length === 0}
        />
      </Paper>
      <UnBilledShipmentsView
        loading={loading}
        inProcess={inProcess}
        shipments={shipments}
        onEditShipment={handleOnEditShipment}
        onCreateOperativeRequest={handleOnCreateOperativeRequest}
        onSelectedRowsChange={handleOnSelectedRowsChange}
        onContainerGoods={handleOnContainerGoods}
        toggleClearSelectedRows={toggleClearSelectedRows}
      />
      {modalMode === 'toBillables' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnMakeBillablesConfirm}
          title="Hacer envíos facturables"
        >
          ¿Estas seguro de hacer facturables los envíos seleccionados?
        </ModalComponent>
      )}
    </>
  );
};

export default UnBilledShipmentsComponent;
