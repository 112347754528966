import React from 'react';
import './ExtraCostsStyles.scss';

import PageTitle from '../../components/PageTitle';
import TabsNavigation from '../../components/UI/TabsNavigation';

const tabsLabels = [ 'Estadías', 'Demora', 'Citas', 'Costos' ];

const ExtraCostsView = ({ activeTab = 0, onTabChange }) => {
  return (
    <>
      <div className="g-page-header extra-costs-header">
        <PageTitle title="Costos extras" />
        <TabsNavigation
          tabs      ={tabsLabels}
          onChange  ={onTabChange}
          active    ={activeTab}
        />
      </div>
    </>
  );
};

export default ExtraCostsView;
