/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
import FilterBy from '../../components/UI/FilterBy';
import PageTitle from './../../components/PageTitle';
import AddButton from '../../components/UI/AddButton';
import MUIDataTable from '../../components/MUIDataTable';
import MUISelect from '../../components/UI/inputs/MUISelect';

/** Import resources section **/
import './CoverageStyles.scss';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import { Chip, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EditIcon from '@material-ui/icons/Edit';

const CoverageView = ({
  states = [],
  data,
  loading,
  onAddCity,
  onEditCity,
  showExtended,
  onDeleteCity,
  selectedState,
  onStateSelect,
  showMSCoverage,
  onShowExtended,
  onSwitchChange,
  handleOnFilterBy,
  onPostalCodesClick
}) => {
  /**
   * Compose buttons for action column.
   * @param row
   * @returns {JSX.Element}
   * @constructor
   */
  const ActionsCell = ({ row }) => {
    return (
      <div className="coverage_actions">
        {row.ms_coverage === 1 ? (
          <Tooltip title="Ir a rutas extendidas">
            <LocalShippingIcon
              className="g-row-action-button complementary-3"
              onClick={() => onShowExtended(row)}
            />
          </Tooltip>
        ) : null}

        <Tooltip title="Ver CP's">
          <LocationOnIcon
            className="g-row-action-button"
            onClick={() => onPostalCodesClick(row)}
          />
        </Tooltip>

        <Tooltip title="Editar">
          <EditIcon
            className="g-row-action-button"
            onClick={() => onEditCity(row)}
          />
        </Tooltip>

        <Tooltip title="Eliminar">
          <DeleteIcon
            className="g-row-action-button delete"
            onClick={() => onDeleteCity(row)}
          />
        </Tooltip>
      </div>
    );
  };

  const ReachableCell = ({ row }) => {
    if (row.ms_coverage === 1) {
      return (
        <Tooltip title="Coberura MS">
          <Chip
            className="chips_manifest"
            label=""
            avatar={<CheckCircleIcon />}
          />
        </Tooltip>
      );
    } else {
      return null;
    }
  };

  const tableColumns = [
    {
      name: 'Municipio',
      selector: 'name',
      sortable: true,
      center: true
    },
    {
      name: 'Estado',
      selector: 'state_name',
      sortable: true,
      center: true
    },
    {
      name: 'Cobertura MS',
      cell: (row) => <ReachableCell row={row} />,
      sortable: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    }
  ];

  return (
    <div className="coverage">
      <PageTitle title="Cobertura" />
      <Paper className="g-page-header coverage-header">
        <div className="coverage_header_filters">
          <div className="coverage_header_filters_text">
            <FormControlLabel
              label=""
              control={<FilterBy onChange={handleOnFilterBy} />}
            />
          </div>

          <MUISelect
            label="Estado"
            name="state"
            items={states}
            idSelector="id"
            selector="name"
            noSelectedLabel="Todos"
            onChange={onStateSelect}
            defaultValue={selectedState}
          />

          <FormControlLabel
            className="coverage_header_filters_switch"
            control={
              <Switch
                checked={showExtended}
                onChange={onSwitchChange}
                name="show-pendents"
                color="primary"
                inputProps={{ 'aria-label': '' }}
              />
            }
            label="Rutas extendidas"
          />

          <FormControlLabel
            className="coverage_header_filters_switch"
            control={
              <Switch
                checked={showMSCoverage}
                onChange={onSwitchChange}
                name="show-ms-coverage"
                color="primary"
                inputProps={{ 'aria-label': '' }}
              />
            }
            label="Cobertura MS"
          />
        </div>

        <AddButton onClick={onAddCity} />
      </Paper>

      <MUIDataTable data={data} columns={tableColumns} loading={loading} />
    </div>
  );
};

export default CoverageView;
