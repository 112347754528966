/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import component section **/
import BillModalView from './BillModalView';

/** Import helpers section **/
import { toast } from 'react-toastify';

import {
  createBillService,
  updateBillService,
  clearBillingService
} from '../../../services/billing-services';

import { showErrorMessages } from '../../../utils/utils';

/** Import resources section **/

const BillModalComponent = ({ data, onClose, onUpdateList }) => {
  const [billData, setBillData] = useState(data);

  useEffect(() => {
    setBillData(data);
  }, [data]);

  const handleOnInputChange = (data) => {
    const { value, name } = data;
    setBillData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnDelete = () => {
    clearBillingService({ id: billData?.bill_id })
      .then((response) => {
        toast('La factura se eliminó correctamente.');
      })
      .catch((error) => {
        showErrorMessages(error);
      })
      .finally(() => {
        onUpdateList();
      });
  };

  const handleOnSave = (event) => {
    event.preventDefault();
    const params = {
      shipment_id: billData?.id,
      id: billData?.bill_id,
      bill_number: billData?.bill_number,
      bill_date: billData?.bill_date
    };
    if (billData?.bill_id) {
      updateBillService(params)
        .then(() => {
          toast('Factura actualizada  correctamente.');
          onUpdateList();
        })
        .catch((error) => {
          toast('Error al actualizar factura.');
          showErrorMessages(error);
          onClose();
        });
    } else {
      createBillService(params)
        .then(() => {
          toast('Factura asignada correctamente.');
          onUpdateList();
        })
        .catch((error) => {
          toast('Error al asignar factura.');
          showErrorMessages(error);
          onClose();
        });
    }
  };

  return (
    <BillModalView
      data={billData}
      onInputChange={handleOnInputChange}
      onSave={handleOnSave}
      onClose={onClose}
      onDelete={handleOnDelete}
    />
  );
};

export default BillModalComponent;
