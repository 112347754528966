/** Import react/libraries section **/
import React, { Fragment, useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { t as typy } from 'typy';
import { toast } from 'react-toastify';

/** Import services section **/
import {
  deleteGoodService,
  getGoodsService
} from '../../../../services/goods-services';

/** Import component section */
import GoodsView from './GoodsView';
import GoodsModalComponent from './GoodsModalComponent';
import ModalComponent from '../../../../components/UI/ModalComponent';

const GoodsModel = {
  id: null,
  description: '',
  quantity: '',
  code: '',
  unit_code: '',
  weigth: 0,
  value: '',
  currency: '',
  dangerous_material: false,
  dangerous_material_code: '',
  packing_code: '',
  shipment_id: null,
  container_id: null
};

export const GoodsComponent = ({ id, consigmentNoteSent }) => {
  const [goods, setGoods] = useState([]);
  const [modalMode, setModalMode] = useState('');
  const [selectedGood, setSelectedGood] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const getGoods = useCallback(() => {
    setLoadingData(true);
    getGoodsService(id)
      .then((response) => {
        const goodsArr = typy(response?.container_goods).safeArray;
        setGoods(goodsArr);
      })
      .catch((error) => {
        toast.error('Error, no se pueden obtener los bienes transportados.');
      })
      .finally(() => {
        setLoadingData(false);
      });
  }, [id]);

  const handleOnUpdateList = () => {
    getGoods();
  };

  useEffect(() => {
    if (!id) return;
    getGoods();
  }, [id, getGoods]);

  const onClose = () => setModalMode('');

  const handleOnAddConsigmentNote = () => {
    setSelectedGood(Object.assign({}, GoodsModel));
    setModalMode('add');
  };

  const handleOnEditConsigmentNote = (row) => {
    setSelectedGood(row);
    setModalMode('edit');
  };

  const handleOnCloseModal = () => {
    setModalMode(null);
    setSelectedGood(null);
  };

  const handleOnDeleteConsigmentNote = (row) => {
    setSelectedGood(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteGoodService(selectedGood.id)
      .then(() => {
        getGoods();
        toast.success('Bien transportado borrado.');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el bien transportado.');
      })
      .finally(() => {
        setSelectedGood(null);
        setModalMode(null);
      });
  };

  return (
    <Fragment>
      <GoodsView
        goods={goods}
        onEditConsigmentNote={handleOnEditConsigmentNote}
        onDeleteConsigmentNote={handleOnDeleteConsigmentNote}
        onAddConsigmentNote={handleOnAddConsigmentNote}
        loading={loadingData}
        consigmentNoteSent={consigmentNoteSent}
      />

      {modalMode === 'add' || modalMode === 'edit' ? (
        <GoodsModalComponent
          good={selectedGood}
          shipmentId={id}
          onUpdateList={handleOnUpdateList}
          onClose={onClose}
          modalMode={modalMode}
        />
      ) : null}

      {modalMode === 'delete' ? (
        <ModalComponent
          open={true}
          onClose={handleOnCloseModal}
          onConfirm={handleOnDeleteConfirm}
          title={'Borrar carta porte'}
        >
          {'¿Esta seguro que desea eliminar el bien?'}
        </ModalComponent>
      ) : null}
    </Fragment>
  );
};
export default GoodsComponent;
