import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { setClient, updateClient } from '../../../redux/quotation/quoteSlice';

import ClientFormView from './ClientFormView';
import { searchOverClients } from '../../../services/clients-services';

export const ClientFormComponent = ({ search }) => {
  let dispatch = useDispatch();

  let clientData = useSelector((store) => store.quote.quotation.client);
  const [foundClients, setFoundClients] = useState([]);

  const handleOnClientChange = (data) => {
    const { name, value } = data;
    if (name === 'business_name')
      dispatch(updateClient({ field: 'name', value: value }));

    dispatch(updateClient({ field: name, value: value }));
  };

  /**
   * Callback function for search a client on API.
   * @param pattern -> String
   */
  const searchCallback = (pattern) => {
    searchOverClients(pattern)
      .then((response) => {
        setFoundClients(response);
      })
      .catch((error) => {
        console.error('ClientFormComp -> SearchIcon error: ', error);
      });
  };

  /**
   * Handler for manage search input selected option.
   * @param option
   * @param value
   */
  const handleOptionSelected = (option, value) => {
    let client = {
      id: value?.id,
      name: value?.name,
      business_name: value?.business_name,
      cfdi_id: value?.cfdi_id,
      street: value?.street,
      external_number: value?.external_number,
      internal_number: value?.internal_number,
      payment_type_id: value?.payment_type_id,
      rfc: value?.rfc,
      contact_name: value?.contact_name,
      email: value?.email,
      phone: value?.phone,
      same_data: false,
      bill_required: 0,
      client_number: null,
      client_type: null,
      fisic_moral: 0,
      credit: 0,
      credit_amount: 0,
      balance: 0,
      credit_days: 0,
      start_credit_date: 0,
      city_id: value?.city_id,
      state_id: value?.state_id,
      cp: value?.cp,
      address: value?.address
    };

    dispatch(setClient({ client: client }));
  };

  return (
    <ClientFormView
      showSearch={search}
      client={clientData}
      optionsFound={foundClients}
      onChange={handleOnClientChange}
      searchCallback={searchCallback}
      handleOptionSelected={handleOptionSelected}
    />
  );
};

export default ClientFormComponent;
