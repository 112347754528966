import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';

import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '130px',
    minWidth: '130px',
    // height: '55px',
    padding: '0 5px',
    '& .MuiTextField-root': {
      padding: '5px 0 0 0'
    },
    '& .MuiInputBase-adornedStart': {
      paddingLeft: '10px',
      backgroundColor: 'white'
    },
    '& .MuiIconButton-root': {
      padding: '0'
    },
    '& .MuiInputLabel-root': {
      width: 'max-content',
      paddingBottom: '10px',
      color: theme.palette.normal.main
    }
  },
  datePicker: {},
  input: {
    padding: '15px 5px 15px 0',
    fontSize: '12px',
    height: '20px',
    backgroundColor: 'white'
  },
  invalid: {
    color: 'red'
  },
  clearIcon: {
    position: 'absolute',
    right: '15px',
    top: 'calc(50% - 10px)',
    width: '16px',
    cursor: 'pointer',
    zIndex: '10',
    color: '#333'
  }
}));

export const MUIDateField = ({
  id,
  name,
  label,
  value,
  onChange,
  disabled,
  minDate = undefined,
  ...props
}) => {
  const classes = useStyles();
  const [date, setDate] = useState(null);
  const [valid, setValid] = useState(true);

  const onClear = () => {
    setDate(null);
  };

  const handleOnDateChange = (dateData) => {
    let validDate = false;
    setDate(dateData);
    if (onChange) {
      if (!dateData) {
        onChange(null);
        return;
      }
      if (dateData.isValid()) {
        validDate = true;
      }
      onChange({ value: dateData.format('yyy-MM-DD'), id: id, name: name });
    }
    setValid(validDate);
  };

  useEffect(() => {
    // si se recibe nulo no se hace nada
    if (!value) {
      setDate(null);
      return;
    }

    // si la fecha es la misma no se hace nada
    if (value === date?.format('yyy-MM-DD')) {
      return;
    }

    // si lo que llega es momento lo toma, si no lo convierte en moment
    const inputDate = moment.isMoment(value)
      ? value
      : moment(value, ['DD/MM/yyy', 'yyy-MM-DD']);

    setDate(inputDate);
    if (onChange)
      onChange({ value: inputDate.format('yyy-MM-DD'), id: id, name: name });
  }, [value, onChange, date, id, name]);

  return (
    <div className={classes.root} id={'MUI_' + id}>
      <KeyboardDatePicker
        className={classes.datePicker}
        autoOk
        id={id}
        label={label}
        value={date}
        onChange={handleOnDateChange}
        placeholder="dd/mm/yy"
        format="DD/MM/YY"
        InputAdornmentProps={{ position: 'start' }}
        disableToolbar
        inputVariant="outlined"
        variant="inline"
        disabled={disabled}
        minDate={disabled === true ? null : minDate}
        InputProps={{
          classes: {
            input: classes.input + (!valid ? ' ' + classes.invalid : '')
          }
        }}
        invalidDateMessage={null}
        {...props}
      />
      {date && <ClearIcon className={classes.clearIcon} onClick={onClear} />}
    </div>
  );
};

export default MUIDateField;
