/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

/** Import helpers section **/
import { t as typy } from 'typy';
import { toast } from 'react-toastify';
import {
	deleteAgreementPriceService,
	getAgreementPricesService
} from '../../services/agreement-services';

/** Import resources section **/
/** Import component section **/
import ModalComponent from '../../components/UI/ModalComponent';

import AgreementPricesView from './AgreementPricesView';
import AgreementPriceModal from './AgreementPriceModal';
import { showErrorMessages } from '../../utils/utils';

/**
 * Price edition.
 * @returns {JSX.Element}
 * @constructor
 */
const AgreementPricesComponent = () => {
	const [originalData, setOriginalData] = useState([]);
	const [prices, setPrices] = useState([]);
	const [loadingData, setLoadingData] = useState(false);

	const [selectedRoute, setSelectedRoute] = useState(null);
	const [selectedShipmentType, setSelectedShipmentType] = useState(null);

	const [focusedItem, setFocusedItem] = useState(null);
	const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);

	let location = useLocation();
	let clientId = location.state?.id;
	let clientName = location.state?.name;

	// -> Fetch data.
	const getData = useCallback(() => {
		setLoadingData(true);
		const params = {
			client_id: clientId,
			origin_destination_id: selectedRoute,
			shipment_type_id: selectedShipmentType
		};

		getAgreementPricesService(params)
			.then((response) => {
				const responseArr = typy(response).safeArray;
				setPrices(responseArr);
				setOriginalData(responseArr);
				setLoadingData(false);
			})
			.catch((error) => {
				console.error(
					'AgreementPricesComponent - Error getting product prices: ',
					error
				);
				showErrorMessages(error);
			})
			.finally(() => setLoadingData(false));
	}, [clientId, selectedRoute, selectedShipmentType]);

	useEffect(() => {
		if (selectedRoute && selectedShipmentType) {
			getData();
		} else {
			setPrices([]);
			setOriginalData([]);
		}
	}, [selectedRoute, selectedShipmentType, getData]);

	const handleOnFilterBy = (filterText) => {
		let filteredItems;

		if (filterText.length === 0) {
			filteredItems = [...originalData];
		} else {
			const filteringText = filterText.toLowerCase();

			filteredItems = originalData.filter((item) => {
				const field1 = item.product_name?.toString().toLowerCase();

				return field1.includes(filteringText) && true;
			});
		}

		setPrices(filteredItems);
	};

	const handleOnEdit = (row) => {
		const item = {
			product_route_price_id: row.product_route_price_id,
			agreement_price_id: row.client_agreement_price_id,
			client_id: clientId,
			price: row.agreement_price,
			extra_kilogram_price: row.agreement_extra_kilogram_price
		};
		setFocusedItem(item);
		setDeleteAlertOpen(false);
	};

	const handleOnSaveConfirm = () => {
		setFocusedItem(null);
		setDeleteAlertOpen(false);
		getData();
	};

	const handleOnDelete = (row) => {
		const item = {
			id: row.client_agreement_price_id
		};
		setFocusedItem(item);
		setDeleteAlertOpen(true);
	};

	const handleOnDeleteConfirm = () => {
		setLoadingData(false);
		deleteAgreementPriceService(focusedItem.id)
			.then(() => {
				getData();
				toast('Precio de convenio eliminado', { type: 'success' });
			})
			.catch((error) => {
				toast('Error, no pudo eliminar el precio del convenio', {
					type: 'error'
				});
				showErrorMessages(error);
				console.error('AgreementPriceCmp - Error deleting: ', error);
			})
			.finally(() => {
				setFocusedItem(null);
				setDeleteAlertOpen(true);
				setLoadingData(false);
			});
	};

	const handleOnModalClose = () => {
		setFocusedItem(null);
		setDeleteAlertOpen(false);
	};

	const handleOnRouteSelect = (routeId) => {
		setSelectedRoute(routeId);
	};

	const handleOnShipmentTypeChange = (shipmentTypeObj) => {
		setSelectedShipmentType(shipmentTypeObj.value);
	};

	return (
		<>
			<AgreementPricesView
				title={clientName}
				data={prices}
				loading={loadingData}
				onFilterBy={handleOnFilterBy}
				selectedRoute={selectedRoute}
				selectedShipmentType={selectedShipmentType}
				onRouteSelect={handleOnRouteSelect}
				onShipmentTypeChange={handleOnShipmentTypeChange}
				onEdit={handleOnEdit}
				onDelete={handleOnDelete}
			/>
			{focusedItem && !deleteAlertOpen && (
				<AgreementPriceModal
					item={focusedItem}
					editMode={true}
					onClose={handleOnModalClose}
					onConfirm={handleOnSaveConfirm}
				/>
			)}
			{focusedItem && deleteAlertOpen && (
				<ModalComponent
					open={true}
					onClose={handleOnModalClose}
					onConfirm={handleOnDeleteConfirm}
					title="Borrar precio de convenio"
				>
					{'Estas seguro de borrar el precio seleccionado?'}
				</ModalComponent>
			)}
		</>
	);
};

export default AgreementPricesComponent;
