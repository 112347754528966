import React from 'react';
import './MainHeader.scss';

import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import logo from '../../assets/images/logo_enviosms.png';

import { clearSession } from '../../redux/session/sessionSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';

export const MainHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfile = JSON.parse(localStorage.getItem('user'));

  const handleLogout = () => {
    dispatch(clearSession());
    localStorage.clear();
    history.replace('/login');
  };

  return (
    <div className="toolbar-container">
      <div className="toolbar-title-items-container">
        <div className="toolbar-logo-container">
          <img src={logo} alt="" className="toolbar-logo" />
        </div>
        <div className="toolbar-title">Sistema de gestión de servicios</div>
      </div>
      <div className="toolbar-session-items-container">
        <div className="toolbar-divider" />
        <Tooltip title={userProfile?.name}>
          <PersonIcon className="toolbar-user-icon" />
        </Tooltip>
        <div className="toolbar-user-name">
          {userProfile?.name.toLowerCase() +
            ' ' +
            userProfile?.last_name.toLowerCase()}
        </div>
        <ExitToAppIcon className="toolbar-logout-icon" onClick={handleLogout} />
      </div>
    </div>
  );
};

export default MainHeader;
