/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, {useEffect, useState} from 'react';

/** Import component section **/
import ContainerTypeModalView from './ContainerTypeModalView';

/** Import helpers section **/
import {toast} from 'react-toastify';
import {createContainerCategory, updateContainerCategory} from '../../../services/catalogues-services';
import {showErrorMessages} from '../../../utils/utils';

/** Import resources section **/

const ContainerTypeModalComponent = ({item, onClose, onConfirm}) => {
	const [category, setCategory] = useState( {} );
	const [selectedTypes, setSelectedTypes] = useState( [] );
	
	useEffect( () => {
		setCategory( item );
		setSelectedTypes( item.shipment_types );
	}, [] );
	
	const handleOnInputChange = (data) => {
		const {value} = data;
		setCategory( (prevState) => ({
			...prevState,
			title: value,
		}) );
	};
	
	const handleOnActiveChange = (event) => {
		let {name, checked} = event.target;
		setCategory( (prevState) => ({
			...prevState,
			[name]: checked === true ? 1 : 0,
		}) );
	};
	
	const handleOnSave = (event) => {
		event.preventDefault();
		category.shipment_types = selectedTypes;
		if (!item.id) {
			createContainerCategory( category )
				.then( () => {
					toast( 'Categoria agregada', {type: 'success'} );
					onConfirm();
				} )
				.catch( error => {
					toast( 'Error, no se pudo guardar', {type: 'error'} );
					showErrorMessages( error );
				} );
		} else {
			updateContainerCategory( category )
				.then( () => {
					toast( 'Categoria actualizada', {type: 'success'} );
					onConfirm();
				} )
				.catch( error => {
					toast( 'Error, no se pudo actualizar', {type: 'error'} );
					showErrorMessages( error );
				} );
		}
	};
	
	const handleOnShipmentTypesChange = (typesArr) => {
		setSelectedTypes( typesArr );
	};
	
	return (
		<ContainerTypeModalView
			category={category}
			selectedTypes={selectedTypes}
			onInputChange={handleOnInputChange}
			onActiveChange={handleOnActiveChange}
			onShipmentTypesChange={handleOnShipmentTypesChange}
			onSave={handleOnSave}
			onClose={onClose}
		/>
	);
};

export default ContainerTypeModalComponent;
