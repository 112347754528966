/* eslint-disable react-hooks/exhaustive-deps */

/** Import react/libraries section **/
import React, {Fragment, useEffect, useState} from 'react';
import {useHistory, useLocation, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';

/** Import component section **/
import RequestView from './RequestView';
import ModalComponent from '../../components/UI/ModalComponent';
import PageTitle from '../../components/PageTitle';

/** Import helpers section **/
import {
	getRequestByIdService,
	saveQuotationRequestService,
	updateRequestService,
} from '../../services/request-services';
import {
	finishRequest,
	setError,
	setExtraServicesCatalog,
	setShipmentTypesCatalog,
	startRequest,
} from '../../redux/catalogs/catalogsSlice';
import {getExtraServices, getShipmentTypeService} from '../../services/catalogues-services';
import {resetQuote, setDeliveryType, setDestination, setOrigin, updateField} from '../../redux/quotation/quoteSlice';
import {REQUESTS_EDIT_ROUTE} from '../../URL-Routes';
import {showErrorMessages} from '../../utils/utils';

const RequestComponent = () => {
	let location = useLocation();
	let dispatch = useDispatch();
	let history = useHistory();
	let {id} = useParams();
	
	let hasCatalogs = useSelector( (store) => store.catalogs.has_catalogs );
	let quotation = useSelector( (store) => store.quote.quotation );
	let inProcess = useSelector( (store) => store.quote.in_process );
	
	const [openDeleteDialog, setOpenDeleteDialog] = useState( false );
	const [mode, setMode] = useState( '' );
	
	// Request all necessary catalogs on mounting.
	useEffect( () => {
		if (!hasCatalogs) {
			dispatch( startRequest() );
			getShipmentTypeService()
				.then( (shipments) => {
					getExtraServices()
						.then( (services) => {
							dispatch( setShipmentTypesCatalog( {catalog: shipments} ) );
							dispatch( setExtraServicesCatalog( {catalog: services} ) );
							dispatch( finishRequest() );
						} )
						.catch( (error) => {
							dispatch( setError( error ) );
						} );
				} )
				.catch( (error) => {
					dispatch( setError( error ) );
				} );
		}
	}, [dispatch, hasCatalogs] );
	
	useEffect( () => {
		setMode( location.pathname.split( '/' )[2] );
	}, [location] );
	
	useEffect( () => {
		if (mode === 'editar') {
			dispatch( startRequest() );
			getRequestByIdService( id ).then( response => {
				dispatch( setOrigin( {origin: response.origin} ) );
				dispatch( setDestination( {destination: response.destination} ) );
				dispatch( setDeliveryType( {shipmentTypes: response.delivery_type} ) );
			} ).catch( error => {
				console.error( 'RequestComp -> Error getting request by ID: ', error );
			} ).finally( () => {
				dispatch( finishRequest() );
			} );
		}
		
		// On unmount component we reset quote on store.
		return () => {
			dispatch( resetQuote() );
		};
	}, [mode] );
	
	const handleOnSave = () => {
		if (mode === 'editar') {
			updateRequestService( quotation ).then( () => {
				toast( 'Solicitud actualizada.', {type: 'success'} );
			} ).catch( error => {
				// console.error('RequestComp -> Error updating request: ', error);
				toast( 'No se ha podido actualizar.', {type: 'error'} );
				showErrorMessages( error );
			} );
		} else {
			saveQuotationRequestService( quotation ).then( response => {
				dispatch( updateField( {field: 'id', value: response.id} ) );
				history.replace( `${REQUESTS_EDIT_ROUTE}/${response.id}` );
				toast( 'Se ha guardado su solicitud.', {type: 'success'} );
			} ).catch( (error) => {
				toast( 'Error, intente de nuevo', {type: 'error'} );
				// console.error('RequestsComponent - Error al guardar: ', error);
				showErrorMessages( error );
			} );
		}
	};
	
	const handleOnPrint = () => {
	};
	
	const handleOnDelete = () => {
		setOpenDeleteDialog( true );
	};
	
	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog( false );
	};
	
	const handleDeleteRequest = () => {
		toast( 'Solicitud borrada' );
		setOpenDeleteDialog( false );
	};
	
	if (hasCatalogs === true) {
		return (
			<Fragment>
				<RequestView
					onSave={handleOnSave}
					onPrint={handleOnPrint}
					onDelete={handleOnDelete}
					inProcess={inProcess}
				/>
				<ModalComponent
					title="Borrado de solicitud"
					open={openDeleteDialog}
					onClose={handleCloseDeleteDialog}
					onConfirm={handleDeleteRequest}
				>
					¿Está seguro de borrar esta solicitud?
				</ModalComponent>
			</Fragment>
		);
	} else {
		return <PageTitle title="Cargando..."/>;
	}
};

export default withRouter( RequestComponent );
