/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { Fragment, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

/** Import component section **/
import ModalComponent from '../../components/UI/ModalComponent';
import ContainerTypesView from './ContainerTypesView';
import ContainerTypeModal from './ContainerTypeModal';

/** Import helpers section **/
import { t as typy } from 'typy';
import { toast } from 'react-toastify';
import {
	deleteContainerCategoriesService,
	getAllContainerCategories
} from '../../services/catalogues-services';
import { CONTAINERS_ROUTE } from '../../URL-Routes';
import { showErrorMessages } from '../../utils/utils';

const containerTypeModel = {
	id: null,
	name: null,
	active: true,
	shipment_types: []
};

const ContainerTypesComponent = () => {
	const [loadingData, setLoadingData] = useState(false);
	const [originalData, setOriginalData] = useState([]);
	const [containers, setContainers] = useState([]);

	const [focusedItem, setFocusedItem] = useState(null);
	const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
	const [redirectToProducts, setRedirectToProducts] = useState(false);

	const [textFilter, setTextFilter] = useState('');
	const [shipmentTypesFilters, setShipmentTypesFilters] = useState([]);

	const getCategories = () => {
		setLoadingData(true);
		getAllContainerCategories()
			.then((response) => {
				const responseArr = typy(response).safeArray;
				setContainers(responseArr);
				setOriginalData(responseArr);
			})
			.catch((error) => {
				console.error(
					'ContainersComp -> Error getting containers: ',
					error
				);
				showErrorMessages(error);
			})
			.finally(() => {
				setLoadingData(false);
			});
	};

	useEffect(() => {
		getCategories();
	}, []);

	const filterData = () => {
		let filteredItems = [...originalData];

		if (textFilter.length > 0) {
			const filteringText = textFilter.toLowerCase();
			filteredItems = filteredItems.filter((item) => {
				const field = item.title?.toString().toLowerCase();
				return field.includes(filteringText) && true;
			});
		}
		if (shipmentTypesFilters.length > 0) {
			const stFilters = [...shipmentTypesFilters];
			filteredItems = filteredItems.filter((item) => {
				const itemShipmentTypes = [...item.shipment_types];
				return stFilters.every((ist) => {
					return (
						itemShipmentTypes.filter((stf) => {
							return ist.id === stf.id;
						}).length > 0
					);
				});
			});
		}

		setContainers(filteredItems);
	};

	const handleOnFilterBy = (filterTexted) => {
		setTextFilter(filterTexted);
	};

	const handleOnShipmentTypesFilterChange = (shipmentTypesSelected) => {
		setShipmentTypesFilters(shipmentTypesSelected);
	};

	useEffect(() => {
		filterData();
	}, [textFilter, shipmentTypesFilters]);

	const handleOnAddContainerType = () => {
		setFocusedItem(containerTypeModel);
	};

	const handleOnEditContainerType = (item) => {
		const editItem = JSON.parse(JSON.stringify(item));
		setFocusedItem(editItem);
	};

	const handleOnModalClose = () => {
		setFocusedItem(null);
		setDeleteAlertOpen(false);
	};

	const handleOnDeleteContainerType = (row) => {
		const deleteItem = {
			id: row.id,
			title: row.title
		};
		setFocusedItem(deleteItem);
		setDeleteAlertOpen(true);
	};

	const handleOnDeleteConfirm = () => {
		deleteContainerCategoriesService(focusedItem?.id)
			.then(() => {
				getCategories();
				toast('Categoría eliminada', { type: 'success' });
			})
			.catch((error) => {
				toast('Error, no pudo eliminar la categoría', {
					type: 'error'
				});
				showErrorMessages(error);
			});
		setFocusedItem(null);
		setDeleteAlertOpen(false);
	};

	const handleOnSaveConfirm = () => {
		setFocusedItem(null);
		setDeleteAlertOpen(false);
		getCategories();
	};

	const handleOnShowProducts = (data) => {
		setFocusedItem(data);
		setRedirectToProducts(true);
	};

	return (
		<Fragment>
			<ContainerTypesView
				containers={containers}
				loading={loadingData}
				onFilterBy={handleOnFilterBy}
				onShipmentTypesFilterChange={handleOnShipmentTypesFilterChange}
				onAdd={handleOnAddContainerType}
				onEdit={handleOnEditContainerType}
				onDelete={handleOnDeleteContainerType}
				onProductsClick={handleOnShowProducts}
			/>
			{focusedItem && !deleteAlertOpen && (
				<ContainerTypeModal
					item={focusedItem}
					onClose={handleOnModalClose}
					onConfirm={handleOnSaveConfirm}
				/>
			)}
			{focusedItem && deleteAlertOpen && (
				<ModalComponent
					open={deleteAlertOpen}
					onClose={handleOnModalClose}
					onConfirm={handleOnDeleteConfirm}
					title="Borrar categoría de empaques "
				>
					{'¿Estas seguro de borrar ' + focusedItem.title + '?'}
				</ModalComponent>
			)}
			{focusedItem && redirectToProducts && (
				<Redirect
					to={{
						pathname: CONTAINERS_ROUTE,
						state: focusedItem
					}}
				/>
			)}
		</Fragment>
	);
};

export default ContainerTypesComponent;
