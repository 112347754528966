/** Import react/libraries section **/
import React, { useState, useEffect, useCallback } from 'react';

/** Import services section **/
import {
  getCPACService,
  updateCPACService
} from '../../../services/cpac-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import component section **/
import CPACModalView from './CPACModalView';

export const CPACModalComponent = ({ item, onClose, onConfirm }) => {
  const [clientCPAC, setClientCPAC] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const getClientCPAC = useCallback(() => {
    setIsLoaded(false);
    getCPACService({ client_id: item?.id })
      .then((response) => {
        setClientCPAC(response?.shipment_types_cpacs);
      })
      .catch((error) => {
        console.error('Error getting cpac: ', error);
      })
      .finally(() => {
        setIsLoaded(true);
      });
  }, [item.id]);

  useEffect(() => {
    getClientCPAC();
  }, [getClientCPAC]);

  const updateClientCPAC = () => {
    updateCPACService({ client_id: item?.id, shipment_types_cpacs: clientCPAC })
      .then((response) => {
        toast('Valores de CPAC actualizados correctamente', {
          type: 'success'
        });
        // toast.success('Valores de CPAC actualizados correctamente');
        onConfirm();
      })
      .catch((error) => {
        console.error('Error getting cpac: ', error);
      });
  };

  const handleOnSave = () => {
    updateClientCPAC();
  };

  const handleOnInputChange = (shipmentTypeId, input, value) => {
    let newClientCPAC = JSON.parse(JSON.stringify(clientCPAC));
    const itemFound = newClientCPAC.find(
      (cpac) => cpac?.shipment_type_id === shipmentTypeId
    );
    if (itemFound) {
      itemFound[input] = value;
      setClientCPAC(newClientCPAC);
    }
  };

  return (
    <CPACModalView
      item={item}
      isLoaded={isLoaded}
      clientCPAC={clientCPAC}
      onInputChange={handleOnInputChange}
      onClose={onClose}
      onSave={handleOnSave}
    />
  );
};

export default CPACModalComponent;
