/** Import react/libraries section **/
import { getMenuPermissionObject } from '../access/menu_permissions';
import { getRole } from './utils';

/** Import component section **/

/** Import helpers section **/

/** Import resources section **/

const checkPermissions = (allowedPermissions, id) => {
  let userPermissions = getMenuPermissionObject(id);
  if (allowedPermissions.length === 0) {
    return true;
  }
  const found = userPermissions?.permissions?.find(
    (element) => element === getRole()
  );

  return found !== undefined;
};

const AccessControl = ({
  id,
  children,
  renderNoAccess,
  allowedPermissions
}) => {
  const permitted = checkPermissions(allowedPermissions, id);

  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  permissions: [],
  renderNoAccess: () => null
};

export default AccessControl;
