/** Import react/libraries section **/
import React from 'react';

/** Import resources **/
import Paper from '@material-ui/core/Paper';

/** Import styles section **/
import './ConsigmentNoteStyles.scss';

/** Import helpers section **/
import { SHIPMENTS_ROUTE } from '../../URL-Routes';
import {
  INITIAL_STATUS,
  SENT_STATUS,
  STAMPED_STATUS
} from './helpers/Constants';

/** Import component section **/
import PageTitle from './../../components/PageTitle';
import MUIDateField from '../../components/UI/inputs/MUIDateField';
import MUITextField from '../../components/UI/inputs/MUITextField';
import MUISelect from '../../components/UI/inputs/MUISelect';
import AddButton from '../../components/UI/AddButton';
import GoodsComponent from './components/Goods';

const ConsigmentNoteView = ({
  onInputDateChange,
  disabled,
  shipmentId,
  onRequestAdnService,
  onCheckStatus,
  consigmentNoteData,
  billingBusiness,
  consigmentNoteState,
  consigmentNoteSent
}) => {
  return (
    <>
      <PageTitle title="Complemento de carta porte" backUrl={SHIPMENTS_ROUTE} />
      <Paper className="consigmentNote_header">
        <div className="consigmentNote_header_complementData">
          <MUIDateField
            id="date"
            name="send_date"
            label="Fecha de envio"
            value={consigmentNoteData?.send_date}
            onChange={onInputDateChange}
          />
          <MUITextField
            id="hour"
            label="Hora de envio"
            value={consigmentNoteData?.send_hour}
            type="time"
            name="send_hour"
            onChange={onInputDateChange}
          />
          <MUIDateField
            id="date_delivery"
            name="delivery_date"
            label="Fecha de entrega"
            value={consigmentNoteData?.delivery_date}
            onChange={onInputDateChange}
          />
          <MUITextField
            id="hour_delivery"
            label="Hora de entrega"
            value={consigmentNoteData?.delivery_hour}
            type="time"
            name="delivery_hour"
            onChange={onInputDateChange}
          />
          {/* <MUISelect
            id="payment_method"
            label="Metodos de pago"
            name="sat_payment_method_id"
            idSelector="id"
            selector="type"
            onChange={onInputDateChange}
            items={paymenthMethods}
            value={consigmentNoteData?.sat_payment_method_id}
          /> */}
          <MUISelect
            id="business_billing_id"
            label="Empresas de facturacion"
            name="business_billing_id"
            idSelector="id"
            selector="rfc"
            onChange={onInputDateChange}
            items={billingBusiness}
            value={consigmentNoteData?.business_billing_id}
          />
        </div>
        <div className="consigmentNote_header_down">
          {consigmentNoteState !== INITIAL_STATUS ? (
            <div className="consigmentNote_header_down-info">
              <div className="consigmentNote_header_down-info_values">
                <div>
                  Folio de Carta Porte:
                  <span className="consigmentNote_header_down-info_values_text">
                    {consigmentNoteData?.folio}
                  </span>
                </div>
                <div className="consigmentNote_header_down-info_values_status">
                  {!!consigmentNoteData?.status}
                  <div>
                    Estatus de Carta Porte:
                    <span className="consigmentNote_header_down-info_values_text">
                      {consigmentNoteData?.status}
                    </span>
                  </div>
                  <div>
                    Ultima fecha de consulta:
                    <span className="consigmentNote_header_down-info_values_text">
                      {consigmentNoteData?.last_read_date}
                    </span>
                  </div>
                </div>
              </div>
              <div className="consigmentNote_header_down-info_actions">
                {consigmentNoteState === SENT_STATUS && (
                  <div className="consigmentNote_header_actions">
                    <AddButton
                      text="Consultar estatus de carta porte"
                      onClick={onCheckStatus}
                    />
                  </div>
                )}

                {consigmentNoteState === STAMPED_STATUS && (
                  <div>
                    Descargar documentos fiscales
                    <div className="consigmentNote_header_down-info_actions-files">
                      <AddButton
                        text="Archivo xml"
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = consigmentNoteData?.xml_url;
                        }}
                      />
                      <AddButton
                        text="Archivo pdf"
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = consigmentNoteData?.pdf_url;
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="consigmentNote_header_down-add">
              <AddButton
                text="Enviar solicitud de carta porte"
                disabled={disabled}
                onClick={onRequestAdnService}
              />
            </div>
          )}
        </div>
      </Paper>
      <div>
        <GoodsComponent
          id={shipmentId}
          consigmentNoteSent={consigmentNoteSent}
        />
      </div>
    </>
  );
};
export default ConsigmentNoteView;
