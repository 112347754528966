import React, { Fragment } from 'react';
import './BillingFormStyles.scss';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';

import { Card, CardContent, CardHeader } from '@material-ui/core';

import MUITextField from '../../UI/inputs/MUITextField';
import MUISelect from '../../../components/UI/inputs/MUISelect';
import { useSelector } from 'react-redux';

export const BillingFormView = ({
  cfdi,
  states,
  cities,
  client,
  billing,
  paymentTypes,
  onBillingChanges,
  useSameClientData,
  onRequireBillChange,
  onStateSelectChange,
  onBillingSelectChange
}) => {
  let isShipment = useSelector((store) => store.quote?.quotation?.is_shipment);
  const disableForm = billing?.bill === 2 || client?.same_data;

  return (
    <Card className="billingForm">
      <CardHeader
        title="Require factura"
        className="billingForm_header"
        action={
          <Fragment>
            <FormControl component="fieldset">
              <RadioGroup
                className="billingForm_header-radios"
                aria-label="requiere factura"
                name="bill"
                value={client?.bill_required}
                onChange={onRequireBillChange}
              >
                <FormControlLabel
                  value={0}
                  control={<Radio data-cy="cy-billing-no" />}
                  disabled={isShipment}
                  label="No"
                />
                <FormControlLabel
                  value={1}
                  control={<Radio data-cy="cy-billing-yes" />}
                  disabled={isShipment}
                  label="Si"
                />
              </RadioGroup>
            </FormControl>
          </Fragment>
        }
      />
      {client?.bill_required === 1 ? (
        <CardContent>
          <div className="billingForm_same">
            <FormControlLabel
              id="same_data"
              control={
                <Checkbox
                  className="billingForm_same_checkbox"
                  checked={client?.same_data}
                  onChange={useSameClientData}
                  name="client_data"
                  disabled={isShipment}
                />
              }
              label="Mismos datos del cliente."
            />
          </div>
          <div className="billingForm_content">
            <MUITextField
              required
              id="billing_name"
              name="name"
              data-cy="cy-billing-name"
              className="name"
              label="Nombre / Razón social"
              value={billing?.name}
              disabled={disableForm}
              onChange={onBillingChanges}
            />

            <MUITextField
              required
              id="billing_rfc"
              name="rfc"
              data-cy="cy-billing-rfc"
              className="rfc"
              label="RFC"
              value={billing?.rfc}
              disabled={disableForm}
              onChange={onBillingChanges}
            />

            <MUISelect
              id="state"
              label="Estado"
              dataCy="cy-state"
              defaultValue={billing?.state_id}
              items={states}
              idSelector="id"
              selector="name"
              noSelectedLabel="Seleccione"
              name="state_id"
              onChange={onStateSelectChange}
              disabled={disableForm}
              readOnly={isShipment}
            />

            <MUISelect
              id="city"
              label="Ciudad"
              dataCy="cy-city"
              defaultValue={billing?.city_id}
              items={cities}
              idSelector="id"
              selector="name"
              noSelectedLabel="Seleccione"
              name="city_id"
              disabled={disableForm}
              onChange={onStateSelectChange}
              readOnly={isShipment}
            />

            <MUITextField
              required
              id="billing_street"
              name="street"
              data-cy="cy-billing-street"
              label="Dirección"
              value={billing?.street}
              disabled={disableForm}
              onChange={onBillingChanges}
            />

            <MUITextField
              required
              id="billing_cp"
              name="cp"
              data-cy="cy-billing-cp"
              label="CP"
              value={billing?.cp}
              disabled={disableForm}
              onChange={onBillingChanges}
            />

            <MUITextField
              required
              id="billing_email"
              name="email"
              data-cy="cy-billing-email"
              label="Email de facturación"
              value={billing?.email}
              disabled={disableForm}
              onChange={onBillingChanges}
            />

            <MUISelect
              id="billing_payment_type"
              label="Tipo de Pago"
              dataCy="cy-payType"
              defaultValue={billing?.payment_type_id}
              items={paymentTypes}
              idSelector="id"
              selector="title"
              noSelectedLabel="Tipo pago"
              name="payment_type_id"
              disabled={disableForm}
              onChange={onBillingSelectChange}
              readOnly={isShipment}
            />

            <MUISelect
              id="billing_cfdi"
              label="Uso de CFDI"
              dataCy="cy-cfdi"
              defaultValue={billing?.cfdi_id}
              items={cfdi}
              idSelector="id"
              selector="description"
              noSelectedLabel="CFDI"
              name="cfdi_id"
              disabled={disableForm}
              onChange={onBillingSelectChange}
              readOnly={isShipment}
            />
          </div>
        </CardContent>
      ) : null}
    </Card>
  );
};

export default BillingFormView;
