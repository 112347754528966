/* eslint-disable react-hooks/exhaustive-deps */
/** Import react/libraries section **/
import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

/** Import component section **/
import QuotesView from './QuotesView';

/** Import helpers section **/
import { t as typy } from 'typy';
import { setQuote } from '../../redux/quotation/quoteSlice';
import { requestUsersAssign } from '../../services/users-services';
import { saveAssignRequest } from '../../services/request-services';
import {
  deleteQuoteService,
  getQuotesService,
  updateShipmentDiscount
} from '../../services/quotes-services';
import {
  billingModel,
  clientModel,
  composeQuotation,
  deliveryTypeModel,
  destinationModel,
  originModel
} from '../../models/quoteModels';
import moment from 'moment';
import { QUOTE_EDIT, QUOTE_NEW } from '../../URL-Routes';
import ModalComponent from '../../components/UI/ModalComponent';
import MUITextField from '../../components/UI/inputs/MUITextField';
import {
  STATUS_APPROVED,
  STATUS_CANCELED,
  STATUS_QUOTATION
} from '../../utils/enumerates';
import { showErrorMessages } from '../../utils/utils';

const QuotesComponent = () => {
  let dispatch = useDispatch();

  const [mode, setMode] = useState('');
  const [users, setUsers] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [quoteID, setQuoteID] = useState(null);
  const [quoteId, setQuoteId] = useState(null);
  const [editing, setEditing] = useState(false);
  const [showClear, setShowClear] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [showCanceled, setShowCanceled] = useState(false);
  const [assignedUser, setAssignedUser] = useState(null);
  const [showAssignDialog, setShowAssignDialog] = useState(false);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [showDiscountDialog, setShowDiscountDialog] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [trackingGuide, setTrackingGuide] = useState('');
  const [filteredText, setFilteredText] = useState('');

  /**
   * Request all quotations, then filter those that are not canceled
   * '1', 'Cotizacion'
   * '2', 'Envio'
   * '3', 'Cancelado'
   * '4', 'Terminado'
   * '5', 'servicio por cobrar'
   */
  const getQuotes = () => {
    setLoadingData(true);
    getQuotesService()
      .then((response) => {
        const quotesArr = typy(response).safeArray;
        let quotations = quotesArr.filter(
          (x) =>
            x.shipment_status_id === STATUS_QUOTATION ||
            x.shipment_status_id === STATUS_APPROVED
        );
        setOriginalData(quotesArr);
        setQuotes(quotations);
      })
      .catch((error) => {
        console.error('QuotesComponent - Error getting quotes: ', error);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  /**
   * Here we filter and set quotes by status,
   * by default canceled are not visible.
   */
  // useEffect(() => {
  //   if (showCanceled === true) {
  //     setQuotes(originalData.filter(x => x.shipment_status_id === 1 || x.shipment_status_id === 3));
  //   } else {
  //     setQuotes(originalData.filter(x => x.shipment_status_id === 1 ));
  //   }
  // }, [  ]);

  useEffect(() => {
    getQuotes();
  }, []);

  // useEffect(() => {
  //   if (dateRange.start !== null && dateRange.end !== null) {
  //     let start = dateRange.start;
  //     let end = moment(dateRange.end).add(12, 'h');
  //     // let filtered = originalData.filter(x => moment(x.created_at).isBetween(start, end));
  //     let filtered = originalData.filter((x) =>
  //       moment(x.created_at).isBetween(
  //         start,
  //         moment(end).add(12, 'hour')
  //       )
  //     );
  //     setQuotes(filtered);
  //   } else {
  //     setQuotes(originalData);
  //   }
  // }, [dateRange.start, dateRange.end]);

  // const onShowCanceled = (event) => {
  //   let { checked } = event.target;

  //   if (checked === true) {
  //     setQuotes(
  //       originalData.filter(
  //         (x) => x.shipment_status_id === STATUS_CANCELED
  //       )
  //     );
  //   } else {
  //     setQuotes(originalData.filter((x) => x.shipment_status_id === 1));
  //   }
  //   setShowCanceled(checked);
  // };

  // const handleOnFilterBy = (filterText) => {
  //   let filteredItems;

  //   if (filterText.length === 0) {
  //     filteredItems = [...originalData];
  //   } else {
  //     const filteringText = filterText
  //       .toLowerCase()
  //       .normalize('NFD')
  //       .replace(/[\u0300-\u036f]/g, '');

  //     filteredItems = originalData.filter((item) => {
  //       let fullName =
  //         item.assigned?.name + '' + item.assigned?.last_name;

  //       const field0 = item.client.business_name
  //         ?.toString()
  //         .toLowerCase();
  //       const field1 = item.recollection_date
  //         ?.toString()
  //         .toLocaleLowerCase();
  //       const field2 = item.shipment_status?.toString().toLowerCase();
  //       const field3 = item.client.name
  //         ?.toString()
  //         .normalize('NFD')
  //         .replace(/[\u0300-\u036f]/g, '');
  //       const field4 = fullName
  //         .toString()
  //         .toLowerCase()
  //         .normalize('NFD')
  //         .replace(/[\u0300-\u036f]/g, '');
  //       const field5 = item.client.rfc.toString().toLowerCase();
  //       const field6 = item.tracking_guide.toString().toLowerCase();

  //       return (
  //         field0?.includes(filteringText) ||
  //         field1?.includes(filteringText) ||
  //         field2?.includes(filteringText) ||
  //         field3?.includes(filteringText) ||
  //         field5?.includes(filteringText) ||
  //         field6?.includes(filteringText) ||
  //         (field4?.includes(filteringText) && true)
  //       );
  //     });
  //   }

  //   setQuotes(filteredItems);
  // };
  const onShowCanceled = (event) => {
    let { checked } = event.target;
    // console.log(typeof checked);
    // console.log(checked);
    setShowCanceled(checked);
  };

  const filterQuotation = useCallback(() => {
    let filteredItems = [...originalData];
    const filteringText = filteredText
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    filteredItems = filteredItems.filter((item) => {
      let fullName = item.assigned?.name + '' + item.assigned?.last_name;

      const field0 = item.client.business_name?.toString().toLowerCase();
      const field1 = item.recollection_date?.toString().toLocaleLowerCase();
      const field2 = item.shipment_status?.toString().toLowerCase();
      const field3 = item.client.name
        ?.toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const field4 = fullName
        .toString()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const field5 = item.client.rfc.toString().toLowerCase();
      const field6 = item.tracking_guide.toString().toLowerCase();

      return (
        field0?.includes(filteringText) ||
        field1?.includes(filteringText) ||
        field2?.includes(filteringText) ||
        field3?.includes(filteringText) ||
        field5?.includes(filteringText) ||
        field6?.includes(filteringText) ||
        (field4?.includes(filteringText) && true)
      );
    });

    if (dateRange.start !== null && dateRange.end !== null) {
      let start = dateRange.start;
      let end = moment(dateRange.end).add(12, 'h');
      // let filtered = originalData.filter(x => moment(x.created_at).isBetween(start, end));
      filteredItems = filteredItems.filter((x) =>
        moment(x.created_at).isBetween(start, moment(end).add(12, 'hour'))
      );
    }
    // console.log(showCanceled);
    if (showCanceled === true) {
      // console.log('entro');
      filteredItems = filteredItems.filter(
        (x) => x.shipment_status_id === STATUS_CANCELED
      );
    } else {
      filteredItems = filteredItems.filter((x) => x.shipment_status_id === 1);
    }
    setQuotes(filteredItems);
  }, [
    originalData,
    filteredText,
    dateRange.start,
    dateRange.end,
    showCanceled
  ]);
  useEffect(() => {
    filterQuotation();
  }, [filterQuotation, filteredText, dateRange, showCanceled]);

  const handleOnFilterBy = (filterText) => {
    setFilteredText(filterText);
    // console.log(filterText);
  };

  const handleOnAddQuote = () => {
    setEditing(true);
    let quote = composeQuotation(
      null,
      clientModel,
      billingModel,
      originModel,
      destinationModel,
      null,
      1,
      [],
      deliveryTypeModel
    );
    dispatch(setQuote({ quote: quote }));
    setMode('new');
  };

  const handleOnEditQuote = (row) => {
    setQuoteID(row.id);
    setMode('edit');
    setEditing(true);
  };

  const onShowAssignRequest = (requestId) => {
    setQuoteId(requestId);
    requestUsersAssign()
      .then((response) => {
        setUsers(response);
        setShowAssignDialog(true);
      })
      .catch((error) => {
        console.error('RequestsComponent - Error requesting users: ', error);
      });
  };

  const handleDateChange = (event) => {
    if (event !== null) {
      setShowClear(true);
      let { value, name } = event;
      if (name === 'start') {
        setDateRange((prevState) => ({
          ...prevState,
          start: value
        }));
        // setStartDate(value);
      } else {
        setDateRange((prevState) => ({
          ...prevState,
          end: value
        }));
        // setEndDate(value);
      }
    }
  };

  const onClear = () => {
    setDateRange({ start: null, end: null });
    setQuotes(originalData);
    setShowClear(false);
    setShowCanceled(false);
  };

  const onSelectChange = (event) => setAssignedUser(event);

  const onCloseDialog = () => setShowAssignDialog(false);

  // const handleOnDeleteQuote = (quote) => {
  //   deleteQuoteService( quote.id ).then( () => {
  //     setLoadingData( false );
  //     getQuotes();
  //     setLoadingData( false );
  //     toast( 'Cotización eliminada.', {type: 'success'} );
  //   } ).catch( (error) => {
  //     // console.error( 'QuotesComp - Deletion error: ', error );
  //     toast( 'Error al eliminar cotización.', {type: 'error'} );
  //     showErrorMessages( error );
  //   } );
  // };

  const onAssignRequest = () => {
    saveAssignRequest(quoteId, assignedUser)
      .then((response) => {
        dispatch(setQuote({ quote: response }));
        toast('Cotización asignada', { type: 'success' });
        getQuotes();
        setLoadingData(false);
      })
      .catch((error) => {
        // console.error( 'Error al asignar cotización: ', error );
        toast('Error, nos se pudo asignar.', { type: 'error' });
        showErrorMessages(error);
      })
      .finally(() => setShowAssignDialog(false));
  };

  const onShowDiscountDialog = (quoteId) => {
    setQuoteId(quoteId);
    setShowDiscountDialog(true);
  };

  const onApplyDiscount = () => {
    updateShipmentDiscount(quoteId, discount)
      .then(() => {
        toast('Se aplico el % de descuento', { type: 'success' });
      })
      .catch((error) => {
        toast('Error al aplicar % de descuento', { type: 'error' });
        showErrorMessages(error);
      })
      .finally(() => {
        setQuoteID(null);
        setShowDiscountDialog(false);
      });
  };

  const onDeleteQuotation = (quotationId, trackingGuide) => {
    setQuoteId(quotationId);
    setTrackingGuide(trackingGuide);
    setShowAlert(true);
  };

  const onConfirm = () => {
    deleteQuoteService(quoteId)
      .then(() => {
        setLoadingData(false);
        getQuotes();
        setLoadingData(false);
        toast('Cotización eliminada.', { type: 'success' });
      })
      .catch((error) => {
        showErrorMessages(error);
      })
      .finally(() => {
        setQuoteId(null);
        setTrackingGuide('');
        setShowAlert(false);
      });
  };

  const onCloseAlert = () => {
    setShowAlert(false);
    setQuoteId(null);
    setTrackingGuide('');
  };

  return (
    <Fragment>
      {editing === true ? (
        <Redirect
          to={{
            pathname: mode === 'edit' ? `${QUOTE_EDIT}/${quoteID}` : QUOTE_NEW
          }}
        />
      ) : null}

      <QuotesView
        data={quotes}
        users={users}
        loading={loadingData}
        onClear={onClear}
        dateRange={dateRange}
        showClear={showClear}
        addHandler={handleOnAddQuote}
        showCanceled={showCanceled}
        onSelectChange={onSelectChange}
        onCloseDialog={onCloseDialog}
        onShowCanceled={onShowCanceled}
        onApplyDiscount={onApplyDiscount}
        onAssignRequest={onAssignRequest}
        handleDateChange={handleDateChange}
        showAssignDialog={showAssignDialog}
        filteringHandler={handleOnFilterBy}
        handleOnEditQuote={handleOnEditQuote}
        onShowAssignRequest={onShowAssignRequest}
        handleOnDeleteQuote={onDeleteQuotation}
        onShowDiscountDialog={onShowDiscountDialog}
      />

      {showDiscountDialog === true ? (
        <ModalComponent
          open={showDiscountDialog}
          onClose={() => setShowDiscountDialog(false)}
          onConfirm={onApplyDiscount}
          title={'Aplicar % de descuento'}
        >
          <MUITextField
            label="% de descuento"
            data-cy="cy-name"
            id="name"
            name="name"
            value={discount}
            type="number"
            onChange={(e) => setDiscount(parseFloat(e.value))}
          />
        </ModalComponent>
      ) : null}

      {showAlert === true ? (
        <ModalComponent
          open={showAlert}
          onClose={onCloseAlert}
          onConfirm={onConfirm}
          title={'Borrar cotización: ' + trackingGuide}
        >
          {'¿Esta seguro que desea borrar la cotización seleccionada ?'}
        </ModalComponent>
      ) : null}
    </Fragment>
  );
};

export default QuotesComponent;
