/** Import react/libraries section **/
import React from 'react';

/** Import component section **/
/** Import helpers section **/
/** Import resources section **/
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Card, CardContent} from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import './ShipmentTypeStyles.scss';

const ShipmentTypeView = ({shipmentTypes, deliveryTypeData, onShipmentChange, isShipment}) => {
	const composeLabels = (index, title) => {
		return (
			<div className="shipmentTypesForm_content_radiogroup_radio_label">
				<b>{title}</b>
				<br/>
				{index === 0 ? <span>(de 0.5 a 1kg)</span> : null}
				{index === 1 ? <span>(de 1Kg a 70kg)</span> : null}
			</div>
		);
	};
	
	return (
		<Card className="shipmentTypesForm">
			<CardContent className="shipmentTypesForm_content">
				<RadioGroup
					className="shipmentTypesForm_content_radiogroup"
					aria-label="gender"
					name="deliveryType"
					value={deliveryTypeData?.shipment_type_id}
					onChange={onShipmentChange}
				>
					{
						shipmentTypes?.map( (type, index) => (
							<FormControlLabel
								key={index}
								label={composeLabels( index, type.title )}
								data-cy={`cy-${type.title}`}
								value={type.id}
								control={<Radio/>}
								disabled={isShipment}
								className="shipmentTypesForm_content_radiogroup_radio"
							/>
						) )}
				</RadioGroup>
			</CardContent>
		</Card>
	);
};

// ShipmentTypeView.propTypes = {
//   shipmentTypes: PropTypes.oneOf([ PropTypes.array, PropTypes.object ]),
//   deliveryTypeData: PropTypes.oneOf([ PropTypes.array, PropTypes.object ]),
//   onShipmentChange: PropTypes.func.isRequired
// };

export default ShipmentTypeView;
