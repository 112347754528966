/** Import react/libraries section **/
import React, { useCallback, useEffect, useState } from 'react';

/** Import component section **/
import ReceivableReportsView from './ReceivableReportsView';
import { getReceivableReportService } from '../../services/reports-service';
import { formatNumberToCurrency } from '../../utils/formats';
// import moment from 'moment';
/** Import helpers section **/

/** Import resources section **/

const ReceivableReportsComponent = () => {
	const [loading, setLoading] = useState(false);
	const [receivables, setReceivables] = useState([]);
	const [originalData, setOriginalData] = useState([]);
	const [filteredText, setFilteredText] = useState('');
	const [showClear, setShowClear] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [dateRange, setDateRange] = useState({ start: null, end: null });

	useEffect(() => {
		setLoading(true);
		getReceivableReportService()
			.then((response) => {
				const payments = response?.payments;
				let data = [];
				payments.forEach((item) => {
					data.push({
						id: item.id,
						business_name: item?.business_name,
						assigned: item?.assigned ?? '',
						total: formatNumberToCurrency(item.total),
						pending_total: formatNumberToCurrency(
							item.pending_total
						),
						days_expired: item?.days_expired,
						total_expired: formatNumberToCurrency(item.total)
					});
				});
				setReceivables(data);
				setOriginalData(data);
			})
			.catch((error) => {
				console.error(
					'ReportsComp -> Error requesting reports: ',
					error
				);
			})
			.finally(() => setLoading(false));
	}, []);

	// const onFilterHandler = (filterText) => {
	// 	let filteredItems;
	// 	if (filterText.length === 0) {
	// 		filteredItems = [...originalData];
	// 	} else {
	// 		const filteringText = filterText.toLowerCase();

	// 		filteredItems = originalData.filter((item) => {
	// 			const field1 = item.tracking_guide.toLowerCase();
	// 			const field2 = item.quotation_date?.toString();
	// 			const field3 = item.shipment_status?.toString();
	// 			const field4 = item.name.toString().toLowerCase();
	// 			const field5 = item.client.toLowerCase();
	// 			const field6 = item.service_type?.toString().toLowerCase();

	// 			return (
	// 				field1.includes(filteringText) ||
	// 				field2.includes(filteringText) ||
	// 				field3.includes(filteringText) ||
	// 				field4.includes(filteringText) ||
	// 				field5.includes(filteringText) ||
	// 				(field6.includes(filteringText) && true)
	// 			);
	// 		});
	// 	}

	// 	setReceivables(filteredItems);
	// };

	// useEffect(() => {
	// 	if (selectedStatus !== null) {
	// 		setShowClear(true);
	// 		let filteredItems = originalData.filter(
	// 			(item) => item.shipment_status_id === selectedStatus
	// 		);
	// 		setReceivables(filteredItems);
	// 	}
	// }, [selectedStatus, originalData]);

	// useEffect(() => {
	// 	if (dateRange.start !== null && dateRange.end !== null) {
	// 		let start = dateRange.start;
	// 		let end = moment(dateRange.end).add(12, 'h');
	// 		let filtered = originalData.filter((x) =>
	// 			moment(x.quotation_date).isBetween(
	// 				start,
	// 				moment(end).add(12, 'hour')
	// 			)
	// 		);

	// 		setReceivables(filtered);
	// 	} else {
	// 		setReceivables(originalData);
	// 	}
	// }, [dateRange.start, dateRange.end, originalData]);

	//new implementation for filtering
	const filterRecivables = useCallback(() => {
		let filteredItems = [...originalData];
		const filterText = filteredText.toLocaleLowerCase();
		if (filterText.length > 0) {
			filteredItems = filteredItems.filter((item) => {
				const field1 = item.business_name.toLowerCase();
				const field2 = item.assigned.toLowerCase();

				return (
					field1.includes(filterText) ||
					(field2.includes(filterText) && true)
				);
			});
		}

		// if (dateRange.start !== null && dateRange.end !== null) {
		// 	let start = dateRange.start;
		// 	let end = moment(dateRange.end).add(12, 'h');
		// 	filteredItems = filteredItems.filter((x) =>
		// 		moment(x.quotation_date).isBetween(
		// 			start,
		// 			moment(end).add(12, 'hour')
		// 		)
		// 	);
		// }

		// if (selectedStatus !== null) {
		// 	setShowClear(true);
		// 	filteredItems = filteredItems.filter(
		// 		(item) => item.shipment_status_id === selectedStatus
		// 	);
		// }

		setReceivables(filteredItems);
	}, [filteredText, originalData]);

	useEffect(() => {
		filterRecivables();
	}, [filterRecivables, filteredText, dateRange, selectedStatus]);

	const handleOnFilterBy = (filterText) => {
		setFilteredText(filterText);
		// console.log(filterText);
	};

	const handleOnStatusChange = (value) => {
		setSelectedStatus(value);
	};

	const handleDateChange = (event) => {
		if (event !== null) {
			setShowClear(true);
			let { value, name } = event;
			if (name === 'start') {
				setDateRange((prevState) => ({
					...prevState,
					start: value
				}));
			} else {
				setDateRange((prevState) => ({
					...prevState,
					end: value
				}));
			}
		}
	};

	const onClear = (event) => {
		event.preventDefault();
		setReceivables(originalData);
		setDateRange({ start: null, end: null });
		setSelectedStatus(null);
		setShowClear(false);
	};

	return (
		<ReceivableReportsView
			loading={loading}
			onClear={onClear}
			receivables={receivables}
			dateRange={dateRange}
			showClear={showClear}
			onFilter={handleOnFilterBy}
			selectedStatus={selectedStatus}
			handleDateChange={handleDateChange}
			handleOnStatusChange={handleOnStatusChange}
		/>
	);
};

export default ReceivableReportsComponent;
