/** Import react/libraries section **/
import React, {useState} from 'react';
import PropTypes from 'prop-types';

/** Import component section **/
/** Import helpers section **/
/** Import resources section **/
import {Card, CardContent, CardHeader, Fab, TextField, Tooltip} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import './EspecialRequerimentsStyles.scss';


const EspecialRequirementsView = ({specialRequirements, deliveryTypeData, onChipClick, onOtherSpecialChange}) => {
	
	const [text, setText] = useState( '' );
	
	const composeSpecialRequirements = () => {
		return specialRequirements.map( (requirement, index) => {
			return (
				<Chip
					label={requirement.title}
					onClick={(event) => onChipClick( event, requirement, index )}
					className={requirement.selected === 0 ? 'normal' : 'selected'}
				/>
			);
		} );
	};
	
	const handleAdd = (event) => {
		event.preventDefault();
		onOtherSpecialChange( text );
		setText( '' );
	};
	
	return (
		<div>
			{
				// eslint-disable-next-line no-mixed-operators
				specialRequirements?.length > 0 &&
				// eslint-disable-next-line no-mixed-operators
				deliveryTypeData?.shipment_type_id === 4 ||
				deliveryTypeData?.shipment_type_id === 3 ?
					
					<Card className="requirements">
						<CardHeader title="Requerimientos especiales"/>
						
						<CardContent className="requirements_content">
							<div>{composeSpecialRequirements()}</div>
							
							<div className="requirements_content-other">
								<TextField
									multiline
									className="requirements-input"
									type="text"
									name="other"
									value={text.slice(0, 149)}
									rows={3}
									rowsMax={4}
									label={text.length > 151 ? 'Longitud de texto excedida!' : 'Otro (especifique)'}
									onChange={(event) => setText( event.target.value )}
									error={text.length > 150}
								/>
								<Tooltip title="Agregar requerimiento">
									<Fab
										className={text === '' ? 'requirements_content-other-disabled': ''}
										onClick={handleAdd}
										size="small"
										disabled={text === ''}
									>
										<AddIcon/>
									</Fab>
								</Tooltip>
							</div>
						</CardContent>
					</Card>
					
					:
					null
			}
		</div>
	);
};

EspecialRequirementsView.propTypes = {
	specialRequirements: PropTypes.array.isRequired,
	deliveryTypeData: PropTypes.array.isRequired,
	onChipClick: PropTypes.func.isRequired,
	onOtherSpecialChange: PropTypes.func.isRequired,
};

export default EspecialRequirementsView;

