/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import component section **/
import MUISelect from '../../UI/inputs/MUISelect';
import { CardContent } from '@material-ui/core';

import MUITextField from '../../UI/inputs/MUITextField';

/** Import helpers section **/
import { MODE_REQUEST, OTHER_CONTAINER } from '../../../utils/enumerates';

/** Import resources section **/
import '../ShipDetailsForm/detailforms/PackagingStyles.scss';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';

const PackagingView = ({
  index,
  products,
  modeType,
  containers,
  isShipment,
  onRadioChange,
  deliveryTypeData,
  onServiceDetailsChange,
  onContainerSelectChange
}) => {
  const [unitType, setUnitType] = useState('');

  useEffect(() => {
    setUnitType(deliveryTypeData?.shipment_type_id === 2 ? '(cm)' : '(m)');
  }, [deliveryTypeData?.shipment_type_id]);

  return (
    <CardContent className="packagingDetail">
      <MUISelect
        aria-label="tipos de contenedor"
        label="Empaque"
        id="package"
        name="product_id"
        idSelector="id"
        selector="title"
        onChange={onContainerSelectChange}
        items={containers}
        value={deliveryTypeData.product_id}
        readOnly={isShipment}
      />

      <MUISelect
        aria-label="tipos de producto"
        label="Productos"
        id="product"
        name="package_id"
        idSelector="id"
        selector="title"
        onChange={onContainerSelectChange}
        items={products}
        value={deliveryTypeData.package_id}
        readOnly={isShipment}
      />

      {deliveryTypeData.container_type_id === OTHER_CONTAINER &&
      modeType === MODE_REQUEST ? (
        <MUITextField
          id="other"
          name="other_value"
          type="text"
          label="Descripción"
          value={deliveryTypeData?.other_value}
          onChange={onServiceDetailsChange}
        />
      ) : null}

      <MUITextField
        id="quantity"
        name="quantity"
        type="number"
        label="Cantidad"
        value={deliveryTypeData.quantity}
        onChange={onServiceDetailsChange}
      />

      <MUITextField
        name="content"
        id="content"
        label="Material o contenido"
        value={deliveryTypeData.content}
        onChange={onServiceDetailsChange}
      />
      <MUITextField
        name="long"
        id="long"
        type="number"
        label={'Largo ' + unitType}
        value={deliveryTypeData.long}
        onChange={onServiceDetailsChange}
      />
      <MUITextField
        name="width"
        id="width"
        type="number"
        label={'Ancho ' + unitType}
        value={deliveryTypeData.width}
        onChange={onServiceDetailsChange}
      />
      <MUITextField
        name="height"
        id="height"
        type="number"
        label={'Alto ' + unitType}
        value={deliveryTypeData.height}
        onChange={onServiceDetailsChange}
      />
      <MUITextField
        name="weight"
        id="weight"
        type="number"
        label="Peso (kg)"
        value={deliveryTypeData.weight}
        onChange={onServiceDetailsChange}
      />
      <MUITextField
        name="comments"
        id="comments"
        label="Comentarios"
        value={deliveryTypeData.comments}
        inputProps={{ maxLength: 256 }}
        onChange={onServiceDetailsChange}
      />
      <MUITextField
        name="volumetric"
        id="volumetric"
        label="Peso Volumetrico"
        value={deliveryTypeData.volumetric_weight}
        onChange={onServiceDetailsChange}
        disabled
      />

      <FormControl className="packagingDetail_stackable">
        <p>Estibable</p>
        <RadioGroup
          className="packagingDetail_stackable_radios"
          name="stackable"
          value={deliveryTypeData?.stackable}
          onChange={(e) => onRadioChange(e, index)}
        >
          <FormControlLabel
            value={0}
            control={<Radio />}
            label="No"
            disabled={isShipment}
          />
          <FormControlLabel
            value={1}
            control={<Radio />}
            label="Si"
            disabled={isShipment}
          />
        </RadioGroup>
      </FormControl>

      <FormControl className="packagingDetail_fragile">
        <p>Frágil</p>
        <RadioGroup
          className="packagingDetail_fragile_radios"
          name="fragile"
          value={deliveryTypeData?.fragile}
          onChange={(e) => onRadioChange(e, index)}
        >
          <FormControlLabel
            value={0}
            control={<Radio />}
            label="No"
            disabled={isShipment}
          />
          <FormControlLabel
            value={1}
            control={<Radio />}
            label="Si"
            disabled={isShipment}
          />
        </RadioGroup>
      </FormControl>
    </CardContent>
  );
};

export default PackagingView;
