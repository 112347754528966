/** Import react/libraries section **/
import React, {Fragment} from 'react';

/** Import component section **/
import {CardContent} from '@material-ui/core';

import MUITextField from '../../../UI/inputs/MUITextField';

/** Import helpers section **/
/** Import resources section **/
import './MessagingStyles.scss';

const MessagingDetail = ({deliveryTypeData, onServiceDetailsChange}) => {
	
	return (
		<CardContent className="messagingDetail">
			<MUITextField
				id="quantity"
				type="number"
				label="Cantidad"
				name="quantity"
				data-cy="cy-message-quantity"
				value={deliveryTypeData.quantity}
				onChange={onServiceDetailsChange}
				required
			/>
			<MUITextField
				id="content"
				required
				type='text'
				name="content"
				data-cy="cy-message-content"
				label="Materíal o contenido"
				value={deliveryTypeData.content}
				onChange={onServiceDetailsChange}
			/>
			
			{
				deliveryTypeData?.shipment_type_id < 1 ?
					
					<Fragment>
						
						<MUITextField
							id="long"
							required
							name="long"
							data-cy="cy-message-long"
							type="number"
							label="Largo (cm)"
							value={deliveryTypeData.long}
							onChange={onServiceDetailsChange}
						/>
						<MUITextField
							id="width"
							required
							name="width"
							data-cy="cy-message-width"
							type="number"
							label="Ancho (cm)"
							value={deliveryTypeData.width}
							onChange={onServiceDetailsChange}
						/>
						<MUITextField
							id="height"
							required
							name="height"
							data-cy="cy-message-height"
							label="Alto (cm)"
							value={deliveryTypeData.height}
							onChange={onServiceDetailsChange}
						/>
					
					</Fragment>
					: null
			}
			
			<MUITextField
				id="weight"
				required
				name="weight"
				data-cy="cy-message-weight"
				type="number"
				label="Peso (Kg)"
				value={deliveryTypeData.weight}
				onChange={onServiceDetailsChange}
			/>
			
			<MUITextField
				id="comments"
				required
				name="comments"
				data-cy="cy-message-comments"
				type="text"
				label="Comentarios"
				value={deliveryTypeData.comments}
				onChange={onServiceDetailsChange}
			/>
		</CardContent>
	);
};

export default MessagingDetail;
